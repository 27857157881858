import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { dateFormat, timeFormat } from "../../utils/helpers";

const AppMobileInfoCard = ({
  firstLogin,
  lastAppOpened,
  appVersion,
  osVersion,
}) => {
  return (
    <Stack bgcolor="#F4F6F8" borderRadius="4px" padding="16px" gap="24px">
      <Stack gap="8px">
        <Typography variant="body1" fontWeight={500}>
          Mobile App Info{" "}
        </Typography>
        <Box display="flex" width="100%">
          <Box display="flex" alignItems="flex-start" width="50%">
            <Stack gap="4px">
              <Typography variant="body2" color="secondary">
                Signup On
              </Typography>
              <Typography variant="body2">
                {firstLogin ? dateFormat(firstLogin) : "-"}
              </Typography>
            </Stack>
          </Box>
          <Box display="flex" alignItems="flex-start" width="50%">
            <Stack gap="4px">
              <Typography variant="body2" color="secondary">
                Signup Time
              </Typography>
              <Typography variant="body2">
                {firstLogin ? timeFormat(firstLogin) : "-"}
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box display="flex" width="100%">
          <Box display="flex" alignItems="flex-start" width="50%">
            <Stack gap="4px">
              <Typography variant="body2" color="secondary">
                Last Open Date
              </Typography>
              <Typography variant="body2">
                {lastAppOpened ? dateFormat(lastAppOpened) : "-"}
              </Typography>
            </Stack>
          </Box>
          <Box display="flex" alignItems="flex-start" width="50%">
            <Stack gap="4px">
              <Typography variant="body2" color="secondary">
                Last Open Time
              </Typography>
              <Typography variant="body2">
                {" "}
                {lastAppOpened ? timeFormat(lastAppOpened) : "-"}
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box display="flex" width="100%">
          <Box display="flex" alignItems="flex-start" width="50%">
            <Stack gap="4px">
              <Typography variant="body2" color="secondary">
                Platform{" "}
              </Typography>
              <Typography variant="body2">
                {osVersion ? osVersion : "-"}
              </Typography>
            </Stack>
          </Box>
          <Box display="flex" alignItems="flex-start" width="50%">
            <Stack gap="4px">
              <Typography variant="body2" color="secondary">
                App Version{" "}
              </Typography>
              <Typography variant="body2">
                {" "}
                {appVersion ? appVersion : "-"}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default AppMobileInfoCard;
