import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import MccLogo from "../../assets/images/mcc-logo.png";
import BgBox from "../../common/BgBox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { statesWithCities } from "../../utils/helpers/states_with_cities";
import { useTheme } from "@emotion/react";
import VitatraxFooter from "../../components/VitatraxFooter";
import {
  clearContact,
  formatPhoneNumber,
  validateEmail,
} from "../../utils/helpers";
import PatientLogoHeader from "../../components/PatientLogoHeader";
import { useDispatch } from "react-redux";
import {
  addPatientDetailsFunc,
  resetPatientDetails,
} from "../../actions/Patients";
import { useSelector } from "react-redux";
import AlertMessage from "../../common/AlertMessage";
import { LoadingButton } from "@mui/lab";

const states = Object.keys(statesWithCities);

const PatientDetails = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const addPatientDetails = useSelector((state) => state.addPatientDetails);
  const { loading, error, patientDetails } = addPatientDetails;

  const agencyId = searchParams.get("agency_id");
  const agencyName = searchParams.get("agency_name");
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    dob: null,
    contactNumber: "",
    email: "",
    address: "",
    state: null,
    city: "",
    zipCode: "",
  });
  const [emailError, setEmailError] = useState(false);

  const [checkedTos, setCheckedTos] = useState(false);
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contactNumberHandler = (e) => {
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setFormValues({ ...formValues, contactNumber: formattedNumber });
  };

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;
    setFormValues({ ...formValues, email: emailInputValue });

    const emailIsValid = validateEmail(emailInputValue);
    if (emailInputValue && !emailIsValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorMessage(false);
  };

  const collectPatientDetailsHandler = () => {
    const patientData = {
      patient_obj: {
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        email: formValues.email,
        contact_number: formValues.contactNumber,
        dob: formValues.dob,
        address: formValues.address,
        city: formValues.city,
        region: formValues.state,
        zipcode: formValues.zipCode,
      },
      agency_id: agencyId,
    };

    dispatch(addPatientDetailsFunc(patientData));
  };

  useEffect(() => {
    if (patientDetails?.ok) {
      navigate(
        `/setup-password?agency_id=${agencyId}&agency_name=${agencyName}`,
        {
          state: {
            patientToken: patientDetails?.token,
            patientId: patientDetails?.patient?.id,
          },
        }
      );
      dispatch(resetPatientDetails());
    } else if (error) {
      setShowErrorMessage(true);
    }
  }, [patientDetails, error]);

  return (
    <>
      {showErrorMessage && (
        <AlertMessage
          message={error}
          severity="error"
          autoHideDuration={6000}
          open={showErrorMessage}
          handleClose={handleAlertClose}
        />
      )}
      <BgBox>
        <Stack
          width="100%"
          gap="8px"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <PatientLogoHeader
            title={`Welcome to ${agencyName}`}
            description="Please share your details to signup"
            mb="27px"
          />

          <Stack
            gap="24px"
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            padding="16px"
            borderRadius="8px"
            bgcolor="#fff"
            maxWidth="793px"
            width="100%"
          >
            <Stack gap="16px">
              <Typography variant="body1" color="#212121" fontWeight={500}>
                Practice Details
              </Typography>

              <Box display="flex" alignItems="center" width="100%" gap="16px">
                {/* FIRST NAME INPUT */}
                <TextField
                  fullWidth
                  id="firstName"
                  required
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  value={formValues?.firstName}
                  onChange={(e) => {
                    setFormValues({ ...formValues, firstName: e.target.value });
                  }}
                />

                {/* LAST NAME FIELD */}
                <TextField
                  fullWidth
                  required
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  value={formValues?.lastName}
                  onChange={(e) => {
                    setFormValues({ ...formValues, lastName: e.target.value });
                  }}
                />
              </Box>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  name="dob"
                  value={formValues?.dob}
                  // maxDate={dayjs()}
                  onChange={(newValue) =>
                    setFormValues({ ...formValues, dob: newValue })
                  }
                  disableFuture
                  onOpen={() => setIsPickerOpen(true)}
                  onClose={() => setIsPickerOpen(false)}
                  open={isPickerOpen}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      sx: {
                        "& .MuiIconButton-root": { display: "none" },
                      },

                      onClick: () => setIsPickerOpen(true),
                    },
                  }}
                />
              </LocalizationProvider>

              <TextField
                id="contactNumber"
                name="contactNumber"
                label="Phone Number"
                required
                variant="outlined"
                value={formValues?.contactNumber}
                onChange={contactNumberHandler}
                fullWidth
                // error={showPatientMessage}
                // helperText={
                //   showPatientMessage &&
                //   "Phone number already exists! Please try different phone number."
                // }
              />

              <TextField
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={formValues?.email}
                onChange={emailChangeHandler}
                error={emailError}
                helperText={emailError && "Please enter valid email"}
              />
            </Stack>

            <Stack gap="16px">
              <Typography variant="body1" color="#212121" fontWeight={500}>
                Address{" "}
              </Typography>
              <TextField
                id="address"
                label="Address"
                name="address"
                value={formValues?.address}
                onChange={(e) =>
                  setFormValues({ ...formValues, address: e.target.value })
                }
                variant="outlined"
                fullWidth
              />

              <Box display="flex" alignItems="center" gap="16px" width="100%">
                <Autocomplete
                  options={[]}
                  fullWidth
                  disabled
                  value={"United States"}
                  renderInput={(params) => (
                    <TextField
                      disabled
                      {...params}
                      label="State/Region"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                <Autocomplete
                  options={states}
                  fullWidth
                  value={formValues?.state}
                  onChange={(_, newValue) =>
                    setFormValues({ ...formValues, state: newValue })
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State/Region"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box display="flex" alignItems="center" gap="16px" width="100%">
                <TextField
                  id="city"
                  placeholder="San Mateo"
                  label="City"
                  name="city"
                  value={formValues?.city}
                  onChange={(e) =>
                    setFormValues({ ...formValues, city: e.target.value })
                  }
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  id="zipCode"
                  name="zipCode"
                  value={formValues?.zipCode}
                  onChange={(e) => {
                    if (/^\d*$/g.test(e.target.value)) {
                      setFormValues({ ...formValues, zipCode: e.target.value });
                    }
                  }}
                  label="Zip/Code"
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Stack>

            <Stack gap="16px">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ padding: "0 9px" }}
                    value={checkedTos}
                    onChange={(e) => setCheckedTos(e.target.checked)}
                  />
                }
                label={
                  <Typography variant="body2" fontSize="1rem">
                    I agree to the{" "}
                    <Box
                      component="a"
                      href="https://vitatrax.co/practice-terms-of-services/"
                      target="_blank"
                      color="#0C80F3"
                      sx={{ cursor: "pointer" }}
                    >
                      Terms of Service
                    </Box>
                  </Typography>
                }
              />
              <FormControlLabel
                sx={{ alignItems: "flex-start" }}
                control={
                  <Checkbox
                    sx={{ padding: "0 9px" }}
                    value={checkedPrivacy}
                    onChange={(e) => setCheckedPrivacy(e.target.checked)}
                  />
                }
                label={
                  <Typography variant="body2" fontSize="1rem">
                    I consent to participate in and the{" "}
                    <Box
                      component="a"
                      href="https://vitatrax.co/practice-terms-of-services/"
                      target="_blank"
                      color="#0C80F3"
                      sx={{ cursor: "pointer" }}
                    >
                      Remote Therapeutic Monitoring (RTM){" "}
                    </Box>
                    collection of health data as described in the
                    <Box
                      component="a"
                      href="https://vitatrax.co/privacy-policy/"
                      target="_blank"
                      color="#0C80F3"
                      sx={{ cursor: "pointer" }}
                    >
                      {" "}
                      Privacy Policy.
                    </Box>
                  </Typography>
                }
              />
            </Stack>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="16px"
            >
              <Button
                variant="contained"
                sx={{
                  minWidth: "174px",
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "118px",
                  },
                  padding: "8px 22px",
                  background: "#E0E0E0",
                  color: "#000",
                  "&:hover": { background: "#E0E0E0" },
                }}
                onClick={() =>
                  navigate(
                    `/practice_detail?agency_id=${agencyId}&agency_name=${agencyName}`
                  )
                }
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                disabled={
                  !formValues?.firstName ||
                  !formValues?.lastName ||
                  !formValues.contactNumber ||
                  !checkedTos ||
                  !checkedPrivacy ||
                  emailError
                }
                onClick={collectPatientDetailsHandler}
                variant="contained"
                sx={{
                  minWidth: "174px",
                  padding: "8px 22px",
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "118px",
                  },
                }}
              >
                Next
              </LoadingButton>
            </Box>
          </Stack>
        </Stack>
        <VitatraxFooter />
      </BgBox>
    </>
  );
};

export default PatientDetails;
