import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import CommonStoreButton from "../CommonStoreButton";
import PatientLogoHeader from "../PatientLogoHeader";

const DownloadApp = () => {
  const theme = useTheme();

  return (
    <>
      <Stack
        gap="24px"
        alignItems="center"
        bgcolor="#fff"
        p="24px 16px"
        borderRadius="8px"
        maxWidth="570px"
        width="100%"
        sx={{
          [theme.breakpoints.down("sm")]: {
            bgcolor: "transparent",
            paddingTop: "0",
          },
        }}
      >
        <Typography
          color="secondary"
          variant="body1"
          fontSize="20px"
          maxWidth="511px"
          width="100%"
          sx={{
            [theme.breakpoints.down("sm")]: {
              fontSize: "16px",
            },
          }}
          textAlign="center"
        >
          Please download VitaTrax app from the button below and use your phone
          number and the password you just created to login
        </Typography>
        <CommonStoreButton />
      </Stack>
    </>
  );
};

export default DownloadApp;
