import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Avatar,
  List,
  ListItemText,
  IconButton,
  ListItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  updateAgentTeamMembersFunc,
  getAgencyDetailFunc,
  resetUpdatedTeamMember,
  resetNewClientFunc,
  resetUpdateClientFunc,
  addNewClientFunc,
  resetPatientEmailFunc,
  getUnpaginatedAgentsListFunc,
  updateClientFunc,
} from "../../../actions/Agency";
import AlertMessage from "../../../common/AlertMessage";
import {
  addRecordConsentFunc,
  getAllPatientsFunc,
  getTabsCountFunc,
  reEnrollPatientFunc,
  resetRecordConsent,
  resetReEnrollPatient,
} from "../../../actions/Patients";
import { segmentError } from "../../../utils/helpers/tracking_utils";
import { LoadingButton } from "@mui/lab";
import { appInsights } from "../../../utils/helpers/AppInsights";
import Cookies from "js-cookie";

import {
  addDiagnosisCodeFunc,
  resetAddDiagnosisCode,
} from "../../../actions/DiagnosisActions";
import { stringAvatar } from "../../../utils/helpers";

const AssignTeamMemberStep = ({
  activeStep,
  setActiveStep,
  steps,
  closeStepperForm,
  formValues,
  diagnosisCodes,
  reEnroll,
  setIsFilledValues,
  selectedPatient,
  setOpenReEnrollMessage,
}) => {
  const [teamMemberArr, setTeamMemberArr] = useState([]);
  const [assignMemberError, setAssignMemberError] = useState(false);
  const [checkedConsent, setCheckedConsent] = useState(false);
  const [newPatientDetail, setNewPatientDetail] = useState(null);

  const cookieAgencyId = Cookies.get("agency_id_vita");

  const navigate = useNavigate();
  const listRef = useRef(null);

  const dispatch = useDispatch();
  const getUnpaginatedAgentLists = useSelector(
    (state) => state.getUnpaginatedAgentLists
  );
  const {
    loading,
    error,
    unPaginatedAgents: allAgents,
  } = getUnpaginatedAgentLists;

  const patientDetailByPatientId = useSelector(
    (state) => state.patientDetailByPatientId
  );
  const {
    loading: loadingPatientDetail,
    error: errorPatientDetail,
    patientDetail,
  } = patientDetailByPatientId;

  const patientListingState = useSelector((state) => state.patientListingState);
  const {
    page,
    pageSize,
    tab: activeTab,
    assignedTo,
    enrollmentStatus,
    educationGiven,
    consentStatusValue,
    query,
    cptCodeItems,
    sortedItems,
  } = patientListingState;

  const changedAgencyId = useSelector((state) => state.changedAgencyId);
  const { changed } = changedAgencyId;

  const addNewClient = useSelector((state) => state.addNewClient);
  const {
    loading: loadingNewClient,
    error: errorNewClient,
    newClient,
  } = addNewClient;

  const reEnrollPatient = useSelector((state) => state.reEnrollPatient);
  const {
    loading: loadingReEnrollPatient,
    error: errorReEnrollPatient,
    reEnrolledPatient,
  } = reEnrollPatient;

  const addDiagnosisCode = useSelector((state) => state.addDiagnosisCode);
  const {
    loading: loadingDiagnosisCode,
    error: errorDiagnosisCode,
    diagnosisCodeAdded,
  } = addDiagnosisCode;

  const updateAgentTeamMembers = useSelector(
    (state) => state.updateAgentTeamMembers
  );
  const {
    loading: loadingUpdateMembers,
    error: errorUpdateMembers,
    updatedAgentTeamMembers,
  } = updateAgentTeamMembers;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const addRecordConsent = useSelector((state) => state.addRecordConsent);
  const { loading: loadingConsent, consentRecorded } = addRecordConsent;

  const updateClient = useSelector((state) => state.updateClient);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    updatedClient,
  } = updateClient;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const teamMembersId = teamMemberArr.map((team) => team.id);

  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const agentOptions =
    allAgents?.agents_list.length > 0
      ? allAgents?.agents_list?.filter(
          (agent) => agent?.agent_type !== "mcc_support"
        )
      : [];

  const assignTeamMemberHandler = async () => {
    if (!teamMembersId?.length) {
      setAssignMemberError(true);
      return;
    }

    setAssignMemberError(false);

    let patientObj;

    if (reEnroll) {
      patientObj = {
        primary_insurance_provider_name: formValues?.primary_insurance_name,
        secondary_insurance_provider_name: formValues?.secondary_insurance_name,
        policy_number: formValues?.policy_number,
        group_number: formValues?.group_number,
        primary_policy_holder_name: formValues?.primary_policy_holder_name,
        primary_policy_holder_dob: formValues?.primary_dob?.utcOffset(0, true)
          ? formValues?.primary_dob
          : null,
        primary_policy_holder_relation: formValues?.policy_relation,
        primary_policy_holder_email: formValues?.primary_email,
        primary_policy_holder_contact: formValues?.primary_contact,
        medical_history: formValues?.medical_notes,
      };
    } else {
      patientObj = {
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        email: formValues.email,
        dob: formValues.dob ? formValues?.dob?.utcOffset(0, true) : null,
        contact_number: formValues.contactNumber,
        address: formValues.address,
        region: formValues.state,
        city: formValues.city,
        additional_info: formValues.notes,
        zipcode: formValues.zipCode,
        primary_insurance_provider_name: formValues?.primary_insurance_name,
        secondary_insurance_provider_name: formValues?.secondary_insurance_name,
        policy_number: formValues?.policy_number,
        group_number: formValues?.group_number,
        primary_policy_holder_name: formValues?.primary_policy_holder_name,
        primary_policy_holder_dob: formValues?.primary_dob?.utcOffset(0, true)
          ? formValues?.primary_dob
          : null,
        primary_policy_holder_relation: formValues?.policy_relation,
        primary_policy_holder_email: formValues?.primary_email,
        primary_policy_holder_contact: formValues?.primary_contact,
        medical_history: formValues?.medical_notes,
      };
    }

    if (reEnroll) {
      const newPatientData = {
        patient_id: selectedPatient?.id,
        patient_profile: patientObj,
      };
      dispatch(updateClientFunc(newPatientData));
    } else {
      const newPatientData = {
        agency_id: cookieAgencyId,
        send_credentials: true,
        patient_obj: patientObj,
      };
      dispatch(addNewClientFunc(newPatientData));
    }
  };

  useEffect(() => {
    if (newClient?.ok) {
      setNewPatientDetail(newClient?.patient);
      if (checkedConsent) {
        const recordObj = {
          patient_id: newClient?.patient?.id,
        };
        dispatch(addRecordConsentFunc(recordObj));
      } else {
        const diagnosisCodeObj = {
          agency_id: cookieAgencyId,
          patient_id: newClient?.patient?.id,
          diagnostic_codes: diagnosisCodes,
        };

        if (diagnosisCodes?.length) {
          dispatch(addDiagnosisCodeFunc(diagnosisCodeObj));
        } else {
          const assignTeamMemberData = {
            patient_id: newClient?.patient?.id,
            members: teamMembersId,
          };
          dispatch(updateAgentTeamMembersFunc(assignTeamMemberData));
        }
      }
    }
  }, [newClient]);

  useEffect(() => {
    if (updatedClient?.ok) {
      if (checkedConsent) {
        const recordObj = {
          patient_id: selectedPatient?.id,
        };
        dispatch(addRecordConsentFunc(recordObj));
      } else {
        const updatedDiagnosisCodes = diagnosisCodes?.filter(
          (item) => !item?.id
        );

        const diagnosisCodeObj = {
          agency_id: cookieAgencyId,
          patient_id: selectedPatient?.id,
          diagnostic_codes: updatedDiagnosisCodes,
        };

        if (updatedDiagnosisCodes?.length) {
          dispatch(addDiagnosisCodeFunc(diagnosisCodeObj));
        } else {
          const assignTeamMemberData = {
            patient_id: selectedPatient?.id,
            members: teamMembersId,
          };
          dispatch(updateAgentTeamMembersFunc(assignTeamMemberData));
        }
      }
    }
  }, [updatedClient]);

  useEffect(() => {
    if (consentRecorded?.ok) {
      const updatedDiagnosisCodes = diagnosisCodes?.filter((item) => !item?.id);

      const diagnosisCodeObj = {
        agency_id: cookieAgencyId,
        patient_id: reEnroll ? selectedPatient?.id : newPatientDetail?.id,
        diagnostic_codes: updatedDiagnosisCodes,
      };

      if (updatedDiagnosisCodes?.length) {
        dispatch(addDiagnosisCodeFunc(diagnosisCodeObj));
      } else {
        const assignTeamMemberData = {
          patient_id: reEnroll ? selectedPatient?.id : newPatientDetail?.id,
          members: teamMembersId,
        };
        dispatch(updateAgentTeamMembersFunc(assignTeamMemberData));
      }

      dispatch(resetRecordConsent());
    }
  }, [consentRecorded]);

  useEffect(() => {
    if (diagnosisCodeAdded?.ok) {
      const assignTeamMemberData = {
        patient_id: reEnroll ? selectedPatient?.id : newPatientDetail?.id,
        members: teamMembersId,
      };
      dispatch(updateAgentTeamMembersFunc(assignTeamMemberData));
    }
  }, [diagnosisCodeAdded]);

  useEffect(() => {
    if (updatedAgentTeamMembers?.ok) {
      if (reEnroll) {
        // HIT RE ENROLL API
        const patientObj = {
          patient_id: selectedPatient?.id,
        };
        dispatch(reEnrollPatientFunc(patientObj));
      } else {
        dispatch(resetUpdatedTeamMember());
        dispatch(resetNewClientFunc());
        dispatch(resetAddDiagnosisCode());
        // dispatch(getAllCasesFunc());
        dispatch(resetPatientEmailFunc());
        closeStepperForm();
        setIsFilledValues(false);
        navigate(`/patient/${newPatientDetail?.id}`);
      }
    } else if (errorUpdateMembers) {
      appInsights.trackTrace({
        message: `Error while adding agent team members in case: ${
          errorUpdateMembers || "Could not add agent team member in case!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_ADDING_AGENT_IN_CASE",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${
          errorUpdateMembers || "Could not add agent team member in case!"
        }`,
      });
    }
  }, [updatedAgentTeamMembers, errorUpdateMembers]);

  useEffect(() => {
    if (reEnrolledPatient?.ok) {
      dispatch(resetUpdatedTeamMember());
      dispatch(resetNewClientFunc());
      dispatch(resetAddDiagnosisCode());
      dispatch(resetUpdateClientFunc());
      dispatch(resetPatientEmailFunc());
      closeStepperForm();
      setIsFilledValues(false);
      dispatch(getTabsCountFunc());
      dispatch(resetReEnrollPatient());

      let listData = {
        agency_id: cookieAgencyId,
        page: page + 1,
        pageSize: pageSize,
        search: query,
        assigned_to: assignedTo,
        enrollment_status: enrollmentStatus,
        cpt_codes: cptCodeItems,
        sort: sortedItems,
        tab: activeTab,
      };

      if (educationGiven !== "all") {
        listData.education_given = educationGiven;
      }

      if (consentStatusValue !== "all") {
        listData.tracking_consent_given = consentStatusValue;
      }

      dispatch(getAllPatientsFunc(listData));

      navigate(`/patients?tab=unenrolled`);
    }
  }, [reEnrolledPatient]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (!agencyDetail) {
      dispatch(getAgencyDetailFunc());
    }

    dispatch(getUnpaginatedAgentsListFunc());
  }, [changed]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [teamMemberArr]);

  useEffect(() => {
    if (reEnroll) {
      setTeamMemberArr([...patientDetail?.patient?.assigned_to_details]);
    }
  }, [patientDetail]);

  return (
    <>
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}
      {!loadingUpdateMembers && errorUpdateMembers && (
        <AlertMessage
          message={errorUpdateMembers}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <DialogContent sx={{ padding: "32px !important" }}>
        <Stack gap="32px">
          <Stack gap="16px">
            <Typography
              color="secondary"
              variant="body1"
              fontWeight={500}
              textTransform="uppercase"
            >
              Key Information and Consent Acknowledgements
            </Typography>
            <Stack gap="12px">
              <Typography color="#13132B" variant="body1" fontWeight={500}>
                Terms of Service Agreement
              </Typography>
              <Typography variant="body1" color="#13132B">
                By proceeding, the patient acknowledges and agrees to the
                following:
              </Typography>
              <Stack
                component="ul"
                paddingLeft="24px"
                gap="4px"
                color="rgba(0, 0, 0, 0.60)"
              >
                <Box component="li">
                  The patient agrees to participate in Remote Therapeutic
                  Monitoring (RTM).
                </Box>
                <Box component="li">
                  The patient understands that there may be a copay associated
                  with RTM services.
                </Box>
                <Box component="li">
                  The patient understands that RTM can be canceled at any time.
                </Box>
                <Box component="li">
                  Information related to the patient's care may be shared with
                  other healthcare providers.
                </Box>
                <Box component="li">
                  Only one practitioner will be able to bill for RTM services.
                </Box>
              </Stack>
            </Stack>
            <FormControlLabel
              sx={{ marginRight: "0", flex: "1" }}
              control={
                <Checkbox
                  value={checkedConsent}
                  onChange={(e) => setCheckedConsent(e.target.checked)}
                />
              }
              label={
                <Typography variant="body1">
                  The patient has read and agrees to the{" "}
                  <Box
                    component="a"
                    color="#0C80F3"
                    sx={{ textDecoration: "underline" }}
                    href="https://vitatrax.co/terms-of-service/"
                    target="_blank"
                  >
                    {" "}
                    Terms of Service.{" "}
                  </Box>
                </Typography>
              }
            />
          </Stack>

          <Stack gap="16px">
            <Typography
              variant="h6"
              fontSize="16px"
              color="secondary"
              textTransform="uppercase"
            >
              Assign provider & care Coordinator
            </Typography>
            <Autocomplete
              multiple
              filterSelectedOptions
              disableClearable
              id="assign-team-member"
              renderTags={() => null}
              fullWidth
              value={teamMemberArr}
              onChange={(e, newValue) => setTeamMemberArr(newValue)}
              options={agentOptions}
              clearOnBlur={false}
              isOptionEqualToValue={(option, value) =>
                Number(option.id) === Number(value.id)
              }
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => {
                const optionKey = `${option?.id}-${option?.name}`;

                return (
                  <Box
                    key={optionKey}
                    component="li"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    {...props}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="8px"
                      maxWidth="326px"
                      width="100%"
                    >
                      {option.profile_picture_url ? (
                        <Avatar
                          src={
                            option.profile_picture_url &&
                            option.profile_picture_url
                          }
                        />
                      ) : (
                        <Avatar
                          {...stringAvatar(option?.name)}
                          sx={{
                            backgroundColor: option?.avatar_color,
                          }}
                        />
                      )}

                      <Typography id={`assignee-${option.name}`}>
                        {" "}
                        {option.name}
                      </Typography>
                    </Box>
                    <Typography>{option.role}</Typography>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="assign-team-member"
                  label="Search Provider"
                  helperText={
                    !teamMembersId.length &&
                    assignMemberError &&
                    "Please add team member"
                  }
                  error={!teamMembersId.length && assignMemberError}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "assign-team-member", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Stack>

          <Stack gap="16px">
            {teamMemberArr?.length > 0 && (
              <Typography variant="body2" color="secondary">
                Selected
              </Typography>
            )}
            <List>
              {teamMemberArr?.map((teamMember) => (
                <ListItem
                  key={teamMember.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: "#F4F6F8",
                    borderRadius: "8px",
                    mb: "16px",
                    padding: "16px",
                  }}
                >
                  <Box display="flex" alignItems="center" gap="8px">
                    {teamMember?.profile_picture_url ? (
                      <Avatar
                        src={teamMember?.profile_picture_url}
                        alt={teamMember?.name}
                      />
                    ) : (
                      <Avatar
                        {...stringAvatar(teamMember?.name)}
                        sx={{
                          backgroundColor: teamMember?.avatar_color,
                        }}
                      />
                    )}

                    <ListItemText primary={teamMember.name} />
                  </Box>

                  <Typography variant="body1" fontSize="14px">
                    {teamMember.email}
                  </Typography>

                  <Typography
                    variant="body1"
                    fontSize="14px"
                    textTransform="capitalize"
                  >
                    {teamMember.role}
                  </Typography>

                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setTeamMemberArr(() =>
                        teamMemberArr.filter((m) => m !== teamMember)
                      );
                    }}
                    sx={{
                      borderRadius: "0 !important",
                      fontSize: "13px",
                      color: "#2196F3",
                      fontWeight: "500",
                    }}
                  >
                    REMOVE
                  </IconButton>
                  <div ref={listRef} />
                </ListItem>
              ))}
            </List>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          padding: "16px 32px",
          boxShadow: "0px -2px 16px 0px rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="primary"
          onClick={handleBack}
          disabled={
            loadingNewClient ||
            loadingDiagnosisCode ||
            loadingUpdateMembers ||
            loadingConsent
          }
          sx={{ textTransform: "uppercase", fontSize: "15px" }}
        >
          Go back
        </Button>

        <LoadingButton
          id="new-case-final-submit"
          loading={
            loadingNewClient ||
            loadingDiagnosisCode ||
            loadingUpdateMembers ||
            loadingUpdate ||
            loadingReEnrollPatient
          }
          disabled={
            (reEnroll && !checkedConsent) ||
            !teamMembersId?.length ||
            loadingNewClient ||
            loadingDiagnosisCode ||
            loadingUpdateMembers
          }
          variant="contained"
          onClick={assignTeamMemberHandler}
        >
          {activeStep === steps.length - 1
            ? reEnroll
              ? "Re-enroll"
              : "Save & finish"
            : "Continue"}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AssignTeamMemberStep;
