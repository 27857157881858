import {
  ADD_NEW_CASE_FAIL,
  ADD_NEW_CASE_REQUEST,
  ADD_NEW_CASE_SUCCESS,
  ADD_NOTE_TO_CASE_FAIL,
  ADD_NOTE_TO_CASE_REQUEST,
  ADD_NOTE_TO_CASE_SUCCESS,
  ADD_QUESTION_TO_CASE_FAIL,
  ADD_QUESTION_TO_CASE_REQUEST,
  ADD_QUESTION_TO_CASE_SUCCESS,
  ADD_RECORD_CONSENT_FAIL,
  ADD_RECORD_CONSENT_REQUEST,
  ADD_RECORD_CONSENT_SUCCESS,
  DELETE_CASE_FAIL,
  DELETE_CASE_REQUEST,
  DELETE_CASE_SUCCESS,
  GENERATE_EVIDENCE_FAIL,
  GENERATE_EVIDENCE_REQUEST,
  GENERATE_EVIDENCE_SUCCESS,
  GET_AGENCY_PATIENTS_FAIL,
  GET_AGENCY_PATIENTS_REQUEST,
  GET_AGENCY_PATIENTS_SUCCESS,
  GET_ALL_CASES_FAIL,
  GET_ALL_CASES_REQUEST,
  GET_ALL_CASES_SUCCESS,
  GET_A_CASE_BY_CID_FAIL,
  GET_A_CASE_BY_CID_REQUEST,
  GET_A_CASE_BY_CID_SUCCESS,
  GET_CASE_BY_AGENT_FAIL,
  GET_CASE_BY_AGENT_REQUEST,
  GET_CASE_BY_AGENT_SUCCESS,
  GET_CASE_CLIENT_DOCUMENTS_FAIL,
  GET_CASE_CLIENT_DOCUMENTS_REQUEST,
  GET_CASE_CLIENT_DOCUMENTS_SUCCESS,
  GET_CHART_PAINLOGS_FAIL,
  GET_CHART_PAINLOGS_REQUEST,
  GET_CHART_PAINLOGS_SUCCESS,
  GET_CLIENT_PAINLOGS_FAIL,
  GET_CLIENT_PAINLOGS_REQUEST,
  GET_CLIENT_PAINLOGS_SUCCESS,
  GET_DASHBOARD_ALERTS_FAIL,
  GET_DASHBOARD_ALERTS_REQUEST,
  GET_DASHBOARD_ALERTS_SUCCESS,
  GET_DASHBOARD_ANALYTICS_FAIL,
  GET_DASHBOARD_ANALYTICS_REQUEST,
  GET_DASHBOARD_ANALYTICS_SUCCESS,
  GET_HIGHRISK_CASES_FAIL,
  GET_HIGHRISK_CASES_REQUEST,
  GET_HIGHRISK_CASES_SUCCESS,
  GET_NEXT_PATIENT_FAIL,
  GET_NEXT_PATIENT_REQUEST,
  GET_NEXT_PATIENT_SUCCESS,
  GET_PIE_CHART_STATS_FAIL,
  GET_PIE_CHART_STATS_REQUEST,
  GET_PIE_CHART_STATS_SUCCESS,
  GET_TABS_COUNT_FAIL,
  GET_TABS_COUNT_REQUEST,
  GET_TABS_COUNT_SUCCESS,
  GET_UNPAGINATED_CASES_FAIL,
  GET_UNPAGINATED_CASES_REQUEST,
  GET_UNPAGINATED_CASES_SUCCESS,
  GET_UNREAD_CHAT_MESSAGES_FAIL,
  GET_UNREAD_CHAT_MESSAGES_REQUEST,
  GET_UNREAD_CHAT_MESSAGES_SUCCESS,
  RESET_ADD_NOTE_TO_CASE,
  RESET_ADD_QUESTION_TO_CASE,
  RESET_ALL_CASES,
  RESET_A_CASE_BY_CID,
  RESET_CASE_CLIENTS_DOCUMENTS,
  RESET_DELETE_CASE,
  RESET_GET_AGENCY_PATIENTS,
  RESET_GET_TABS_COUNT,
  RESET_LISTING_STATES,
  RESET_NEXT_PATIENT,
  RESET_PIE_CHART_STATS,
  RESET_RECORD_CONSENT,
  RESET_UNENROLL_PATIENT,
  RESET_UNPAGINATED_CASES,
  SET_ASSIGNED_TO,
  SET_CPT_CODES_ITEMS,
  SET_ENROLLMENT_STATUS,
  SET_LISTING_TAB,
  SET_NEXT_PATIENT_OBJECT,
  SET_PAGE_NUMBER,
  SET_PAGE_SIZE,
  SET_QUERY,
  SET_SORTED_ITEMS,
  UNENROLL_PATIENT_FAIL,
  UNENROLL_PATIENT_REQUEST,
  UNENROLL_PATIENT_SUCCESS,
  UPDATE_CASE_FAIL,
  UPDATE_CASE_REQUEST,
  UPDATE_CASE_RESET,
  UPDATE_CASE_SUCCESS,
} from "../../assets/constants/caseConstants";

const initTabState = {
  tab: "all",
  page: 0,
  pageSize: 10,
  assignedTo: [],
  enrollmentStatus: "",
  query: "",
  cptCodeItems: [],
  sortedItems: "",
  nextPatientObject: {},
};

export const patientListingStateReducer = (state = initTabState, action) => {
  switch (action.type) {
    case SET_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_LISTING_TAB:
      return {
        ...state,
        tab: action.payload,
      };

    case SET_ASSIGNED_TO:
      return {
        ...state,
        assignedTo: action.payload,
      };

    case SET_ENROLLMENT_STATUS:
      return {
        ...state,
        enrollmentStatus: action.payload,
      };

    case SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case SET_CPT_CODES_ITEMS:
      return {
        ...state,
        cptCodeItems: action.payload,
      };

    case SET_SORTED_ITEMS:
      return {
        ...state,
        sortedItems: action.payload,
      };

    case SET_NEXT_PATIENT_OBJECT:
      return {
        ...state,
        nextPatientObject: action.payload,
      };

    case RESET_LISTING_STATES:
      return {
        tab: "all",
        page: 0,
        pageSize: 10,
        assignedTo: [],
        enrollmentStatus: "",
        query: "",
        cptCodeItems: [],
        sortedItems: "",
        nextPatientObject: {},
      };

    default:
      return { ...state };
  }
};

// GET ALL CASES REDUCER
export const getAllCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CASES_REQUEST:
      return { loading: true };
    case GET_ALL_CASES_SUCCESS:
      return { loading: false, cases: action.payload };
    case GET_ALL_CASES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ALL_CASES:
      return { loading: false, cases: [] };
    default:
      return state;
  }
};

// GET TABS COUNT REDUCER
export const getTabsCountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TABS_COUNT_REQUEST:
      return { loading: true };
    case GET_TABS_COUNT_SUCCESS:
      return { loading: false, tabsCount: action.payload };
    case GET_TABS_COUNT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_TABS_COUNT:
      return {};
    default:
      return state;
  }
};

// GET NEXT PATIENT REDUCER
export const getNextPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NEXT_PATIENT_REQUEST:
      return { loading: true };
    case GET_NEXT_PATIENT_SUCCESS:
      return { loading: false, nextPatientData: action.payload };
    case GET_NEXT_PATIENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_NEXT_PATIENT:
      return {};
    default:
      return state;
  }
};

// GET ALL PATIENTS BY AGENCY REDUCER
export const getAllPatientsByAgencyReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENCY_PATIENTS_REQUEST:
      return { loading: true, allAgencyPatients: [], error: null };
    case GET_AGENCY_PATIENTS_SUCCESS:
      return { loading: false, allAgencyPatients: action.payload, error: null };
    case GET_AGENCY_PATIENTS_FAIL:
      return { loading: false, error: action.payload, allAgencyPatients: [] };
    case RESET_GET_AGENCY_PATIENTS:
      return {};
    default:
      return state;
  }
};

// GET ALL UNPAGINATED CASES REDUCER
export const getUnpaginatedCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UNPAGINATED_CASES_REQUEST:
      return { loading: true, allUnpaginatedCases: [] };
    case GET_UNPAGINATED_CASES_SUCCESS:
      return { loading: false, allUnpaginatedCases: action.payload };
    case GET_UNPAGINATED_CASES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UNPAGINATED_CASES:
      return { loading: false, allUnpaginatedCases: [] };
    default:
      return state;
  }
};

// GET A CASE BY CID REDUCER
export const getCaseByCaseIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_A_CASE_BY_CID_REQUEST:
      return { loading: true };
    case GET_A_CASE_BY_CID_SUCCESS:
      return { loading: false, caseDetail: action.payload };
    case GET_A_CASE_BY_CID_FAIL:
      return { loading: false, error: action.payload };
    case RESET_A_CASE_BY_CID:
      return {};
    default:
      return state;
  }
};

// ADD RECORD CONSENT REDUCER
export const addRecordConsentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_RECORD_CONSENT_REQUEST:
      return { loading: true };
    case ADD_RECORD_CONSENT_SUCCESS:
      return { loading: false, consentRecorded: action.payload };
    case ADD_RECORD_CONSENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_RECORD_CONSENT:
      return {};
    default:
      return state;
  }
};

// UNENROLL PATIENT REDUCER
export const unEnrollPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case UNENROLL_PATIENT_REQUEST:
      return { loading: true };
    case UNENROLL_PATIENT_SUCCESS:
      return { loading: false, unEnrolledPatient: action.payload };
    case UNENROLL_PATIENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UNENROLL_PATIENT:
      return {};
    default:
      return state;
  }
};

// GET A CASE BY AGENT REDUCER
export const getCaseByAgentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CASE_BY_AGENT_REQUEST:
      return { loading: true };
    case GET_CASE_BY_AGENT_SUCCESS:
      return { loading: false, caseInfoByAgent: action.payload };
    case GET_CASE_BY_AGENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET ALL CASES REDUCER
export const updateCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CASE_REQUEST:
      return { loading: true };
    case UPDATE_CASE_SUCCESS:
      return { loading: false, updatedCase: action.payload };
    case UPDATE_CASE_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_CASE_RESET:
      return {};
    default:
      return state;
  }
};

// GET CLIENT PAINLOGS REDUCER
export const getClientPainlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENT_PAINLOGS_REQUEST:
      return { loading: true };
    case GET_CLIENT_PAINLOGS_SUCCESS:
      return { loading: false, painlogs: action.payload };
    case GET_CLIENT_PAINLOGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET CHART PAINLOGS REDUCER
export const getChartPainlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHART_PAINLOGS_REQUEST:
      return { loading: true };
    case GET_CHART_PAINLOGS_SUCCESS:
      return { loading: false, chartPainLogs: action.payload };
    case GET_CHART_PAINLOGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ADD/POST NEW CASE REDUCER
export const addNewCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NEW_CASE_REQUEST:
      return { loading: true };
    case ADD_NEW_CASE_SUCCESS:
      return { loading: false, newcase: action.payload };
    case ADD_NEW_CASE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DELETE CASE LISTING REDUCER
export const deleteCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CASE_REQUEST:
      return { loading: true };
    case DELETE_CASE_SUCCESS:
      return { loading: false, success: true };
    case DELETE_CASE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_CASE:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// GENERATE EVIDENCE PDF REDUCER
export const generateEvidenceReducer = (state = {}, action) => {
  switch (action.type) {
    case GENERATE_EVIDENCE_REQUEST:
      return { loading: true, error: null };
    case GENERATE_EVIDENCE_SUCCESS:
      return {
        loading: false,
        generatedReport: action.payload,
      };
    case GENERATE_EVIDENCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET CASE AND CLIENTS LEVEL DOCUMENTS REDUCER
export const getCaseClientDocumentsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CASE_CLIENT_DOCUMENTS_REQUEST:
      return { loading: true };
    case GET_CASE_CLIENT_DOCUMENTS_SUCCESS:
      return { loading: false, caseClientDocs: action.payload };
    case GET_CASE_CLIENT_DOCUMENTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CASE_CLIENTS_DOCUMENTS:
      return {};
    default:
      return state;
  }
};

// GET DASHBOARD ANALYTICS REDUCER
export const getDashboardAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ANALYTICS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_DASHBOARD_ANALYTICS_SUCCESS:
      return { ...state, loading: false, dashboardAnalytic: action.payload };
    case GET_DASHBOARD_ANALYTICS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET DASHBOARD ALERTS REDUCER
export const getDashboardAlertsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ALERTS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_DASHBOARD_ALERTS_SUCCESS:
      return { ...state, loading: false, dashboardAlert: action.payload };
    case GET_DASHBOARD_ALERTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET HIGH RISK CASES REDUCER
export const getHighRiskCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HIGHRISK_CASES_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_HIGHRISK_CASES_SUCCESS:
      return {
        ...state,
        loading: false,
        highRiskCases: action.payload,
        error: null,
      };
    case GET_HIGHRISK_CASES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET UNREAD CHAT MESSAGES REDUCER
export const getUnreadChatMessagesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UNREAD_CHAT_MESSAGES_REQUEST:
      return { loading: true, error: null };
    case GET_UNREAD_CHAT_MESSAGES_SUCCESS:
      return {
        loading: false,
        unreadChatMessages: action.payload,
        error: null,
      };
    case GET_UNREAD_CHAT_MESSAGES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET PIE CHART STATS REDUCER
export const getPieChartStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PIE_CHART_STATS_REQUEST:
      return { loading: true, error: null };
    case GET_PIE_CHART_STATS_SUCCESS:
      return {
        loading: false,
        pieChartStats: action.payload,
        error: null,
      };
    case GET_PIE_CHART_STATS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_PIE_CHART_STATS:
      return { loading: false, error: null, pieChartStats: {} };
    default:
      return state;
  }
};

// ADD QUESTION TO CASE REDUCER
export const addQuestionToCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_QUESTION_TO_CASE_REQUEST:
      return { loading: true };
    case ADD_QUESTION_TO_CASE_SUCCESS:
      return { loading: false, addedQuestion: action.payload };
    case ADD_QUESTION_TO_CASE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ADD_QUESTION_TO_CASE:
      return {};
    default:
      return state;
  }
};

// ADDNOTE TO CASE REDUCER
export const addNoteToCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NOTE_TO_CASE_REQUEST:
      return { loading: true };
    case ADD_NOTE_TO_CASE_SUCCESS:
      return { loading: false, addedNote: action.payload };
    case ADD_NOTE_TO_CASE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ADD_NOTE_TO_CASE:
      return {};
    default:
      return state;
  }
};
