import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { TimerIcon } from "../../assets/constants";
import WaitingClockIcon from "../../icons/WaitingClockIcon";
import DateTimeCard from "../DateTimeCard";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import dayjs from "dayjs";
import { formatElapsedTime, getLogTypeValues } from "../../utils/helpers";

const formatDate = (date) => dayjs(date)?.format("MMM D, YYYY");

const TimeEntriesComponent = ({
  openTimeDrawerHandler,
  groupedEntries,
  deleteTimeEntryHandler,
  showMoreVertIcon = true,
  patientDetail,
}) => {
  const sortedDates = Object.keys(groupedEntries)?.sort((a, b) =>
    dayjs(b)?.diff(dayjs(a))
  );

  return (
    <>
      {sortedDates?.map((date, index) => (
        <React.Fragment key={index}>
          {" "}
          <Stack gap="8px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" fontWeight={500} color="secondary">
                {date === dayjs()?.format("YYYY-MM-DD")
                  ? `Today - ${formatDate(date)}`
                  : date === dayjs()?.subtract(1, "day").format("YYYY-MM-DD")
                  ? `Yesterday - ${formatDate(date)}`
                  : formatDate(date)}
              </Typography>
              <Box display="flex" alignItems="center" gap="12px">
                {showMoreVertIcon &&
                  patientDetail?.enrollment_status === "enrolled" && (
                    <Button
                      variant="text"
                      startIcon={<AddRoundedIcon />}
                      onClick={() => openTimeDrawerHandler(date)}
                    >
                      Add
                    </Button>
                  )}
                <Box display="flex" alignItems="center" gap="8px">
                  <TimerIcon />
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    color="secondary"
                  >
                    {groupedEntries[date]?.entries?.length}{" "}
                    {groupedEntries[date]?.entries?.length === 1
                      ? "Log"
                      : "Logs"}
                  </Typography>
                </Box>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ height: "16px" }}
                />

                <Box display="flex" alignItems="center" gap="8px">
                  <WaitingClockIcon />
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    color="secondary"
                  >
                    {formatElapsedTime(groupedEntries[date]?.totalTime * 1000)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {groupedEntries[date]?.entries?.map((item, index) => (
              <DateTimeCard
                key={index}
                heading={item?.category}
                id={index}
                agentId={item?.agent_id}
                timeEntryId={item?.id}
                startTime={item.start_time}
                endTime={item.end_time}
                elapsedTime={item?.time_in_seconds}
                notesValue={item?.notes}
                deleteTimeEntryHandler={deleteTimeEntryHandler}
                timeDetails={item}
                openTimeDrawerHandler={openTimeDrawerHandler}
                showMoreVertIcon={showMoreVertIcon}
              />
            ))}
          </Stack>
          <Divider />
        </React.Fragment>
      ))}
    </>
  );
};

export default TimeEntriesComponent;
