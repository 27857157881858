import React from "react";

const PatientVector = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="34"
      viewBox="0 0 67 34"
      fill="none"
    >
      <path
        d="M2 30.1178L3.3125 29.8049C4.625 29.4921 7.25 28.8664 9.875 29.416C12.5 29.9656 15.125 31.6905 17.75 31.9637C20.375 32.2368 23 31.0581 25.625 25.4164C28.25 19.7747 30.875 9.66993 33.5 5.11852C36.125 0.567115 38.75 1.56904 41.375 4.48672C44 7.40439 46.625 12.2378 49.25 15.5287C51.875 18.8196 54.5 20.5679 57.125 18.498C59.75 16.4281 62.375 10.5401 63.6875 7.59602L65 4.65199"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PatientVector;
