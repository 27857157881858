import { Alert, Box, Snackbar, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { getPatientReportsFunc } from "../../../actions/Patients";
import { useSelector } from "react-redux";
import EvidenceReportCard from "../../../components/EvidenceReportCard";
import RectangleSkeletons from "../../../components/RectangleSkeletons";
import EmptyBox from "../../../assets/images/empty-box.png";
import DownloadSelectedReportButton from "../../../components/DownloadSelectedReportButton";

const EvidenceOfCare = React.memo(({ patientDetail }) => {
  const dispatch = useDispatch();

  const [selectedReports, setSelectedReports] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState(null);

  const getPatientReports = useSelector((state) => state.getPatientReports);
  const { loading: loadingReports, error, patientReports } = getPatientReports;

  const patientId = patientDetail?.patient?.id;
  const patientName = patientDetail?.patient?.name;

  useEffect(() => {
    if (patientId) {
      dispatch(getPatientReportsFunc(patientId));
    }
  }, [patientDetail]);

  const closeSnackbarHandler = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const checkedReportHandler = (item) => {
    setSelectedReports((prevState) =>
      prevState.some((i) => i?.id === item?.id)
        ? prevState?.filter((i) => i?.id !== item?.id)
        : [...prevState, item]
    );
  };

  const downloadReportHandler = async (item) => {
    try {
      const response = await fetch(item?.download_url, {
        method: "GET",
        mode: "cors",
      });

      if (!response?.ok) {
        throw new Error("Failed to download");
      }

      const timestamp = `${item?.month} ${item?.year}`;

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${patientName} - ${timestamp}`;
      document.body.appendChild(link);
      link.click();
      setOpenSnackbar(false);

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  return (
    <>
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          onClose={closeSnackbarHandler}
          anchorOrigin={{
            vertical: snackbarData?.vertical || "bottom",
            horizontal: snackbarData?.horizontal || "right",
          }}
        >
          <Alert
            onClose={closeSnackbarHandler}
            severity={snackbarData.severity}
            color={snackbarData.color}
            sx={{ width: "100%" }}
          >
            {snackbarData.textContent}
          </Alert>
        </Snackbar>
      )}

      <Stack
        height="calc(100vh - 370px)"
        width="100%"
        padding="24px"
        border="1px solid #EBEBEB"
        borderRadius="8px"
        gap="16px"
        bgcolor="#fff"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="16px"
        >
          <Typography
            variant="body1"
            color="secondary"
            fontWeight={500}
            textTransform="uppercase"
          >
            Evidence of Care
          </Typography>
          <DownloadSelectedReportButton
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarData={setSnackbarData}
            selectedReports={selectedReports}
            patientName={patientName}
          />
          {/* {selectedReports?.length ? (
            <Button
              onClick={downloadSelectedReportsHandler}
              sx={{ fontSize: "13px" }}
              variant="contained"
              color="primary"
            >
              Download Selected
            </Button>
          ) : (
            <></>
          )} */}
        </Box>
        <Stack gap="8px" height="100%">
          {loadingReports ? (
            <RectangleSkeletons totalRows={3} />
          ) : patientReports?.reports?.length ? (
            patientReports?.reports?.map((item, index) => (
              <EvidenceReportCard
                key={index}
                date={item?.created_at}
                downloadUrl={item?.download_url}
                selectedReports={selectedReports}
                id={item?.id}
                item={item}
                onToggleReport={checkedReportHandler}
                downloadReportHandler={downloadReportHandler}
                patientName={patientName}
              />
            ))
          ) : (
            <Stack
              gap="16px"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Box width="105px">
                <img src={EmptyBox} alt="empty box" width="100%" />
              </Box>
              <Typography variant="body2" color="rgba(0, 0, 0, 0.87)">
                Evidence of Care reports will be automatically generated at the
                end of the month.
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
});

export default EvidenceOfCare;
