import React from "react";

const PhoneStatusIcon = ({ color = "#0D0D0D" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
    >
      <path
        d="M17 6.5V16.5C17 20.5 16 21.5 12 21.5H6C2 21.5 1 20.5 1 16.5V6.5C1 2.5 2 1.5 6 1.5H12C16 1.5 17 2.5 17 6.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5H7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0002 18.6C9.85624 18.6 10.5502 17.906 10.5502 17.05C10.5502 16.194 9.85624 15.5 9.0002 15.5C8.14415 15.5 7.4502 16.194 7.4502 17.05C7.4502 17.906 8.14415 18.6 9.0002 18.6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneStatusIcon;
