import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Snackbar,
  Typography,
} from "@mui/material";
import React from "react";

const CustomWarningModal = ({
  width = "501px",
  open = false,
  closeWarningModalHandler,
  warningModalContent,
  proceedHandler,
  isLoading,
}) => {
  return (
    <Snackbar
      sx={{ width: width && width }}
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      //   onClose={handleClose}
    >
      <Alert severity="warning">
        <AlertTitle>{warningModalContent?.title}</AlertTitle>
        <Typography variant="body2" pb="8px">
          {" "}
          {warningModalContent?.subtitle}
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="text"
            color="inherit"
            onClick={closeWarningModalHandler}
          >
            CANCEL
          </Button>
          <LoadingButton
            variant="text"
            sx={{ color: "inherit" }}
            onClick={proceedHandler}
            loading={isLoading}
          >
            PROCEED
          </LoadingButton>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default CustomWarningModal;
