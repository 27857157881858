import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import { amPmOptions, hoursOptions, minutesOptions } from "../../utils/helpers";

const CustomTimerDropdown = ({
  startTimeOption,
  setStartTimeOption,
  endTimeOption,
  setEndTimeOption,
  endTimeError,
  setEndTimeError,
  setChangedTime,
  setSelectedQuickTimeValue,
}) => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      gap="24px"
      width="100%"
      paddingBottom="4px"
    >
      <Stack sx={{ width: "48%" }}>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.56)">
          Start Time
        </Typography>
        <Box display="flex" alignItems="center" gap="3px">
          {/* <Box minWidth="25px">
        <Autocomplete
          id="start-time"
          options={hoursOptions}
          disableClearable
          // defaultValue={allBodyPartsData}
          value={startTimeOption?.hours}
          // onChange={(e, value) => {
          //   if (value) {
          //     setBodyPartsValue(value);
          //   }
          // }}
          size="small"
          fullWidth
          getOptionLabel={(option) => option}
          // filterSelectedOptions
          renderInput={(params) => (
            <TextField
              // error={dateFieldIsTouched && !bodyPartsValue?.length}
              size="small"
              fullWidth
              onChange={hoursChangeHandler}
              sx={{ textAlign: "center" }}
              {...params}
              InputProps={{
                ...params.InputProps,
                maxLength: 2,
                pattern: "[0-1][0-9]",
              }}
            />
          )}
        />
      </Box> */}
          {/* <Box minWidth="25px">
        <Autocomplete
          freeSolo
          id="start-time"
          options={minutesOptions}
          disableClearable
          // defaultValue={allBodyPartsData}
          value={1}
          // onChange={(e, value) => {
          //   if (value) {
          //     setBodyPartsValue(value);
          //   }
          // }}
          size="small"
          fullWidth
          getOptionLabel={(option) => option}
          // filterSelectedOptions
          renderInput={(params) => (
            <TextField
              // error={dateFieldIsTouched && !bodyPartsValue?.length}
              size="small"
              fullWidth
              sx={{ textAlign: "center" }}
              {...params}
              InputProps={{
                ...params.InputProps,
                maxLength: 2,
                pattern: "[0-1][0-9]",
              }}
            />
          )}
        />
      </Box> */}
          <CustomSelectFilter
            height={300}
            menuItems={hoursOptions}
            changeSelectValueHandler={(e) => {
              setChangedTime(true);
              setEndTimeError(false);
              setSelectedQuickTimeValue(null);
              setStartTimeOption((prev) => ({
                ...prev,
                hours: e.target.value,
              }));
            }}
            selectValue={startTimeOption?.hours}
            id="ampm"
            // minWidth={65}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 65 * 4.5 + 8,
                },
              },
            }}
          />
          <Typography variant="body2">:</Typography>
          <CustomSelectFilter
            height={300}
            menuItems={minutesOptions}
            changeSelectValueHandler={(e) => {
              setChangedTime(true);
              setEndTimeError(false);
              setSelectedQuickTimeValue(null);
              setStartTimeOption((prev) => ({
                ...prev,
                minutes: e.target.value,
              }));
            }}
            selectValue={startTimeOption?.minutes}
            id="ampm"
            // minWidth={65}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 65 * 4.5 + 8,
                },
              },
            }}
          />
          <CustomSelectFilter
            menuItems={amPmOptions}
            changeSelectValueHandler={(e) => {
              setChangedTime(true);
              setEndTimeError(false);
              setSelectedQuickTimeValue(null);
              setStartTimeOption((prev) => ({
                ...prev,
                amPm: e.target.value,
              }));
            }}
            selectValue={startTimeOption?.amPm}
            id="ampm"
            // minWidth={71}
            size="small"
          />
          {/* <Autocomplete
          id="start-time"
          options={["AM", "PM"]}
          disableClearable
          // defaultValue={allBodyPartsData}
          value={startTimeOption?.amPm}
          onChange={(_, value) =>
            setStartTimeOption((prev) => ({ ...prev, amPm: value }))
          }
          size="small"
          fullWidth
          getOptionLabel={(option) => option}
          onInputChange={(_, inputValue) => {
            const upperInput = inputValue?.toUpperCase();

            if (upperInput === "A" || upperInput === "AM") {
              setStartTimeOption((prev) => ({
                ...prev,
                amPm: "AM",
              }));
            } else if (upperInput === "P" || upperInput === "PM") {
              setStartTimeOption((prev) => ({
                ...prev,
                amPm: "PM",
              }));
            } else {
              setStartTimeOption((prev) => ({
                ...prev,
                amPm: "",
              }));
            }
          }}
          renderInput={(params) => (
            <TextField
              // error={dateFieldIsTouched && !bodyPartsValue?.length}
              size="small"
              fullWidth
              sx={{ textAlign: "center" }}
              {...params}
              InputProps={{
                ...params.InputProps,
                maxLength: 2,
              }}
            />
          )}
        /> */}
        </Box>

        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Start Time"
        value={startTimeValue}
        viewRenderers={{
          hours: renderDigitalClockTimeView,
          minutes: renderDigitalClockTimeView,
        }}
        onChange={(newValue) => {
          setSelectedQuickTimeValue(null);
          setStartTimeValue(newValue);
          setChangedTime(true);
        }}
        onOpen={() => setOpenStartTimePicker(true)}
        onClose={() => setOpenStartTimePicker(false)}
        open={openStartTimePicker}
        slotProps={{
          textField: {
            fullWidth: true,
            error: false,
            onClick: () => setOpenStartTimePicker(true),
          },
        }}
        timeSteps={{ minutes: 1 }}

        // slotProps={{ textField: { size: "small" } }}
      />
    </LocalizationProvider> */}
      </Stack>
      <Stack sx={{ width: "48%" }}>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.56)">
          End Time
        </Typography>
        <Box display="flex" alignItems="center" gap="3px">
          <CustomSelectFilter
            height={300}
            menuItems={hoursOptions}
            changeSelectValueHandler={(e) => {
              setChangedTime(true);
              setSelectedQuickTimeValue(null);
              setEndTimeError(false);
              setEndTimeOption((prev) => ({
                ...prev,
                hours: e.target.value,
              }));
            }}
            selectValue={endTimeOption?.hours}
            id="ampm"
            // minWidth={65}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 65 * 4.5 + 8,
                },
              },
            }}
          />
          <Typography variant="body2">:</Typography>
          <CustomSelectFilter
            height={300}
            menuItems={minutesOptions}
            changeSelectValueHandler={(e) => {
              setChangedTime(true);
              setSelectedQuickTimeValue(null);
              setEndTimeError(false);
              setEndTimeOption((prev) => ({
                ...prev,
                minutes: e.target.value,
              }));
            }}
            selectValue={endTimeOption?.minutes}
            id="ampm"
            // minWidth={65}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 65 * 4.5 + 8,
                },
              },
            }}
          />
          <CustomSelectFilter
            menuItems={amPmOptions}
            changeSelectValueHandler={(e) => {
              setChangedTime(true);
              setEndTimeError(false);
              setSelectedQuickTimeValue(null);
              setEndTimeOption((prev) => ({
                ...prev,
                amPm: e.target.value,
              }));
            }}
            selectValue={endTimeOption?.amPm}
            id="ampm"
            // minWidth={71}
            size="small"
          />
          {/* <Autocomplete
          id="start-time"
          options={["AM", "PM"]}
          disableClearable
          // defaultValue={allBodyPartsData}
          value={startTimeOption?.amPm}
          onChange={(_, value) =>
            setStartTimeOption((prev) => ({ ...prev, amPm: value }))
          }
          size="small"
          fullWidth
          getOptionLabel={(option) => option}
          onInputChange={(_, inputValue) => {
            const upperInput = inputValue?.toUpperCase();

            if (upperInput === "A" || upperInput === "AM") {
              setStartTimeOption((prev) => ({
                ...prev,
                amPm: "AM",
              }));
            } else if (upperInput === "P" || upperInput === "PM") {
              setStartTimeOption((prev) => ({
                ...prev,
                amPm: "PM",
              }));
            } else {
              setStartTimeOption((prev) => ({
                ...prev,
                amPm: "",
              }));
            }
          }}
          renderInput={(params) => (
            <TextField
              // error={dateFieldIsTouched && !bodyPartsValue?.length}
              size="small"
              fullWidth
              sx={{ textAlign: "center" }}
              {...params}
              InputProps={{
                ...params.InputProps,
                maxLength: 2,
              }}
            />
          )}
        /> */}
        </Box>
        {endTimeError && (
          <Typography
            width="80%"
            padding="3px 14px"
            variant="caption"
            color="#D32F2F"
          >
            End time cannot be earlier than start time.
          </Typography>
        )}
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="End Time"
        value={endTimeValue}
        viewRenderers={{
          hours: renderDigitalClockTimeView,
          minutes: renderDigitalClockTimeView,
        }}
        onChange={(newValue) => {
          setSelectedQuickTimeValue(null);
          setEndTimeError(false);
          setEndTimeValue(newValue);
          setChangedTime(true);
        }}
        onOpen={() => setOpenEndTimePicker(true)}
        onClose={() => setOpenEndTimePicker(false)}
        open={openEndTimePicker}
        slotProps={{
          textField: {
            fullWidth: true,
            error: false,
            onClick: () => setOpenEndTimePicker(true),
            error: endTimeError,
            helperText: endTimeError
              ? "End time cannot be earlier than start time."
              : "",
            sx: endTimeError
              ? {
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#D32F2F",
                    },
                  },
                  "& .MuiFormLabel-root.Mui-error": {
                    color: "#D32F2F",
                  },
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "#D32F2F",
                  },
                }
              : {},
          },
        }}
        timeSteps={{ minutes: 1 }}
      />
    </LocalizationProvider> */}
      </Stack>
    </Box>
  );
};

export default CustomTimerDropdown;
