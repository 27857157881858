import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Stack,
  Avatar,
  IconButton,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { ChatClient } from "@azure/communication-chat";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import Cookies from "js-cookie";

import CardUI from "../../../common/Card";
import HeaderText from "../../../common/HeaderText";
import ProfilePicture from "../../../common/ProfilePicture";
import {
  DeleteIcon,
  DoubleCheckIcon,
  SendIcon,
} from "../../../assets/constants";
import CircularProgressLoading from "../../../common/CircularProgress";
import {
  segmentError,
  segmentTrack,
} from "../../../utils/helpers/tracking_utils";
import { useDispatch, useSelector } from "react-redux";
import { getAgentProfile, getAgencyDetailFunc } from "../../../actions/Agency";
import axios from "axios";
import AlertMessage from "../../../common/AlertMessage";
import axiosRetry from "axios-retry";
import SkeletonGrid from "../../../components/SkeletonGrid";
import ChatSkeleton from "../../../components/ChatSkeleton";
import ChatProfileSkeleton from "../../../components/ChatProfileSkeleton";
import { appInsights } from "../../../utils/helpers/AppInsights";
import { onlyDateFormat } from "../../../utils/helpers";
import ChatTickIcon from "../../../icons/ChatTickIcon";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import FailedChatIcon from "../../../icons/FailedChatIcon";
import NetworkStatusBanner from "../../../components/NetworkStatusBanner";
import ChatSeenIcon from "../../../icons/ChatSeenIcon";
import ScrollableFeed from "react-scrollable-feed";

axiosRetry(axios, {
  retries: 3,
  retryCondition: (error) => {
    return error?.response?.status >= 500 || error?.code === "ECONNABORTED";
  },
});

const ChatTab = React.memo(({ caseDetail, loadingCaseDetail }) => {
  const chatWindowRef = useRef(null);
  const location = useLocation();

  let prevDate = null;

  const [errorMsg, setErrorMsg] = useState(null);
  const [chatClient, setChatClient] = useState(null);
  const [chatThreadClient, setChatThreadClient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [displayName, setDisplayName] = useState("");
  const [toastMessage, setToastMessage] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [clientIsTyping, setClientIsTyping] = useState(false);
  const [message, setMessage] = useState("");
  const [azureFailed, setAzureFailed] = useState(false);
  const [backendFailed, setBackendFailed] = useState(false);
  const [showAlertBanner, setShowAlertBanner] = useState(false);
  const [endpointUrlState, setEndpointUrlState] = useState(false);
  const [userAccessTokenState, setUserAccessTokenState] = useState(false);
  const [retryTimes, setRetryTimes] = useState(0);
  const [showWaitingBanner, setShowWaitingBanner] = useState(false);
  const [failedToStore, setFailedToStore] = useState(false);
  const [userAtBottom, setUserAtBottom] = useState(true);
  const [unreadMessageLength, setUnreadMessageLength] = useState(0);
  const maxRetryTimes = 3;

  const messagesRef = useRef(messages); // Create a ref to track messages

  const agentToken = Cookies.get("token");
  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading, error, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const threadID = caseDetail?.patient?.comm_thread_id;
  const agencyId = Cookies.get("agency_id_vita");
  const pulseInterval = 30000;

  var reconnectAttempts = 0;
  const maxReconnectAttempts = 3;

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // Sync messagesRef whenever messages state updates
  useEffect(() => {
    const uniqueMessages = Array.from(
      new Map(messages.map((message) => [message.id, message])).values()
    );

    if (uniqueMessages.length !== messages.length) {
      setMessages(uniqueMessages);
    }

    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (retryTimes >= maxRetryTimes) {
      setFailedToStore(true);
    }
  }, [retryTimes]);

  const includeAgent = async () => {
    appInsights.trackTrace({ message: "Including agent function called!" });
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${agentToken}`,
        },
      };

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/chat/add-to-thread`,
        { threadId: threadID, patientId: caseDetail?.patient?.id },
        config
      );

      // segment logs + AI traces but only for error
      if (!res?.data?.ok) {
        setMessagesLoading(false);
        appInsights.trackTrace({
          message: `Error while adding agent in chat ${
            res?.data?.message || "Could not add agent in chat message!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_CHAT_ADD_TO_THREAD",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: res?.data?.message || "Could not add agent in chat message!",
        });
      }

      appInsights.trackTrace({ message: "Agent included in chat thread!" });
    } catch (error) {
      setMessagesLoading(false);
      console.log(error);
      appInsights.trackTrace({
        message: `Error while adding agent in chat ${error}`,
      });
      segmentError({
        event: "ERR_BACKEND_INCLUDING_AGENT_IN_CHAT",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: error || `Error while adding agent in chat ${error}!`,
      });
      setErrorMsg(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const fetchTokens = async () => {
    try {
      appInsights.trackTrace({
        message: "Inside fetch tokens function",
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${agentToken}`,
        },
      };

      const endpoint_response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/chat/get-endpoint-url`,
        config
      );

      // segment logs + AI traces but only for error
      if (!endpoint_response?.data?.ok) {
        setMessagesLoading(false);
        appInsights.trackTrace({
          message: `Error while getting chat endpoint url ${
            endpoint_response?.data?.message ||
            "Could not get chat endpoint url!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_GET_CHAT_ENDPOINT_URL",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${
            endpoint_response?.data?.message ||
            "Could not get chat endpoint url!"
          }`,
        });
      }

      appInsights.trackTrace({
        message: "Getting the chat endpoint url on frontend!",
      });

      const token_response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/chat/get-token`,
        config
      );

      // segment logs + AI traces but only for error
      if (!token_response?.data?.ok) {
        appInsights.trackTrace({
          message: `Error while getting chat token ${
            token_response?.data?.message || "Could not get chat token!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_GETTING_CHAT_TOKEN",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: ` ${
            token_response?.data?.message || "Could not get chat token!"
          }`,
        });
        setMessagesLoading(false);
      }

      appInsights.trackTrace({
        message: "Get Chat token received!",
      });

      const endpointURL = endpoint_response?.data?.endpointURL;
      const userAccessToken = token_response?.data?.token;

      setUserAccessTokenState(userAccessToken);
      setEndpointUrlState(endpointURL);

      const chatClient = new ChatClient(
        endpointURL,
        new AzureCommunicationTokenCredential(userAccessToken)
      );
      const chatThreadClient = chatClient.getChatThreadClient(threadID);
      setChatClient(chatClient);
      setChatThreadClient(chatThreadClient);

      startPulse(chatClient, chatThreadClient);
      handleDisconnect(chatClient);

      // setMessagesLoading(false);

      appInsights.trackTrace({
        message: "Chat client is set in chatClient state!",
      });
      appInsights.trackTrace({
        message: "Chat thread client is set in chatThreadClient state!",
      });
    } catch (error) {
      setShowAlertBanner(true);
      setShowWaitingBanner(true);
      setMessagesLoading(false);
    }
  };

  useEffect(() => {
    if (agentToken) {
      appInsights.trackTrace({
        message: "getting agent token and messages!",
      });
      // setMessagesLoading(true);
      dispatch(getAgentProfile());
      dispatch(getAgencyDetailFunc());
    }
  }, [agentToken]);

  let pulseTimer = null;
  useEffect(() => {
    if (threadID) {
      appInsights.trackTrace({
        message: "Getting the list of messages!",
      });
      // setMessagesLoading(true);
      fetchTokens();
    }

    return () => {
      clearInterval(pulseTimer);
    };
  }, [threadID]);

  // Function to handle reconnection with exponential backoff
  const handleReconnection = (client, threadClient) => {
    if (reconnectAttempts >= maxReconnectAttempts) {
      console.error(
        "Max reconnection attempts reached. Please check the connection."
      ); // SHOW CHAT ALERT/BANNER
      setShowAlertBanner(true);
      setShowWaitingBanner(false);
      setMessagesLoading(false);
      appInsights.trackTrace({
        message:
          "Max reconnection attempts reached. Please check the connection.",
      });
      return;
    }

    // SHOW REFRESHING BANNER
    setShowWaitingBanner(true);
    setShowAlertBanner(false);

    reconnectAttempts++;

    const reconnectDelay = Math.min(reconnectAttempts * 1000, 16000); // Exponential backoff

    setTimeout(async () => {
      try {
        // suggestion: re-initialize chatClient & chatThreadClient
        const chatClient = new ChatClient(
          endpointUrlState,
          new AzureCommunicationTokenCredential(userAccessTokenState)
        );
        const chatThreadClient = chatClient.getChatThreadClient(threadID);

        await client.startRealtimeNotifications(); // Reconnect to the chat service

        console.log("Reconnected successfully"); // HIDE REFRESHING BANNER
        setShowWaitingBanner(false);
        appInsights.trackTrace({
          message: "Reconnected successfully",
        });

        startPulse(chatClient, chatThreadClient); // Restart pulse after successful reconnect
      } catch (error) {
        console.error("Reconnection failed", error);
        // setShowAlertBanner(true);
        setShowWaitingBanner(true);
        appInsights.trackTrace({
          message: "Reconnected failed",
        });
        handleReconnection(client, threadClient); // Retry reconnection
      }
    }, reconnectDelay);
  };

  // Function to start pulse (connection check)
  const startPulse = (client, threadClient) => {
    pulseTimer = setInterval(async () => {
      try {
        await threadClient.listMessages();

        console.log("Connection is healthy");

        setShowAlertBanner(false);
        reconnectAttempts = 0; // Reset attempts if successful
      } catch (error) {
        setShowWaitingBanner(true);
        console.error("Pulse failed. Attempting to reconnect...", error);
        appInsights.trackTrace({
          message: "Pulse failed. Attempting to reconnect...",
        });
        clearInterval(pulseTimer); // Stop pulse during reconnection
        handleReconnection(client, threadClient);
      }
    }, pulseInterval);
  };

  // Handle disconnection event
  const handleDisconnect = (client) => {
    client.on("realTimeNotificationDisconnected", () => {
      // console.log("Connection lost, trying to reconnect...");
      appInsights.trackTrace({
        message: "Connection lost, trying to reconnect...",
      });
      setShowWaitingBanner(true);
      clearInterval(pulseTimer); // Stop pulse on disconnect

      handleReconnection(client, chatThreadClient);
    });
  };

  const initChat = async () => {
    // setMessagesLoading(true);

    // const chatClient = new ChatClient(
    //   endpointURL,
    //   new AzureCommunicationTokenCredential(userAccessToken)
    // );
    // const chatThreadClient = chatClient.getChatThreadClient(threadID);
    // setChatClient(chatClient);
    // setChatThreadClient(chatThreadClient);

    // If logged in user is not included in case.agents
    const agentIncluded = caseDetail?.patient?.agents?.some(
      (agent) => agent?.id === agentUser?.agent?.id
    );

    if (!agentIncluded) {
      appInsights.trackTrace({
        message: "Agent is not included in chat, then including agent!",
      });
      await includeAgent();
      // setMessagesLoading(false);
    }

    appInsights.trackTrace({
      message: "Agent is already included in chat thread",
    });

    await chatClient.startRealtimeNotifications();

    await chatClient.on("typingIndicatorReceived", (event) => {
      if (
        event.sender.communicationUserId !== agentUser?.agent?.azure_comm_id
      ) {
        if (event?.senderDisplayName === "start") {
          setClientIsTyping(true);
        } else {
          setTimeout(() => setClientIsTyping(false), 2000);
        }
      }
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${agentToken}`,
      },
    };

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/chat/list-messages?threadId=${threadID}`,
        config
      );
      // segment logs + AI traces but only for error

      if (!data.ok) {
        setMessagesLoading(false);
        appInsights.trackTrace({
          message: `Error while getting chat message listing ${
            data?.message || "Could not get chat messages list!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_CHAT_MESSAGES_LISTING",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${data?.message || "Could not get chat messages list!"}`,
        });
        setErrorMsg(data.message);
      }

      appInsights.trackTrace({
        message: "List of Chat messages received!",
      });

      const existing_messages = await data?.messages;

      if (existing_messages?.length > 0) {
        const messageId = existing_messages[0]?.id;

        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/chat/mark-as-read`,
          { messageId: messageId },
          config
        );

        try {
          await chatThreadClient?.sendReadReceipt({ chatMessageId: messageId });
        } catch (error) {
          setMessagesLoading(false);
          console.log(error);
        }

        if (!data.ok) {
          setMessagesLoading(false);
          // segment logs + AI traces but only for error
          appInsights.trackTrace({
            message: `Error while read chat messages ${
              data?.message || "Could not read the chat message!"
            }`,
          });
          segmentError({
            event: "ERR_BACKEND_MARK_MESSAGES_AS_READ",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
            error: `${data?.message || "Could not read the chat message!"}`,
          });
          setErrorMsg(data.message);
        }

        appInsights.trackTrace({
          message: "Chat message read successfully!",
        });
      }

      const messagesArray = [];
      for (const message of existing_messages) {
        if (message?.content?.message) {
          messagesArray.push(message);
        }
      }

      const failedMessages =
        JSON.parse(localStorage.getItem("failedMessages")) || [];

      const patientFailedMessages = failedMessages?.length
        ? failedMessages?.filter(
            (item) => item?.patientId === caseDetail?.patient?.id
          )
        : [];

      const allMessages = [
        ...messagesArray.reverse(),
        ...patientFailedMessages,
      ];

      setChatClient(chatClient);
      setChatThreadClient(chatThreadClient);
      setMessages(allMessages);
      // setMessagesLoading(false);

      await chatClient.startRealtimeNotifications();

      await chatClient.on("readReceiptReceived", (readReceiptEvent) => {
        if (
          readReceiptEvent?.sender?.communicationUserId ===
            caseDetail?.patient?.azure_comm_id &&
          `${location.pathname}${location.search}` ===
            `/patient/${caseDetail?.patient?.id}?tab=chat`
        ) {
          // get the message from messages array with id === readReceiptEvent.chatMessageId
          // set it as read

          const lastSeenMessageIndex = messagesRef.current.findIndex(
            (message) => message.id === readReceiptEvent.chatMessageId
          );

          if (lastSeenMessageIndex !== -1) {
            // Mark all messages up to and including the last seen message as "seen"
            const updatedMessages = messagesRef.current.map((msg, index) =>
              index <= lastSeenMessageIndex ? { ...msg, seen: true } : msg
            );

            setMessages(updatedMessages);
          }
        }
      });

      setMessagesLoading(false);

      chatClient.on("chatMessageReceived", async (e) => {
        const newMessage = {
          id: e.id,
          type: e.type.toLowerCase(),
          sequenceId: e.version,
          version: e.version,
          senderDisplayName: e.senderDisplayName,
          createdOn: e.createdOn,
          content: {
            message: e.message,
          },
          sender: e.sender,
        };

        setUnreadMessageLength((prev) => prev + 1);

        // chatClient.on("typingIndicatorReceived", (event) => {
        //   // if (event.from !== currentUser) {
        //   // setIsTyping(true);
        //   // Hide the typing indicator after some time
        //   // setTimeout(() => setIsTyping(false), 3000); // Adjust time as needed
        //   // }
        // });

        if (
          !messages.some((message) => message.id === newMessage.id) &&
          e.sender.communicationUserId !== agentUser?.agent?.azure_comm_id &&
          e.threadId === caseDetail?.patient?.comm_thread_id
        ) {
          setMessages((prevMessages) => [...prevMessages, newMessage]);

          // wait 2 seconds before marking the latest received message as read
          if (
            `${location.pathname}${location.search}` ===
            `/patient/${caseDetail?.patient?.id}?tab=chat`
          ) {
            setTimeout(async () => {
              const { data } = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/chat/mark-as-read`,
                { messageId: newMessage?.id },
                config
              );

              try {
                chatThreadClient.sendReadReceipt({
                  chatMessageId: newMessage?.id,
                });
              } catch (error) {
                console.log(error);
              }

              // segment logs + AI traces but only for error

              if (!data.ok) {
                setMessagesLoading(false);
                appInsights.trackTrace({
                  message: `Error while read chat messages ${
                    data?.message || "Could not read the chat message!"
                  }`,
                });
                segmentError({
                  event: "ERR_BACKEND_MARK_MESSAGES_AS_READ",
                  agent: agentUser?.agent,
                  agency: agencyDetail?.agency,
                  error: `${
                    data?.message || "Could not read the chat message!"
                  }`,
                });
                console.log(data.message);
              }

              appInsights.trackTrace({
                message: "Chat message read successfully!",
              });
            }, 2000);
          }
        }
      });

      setMessagesLoading(false);
    } catch (error) {
      setMessagesLoading(false);
      // segment logs + AI traces but only for error
      appInsights.trackTrace({
        message: `Error while listing chat messages ${error}`,
      });
      segmentError({
        event: "ERR_BACKEND_CHAT_MESSAGES_LISTING",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${error || "Could not get chat messages list!"}`,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (caseDetail?.patient && chatClient) {
      appInsights.trackTrace({
        message:
          "calling initChat function when patientDetail or chatClient is changed",
      });
      // setMessagesLoading(true);
      initChat();
    }
  }, [caseDetail, chatClient]);

  useEffect(() => {
    if (agentUser) {
      setDisplayName(
        agentUser?.agent?.name || agentUser?.agent?.email?.split("@")[0]
      );
    }
  }, [agentUser]);

  // useEffect(() => {
  //   if (chatWindowRef.current) {
  //     chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  //   }
  // }, [messages]);

  const handleFailedMessage = (failedMessage) => {
    const failedMessages =
      JSON.parse(localStorage.getItem("failedMessages")) || [];

    // Check if the message with the same id already exists in local storage
    const messageExists = failedMessages.some(
      (msg) => msg.id === failedMessage.id
    );

    if (!messageExists) {
      // Only add the message if it doesn't already exist
      failedMessages.push(failedMessage);
      localStorage.setItem("failedMessages", JSON.stringify(failedMessages));
    } else {
      // If it exists, update the status
      const updatedFailedMessages = failedMessages.map((msg) =>
        msg.id === failedMessage.id ? { ...msg, status: "failed" } : msg
      );
      localStorage.setItem(
        "failedMessages",
        JSON.stringify(updatedFailedMessages)
      );
    }
  };

  const sendMessageToBackend = async (messageId, content) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${agentToken}`,
        },
      };

      var messageData = {
        id: messageId,
        content: { message: content },
        senderDisplayName: displayName,
        threadId: threadID,
        patientId: caseDetail?.patient?.id,
        createdOn: new Date(),
        sentFrom: "pc",
        agencyId,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/chat/send-message`,
        messageData,
        config
      );

      if (!res?.data?.ok) {
        setBackendFailed(true);

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === messageId ? { ...msg, status: "failed" } : msg
          )
        );
        handleFailedMessage({ ...messageData, status: "failed" });
        appInsights.trackTrace({
          message: `Error sending chat message on Backend: ${
            res?.data?.message || "Error sending chat message!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_CHAT_SEND",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${res?.data?.message || "Error sending chat message!"}`,
        });
        // setToastMessage("Message not sent. Please try again.");
        // setOpen(true);
        return;
      }

      setBackendFailed(false);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId ? { ...msg, id: messageId, status: "sent" } : msg
        )
      );

      appInsights.trackTrace({ message: "Chat message sent to backend!" });

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "message_sent",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
      return messageId;
    } catch (error) {
      setBackendFailed(true);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId ? { ...msg, status: "failed" } : msg
        )
      );
      handleFailedMessage({ ...messageData, status: "failed" });
      appInsights.trackTrace({
        message: `Error sending chat message on Azure: ${error}`,
      });

      // setToastMessage("Message not sent. Please try again.");
      // setOpen(true);
      segmentError({
        event: "ERR_AZURE_CHAT_SEND",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${error || "Error sending chat message on Azure"} `,
      });
      console.log(error);
      setErrorMsg(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const sendMessage = async (content, message = null) => {
    if (chatThreadClient) {
      appInsights.trackTrace({ message: "Inside send message function" });
      var messageId;
      let newMessage;
      let tempMessageId;

      // const tempMessageId = uuidv4();
      if (message?.id) {
        tempMessageId = message?.id;
        newMessage = { ...message, id: tempMessageId, status: "sending" };
      } else {
        tempMessageId = uuidv4();

        newMessage = {
          id: tempMessageId,
          content: { message: content },
          senderDisplayName: displayName,
          createdOn: new Date(),
          senderId: agentUser?.agent?.id,
          sender: { communicationUserId: agentUser?.agent?.azure_comm_id },
          status: "sending",
        };
      }

      setMessages((prevMessages) =>
        prevMessages
          .map((msg) =>
            msg.id === tempMessageId ? { ...msg, status: "sending" } : msg
          )
          .concat(!message?.id ? [newMessage] : [])
      );

      try {
        const sendMessageRequest = { content };
        const sendMessageOptions = { senderDisplayName: displayName };
        const sendChatMessageResult = await chatThreadClient.sendMessage(
          sendMessageRequest,
          sendMessageOptions
        );

        if (!sendChatMessageResult?.id) {
          setAzureFailed(true);
          messageId = tempMessageId;
          setMessagesLoading(false);
          appInsights.trackTrace({
            message: `Error sending chat message to Azure: ${sendChatMessageResult}`,
          });
          segmentError({
            event: "ERR_AZURE_CHAT_SEND",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
            error: `${sendChatMessageResult}`,
          });

          setToastMessage("Message not sent. Please try again.");
          setOpen(true);
          setMessagesLoading(false);

          return;
        } else {
          appInsights.trackTrace({ message: "Chat message sent to Azure" });
          setMessagesLoading(false);
        }

        setAzureFailed(false);
        messageId = sendChatMessageResult?.id;

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === tempMessageId
              ? { ...msg, id: messageId, status: "sending" }
              : msg
          )
        );
        // const newMessage = {
        //   id: messageId,
        //   content: { message: content },
        //   senderDisplayName: displayName,
        //   createdOn: new Date(),
        //   senderId: agentUser?.agent?.id,
        //   sender: { communicationUserId: agentUser?.agent?.azure_comm_id },
        // };
        // setMessages((prevMessages) => [...prevMessages, newMessage]);
        appInsights.trackTrace({
          message: "Messages are set inside setMessages state",
        });
        setMessagesLoading(false);
        setShowAlertBanner(false);
      } catch (error) {
        setAzureFailed(true);
        messageId = tempMessageId;
        console.log(`Error sending chat message to Azure: ${error}`);
        appInsights.trackTrace({
          message: `Error sending chat message to Azure: ${error}`,
        });
        setMessagesLoading(false);
      }

      await sendMessageToBackend(messageId, content);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const content = message.trim();
    if (!content) return;
    chatThreadClient.sendTypingNotification({ senderDisplayName: "stop" });
    appInsights.trackTrace({ message: "Send message button clicked!" });
    sendMessage(content);

    const chatContainer = document.querySelector("#chat_container");
    if (chatContainer) {
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
    }

    setMessage("");
  };

  const changeMessageHandler = async (e) => {
    setMessage(e.target.value);
    setIsTyping(true);
    if (!isTyping) {
      chatThreadClient.sendTypingNotification({ senderDisplayName: "start" });
    }

    const lastTypingTime = new Date().getTime();

    setTimeout(() => {
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - lastTypingTime;

      if (timeDiff >= 3000) {
        setIsTyping(false);
        chatThreadClient.sendTypingNotification({ senderDisplayName: "stop" });
      }
    }, 3000);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const retryMessageHandler = async (message) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === message.id ? { ...msg, status: "sending" } : msg
      )
    );

    try {
      let messageId;
      //   if (message.status === "failed" && message.id.includes("temp")) {
      //     // If both Azure and backend failed, retry sending to both
      //     await sendMessage(message.content.message, message.id);
      //   } else {
      //     // If only backend failed, retry sending to the backend
      //     await sendMessageToBackend(message.id, message.content.message);
      //   }
      messageId = await sendMessageToBackend(
        message.id,
        message.content.message
      );

      if (messageId) {
        const failedMessages =
          JSON.parse(localStorage.getItem("failedMessages")) || [];
        const updatedFailedMessages = failedMessages.filter(
          (msg) => msg.id !== message.id
        );
        localStorage.setItem(
          "failedMessages",
          JSON.stringify(updatedFailedMessages)
        );

        // Update the message state to reflect success
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === message.id ? { ...msg, status: "sent" } : msg
          )
        );
      }

      appInsights.trackTrace({ message: "Retry message sent successfully" });
    } catch (error) {
      console.error("Retry failed:", error);
      // If retry fails, mark the message as "failed" again
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? { ...msg, status: "failed" } : msg
        )
      );
    }
  };

  const deleteFailedMessageHandler = (message) => {
    const failedMessages =
      JSON.parse(localStorage.getItem("failedMessages")) || [];

    const updatedFailedMessages = failedMessages?.filter(
      (item) => item?.id !== message?.id
    );

    localStorage.setItem(
      "failedMessages",
      JSON.stringify(updatedFailedMessages)
    );

    initChat();
  };

  const scrollToBottomHandler = () => {
    setUnreadMessageLength(0);
    chatWindowRef?.current?.scrollToBottom({ behavior: "smooth" });
  };

  const scrollbarHandler = (atBottom) => {
    setUserAtBottom(atBottom);
    if (atBottom) {
      setUnreadMessageLength(0);
    }
  };

  return (
    <>
      {toastMessage && (
        <AlertMessage
          message={toastMessage}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <Box
        display="flex"
        width="100%"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={3}
      >
        <Stack
          gap="20px"
          width="100%"
          boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
          padding="24px 0"
          borderRadius="8px"
          height="calc(100vh - 374px)"
          position="relative"
          justifyContent="flex-end"
        >
          {(showAlertBanner || failedToStore) && (
            <Box
              position="absolute"
              width="100%"
              top="0"
              left="0"
              bgcolor="#FDEDED"
              padding="6px 16px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="4px"
              zIndex="99"
              height="42px"
            >
              <FailedChatIcon />
              <Typography variant="body2" color="#5F2120">
                Something went wrong, Click{" "}
                <Box
                  component="span"
                  color="#3F3CD8"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    reconnectAttempts = 0;
                    setShowAlertBanner(false);
                    setShowWaitingBanner(false);
                    setFailedToStore(false);
                    initChat();
                    startPulse();
                  }}
                >
                  {" "}
                  here
                </Box>{" "}
                to refresh.
              </Typography>
            </Box>
          )}

          {showWaitingBanner && (
            <Box
              position="absolute"
              width="100%"
              top="0"
              left="0"
              bgcolor="#ECECEC"
              padding="6px 16px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="8px"
              zIndex="99"
              height="42px"
            >
              <Box>
                <CircularProgressLoading size={16} color="inherit" />
              </Box>
              <Typography variant="body2" color="#13132B">
                Something went wrong, Please wait...
              </Typography>
            </Box>
          )}

          {/* || loadingAgency || loading || loadingCaseDetail || */}
          {messagesLoading || loadingCaseDetail || loadingAgency || loading ? (
            <Box padding="0 32px">
              <ChatSkeleton />
            </Box>
          ) : messages?.length === 0 ? (
            <Box
              height="100%"
              display="flex"
              width="100%"
              alignItems="flex-end"
              justifyContent="center"
            >
              <Typography color="secondary" variant="body1">
                Start chatting with patient{" "}
              </Typography>
            </Box>
          ) : (
            // <Box
            //   height="100%"
            //   sx={{ overflowY: "auto" }}
            //   padding="6px"
            //   // ref={chatWindowRef}
            //   display="flex"
            //   flexDirection="column"
            //   // justifyContent={messages?.length > 4 ? "flex-start" : "flex-end"}
            //   position="relative"
            // >
            <ScrollableFeed
              ref={chatWindowRef}
              id="chat_container"
              onScroll={scrollbarHandler}
            >
              {messages?.map((message, index) => {
                const messageDate = new Date(
                  message.createdOn
                ).toLocaleDateString();
                const showDateSeparator = messageDate !== prevDate;

                prevDate = messageDate;

                return (
                  <Stack
                    key={index}
                    mb={2}
                    padding="0 32px"
                    gap="3px"
                    alignItems={
                      (message.senderCommId ===
                        agentUser?.agent?.azure_comm_id &&
                        message.senderId === agentUser?.agent?.id) ||
                      message.sender?.communicationUserId ===
                        agentUser?.agent?.azure_comm_id ||
                      message.status === "sending" ||
                      message.status === "sent"
                        ? "flex-end"
                        : message?.status === "failed" && "flex-end"
                    }
                  >
                    {showDateSeparator && (
                      <Box textAlign="center" width="100%" mb={2}>
                        <Typography
                          variant="body1"
                          color="rgba(0, 0, 0, 0.38)"
                          fontSize="14px"
                        >
                          {onlyDateFormat(message?.createdOn)}
                        </Typography>
                      </Box>
                    )}

                    <Box
                      bgcolor={
                        message?.status === "failed" ||
                        message?.status === "sending" ||
                        message.status === "sent" ||
                        (message.senderCommId ===
                          agentUser?.agent?.azure_comm_id &&
                          message.senderId === agentUser?.agent?.id) ||
                        message.sender?.communicationUserId ===
                          agentUser?.agent?.azure_comm_id
                          ? message?.status === "failed"
                            ? "rgba(211, 47, 47, 0.15)"
                            : "#0C80F3"
                          : "#ECECEC"
                      }
                      border={
                        (message.senderCommId ===
                          agentUser?.agent?.azure_comm_id &&
                          message.senderId === agentUser?.agent?.id) ||
                        (message.sender?.communicationUserId ===
                          agentUser?.agent?.azure_comm_id &&
                          message?.status === "sending")
                          ? ""
                          : message.status === "failed" && "1px solid #FF2D2D"
                      }
                      borderRadius="8px"
                      padding="16px 24px"
                      maxWidth={"544px"}
                      width="max-content"
                    >
                      {(message.senderCommId !==
                        agentUser?.agent?.azure_comm_id ||
                        message.senderId !== agentUser?.agent?.id) &&
                        message.sender?.communicationUserId !==
                          agentUser?.agent?.azure_comm_id &&
                        message?.status !== "failed" &&
                        message?.status !== "sending" &&
                        message?.status !== "sent" && (
                          <Typography
                            fontSize="12px"
                            color="#0D0D0D"
                            variant="body1"
                            pb={1}
                          >
                            {message.senderDisplayName}
                          </Typography>
                        )}

                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography
                          component="p"
                          fontSize="14px"
                          color={
                            message?.status === "failed" ||
                            message?.status === "sending" ||
                            message.status === "sent" ||
                            (message.senderCommId ===
                              agentUser?.agent?.azure_comm_id &&
                              message.senderId === agentUser?.agent?.id) ||
                            message.sender?.communicationUserId ===
                              agentUser?.agent?.azure_comm_id
                              ? message?.status === "sending" ||
                                message.status === "sent"
                                ? "#fff"
                                : message?.status === "failed"
                                ? "#0D0D0D"
                                : "#fff"
                              : "#0D0D0D"
                          }
                          variant="body1"
                          width="100%"
                          sx={{ wordWrap: "break-word" }}
                        >
                          {message?.content?.message
                            ?.split("\n")
                            .map((item, index) => (
                              <React.Fragment key={index}>
                                {" "}
                                {item}{" "}
                                {index <
                                  message?.content?.message?.split("\n")
                                    ?.length -
                                    1 && <br />}{" "}
                              </React.Fragment>
                            ))}
                        </Typography>
                        {message?.status === "failed" &&
                          ((message.senderCommId ===
                            agentUser?.agent?.azure_comm_id &&
                            message.senderId === agentUser?.agent?.id) ||
                            message.sender?.communicationUserId ===
                              agentUser?.agent?.azure_comm_id ||
                            message?.status === "failed") && (
                            <IconButton
                              sx={{ padding: 0 }}
                              onClick={() =>
                                deleteFailedMessageHandler(message)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                      </Box>
                    </Box>

                    {message.status === "sending" ? (
                      <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
                        Sending...
                      </Typography>
                    ) : message.status === "failed" ? (
                      <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                          <FailedChatIcon />
                          <Typography variant="body2" color="#E76072">
                            Failed to send,
                          </Typography>
                        </Box>
                        <Button
                          onClick={() => {
                            setRetryTimes((prev) => prev + 1);
                            azureFailed && backendFailed
                              ? sendMessage(message?.content?.message, message)
                              : retryMessageHandler(message);
                          }}
                          startIcon={<ReplayOutlinedIcon />}
                          variant="text"
                          sx={{
                            // fontSize: "14px",
                            padding: "0",
                            textTransform: "capitalize",
                            "& .MuiButton-startIcon": {
                              marginRight: 0,
                            },
                          }}
                        >
                          Retry
                        </Button>
                        <Typography
                          variant="body1"
                          fontSize="12px"
                          color="rgba(0, 0, 0, 0.38)"
                        >
                          {dayjs(message?.createdOn).format("hh:mm A")}
                        </Typography>
                      </Box>
                    ) : (
                      <Box display="flex" gap="2px" alignItems="center">
                        {(message.senderCommId ===
                          agentUser?.agent?.azure_comm_id &&
                          message.senderId === agentUser?.agent?.id) ||
                        message.sender?.communicationUserId ===
                          agentUser?.agent?.azure_comm_id ||
                        message?.status === "sent" ? (
                          <>
                            {" "}
                            {message?.read_receipts?.some(
                              (item) => item?.userType === "patient"
                            ) || message?.seen ? (
                              <ChatSeenIcon />
                            ) : (
                              <ChatTickIcon />
                            )}
                            <Typography
                              variant="body1"
                              fontSize="12px"
                              color="rgba(0, 0, 0, 0.38)"
                            >
                              {message?.read_receipts?.some(
                                (item) => item?.userType === "patient"
                              ) || message?.seen
                                ? "Seen"
                                : "Sent"}
                            </Typography>
                            <Typography
                              variant="body1"
                              fontSize="12px"
                              color="rgba(0, 0, 0, 0.38)"
                            >
                              {dayjs(message?.createdOn).format("hh:mm A")}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            variant="body1"
                            fontSize="12px"
                            color="rgba(0, 0, 0, 0.38)"
                          >
                            {dayjs(message?.createdOn).format("hh:mm A")}
                          </Typography>
                        )}

                        {/* ERROR ON THIS LINE */}
                        {/* {message.senderId === agentUser?.agent?.id ||
           (message.sender?.communicationUserId ===
             agentUser?.agent?.azure_comm_id && (
             <IconButton
               sx={{ padding: 0, width: "16px", height: "16px" }}
             >
               <DoubleCheckIcon />
             </IconButton>
           ))} */}
                      </Box>
                    )}
                  </Stack>
                );
              })}

              {clientIsTyping && (
                <Stack gap="8px" padding="0 32px">
                  <Box display="flex" alignItems="center" gap="8px">
                    <Avatar
                      src={caseDetail?.patient?.profile_picture_url}
                      alt={caseDetail?.patient?.name}
                    />
                    <Typography variant="body2">
                      {caseDetail?.patient?.name}
                    </Typography>
                  </Box>
                  <Box
                    bgcolor="#F4FAFF"
                    padding="16px"
                    borderRadius="8px"
                    width="422px"
                  >
                    <Typography variant="body2" color="#0D0D0D">
                      Typing...
                    </Typography>
                  </Box>
                </Stack>
              )}

              {unreadMessageLength > 0 && !userAtBottom && (
                <Box
                  padding="6px 16px"
                  position="sticky"
                  bottom="0"
                  right="0"
                  bgcolor="#DEEFFF"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body2" padding="8px 0" color="#13132B">
                    {unreadMessageLength} Unread Messages{" "}
                    <Box
                      component="span"
                      color="#0C80F3"
                      sx={{ cursor: "pointer" }}
                      onClick={scrollToBottomHandler}
                    >
                      View
                    </Box>
                  </Typography>
                </Box>
              )}
              {/* <Button
                sx={{ position: "sticky", bottom: 0, right: 0 }}
                variant="contained"
                onClick={scrollToBottomHandler}
              >
                Scroll to Bottom
              </Button> */}
            </ScrollableFeed>
            // </Box>
          )}

          {/* {clientIsTyping && (
            <Stack gap="8px">
              <Box
                bgcolor={
                  (message.senderCommId === agentUser?.agent?.azure_comm_id &&
                    message.senderId === agentUser?.agent?.id) ||
                  message.sender?.communicationUserId ===
                    agentUser?.agent?.azure_comm_id
                    ? "#0C80F3"
                    : "#ECECEC"
                }
                borderRadius="8px"
                padding="16px 24px"
                maxWidth="554px"
                width="100%"
              >
                {(message.senderCommId !== agentUser?.agent?.azure_comm_id ||
                  message.senderId !== agentUser?.agent?.id) &&
                  message.sender?.communicationUserId !==
                    agentUser?.agent?.azure_comm_id && (
                    <Typography
                      fontSize="12px"
                      color="#0D0D0D"
                      variant="body1"
                      pb={1}
                    >
                      {message.senderDisplayName}
                    </Typography>
                  )}
              </Box>
            </Stack>
          )} */}

          {/* SUBMIT MESSAGE FORM */}
          {caseDetail?.patient?.enrollment_status === "enrolled" ||
          messagesLoading ||
          loadingAgency ||
          loading ||
          loadingCaseDetail ? (
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              gap="16px"
              width="100%"
              component="form"
              padding="0 32px"
              onSubmit={handleSubmit}
            >
              <Box flex="1">
                <TextField
                  multiline
                  disabled={
                    messagesLoading ||
                    loadingAgency ||
                    loading ||
                    loadingCaseDetail
                  }
                  maxRows={4}
                  value={message}
                  name="message"
                  placeholder="Write your message here"
                  onChange={changeMessageHandler}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  onKeyDown={handleKeyDown}
                />
              </Box>
              <Button
                sx={{
                  "& span": {
                    padding: "4px 0 !important",
                  },
                }}
                startIcon={<SendIcon />}
                variant="contained"
                type="submit"
              >
                Send
              </Button>
            </Box>
          ) : (
            <Alert severity="info">
              Cannot send or receive messages as {caseDetail?.patient?.name} has
              been un-enrolled from the RTM program.
            </Alert>
          )}
        </Stack>

        {/* USER CHAT PROFILE */}
        <CardUI maxWidth="267px" padding="16px" paddingTop="16px">
          <Stack gap="24px" alignItems="center" pb={1}>
            <Typography
              variant="h3"
              fontSize="16px"
              fontWeight="500"
              color="secondary"
              alignSelf="flex-start"
            >
              CHATTING WITH
            </Typography>
            {loadingCaseDetail ? (
              <ChatProfileSkeleton />
            ) : (
              <>
                {" "}
                <ProfilePicture
                  width="134px"
                  height="134px"
                  src={caseDetail?.patient?.profile_picture_url}
                  alt={caseDetail?.patient?.name}
                />
                <Stack gap="9px" alignItems="center">
                  <HeaderText
                    textContent={caseDetail?.patient?.name}
                    fontSize="28px"
                    fontWeight="500"
                    color="#000"
                    variant="h3"
                    textAlign="center"
                  />
                  <Typography
                    variant="body1"
                    color="#0D0D0D"
                    fontWeight="400"
                    fontSize="14px"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {caseDetail?.patient?.email}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#0D0D0D"
                    fontWeight="400"
                    fontSize="14px"
                  >
                    {caseDetail?.patient?.contact_number}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        </CardUI>
      </Box>
    </>
  );
});

export default ChatTab;
