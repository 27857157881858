import {
  Box,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import WaitingClockIcon from "../../icons/WaitingClockIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getCptCodeSubtitleText } from "../../utils/helpers";
import { useSelector } from "react-redux";

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          fontSize="12px"
          color="secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const CptProgressCard = ({
  cptCode,
  openDrawerHandler,
  cptItem,
  timeSpent,
  transmissions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  // const cptTransmissions = JSON.parse(transmissions);
  // const distinctDates = [
  //   ...new Set(cptTransmissions?.map((item) => item?.date)),
  // ];

  // const inProgressPerc =
  //   (distinctDates?.length / agencyDetail?.agency?.number_of_days_for_77) * 100;

  //   : distinctDates?.length
  //   ? inProgressPerc >= 100
  //     ? 100
  //     : inProgressPerc

  const timeSpentText =
    cptCode === "98980" || cptCode === "98981"
      ? timeSpent
        ? (timeSpent / 1200) * 100
        : 0
      : 0;

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="12px"
      bgcolor="#FCFCFC"
      padding="8px"
      borderRadius="13px"
      onClick={() => openDrawerHandler(cptItem)}
      sx={{ cursor: "pointer" }}
    >
      <Box
        bgcolor="rgba(255, 133, 45, 0.10)"
        border="1px solid #FF852D"
        borderRadius="6px"
        padding="8px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <WaitingClockIcon width="24" height="24" color="#FF852D" />
      </Box>
      <Stack flex="1">
        <Typography width="143px" variant="body1">
          CPT Code {cptCode}
        </Typography>
        <Typography width="181px" variant="caption">
          {cptCode === "98977"
            ? "Musculoskeletal RTM"
            : cptCode === "98975"
            ? "Initial Setup"
            : getCptCodeSubtitleText(cptCode)}{" "}
        </Typography>
      </Stack>
      <Stack width="142px" flex="1">
        <Typography variant="body2">Pending requirements</Typography>
        <Box display="flex" alignItems="center" gap="8px">
          <Typography variant="caption" fontSize="16px" color="secondary">
            1
          </Typography>
        </Box>
      </Stack>
      {cptCode !== "98977" && (
        <Stack height="100%" gap="4px">
          <Typography variant="body2">Progress</Typography>
          <LinearProgressWithLabel
            color="primary"
            value={timeSpentText}
            sx={{ width: "110px", borderRadius: "10px" }}
          />{" "}
        </Stack>
      )}
      {/* <Box>
        <IconButton
          aria-label="cpt-menu"
          id="cpt-menu"
          aria-controls={open ? "cpt-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="cpt-menu"
          MenuListProps={{
            "aria-labelledby": "cpt-menu",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          //   PaperProps={{
          //     style: {
          //       maxHeight: ITEM_HEIGHT * 4.5,
          //       width: "20ch",
          //     },
          //   }}
        >
          <MenuItem>Edit</MenuItem>
          <MenuItem>Delete</MenuItem>
        </Menu>
      </Box> */}
    </Box>
  );
};

export default CptProgressCard;
