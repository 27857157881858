// LISTING TABLE STATES
export const SET_LISTING_TAB = "SET_LISTING_TAB";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_ASSIGNED_TO = "SET_ASSIGNED_TO";
export const SET_ENROLLMENT_STATUS = "SET_ENROLLMENT_STATUS";
export const SET_SENT_CREDENTIALS = "SET_SENT_CREDENTIALS";
export const SET_EDUCATION_GIVEN = "SET_EDUCATION_GIVEN";
export const SET_CONSENT_STATUS = "SET_CONSENT_STATUS";
export const SET_QUERY = "SET_QUERY";
export const SET_CPT_CODES_ITEMS = "SET_CPT_CODES_ITEMS";
export const SET_SORTED_ITEMS = "SET_SORTED_ITEMS";
export const RESET_LISTING_STATES = "RESET_LISTING_STATES";
export const SET_NEXT_PATIENT_OBJECT = "SET_NEXT_PATIENT_OBJECT";

export const GET_ALL_PATIENTS_REQUEST = "GET_ALL_PATIENTS_REQUEST";
export const GET_ALL_PATIENTS_SUCCESS = "GET_ALL_PATIENTS_SUCCESS";
export const GET_ALL_PATIENTS_FAIL = "GET_ALL_PATIENTS_FAIL";
export const RESET_ALL_PATIENTS = "RESET_ALL_PATIENTS";

export const GET_NEXT_PATIENT_REQUEST = "GET_NEXT_PATIENT_REQUEST";
export const GET_NEXT_PATIENT_SUCCESS = "GET_NEXT_PATIENT_SUCCESS";
export const GET_NEXT_PATIENT_FAIL = "GET_NEXT_PATIENT_FAIL";
export const RESET_NEXT_PATIENT = "RESET_NEXT_PATIENT";

export const GET_AGENCY_PATIENTS_REQUEST = "GET_AGENCY_PATIENTS_REQUEST";
export const GET_AGENCY_PATIENTS_SUCCESS = "GET_AGENCY_PATIENTS_SUCCESS";
export const GET_AGENCY_PATIENTS_FAIL = "GET_AGENCY_PATIENTS_FAIL";
export const RESET_GET_AGENCY_PATIENTS = "RESET_GET_AGENCY_PATIENTS";

export const GET_UNPAGINATED_PATIENTS_REQUEST =
  "GET_UNPAGINATED_PATIENTS_REQUEST";
export const GET_UNPAGINATED_PATIENTS_SUCCESS =
  "GET_UNPAGINATED_PATIENTS_SUCCESS";
export const GET_UNPAGINATED_PATIENTS_FAIL = "GET_UNPAGINATED_PATIENTS_FAIL";
export const RESET_UNPAGINATED_PATIENTS = "RESET_UNPAGINATED_PATIENTS";

export const GET_A_PATIENT_BY_PID_REQUEST = "GET_A_PATIENT_BY_PID_REQUEST";
export const GET_A_PATIENT_BY_PID_SUCCESS = "GET_A_PATIENT_BY_PID_SUCCESS";
export const GET_A_PATIENT_BY_PID_FAIL = "GET_A_PATIENT_BY_PID_FAIL";
export const RESET_A_PATIENT_BY_PID = "RESET_A_PATIENT_BY_PID";

export const ADD_RECORD_CONSENT_REQUEST = "ADD_RECORD_CONSENT_REQUEST";
export const ADD_RECORD_CONSENT_SUCCESS = "ADD_RECORD_CONSENT_SUCCESS";
export const ADD_RECORD_CONSENT_FAIL = "ADD_RECORD_CONSENT_FAIL";
export const RESET_RECORD_CONSENT = "RESET_RECORD_CONSENT";

export const EQUIP_SETUP_REQUEST = "EQUIP_SETUP_REQUEST";
export const EQUIP_SETUP_SUCCESS = "EQUIP_SETUP_SUCCESS";
export const EQUIP_SETUP_FAIL = "EQUIP_SETUP_FAIL";
export const RESET_EQUIP_SETUP = "RESET_EQUIP_SETUP";

export const EDUCATION_SETUP_REQUEST = "EDUCATION_SETUP_REQUEST";
export const EDUCATION_SETUP_SUCCESS = "EDUCATION_SETUP_SUCCESS";
export const EDUCATION_SETUP_FAIL = "EDUCATION_SETUP_FAIL";
export const RESET_EDUCATION_SETUP = "RESET_EDUCATION_SETUP";

export const UNENROLL_PATIENT_REQUEST = "UNENROLL_PATIENT_REQUEST";
export const UNENROLL_PATIENT_SUCCESS = "UNENROLL_PATIENT_SUCCESS";
export const UNENROLL_PATIENT_FAIL = "UNENROLL_PATIENT_FAIL";
export const RESET_UNENROLL_PATIENT = "RESET_UNENROLL_PATIENT";

export const GET_TABS_COUNT_REQUEST = "GET_TABS_COUNT_REQUEST";
export const GET_TABS_COUNT_SUCCESS = "GET_TABS_COUNT_SUCCESS";
export const GET_TABS_COUNT_FAIL = "GET_TABS_COUNT_FAIL";
export const RESET_GET_TABS_COUNT = "RESET_GET_TABS_COUNT";

export const GET_CLIENT_PAINLOGS_REQUEST = "GET_CLIENT_PAINLOGS_REQUEST";
export const GET_CLIENT_PAINLOGS_SUCCESS = "GET_CLIENT_PAINLOGS_SUCCESS";
export const GET_CLIENT_PAINLOGS_FAIL = "GET_CLIENT_PAINLOGS_FAIL";

export const GET_MEDICINE_DATA_REQUEST = "GET_MEDICINE_DATA_REQUEST";
export const GET_MEDICINE_DATA_SUCCESS = "GET_MEDICINE_DATA_SUCCESS";
export const GET_MEDICINE_DATA_FAIL = "GET_MEDICINE_DATA_FAIL";
export const RESET_MEDICINE_DATA = "RESET_MEDICINE_DATA";

export const GET_CHART_PAINLOGS_REQUEST = "GET_CHART_PAINLOGS_REQUEST";
export const GET_CHART_PAINLOGS_SUCCESS = "GET_CHART_PAINLOGS_SUCCESS";
export const GET_CHART_PAINLOGS_FAIL = "GET_CHART_PAINLOGS_FAIL";

export const ADD_NEW_PATIENT_REQUEST = "ADD_NEW_PATIENT_REQUEST";
export const ADD_NEW_PATIENT_SUCCESS = "ADD_NEW_PATIENT_SUCCESS";
export const ADD_NEW_PATIENT_FAIL = "ADD_NEW_PATIENT_FAIL";

export const UPDATE_PATIENT_REQUEST = "UPDATE_PATIENT_REQUEST";
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS";
export const UPDATE_PATIENT_FAIL = "UPDATE_PATIENT_FAIL";
export const UPDATE_PATIENT_RESET = "UPDATE_PATIENT_RESET";

export const DELETE_PATIENT_REQUEST = "DELETE_PATIENT_REQUEST";
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS";
export const DELETE_PATIENT_FAIL = "DELETE_PATIENT_FAIL";
export const RESET_DELETE_PATIENT = "RESET_DELETE_PATIENT";

export const GENERATE_EVIDENCE_REQUEST = "GENERATE_EVIDENCE_REQUEST";
export const GENERATE_EVIDENCE_SUCCESS = "GENERATE_EVIDENCE_SUCCESS";
export const GENERATE_EVIDENCE_FAIL = "GENERATE_EVIDENCE_FAIL";
export const RESET_GENERATE_EVIDENCE = "RESET_GENERATE_EVIDENCE";

// DASHBOARD ANALYTICS
export const GET_DASHBOARD_ANALYTICS_REQUEST =
  "GET_DASHBOARD_ANALYTICS_REQUEST";
export const GET_DASHBOARD_ANALYTICS_SUCCESS =
  "GET_DASHBOARD_ANALYTICS_SUCCESS";
export const GET_DASHBOARD_ANALYTICS_FAIL = "GET_DASHBOARD_ANALYTICS_FAIL";

export const GET_DASHBOARD_ALERTS_REQUEST = "GET_DASHBOARD_ALERTS_REQUEST";
export const GET_DASHBOARD_ALERTS_SUCCESS = "GET_DASHBOARD_ALERTS_SUCCESS";
export const GET_DASHBOARD_ALERTS_FAIL = "GET_DASHBOARD_ALERTS_FAIL";

export const GET_UNREAD_CHAT_MESSAGES_REQUEST =
  "GET_UNREAD_CHAT_MESSAGES_REQUEST";
export const GET_UNREAD_CHAT_MESSAGES_SUCCESS =
  "GET_UNREAD_CHAT_MESSAGES_SUCCESS";
export const GET_UNREAD_CHAT_MESSAGES_FAIL = "GET_UNREAD_CHAT_MESSAGES_FAIL";

export const GET_PIE_CHART_STATS_REQUEST = "GET_PIE_CHART_STATS_REQUEST";
export const GET_PIE_CHART_STATS_SUCCESS = "GET_PIE_CHART_STATS_SUCCESS";
export const GET_PIE_CHART_STATS_FAIL = "GET_PIE_CHART_STATS_FAIL";
export const RESET_PIE_CHART_STATS = "RESET_PIE_CHART_STATS";

export const ADD_PATIENT_DETAILS_REQUEST = "ADD_PATIENT_DETAILS_REQUEST";
export const ADD_PATIENT_DETAILS_SUCCESS = "ADD_PATIENT_DETAILS_SUCCESS";
export const ADD_PATIENT_DETAILS_FAIL = "ADD_PATIENT_DETAILS_FAIL";
export const RESET_ADD_PATIENT_DETAILS = "RESET_ADD_PATIENT_DETAILS";

export const ADD_PATIENT_PASSWORD_REQUEST = "ADD_PATIENT_PASSWORD_REQUEST";
export const ADD_PATIENT_PASSWORD_SUCCESS = "ADD_PATIENT_PASSWORD_SUCCESS";
export const ADD_PATIENT_PASSWORD_FAIL = "ADD_PATIENT_PASSWORD_FAIL";
export const RESET_ADD_PATIENT_PASSWORD = "RESET_ADD_PATIENT_PASSWORD";

export const REENROLL_PATIENT_REQUEST = "REENROLL_PATIENT_REQUEST";
export const REENROLL_PATIENT_SUCCESS = "REENROLL_PATIENT_SUCCESS";
export const REENROLL_PATIENT_FAIL = "REENROLL_PATIENT_FAIL";
export const RESET_REENROLL_PATIENT = "RESET_REENROLL_PATIENT";

export const GET_PINNED_NOTES_REQUEST = "GET_PINNED_NOTES_REQUEST";
export const GET_PINNED_NOTES_SUCCESS = "GET_PINNED_NOTES_SUCCESS";
export const GET_PINNED_NOTES_FAIL = "GET_PINNED_NOTES_FAIL";

export const EXPORT_PATIENT_LISTINGS_REQUEST =
  "EXPORT_PATIENT_LISTINGS_REQUEST";
export const EXPORT_PATIENT_LISTINGS_SUCCESS =
  "EXPORT_PATIENT_LISTINGS_SUCCESS";
export const EXPORT_PATIENT_LISTINGS_FAIL = "EXPORT_PATIENT_LISTINGS_FAIL";
export const RESET_EXPORT_PATIENT_LISTINGS = "RESET_EXPORT_PATIENT_LISTINGS";

export const GET_PATIENT_REPORTS_REQUEST = "GET_PATIENT_REPORTS_REQUEST";
export const GET_PATIENT_REPORTS_SUCCESS = "GET_PATIENT_REPORTS_SUCCESS";
export const GET_PATIENT_REPORTS_FAIL = "GET_PATIENT_REPORTS_FAIL";
