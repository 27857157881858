import React from "react";

const InactivePatientVector = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="34"
      viewBox="0 0 66 34"
      fill="none"
    >
      <path
        d="M2 27.4158L3.3125 21.8289C4.625 16.2419 7.25 5.06806 9.875 2.55411C12.5 0.0401637 15.125 6.18613 17.75 7.65045C20.375 9.11477 23 5.89745 25.625 5.11173C28.25 4.32601 30.875 5.9719 33.5 6.97831C36.125 7.98471 38.75 8.35163 41.375 12.5391C44 16.7266 46.625 24.7346 49.25 28.216C51.875 31.6973 54.5 30.652 57.125 30.521C59.75 30.3901 62.375 31.1736 63.6875 31.5653L65 31.957"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
};

export default InactivePatientVector;
