import {
  Avatar,
  Box,
  Button,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  getNotesBackgroundColor,
  getNotesColor,
  shortDateTimeFormat,
  showNotesType,
  stringAvatar,
} from "../../utils/helpers";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const NoteLogsCard = ({
  time,
  startTime,
  endTime,
  type,
  notesText,
  agentId,
  pinnedNote,
  pinnedModal = false,
  unPinnedNoteHandler,
  loadingUpdateNote = false,
  dismissNoteHandler,
}) => {
  const getUnpaginatedAgentLists = useSelector(
    (state) => state.getUnpaginatedAgentLists
  );
  const { loading: loadingAgent, unPaginatedAgents: allAgents } =
    getUnpaginatedAgentLists;

  const agentDetail = allAgents?.agents_list?.find(
    (item) => item?.id === agentId
  );

  return (
    <Stack
      gap="7px"
      border={pinnedNote ? "1px solid #0C80F3" : "1px solid #EBEBEB"}
      bgcolor={pinnedNote ? "rgba(12, 128, 243, 0.08)" : "#FCFCFC"}
      padding="16px"
      borderRadius="8px"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap="7px" width="100%">
          <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
            {shortDateTimeFormat(time)}
          </Typography>
          <Stack gap="4px" direction="row" alignItems="center">
            {agentDetail?.profile_picture_url ? (
              <Avatar
                alt="name"
                sx={{ width: 16, height: 16 }}
                src={agentDetail?.profile_picture_url}
              />
            ) : (
              <Avatar
                alt={agentDetail?.name}
                {...stringAvatar(agentDetail?.name)}
                sx={{
                  width: 16,
                  height: 16,
                  fontSize: 8,
                  backgroundColor: agentDetail?.avatar_color,
                }}
              />
            )}
            {agentDetail?.name?.length >= 22 ? (
              <Tooltip title={agentDetail?.name}>
                <Typography
                  variant="body2"
                  sx={{
                    textOverflow: "ellipsis !important",
                    whiteSpace: "nowrap !important",
                    width: "176px !important",
                    overflow: "hidden !important",
                  }}
                >
                  {agentDetail?.name}
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant="body2">{agentDetail?.name}</Typography>
            )}
          </Stack>
          {!pinnedModal &&
            (pinnedNote ? (
              <Box textAlign="end" flex="1">
                <Chip
                  size="small"
                  label="Pinned"
                  // color={getNotesColor(type)}
                  sx={{
                    color: "#0C80F3",
                    bgcolor: "rgba(12, 128, 243, 0.08)",
                    border: "none",
                  }}
                  variant="outlined"
                />
              </Box>
            ) : (
              <Box textAlign="end" flex="1">
                <Chip
                  size="small"
                  label={
                    type === "time_entry"
                      ? `${dayjs(startTime)?.format("hh:mm A")} to ${dayjs(
                          endTime
                        )?.format("hh:mm A")}`
                      : showNotesType(type)
                  }
                  // color={getNotesColor(type)}
                  sx={{
                    color: getNotesColor(type),
                    bgcolor: getNotesBackgroundColor(type),
                    border: "none",
                  }}
                  variant="outlined"
                />
              </Box>
            ))}
        </Box>
      </Box>
      <Typography variant="body2">{notesText}</Typography>
      {pinnedModal ? (
        <Box display="flex" alignSelf="flex-end" gap="7px">
          <LoadingButton
            loading={loadingUpdateNote}
            variant="text"
            color="primary"
            sx={{ fontSize: "13px" }}
            onClick={unPinnedNoteHandler}
          >
            Unmark as pinned & Close
          </LoadingButton>
          <Button
            variant="outlined"
            color="primary"
            sx={{ fontSize: "13px" }}
            onClick={dismissNoteHandler}
          >
            Dismiss
          </Button>
        </Box>
      ) : (
        pinnedNote && (
          <Box display="flex" alignSelf="flex-end">
            <LoadingButton
              sx={{ fontSize: "13px" }}
              variant="text"
              onClick={unPinnedNoteHandler}
              loading={loadingUpdateNote}
            >
              UNPIN
            </LoadingButton>
          </Box>
        )
      )}
    </Stack>
  );
};

export default NoteLogsCard;
