import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import WaitingClockIcon from "../../icons/WaitingClockIcon";
import AvatarWithName from "../AvatarWithName";
import CptCodesCard from "../CptCodesCard";
import { useSelector } from "react-redux";
import RectangleSkeletons from "../RectangleSkeletons";
import EmptyBox from "../../assets/images/empty-box.png";

const InProgressCptCode = ({ activeTab }) => {
  const getReportCptCodesList = useSelector(
    (state) => state.getReportCptCodesList
  );
  const {
    loading: loadingCptCodes,
    error: errorCptCodes,
    reportCptCodesList,
  } = getReportCptCodesList;

  return (
    <Stack gap="8px" padding="16px 24px 20px" height="100%" overflow="auto">
      {loadingCptCodes ? (
        <RectangleSkeletons totalRows={5} height={32} />
      ) : reportCptCodesList?.cpt_codes?.length ? (
        reportCptCodesList?.cpt_codes?.map((item, index) => (
          <CptCodesCard
            activeTab={activeTab}
            key={index}
            cptCode={item?.cpt_code}
            patientName={item?.patient?.name}
            imageUrl={item?.patient?.profile_picture_url}
            bgColor={item?.patient?.avatar_color}
            approved_at={item?.approved_at}
            timeSpent={item?.time_spent}
            transmissions={item?.transmissions}
          />
        ))
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="100%"
          gap="8px"
        >
          <Avatar
            src={EmptyBox}
            alt="no reports found"
            sx={{
              width: "81px",
              height: "81px",
            }}
          />
          <Typography
            variant="caption"
            color="secondary"
            textAlign="center"
            width="330px"
          >
            {activeTab === 0
              ? "No CPT code is in progress."
              : "No CPT code generated yet."}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default InProgressCptCode;
