import React from "react";
import { EocDownloadIcon } from "../../assets/constants";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";

const EOCProgressCard = ({ item, name }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      padding="8px 12px"
      borderRadius="8px"
      bgcolor="rgba(12, 128, 243, 0.05)"
    >
      <Box bgcolor="#E3F1FF" borderRadius="7px" padding="10px">
        <EocDownloadIcon />{" "}
      </Box>

      <Stack flex="1" gap="2px" height="100%" justifyContent="space-evenly">
        <Typography variant="body2" color="#0C80F3">
          Generating EOC for{" "}
          {item?.file_type === "pdf"
            ? name
            : `${item?.number_of_subreports} Patients`}
        </Typography>

        <Box width="220px">
          <LinearProgress />
        </Box>
      </Stack>
    </Box>
  );
};

export default EOCProgressCard;
