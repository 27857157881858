import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RectangleSkeletons from "../RectangleSkeletons";

const TimeBillCard = ({
  cardHeading,
  cardNum,
  dollarSign = false,
  diagnosisCode = false,
  children,
  openDiagnosisAddModalHandler,
  loadingTimeEntry = false,
  boxShadow = "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  showBoxShadow = true,
  height = "164px",
  border,
  titleFontWeight = "400",
  justifyContent = "normal",
  patientDetail,
}) => {
  return (
    <Stack
      boxShadow={showBoxShadow && boxShadow}
      border={border && border}
      borderRadius="8px"
      bgcolor="#fff"
      padding="24px"
      justifyContent={justifyContent}
      gap={diagnosisCode ? "8px" : "24px"}
      height={height}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="8px"
      >
        <Typography variant="body2" fontWeight={titleFontWeight}>
          {cardHeading}
        </Typography>
        {diagnosisCode && patientDetail?.enrollment_status === "enrolled" && (
          <Button
            variant="text"
            startIcon={<AddIcon />}
            sx={{ fontSize: "13px" }}
            onClick={openDiagnosisAddModalHandler}
          >
            Add
          </Button>
        )}{" "}
      </Box>
      {diagnosisCode ? (
        loadingTimeEntry ? (
          <RectangleSkeletons height={40} totalRows={1} />
        ) : (
          children
        )
      ) : loadingTimeEntry ? (
        <RectangleSkeletons height={40} totalRows={1} />
      ) : (
        <Typography
          color="secondary"
          variant="h5"
          fontSize="40px"
          fontWeight={700}
        >
          {dollarSign && (
            <Box component="span" fontSize="29px">
              $
            </Box>
          )}
          {cardNum}
        </Typography>
      )}
    </Stack>
  );
};

export default TimeBillCard;
