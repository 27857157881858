import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  Link,
  Avatar,
} from "@mui/material";
// import DashboardImg from "../../assets/images/dashboard-img.png";
// import bellIcon from "../../assets/images/bell-img2.png";
// import DashboardCard from "../DashboardCard";
// import FolderImg from "../../assets/images/folder-img.png";
// import PaperImg from "../../assets/images/paper-img.png";
// import NetworkImg from "../../assets/images/network-img.png";
// import { InfoCircle } from "../../assets/constants";
import { MessageFav } from "../../assets/constants";
import ProfilePicture from "../../common/ProfilePicture";
import { Link as LinkTo, useNavigate } from "react-router-dom";
import { stringAvatar } from "../../utils/helpers";

const NewMessageCard = ({
  senderProfile,
  senderName,
  chatDate,
  messageContent,
  patient_id,
  avatarColor,
}) => {
  const parsedMessage = JSON.parse(messageContent);
  const navigate = useNavigate();

  return (
    <Box
      padding="12px"
      borderRadius="8px"
      bgcolor="#F7F7F7"
      display="flex"
      alignItems="flex-start"
      gap="12px"
      width="100%"
      sx={{ cursor: "pointer" }}
      onClick={() => navigate(`/patient/${patient_id}?tab=chat`)}
    >
      {/* <Box
        width="40px"
        height="40px"
        padding="6px"
        bgcolor="#fff"
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <MessageFav />
      </Box> */}

      {senderProfile ? (
        <ProfilePicture
          width="20px"
          height="20px"
          src={senderProfile}
          alt={senderName}
        />
      ) : (
        <Avatar
          alt={senderName}
          {...stringAvatar(senderName)}
          sx={{
            width: 24,
            height: 24,
            fontSize: 14,
            backgroundColor: avatarColor,
          }}
        />
      )}

      <Stack width="100%" gap="8px">
        <Stack gap="4px">
          <Typography variant="body1">
            {senderName}{" "}
            <Box component="span" color="rgba(0, 0, 0, 0.38)">
              {" "}
              replied in chat{" "}
            </Box>{" "}
            {/* <Box
                component="span"
                color="#0C80F3"
                sx={{ textDecoration: "underline" }}
                >
                <Link
                component={LinkTo}
                // to={`/patient/${case_id}?tab=chat`}
                textAlign="center"
                underline="none"
                >
                chat
                </Link>
                </Box>{" "} */}
          </Typography>
          <Typography
            variant="body1"
            color="rgba(0, 0, 0, 0.38)"
            fontSize="12px"
          >
            {chatDate}
          </Typography>
        </Stack>

        <Typography variant="body1" color="#566875" fontSize="14px">
          {parsedMessage?.message}
        </Typography>
      </Stack>
    </Box>
  );
};

export default NewMessageCard;
