import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import WaitingClockIcon from "../../icons/WaitingClockIcon";
import AvatarWithName from "../AvatarWithName";
import { TickCircleIcon } from "../../assets/constants";
import { dateTimeFormat } from "../../utils/helpers";
import { useSelector } from "react-redux";

const CptCodesCard = ({
  activeTab,
  cptCode,
  patientName,
  imageUrl,
  bgColor,
  approved_at,
  timeSpent,
  transmissions,
}) => {
  const isBelow1200Screen = useMediaQuery("(max-width:1310px)");

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const { agencyDetail } = getAgencyDetail;

  function getPercentage() {
    if (cptCode === "98980" || cptCode === "98981") {
      const percentage = timeSpent ? (timeSpent / 1200) * 100 : 0;
      return Math.round(percentage);
    } else if (cptCode === "98977") {
      const cptTransmissions = JSON.parse(transmissions)?.filter(
        (item) => item?.type === "pain_entry" || item?.type === "medicine_entry"
      );

      const distinctDates = [
        ...new Set(cptTransmissions?.map((item) => item?.date)),
      ];

      const inProgressPerc =
        (distinctDates?.length / agencyDetail?.agency?.number_of_days_for_77) *
        100;

      return Math.round(inProgressPerc) >= 100
        ? 100
        : Math.round(inProgressPerc);
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="12px"
      padding="4px 8px"
      bgcolor="#FCFCFC"
      borderRadius="8px"
    >
      {activeTab === 0 ? (
        <WaitingClockIcon color="#FF852D" />
      ) : (
        <TickCircleIcon />
      )}
      {isBelow1200Screen ? (
        <Stack flex="1" gap="2px">
          <Typography variant="body1">CPT Code {cptCode} </Typography>
          <AvatarWithName
            name={patientName}
            imageURL={imageUrl}
            size={14}
            avatarFontSize={8}
            columnGap="4px"
            avatarBgColor={bgColor}
          />
        </Stack>
      ) : (
        <>
          <Stack flex="1">
            <Typography variant="body1">CPT Code {cptCode} </Typography>
          </Stack>

          <Box flex="1">
            <AvatarWithName
              name={patientName}
              imageURL={imageUrl}
              size={14}
              avatarFontSize={8}
              columnGap="4px"
              avatarBgColor={bgColor}
            />
          </Box>
        </>
      )}

      {activeTab === 0 ? (
        <>
          <Typography variant="caption" color="secondary">
            {getPercentage()}%
          </Typography>
        </>
      ) : (
        <Typography variant="caption" color="secondary">
          {dateTimeFormat(approved_at)}
          {/* 10:05am, 18July */}
        </Typography>
      )}
    </Box>
  );
};

export default CptCodesCard;
