import React from "react";

const ReportsIcon = ({ color = "#566875" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M7.58272 8.36328H5.59522C5.12272 8.36328 4.74023 8.74575 4.74023 9.21825V13.0582H7.58272V8.36328V8.36328Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57117 4.94922H8.43116C7.95866 4.94922 7.57617 5.33173 7.57617 5.80423V13.0493H10.4187V5.80423C10.4187 5.33173 10.0437 4.94922 9.57117 4.94922Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4113 9.63672H10.4238V13.0493H13.2663V10.4917C13.2588 10.0192 12.8763 9.63672 12.4113 9.63672Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReportsIcon;
