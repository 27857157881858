import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "..";
import {
  DOWNLOAD_ALL_REPORT_FAIL,
  DOWNLOAD_ALL_REPORT_REQUEST,
  DOWNLOAD_ALL_REPORT_SUCCESS,
  GET_ALL_PAYERS_FAIL,
  GET_ALL_PAYERS_REQUEST,
  GET_ALL_PAYERS_SUCCESS,
  GET_REPORT_CPT_CODES_LIST_FAIL,
  GET_REPORT_CPT_CODES_LIST_REQUEST,
  GET_REPORT_CPT_CODES_LIST_SUCCESS,
  GET_REPORT_STATS_FAIL,
  GET_REPORT_STATS_REQUEST,
  GET_REPORT_STATS_SUCCESS,
  GET_REPORTS_LIST_FAIL,
  GET_REPORTS_LIST_REQUEST,
  GET_REPORTS_LIST_SUCCESS,
  RESET_DOWNLOAD_ALL_REPORT,
} from "../../assets/constants/reportsConstants";

// ================== GET REPORTS LIST  ACTIONS  =========================
export const getReportsListFunc = (reportObj) => async (dispatch) => {
  try {
    dispatch({ type: GET_REPORTS_LIST_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/report/get-list`,
        reportObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_REPORTS_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_REPORTS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== DOWNLOAD REPORTS ALL  ACTIONS  =========================
export const downloadAllReportFunc =
  (reportObj) =>
  async (dispatch) => {
    try {
      dispatch({ type: DOWNLOAD_ALL_REPORT_REQUEST });

      const token = Cookies.get("token");
      const agencyId = Cookies.get("agency_id_vita");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${BASE_URL}/report/download-all-eoc`,
          reportObj,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: DOWNLOAD_ALL_REPORT_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: DOWNLOAD_ALL_REPORT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetDownloadAll = () => async (dispatch) => {
  dispatch({ type: RESET_DOWNLOAD_ALL_REPORT });
};

// ================== GET REPORT STATS ACTIONS  =========================
export const getReportStatsFunc = (statsObj) => async (dispatch) => {
  try {
    dispatch({ type: GET_REPORT_STATS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/report/get-stats`,
        statsObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_REPORT_STATS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_REPORT_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET ALL PAYERS ACTIONS  =========================
export const getAllPayersFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PAYERS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/report/get-payers?agency_id=${agencyId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_ALL_PAYERS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PAYERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET REPORT CPT CODES LIST ACTIONS  =========================
export const getReportCptCodesListFunc =
  (reportCptCodesObj) => async (dispatch) => {
    try {
      dispatch({ type: GET_REPORT_CPT_CODES_LIST_REQUEST });

      const token = Cookies.get("token");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${BASE_URL}/report/get-cpt-codes-list`,
          reportCptCodesObj,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_REPORT_CPT_CODES_LIST_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_REPORT_CPT_CODES_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
