import React from "react";
import { Box, Button } from "@mui/material";
import { isAndroid, isIOS, isMacOs, isWindows } from "react-device-detect";

const CommonStoreButton = () => {
  const appStoreHandler = () => {
    if (isAndroid || isWindows) {
      window.open("https://play.google.com/store/apps/details?id=com.vitatrax");
    } else if (isIOS || isMacOs) {
      window.open("https://apps.apple.com/us/app/vitatrax/id6587574641");
    }
  };

  return (
    <Box padding="20px 0">
      <Button
        variant="contained"
        onClick={appStoreHandler}
        sx={{ textTransform: "capitalize" }}
      >
        Download VitaTrax Mobile app
      </Button>
    </Box>
  );
};

export default CommonStoreButton;
