import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import styled from "@emotion/styled";

const StyledLink = styled(Typography)(() => ({
  color: "rgba(0, 0, 0, 0.38)",
  cursor: "pointer",
}));

const VitatraxFooter = () => {
  return (
    <Box component="footer" display="flex" alignItems="center" gap="8px">
      <StyledLink variant="caption" component="a" href="https://vitatrax.life">
        {" "}
        &#169; 2025 Vitatrax
      </StyledLink>
      <Divider orientation="vertical" variant="middle" flexItem />
      <StyledLink
        variant="caption"
        component="a"
        href="https://vitatrax.life/contact"
      >
        {" "}
        Contact Support{" "}
      </StyledLink>
      <Divider orientation="vertical" variant="middle" flexItem />
      <StyledLink
        variant="caption"
        component="a"
        href="https://vitatrax.life/privacy-policy"
      >
        {" "}
        Privacy Policy
      </StyledLink>
    </Box>
  );
};

export default VitatraxFooter;
