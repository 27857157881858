import {
  ADD_NEW_PATIENT_FAIL,
  ADD_NEW_PATIENT_REQUEST,
  ADD_NEW_PATIENT_SUCCESS,
  ADD_PATIENT_DETAILS_FAIL,
  ADD_PATIENT_DETAILS_REQUEST,
  ADD_PATIENT_DETAILS_SUCCESS,
  ADD_PATIENT_PASSWORD_FAIL,
  ADD_PATIENT_PASSWORD_REQUEST,
  ADD_PATIENT_PASSWORD_SUCCESS,
  ADD_RECORD_CONSENT_FAIL,
  ADD_RECORD_CONSENT_REQUEST,
  ADD_RECORD_CONSENT_SUCCESS,
  DELETE_PATIENT_FAIL,
  DELETE_PATIENT_REQUEST,
  DELETE_PATIENT_SUCCESS,
  EDUCATION_SETUP_FAIL,
  EDUCATION_SETUP_REQUEST,
  EDUCATION_SETUP_SUCCESS,
  EQUIP_SETUP_FAIL,
  EQUIP_SETUP_REQUEST,
  EQUIP_SETUP_SUCCESS,
  EXPORT_PATIENT_LISTINGS_FAIL,
  EXPORT_PATIENT_LISTINGS_REQUEST,
  EXPORT_PATIENT_LISTINGS_SUCCESS,
  GENERATE_EVIDENCE_FAIL,
  GENERATE_EVIDENCE_REQUEST,
  GENERATE_EVIDENCE_SUCCESS,
  GET_AGENCY_PATIENTS_FAIL,
  GET_AGENCY_PATIENTS_REQUEST,
  GET_AGENCY_PATIENTS_SUCCESS,
  GET_ALL_PATIENTS_FAIL,
  GET_ALL_PATIENTS_REQUEST,
  GET_ALL_PATIENTS_SUCCESS,
  GET_A_PATIENT_BY_PID_FAIL,
  GET_A_PATIENT_BY_PID_REQUEST,
  GET_A_PATIENT_BY_PID_SUCCESS,
  GET_CHART_PAINLOGS_FAIL,
  GET_CHART_PAINLOGS_REQUEST,
  GET_CHART_PAINLOGS_SUCCESS,
  GET_CLIENT_PAINLOGS_FAIL,
  GET_CLIENT_PAINLOGS_REQUEST,
  GET_CLIENT_PAINLOGS_SUCCESS,
  GET_DASHBOARD_ALERTS_FAIL,
  GET_DASHBOARD_ALERTS_REQUEST,
  GET_DASHBOARD_ALERTS_SUCCESS,
  GET_DASHBOARD_ANALYTICS_FAIL,
  GET_DASHBOARD_ANALYTICS_REQUEST,
  GET_DASHBOARD_ANALYTICS_SUCCESS,
  GET_MEDICINE_DATA_FAIL,
  GET_MEDICINE_DATA_REQUEST,
  GET_MEDICINE_DATA_SUCCESS,
  GET_NEXT_PATIENT_FAIL,
  GET_NEXT_PATIENT_REQUEST,
  GET_NEXT_PATIENT_SUCCESS,
  GET_PATIENT_REPORTS_FAIL,
  GET_PATIENT_REPORTS_REQUEST,
  GET_PATIENT_REPORTS_SUCCESS,
  GET_PIE_CHART_STATS_FAIL,
  GET_PIE_CHART_STATS_REQUEST,
  GET_PIE_CHART_STATS_SUCCESS,
  GET_PINNED_NOTES_FAIL,
  GET_PINNED_NOTES_REQUEST,
  GET_PINNED_NOTES_SUCCESS,
  GET_TABS_COUNT_FAIL,
  GET_TABS_COUNT_REQUEST,
  GET_TABS_COUNT_SUCCESS,
  GET_UNPAGINATED_PATIENTS_FAIL,
  GET_UNPAGINATED_PATIENTS_REQUEST,
  GET_UNPAGINATED_PATIENTS_SUCCESS,
  GET_UNREAD_CHAT_MESSAGES_FAIL,
  GET_UNREAD_CHAT_MESSAGES_REQUEST,
  GET_UNREAD_CHAT_MESSAGES_SUCCESS,
  REENROLL_PATIENT_FAIL,
  REENROLL_PATIENT_REQUEST,
  REENROLL_PATIENT_SUCCESS,
  RESET_ADD_PATIENT_DETAILS,
  RESET_ADD_PATIENT_PASSWORD,
  RESET_ALL_PATIENTS,
  RESET_A_PATIENT_BY_PID,
  RESET_DELETE_PATIENT,
  RESET_EDUCATION_SETUP,
  RESET_EQUIP_SETUP,
  RESET_EXPORT_PATIENT_LISTINGS,
  RESET_GET_AGENCY_PATIENTS,
  RESET_GET_TABS_COUNT,
  RESET_LISTING_STATES,
  RESET_MEDICINE_DATA,
  RESET_NEXT_PATIENT,
  RESET_PIE_CHART_STATS,
  RESET_RECORD_CONSENT,
  RESET_REENROLL_PATIENT,
  RESET_UNENROLL_PATIENT,
  RESET_UNPAGINATED_PATIENTS,
  SET_ASSIGNED_TO,
  SET_CONSENT_STATUS,
  SET_CPT_CODES_ITEMS,
  SET_EDUCATION_GIVEN,
  SET_ENROLLMENT_STATUS,
  SET_LISTING_TAB,
  SET_NEXT_PATIENT_OBJECT,
  SET_PAGE_NUMBER,
  SET_PAGE_SIZE,
  SET_QUERY,
  SET_SENT_CREDENTIALS,
  SET_SORTED_ITEMS,
  UNENROLL_PATIENT_FAIL,
  UNENROLL_PATIENT_REQUEST,
  UNENROLL_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_RESET,
  UPDATE_PATIENT_SUCCESS,
} from "../../assets/constants/patientConstants";

const initTabState = {
  tab: "all",
  page: 0,
  pageSize: 10,
  assignedTo: [],
  sentCredentialsValue: "",
  enrollmentStatus: "",
  educationGiven: "",
  consentStatusValue: "",
  query: "",
  cptCodeItems: [],
  sortedItems: "",
  nextPatientObject: {},
};

export const patientListingStateReducer = (state = initTabState, action) => {
  switch (action.type) {
    case SET_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_LISTING_TAB:
      return {
        ...state,
        tab: action.payload,
      };

    case SET_ASSIGNED_TO:
      return {
        ...state,
        assignedTo: action.payload,
      };

    case SET_SENT_CREDENTIALS:
      return {
        ...state,
        sentCredentialsValue: action.payload,
      };

    case SET_ENROLLMENT_STATUS:
      return {
        ...state,
        enrollmentStatus: action.payload,
      };

    case SET_EDUCATION_GIVEN:
      return {
        ...state,
        educationGiven: action.payload,
      };

    case SET_CONSENT_STATUS:
      return {
        ...state,
        consentStatusValue: action.payload,
      };

    case SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case SET_CPT_CODES_ITEMS:
      return {
        ...state,
        cptCodeItems: action.payload,
      };

    case SET_SORTED_ITEMS:
      return {
        ...state,
        sortedItems: action.payload,
      };

    case SET_NEXT_PATIENT_OBJECT:
      return {
        ...state,
        nextPatientObject: action.payload,
      };

    case RESET_LISTING_STATES:
      return {
        tab: "all",
        page: 0,
        pageSize: 10,
        assignedTo: [],
        enrollmentStatus: "",
        educationGiven: "",
        sentCredentialsValue: "",
        consentStatusValue: "",
        query: "",
        cptCodeItems: [],
        sortedItems: "",
        nextPatientObject: {},
      };

    default:
      return { ...state };
  }
};

// GET ALL CASES REDUCER
export const getAllCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PATIENTS_REQUEST:
      return { loading: true };
    case GET_ALL_PATIENTS_SUCCESS:
      return { loading: false, cases: action.payload };
    case GET_ALL_PATIENTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ALL_PATIENTS:
      return { loading: false, cases: [] };
    default:
      return state;
  }
};

// GET TABS COUNT REDUCER
export const getTabsCountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TABS_COUNT_REQUEST:
      return { loading: true };
    case GET_TABS_COUNT_SUCCESS:
      return { loading: false, tabsCount: action.payload };
    case GET_TABS_COUNT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_TABS_COUNT:
      return {};
    default:
      return state;
  }
};

// GET NEXT PATIENT REDUCER
export const getNextPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NEXT_PATIENT_REQUEST:
      return { loading: true };
    case GET_NEXT_PATIENT_SUCCESS:
      return { loading: false, nextPatientData: action.payload };
    case GET_NEXT_PATIENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_NEXT_PATIENT:
      return {};
    default:
      return state;
  }
};

// GET ALL PATIENTS BY AGENCY REDUCER
export const getAllPatientsByAgencyReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENCY_PATIENTS_REQUEST:
      return { loading: true, allAgencyPatients: [], error: null };
    case GET_AGENCY_PATIENTS_SUCCESS:
      return { loading: false, allAgencyPatients: action.payload, error: null };
    case GET_AGENCY_PATIENTS_FAIL:
      return { loading: false, error: action.payload, allAgencyPatients: [] };
    case RESET_GET_AGENCY_PATIENTS:
      return {};
    default:
      return state;
  }
};

// GET ALL UNPAGINATED CASES REDUCER
export const getUnpaginatedPatientsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UNPAGINATED_PATIENTS_REQUEST:
      return { loading: true, allUnpaginatedCases: [] };
    case GET_UNPAGINATED_PATIENTS_SUCCESS:
      return { loading: false, allUnpaginatedCases: action.payload };
    case GET_UNPAGINATED_PATIENTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UNPAGINATED_PATIENTS:
      return { loading: false, allUnpaginatedCases: [] };
    default:
      return state;
  }
};

// GET A CASE BY CID REDUCER
export const getPatientByPatientIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_A_PATIENT_BY_PID_REQUEST:
      return { loading: true };
    case GET_A_PATIENT_BY_PID_SUCCESS:
      return { loading: false, patientDetail: action.payload };
    case GET_A_PATIENT_BY_PID_FAIL:
      return { loading: false, error: action.payload };
    case RESET_A_PATIENT_BY_PID:
      return {};
    default:
      return state;
  }
};

// ADD RECORD CONSENT REDUCER
export const addRecordConsentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_RECORD_CONSENT_REQUEST:
      return { loading: true };
    case ADD_RECORD_CONSENT_SUCCESS:
      return { loading: false, consentRecorded: action.payload };
    case ADD_RECORD_CONSENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_RECORD_CONSENT:
      return {};
    default:
      return state;
  }
};

// ADD EQUIP SETUP REDUCER
export const addEquipSetupReducer = (state = {}, action) => {
  switch (action.type) {
    case EQUIP_SETUP_REQUEST:
      return { loading: true };
    case EQUIP_SETUP_SUCCESS:
      return { loading: false, equipSetupDone: action.payload };
    case EQUIP_SETUP_FAIL:
      return { loading: false, error: action.payload };
    case RESET_EQUIP_SETUP:
      return {};
    default:
      return state;
  }
};

// ADD EDUCATION SETUP REDUCER
export const addEducationSetupReducer = (state = {}, action) => {
  switch (action.type) {
    case EDUCATION_SETUP_REQUEST:
      return { loading: true };
    case EDUCATION_SETUP_SUCCESS:
      return { loading: false, educationSetupDone: action.payload };
    case EDUCATION_SETUP_FAIL:
      return { loading: false, error: action.payload };
    case RESET_EDUCATION_SETUP:
      return {};
    default:
      return state;
  }
};

// UNENROLL PATIENT REDUCER
export const unEnrollPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case UNENROLL_PATIENT_REQUEST:
      return { loading: true };
    case UNENROLL_PATIENT_SUCCESS:
      return { loading: false, unEnrolledPatient: action.payload };
    case UNENROLL_PATIENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UNENROLL_PATIENT:
      return {};
    default:
      return state;
  }
};

// GET ALL CASES REDUCER
export const updateCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PATIENT_REQUEST:
      return { loading: true };
    case UPDATE_PATIENT_SUCCESS:
      return { loading: false, updatedCase: action.payload };
    case UPDATE_PATIENT_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_PATIENT_RESET:
      return {};
    default:
      return state;
  }
};

// GET CLIENT PAINLOGS REDUCER
export const getClientPainlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENT_PAINLOGS_REQUEST:
      return { loading: true };
    case GET_CLIENT_PAINLOGS_SUCCESS:
      return { loading: false, painlogs: action.payload };
    case GET_CLIENT_PAINLOGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET MEDICINE DATA REDUCER
export const getMedicineDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MEDICINE_DATA_REQUEST:
      return { loading: true };
    case GET_MEDICINE_DATA_SUCCESS:
      return { loading: false, medicineData: action.payload };
    case GET_MEDICINE_DATA_FAIL:
      return { loading: false, error: action.payload };
    case RESET_MEDICINE_DATA:
      return {};
    default:
      return state;
  }
};

// GET CHART PAINLOGS REDUCER
export const getChartPainlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHART_PAINLOGS_REQUEST:
      return { loading: true };
    case GET_CHART_PAINLOGS_SUCCESS:
      return { loading: false, chartPainLogs: action.payload };
    case GET_CHART_PAINLOGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ADD/POST NEW CASE REDUCER
export const addNewCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NEW_PATIENT_REQUEST:
      return { loading: true };
    case ADD_NEW_PATIENT_SUCCESS:
      return { loading: false, newcase: action.payload };
    case ADD_NEW_PATIENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DELETE CASE LISTING REDUCER
export const deleteCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PATIENT_REQUEST:
      return { loading: true };
    case DELETE_PATIENT_SUCCESS:
      return { loading: false, success: true };
    case DELETE_PATIENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_PATIENT:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// GENERATE EVIDENCE PDF REDUCER
export const generateEvidenceReducer = (state = {}, action) => {
  switch (action.type) {
    case GENERATE_EVIDENCE_REQUEST:
      return { loading: true, error: null };
    case GENERATE_EVIDENCE_SUCCESS:
      return {
        loading: false,
        generatedReport: action.payload,
      };
    case GENERATE_EVIDENCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET DASHBOARD ANALYTICS REDUCER
export const getDashboardAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ANALYTICS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_DASHBOARD_ANALYTICS_SUCCESS:
      return { ...state, loading: false, dashboardAnalytic: action.payload };
    case GET_DASHBOARD_ANALYTICS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET DASHBOARD ALERTS REDUCER
export const getDashboardAlertsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ALERTS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_DASHBOARD_ALERTS_SUCCESS:
      return { ...state, loading: false, dashboardAlert: action.payload };
    case GET_DASHBOARD_ALERTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET UNREAD CHAT MESSAGES REDUCER
export const getUnreadChatMessagesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UNREAD_CHAT_MESSAGES_REQUEST:
      return { loading: true, error: null };
    case GET_UNREAD_CHAT_MESSAGES_SUCCESS:
      return {
        loading: false,
        unreadChatMessages: action.payload,
        error: null,
      };
    case GET_UNREAD_CHAT_MESSAGES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET PIE CHART STATS REDUCER
export const getPieChartStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PIE_CHART_STATS_REQUEST:
      return { loading: true, error: null };
    case GET_PIE_CHART_STATS_SUCCESS:
      return {
        loading: false,
        pieChartStats: action.payload,
        error: null,
      };
    case GET_PIE_CHART_STATS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_PIE_CHART_STATS:
      return { loading: false, error: null, pieChartStats: {} };
    default:
      return state;
  }
};

// ADD PATIENT DETAILS REDUCER
export const addPatientDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PATIENT_DETAILS_REQUEST:
      return { loading: true };
    case ADD_PATIENT_DETAILS_SUCCESS:
      return { loading: false, patientDetails: action.payload };
    case ADD_PATIENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ADD_PATIENT_DETAILS:
      return {};
    default:
      return state;
  }
};

// ADD PATIENT PASSWORD REDUCER
export const addPatientPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PATIENT_PASSWORD_REQUEST:
      return { loading: true };
    case ADD_PATIENT_PASSWORD_SUCCESS:
      return { loading: false, patientPassword: action.payload };
    case ADD_PATIENT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ADD_PATIENT_PASSWORD:
      return {};
    default:
      return state;
  }
};

// RE ENROLL PATIENT REDUCER
export const reEnrollPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case REENROLL_PATIENT_REQUEST:
      return { loading: true };
    case REENROLL_PATIENT_SUCCESS:
      return { loading: false, reEnrolledPatient: action.payload };
    case REENROLL_PATIENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_REENROLL_PATIENT:
      return {};
    default:
      return state;
  }
};

// EXPORT PATIENT LISTINGS REDUCER
export const exportPatientListingsReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPORT_PATIENT_LISTINGS_REQUEST:
      return { loading: true };
    case EXPORT_PATIENT_LISTINGS_SUCCESS:
      return { loading: false, exportedPatientListings: action.payload };
    case EXPORT_PATIENT_LISTINGS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_EXPORT_PATIENT_LISTINGS:
      return {};
    default:
      return state;
  }
};

// GET PATIENT REPORTS REDUCER
export const getPatientReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PATIENT_REPORTS_REQUEST:
      return { loading: true };
    case GET_PATIENT_REPORTS_SUCCESS:
      return { loading: false, patientReports: action.payload };
    case GET_PATIENT_REPORTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET PINNED NOTES REDUCER
export const getPinnedNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PINNED_NOTES_REQUEST:
      return { loading: true };
    case GET_PINNED_NOTES_SUCCESS:
      return { loading: false, pinnedNotes: action.payload };
    case GET_PINNED_NOTES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
