import axios from "axios";
import {
  ADD_BULK_NOTES_FAIL,
  ADD_BULK_NOTES_REQUEST,
  ADD_BULK_NOTES_SUCCESS,
  ALERT_NOTIFICATIONS_FAIL,
  ALERT_NOTIFICATIONS_REQUEST,
  ALERT_NOTIFICATIONS_SUCCESS,
  ALL_NOTIFICATIONS_FAIL,
  ALL_NOTIFICATIONS_REQUEST,
  ALL_NOTIFICATIONS_SUCCESS,
  CHANGED_AGENCY_ID,
  CHAT_NOTIFICATIONS_FAIL,
  CHAT_NOTIFICATIONS_REQUEST,
  CHAT_NOTIFICATIONS_SUCCESS,
  CLOSE_TIME_MODAL,
  CREATE_CUSTOM_NOTE_FAIL,
  CREATE_CUSTOM_NOTE_REQUEST,
  CREATE_CUSTOM_NOTE_SUCCESS,
  CURRENT_PATIENT_INDEX,
  DISABLE_APP_ACCESS_FAIL,
  DISABLE_APP_ACCESS_REQUEST,
  DISABLE_APP_ACCESS_SUCCESS,
  ENABLE_APP_ACCESS_FAIL,
  ENABLE_APP_ACCESS_REQUEST,
  ENABLE_APP_ACCESS_SUCCESS,
  GET_BODY_PARTS_FAIL,
  GET_BODY_PARTS_REQUEST,
  GET_BODY_PARTS_SUCCESS,
  GET_MOBILE_STATUS_FAIL,
  GET_MOBILE_STATUS_REQUEST,
  GET_MOBILE_STATUS_SUCCESS,
  GET_REVIEWED_PATIENTS_FAIL,
  GET_REVIEWED_PATIENTS_REQUEST,
  GET_REVIEWED_PATIENTS_SUCCESS,
  GET_UNREVIEWED_PATIENTS_FAIL,
  GET_UNREVIEWED_PATIENTS_REQUEST,
  GET_UNREVIEWED_PATIENTS_SUCCESS,
  IS_ACTION_PERFORMED,
  MARK_NOTIFICATIONS_FAIL,
  MARK_NOTIFICATIONS_REQUEST,
  MARK_NOTIFICATIONS_SUCCESS,
  MARK_PATIENT_REVIEWED_FAIL,
  MARK_PATIENT_REVIEWED_REQUEST,
  MARK_PATIENT_REVIEWED_SUCCESS,
  OPEN_TIME_MODAL,
  RESET_ADD_BULK_NOTES,
  RESET_ALERT_NOTIFICATIONS,
  RESET_ALL_NOTIFICATIONS,
  RESET_APP_ACCESS_STATE,
  RESET_BODY_PARTS,
  RESET_CHAT_NOTIFICATIONS,
  RESET_CREATE_CUSTOM_NOTE,
  RESET_GET_MOBILE_STATUS,
  RESET_MARK_PATIENT_REVIEWED,
  RESET_SEND_BULK_MESSAGES,
  RESET_SEND_INACTIVE_EMAIL,
  RESET_SEND_PAIN_LOG_REMINDER,
  RESET_SEND_PENDING_EMAIL,
  RESET_TIMER_ITEMS,
  RESET_UPDATE_CUSTOM_NOTE,
  RESET_USER_ACTIVITY_NOTIFICATIONS,
  RESET_USER_FEED_NOTIFICATIONS,
  SEND_BULK_MESSAGES_FAIL,
  SEND_BULK_MESSAGES_REQUEST,
  SEND_BULK_MESSAGES_SUCCESS,
  SEND_INACTIVE_EMAIL_FAIL,
  SEND_INACTIVE_EMAIL_REQUEST,
  SEND_INACTIVE_EMAIL_SUCCESS,
  SEND_PAIN_LOG_REMINDER_FAIL,
  SEND_PAIN_LOG_REMINDER_REQUEST,
  SEND_PAIN_LOG_REMINDER_SUCCESS,
  SEND_PENDING_EMAIL_FAIL,
  SEND_PENDING_EMAIL_REQUEST,
  SEND_PENDING_EMAIL_SUCCESS,
  SET_CLICKED_LOGOUT,
  SET_COMM_INTERACTION,
  SET_DATE_VALUE,
  SET_ELAPSED_TIME,
  SET_END_TIME,
  SET_GO_BACK,
  SET_GO_NEXT_PATIENT,
  SET_IS_PAUSED,
  SET_IS_RUNNING,
  SET_IS_STOPPED,
  SET_LOG_TYPE_VALUE,
  SET_NAVIGATE_ITEM,
  SET_NOTES_VALUE,
  SET_PAUSED_START_TIME,
  SET_PAUSED_TIME,
  SET_PREVIOUS_PAGE,
  SET_START_TIME,
  SET_TIME_CHANGED,
  SHOW_REVIEWED_SCREEN,
  TOGGLE_IMPORT_MINIMIZE,
  UPDATE_CUSTOM_NOTE_FAIL,
  UPDATE_CUSTOM_NOTE_REQUEST,
  UPDATE_CUSTOM_NOTE_SUCCESS,
  USER_ACTIVITY_NOTIFICATIONS_FAIL,
  USER_ACTIVITY_NOTIFICATIONS_REQUEST,
  USER_ACTIVITY_NOTIFICATIONS_SUCCESS,
  USER_FEED_NOTIFICATIONS_FAIL,
  USER_FEED_NOTIFICATIONS_REQUEST,
  USER_FEED_NOTIFICATIONS_SUCCESS,
} from "../../assets/constants/appConstants";
import { BASE_URL } from "..";
import Cookies from "js-cookie";

// ================== TIMER  ACTIONS  =========================

export const openTimeModal = () => (dispatch) =>
  dispatch({
    type: OPEN_TIME_MODAL,
  });

export const elapsedTimeFunc = (time) => (dispatch) =>
  dispatch({
    type: SET_ELAPSED_TIME,
    payload: time,
  });

export const closeTimeModal = () => ({
  type: CLOSE_TIME_MODAL,
});

export const setStartTime = (startTime) => (dispatch) =>
  dispatch({
    type: SET_START_TIME,
    payload: startTime,
  });

export const setEndTime = (endTime) => (dispatch) => {
  return dispatch({
    type: SET_END_TIME,
    payload: endTime,
  });
};

export const timeChangedFunc = (timeChanged) => (dispatch) => {
  return dispatch({
    type: SET_TIME_CHANGED,
    payload: timeChanged,
  });
};

export const setPausedTime = (pausedTime) => (dispatch) => {
  return dispatch({
    type: SET_PAUSED_TIME,
    payload: pausedTime,
  });
};

export const setPauseStartTime = (pauseStartTime) => ({
  type: SET_PAUSED_START_TIME,
  payload: pauseStartTime,
});

export const setGoBackFunc = (isGoBack) => (dispatch) => {
  return dispatch({
    type: SET_GO_BACK,
    payload: isGoBack,
  });
};

export const setGoNextPatientFunc = (goNextPatient) => (dispatch) => {
  return dispatch({
    type: SET_GO_NEXT_PATIENT,
    payload: goNextPatient,
  });
};

export const setPreviousPageFunc = (isPreviousPage) => (dispatch) => {
  return dispatch({
    type: SET_PREVIOUS_PAGE,
    payload: isPreviousPage,
  });
};

export const setNavigateItemFunc = (navigatePath) => (dispatch) => {
  return dispatch({
    type: SET_NAVIGATE_ITEM,
    payload: navigatePath,
  });
};

export const setLogTypeValue = (logType) => (dispatch) =>
  dispatch({
    type: SET_LOG_TYPE_VALUE,
    payload: logType,
  });

export const setToggleMinimize = (value) => (dispatch) =>
  dispatch({
    type: TOGGLE_IMPORT_MINIMIZE,
    payload: value,
  });

// export const setPatientIdFunc = (patientId) => (dispatch) =>
//   dispatch({
//     type: SET_PATIENT_ID,
//     payload: patientId,
//   });

export const setDateValue = (dateValue) => (dispatch) =>
  dispatch({
    type: SET_DATE_VALUE,
    payload: dateValue,
  });

export const setNotesValue = (notesValue) => (dispatch) =>
  dispatch({
    type: SET_NOTES_VALUE,
    payload: notesValue,
  });

export const setCommunicationInteraction = (commInteraction) => (dispatch) =>
  dispatch({
    type: SET_COMM_INTERACTION,
    payload: commInteraction,
  });

export const setIsRunning = (isRunning) => (dispatch) => {
  return dispatch({
    type: SET_IS_RUNNING,
    payload: isRunning,
  });
};

export const setIsPaused = (isPaused) => (dispatch) =>
  dispatch({
    type: SET_IS_PAUSED,
    payload: isPaused,
  });

export const setIsStopped = (isStopped) => (dispatch) =>
  dispatch({
    type: SET_IS_STOPPED,
    payload: isStopped,
  });

export const resetTimerItemsFunc = () => (dispatch) =>
  dispatch({
    type: RESET_TIMER_ITEMS,
  });

export const setLogoutClickedFunc = (logoutClicked) => (dispatch) =>
  dispatch({
    type: SET_CLICKED_LOGOUT,
    payload: logoutClicked,
  });

export const getCurrentPatientIndexFunc = (index) => (dispatch) =>
  dispatch({
    type: CURRENT_PATIENT_INDEX,
    payload: index,
  });

export const actionsPerformedFunc = (done) => (dispatch) =>
  dispatch({
    type: IS_ACTION_PERFORMED,
    payload: done,
  });

export const setShowReviewedScreenFunc = (value) => (dispatch) =>
  dispatch({
    type: SHOW_REVIEWED_SCREEN,
    payload: value,
  });

// ================== GET MOBILE APP STATUS ACTIONS  =========================
export const getMobileStatusFunc = (patientId) => async (dispatch) => {
  try {
    dispatch({ type: GET_MOBILE_STATUS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/patient/get-activity?patient_id=${patientId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_MOBILE_STATUS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_MOBILE_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== SEND BULK MESSAGES ACTIONS  =========================
export const sendBulkMessagesFunc = (messageObj) => async (dispatch) => {
  try {
    dispatch({ type: SEND_BULK_MESSAGES_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/chat/send-bulk-message`,
        messageObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: SEND_BULK_MESSAGES_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: SEND_BULK_MESSAGES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetAddBulkMessagesFunc = () => (dispatch) =>
  dispatch({
    type: RESET_SEND_BULK_MESSAGES,
  });

export const resetAppStatus = () => (dispatch) =>
  dispatch({
    type: RESET_GET_MOBILE_STATUS,
  });

// ================== ADD BULK NOTES ACTIONS  =========================
export const addBulkNotesFunc = (notesObj) => async (dispatch) => {
  try {
    dispatch({ type: ADD_BULK_NOTES_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient-note/create-bulk`,
        notesObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ADD_BULK_NOTES_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_BULK_NOTES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetAddBulkNotesFunc = () => (dispatch) =>
  dispatch({
    type: RESET_ADD_BULK_NOTES,
  });

// ================== SENDING MOBILE APP REMINDERS STATUS ACTIONS  =========================
export const sendInactiveEmailReminderFunc =
  (patientObj) => async (dispatch) => {
    try {
      dispatch({ type: SEND_INACTIVE_EMAIL_REQUEST });

      const token = Cookies.get("token");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${BASE_URL}/patient/send-inactivity-reminder`,
          patientObj,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: SEND_INACTIVE_EMAIL_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SEND_INACTIVE_EMAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetInactiveReminder = () => (dispatch) =>
  dispatch({
    type: RESET_SEND_INACTIVE_EMAIL,
  });

// PENDING MOBILE APP STATUS REMINDER
export const sendPendingEmailReminderFunc =
  (patientObj) => async (dispatch) => {
    try {
      dispatch({ type: SEND_PENDING_EMAIL_REQUEST });

      const token = Cookies.get("token");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${BASE_URL}/patient/send-signup-reminder`,
          patientObj,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: SEND_PENDING_EMAIL_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SEND_PENDING_EMAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetPendingReminder = () => (dispatch) =>
  dispatch({
    type: RESET_SEND_PENDING_EMAIL,
  });
// ================== CREATE CUSTOM NOTES ACTIONS  =========================
export const createCustomNotesFunc = (notesObj) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CUSTOM_NOTE_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient-note/create`,
        notesObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: CREATE_CUSTOM_NOTE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_CUSTOM_NOTE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetCreateCustomNotes = () => (dispatch) =>
  dispatch({
    type: RESET_CREATE_CUSTOM_NOTE,
  });

// ================== UPDATE CUSTOM NOTES ACTIONS  =========================
export const updateCustomNotesFunc = (notesObj) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUSTOM_NOTE_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient-note/update`,
        notesObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: UPDATE_CUSTOM_NOTE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_CUSTOM_NOTE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetUpdateCustomNotes = () => (dispatch) =>
  dispatch({
    type: RESET_UPDATE_CUSTOM_NOTE,
  });

// ================== DISABLE ACCESS APP ACTIONS  =========================
export const disableAppAccessFunc = (clientId) => async (dispatch) => {
  try {
    dispatch({ type: DISABLE_APP_ACCESS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/revoke-client-access`,
        { client_id: clientId },
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: DISABLE_APP_ACCESS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DISABLE_APP_ACCESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET UNREVIEWED PATIENTS ACTIONS  =========================
export const getUnreviewedPatientsFunc = (agentId) => async (dispatch) => {
  try {
    dispatch({ type: GET_UNREVIEWED_PATIENTS_REQUEST });

    const token = Cookies.get("token");
    const agency_id = Cookies.get("agency_id_vita");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let response;
      if (agentId === "all") {
        response = await axios.get(
          `${BASE_URL}/dashboard/get-unreviewed-patients?agency_id=${agency_id}`,
          config
        );
      } else {
        response = await axios.get(
          `${BASE_URL}/dashboard/get-unreviewed-patients?agency_id=${agency_id}&assigned_to=${agentId}`,
          config
        );
      }

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_UNREVIEWED_PATIENTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_UNREVIEWED_PATIENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET REVIEWED PATIENTS ACTIONS  =========================
export const getReviewedPatientsFunc = (agentId) => async (dispatch) => {
  try {
    dispatch({ type: GET_REVIEWED_PATIENTS_REQUEST });

    const token = Cookies.get("token");
    const agency_id = Cookies.get("agency_id_vita");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let response;
      if (agentId === "all") {
        response = await axios.get(
          `${BASE_URL}/dashboard/get-reviewed-patients?agency_id=${agency_id}`,
          config
        );
      } else {
        response = await axios.get(
          `${BASE_URL}/dashboard/get-reviewed-patients?agency_id=${agency_id}&assigned_to=${agentId}`,
          config
        );
      }

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_REVIEWED_PATIENTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_REVIEWED_PATIENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== MARK PATIENT REVIEWED ACTIONS  =========================
export const markPatientReviewedFunc = (patientIdsObj) => async (dispatch) => {
  try {
    dispatch({ type: MARK_PATIENT_REVIEWED_REQUEST });

    const token = Cookies.get("token");
    const agency_id = Cookies.get("agency_id_vita");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/mark-patients-as-reviewed`,
        patientIdsObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: MARK_PATIENT_REVIEWED_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: MARK_PATIENT_REVIEWED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetMarkPatientReviewedFunc = () => (dispatch) =>
  dispatch({
    type: RESET_MARK_PATIENT_REVIEWED,
  });

// ================== ENABLE ACCESS APP ACTIONS  =========================
export const enableAppAccessFunc = (clientId) => async (dispatch) => {
  try {
    dispatch({ type: ENABLE_APP_ACCESS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/enable-client-access`,
        { client_id: clientId },
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ENABLE_APP_ACCESS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ENABLE_APP_ACCESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET USER FEEDS NOTIFICATIONS ACTIONS  ========================
export const userFeedNotificationsFunc =
  (page, pageSize) => async (dispatch) => {
    try {
      dispatch({ type: USER_FEED_NOTIFICATIONS_REQUEST });

      const token = Cookies.get("token");
      const agencyId = Cookies.get("agency_id_vita");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/log/get-user-feed-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: USER_FEED_NOTIFICATIONS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: USER_FEED_NOTIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ========== CHANGED AGENCY ID FUNC ===============

export const changedAgencyIdFunc = (changed) => (dispatch) => {
  dispatch({ type: CHANGED_AGENCY_ID, payload: changed });
};

// ============= GET ALL NOTIFICATIONS ACTIONS  ========================
export const allNotificationsFunc = (page, pageSize) => async (dispatch) => {
  try {
    dispatch({ type: ALL_NOTIFICATIONS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/log/get-notifications-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ALL_NOTIFICATIONS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_NOTIFICATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET CHAT NOTIFICATIONS ACTIONS  ========================
export const chatNotificationsFunc = (page, pageSize) => async (dispatch) => {
  try {
    dispatch({ type: CHAT_NOTIFICATIONS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/log/get-chat-activity-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: CHAT_NOTIFICATIONS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CHAT_NOTIFICATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET ALERT NOTIFICATIONS ACTIONS  ========================
export const alertNotificationsFunc = (page, pageSize) => async (dispatch) => {
  try {
    dispatch({ type: ALERT_NOTIFICATIONS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/log/get-alerts-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ALERT_NOTIFICATIONS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ALERT_NOTIFICATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET USER ACTIVITY NOTIFICATIONS ACTIONS  ========================
export const userActivityNotificationsFunc =
  (page, pageSize) => async (dispatch) => {
    try {
      dispatch({ type: USER_ACTIVITY_NOTIFICATIONS_REQUEST });

      const token = Cookies.get("token");
      const agencyId = Cookies.get("agency_id_vita");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/log/get-user-activity-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: USER_ACTIVITY_NOTIFICATIONS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: USER_ACTIVITY_NOTIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ============= MARKED AS READ NOTIFICATIONS ACTIONS  ========================
export const markNotificationsFunc = () => async (dispatch) => {
  try {
    dispatch({ type: MARK_NOTIFICATIONS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/mark-notifications-as-read`,
        {},
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: MARK_NOTIFICATIONS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: MARK_NOTIFICATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET ALL BODY PARTS ACTIONS  ========================
export const getAllBodyPartsFunc = (patientId) => async (dispatch) => {
  try {
    dispatch({ type: GET_BODY_PARTS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/pain-entry/get-body-parts?patient_id=${patientId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_BODY_PARTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_BODY_PARTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetAllBodyParts = () => (dispatch) =>
  dispatch({
    type: RESET_BODY_PARTS,
  });

export const resetUserFeedNotifications = () => ({
  type: RESET_USER_FEED_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetAllNotifications = () => ({
  type: RESET_ALL_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetChatNotifications = () => ({
  type: RESET_CHAT_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetAlertNotifications = () => ({
  type: RESET_ALERT_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetUserActivityNotifications = () => ({
  type: RESET_USER_ACTIVITY_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetAppAccessState = () => (dispatch) =>
  dispatch({
    type: RESET_APP_ACCESS_STATE,
  });

// ================== SEND MISSED  PAIN LOG REMINDER ACTIONS  =========================
export const sendPainLogReminderFunc = (painObj) => async (dispatch) => {
  try {
    dispatch({ type: SEND_PAIN_LOG_REMINDER_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/pain-entry/send-missed-log-reminder`,
        painObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: SEND_PAIN_LOG_REMINDER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: SEND_PAIN_LOG_REMINDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetSendPainLogReminder = () => (dispatch) =>
  dispatch({
    type: RESET_SEND_PAIN_LOG_REMINDER,
  });
