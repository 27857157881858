import React, { useEffect, useState } from "react";
import BgBox from "../../common/BgBox";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MccLogo from "../../assets/images/mcc-logo.png";
import VitatraxFooter from "../../components/VitatraxFooter";
import { useLocation, useSearchParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PatientLogoHeader from "../../components/PatientLogoHeader";
import DownloadApp from "../../components/DownloadApp";
import { useDispatch } from "react-redux";
import {
  addPatientPasswordFunc,
  resetPatientPassword,
} from "../../actions/Cases";
import { useSelector } from "react-redux";
import AlertMessage from "../../common/AlertMessage";
import { LoadingButton } from "@mui/lab";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.?])[A-Za-z\d!@#$%^&*.?]{8,}$/;

const SetupPassword = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const patientToken = location?.state?.patientToken;
  const patientId = location?.state?.patientId;

  const agencyId = searchParams.get("agency_id");
  const agencyName = searchParams.get("agency_name");
  const [showCompleteSetup, setShowCompleteSetup] = useState(false);

  const [fieldVals, setFieldVals] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [validPassword, setValidPassword] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [showError, setShowError] = useState(false);

  const addPatientPassword = useSelector((state) => state.addPatientPassword);
  const { loading, error, patientPassword } = addPatientPassword;

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  const changePasswordHandler = (event) => {
    const { name, value } = event?.target;
    setFieldVals({ ...fieldVals, [name]: value });
    if (value) {
      setValidPassword(passwordRegex.test(value));
    } else {
      setValidPassword(true);
    }
  };

  const changeConfirmPasswordHandler = (event) => {
    const { name, value } = event?.target;
    setFieldVals({ ...fieldVals, [name]: value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const submitPasswordHandler = () => {
    if (fieldVals?.newPassword !== fieldVals?.confirmNewPassword) {
      setErrorMsg(true);
      return;
    }

    const passwordData = {
      patient_id: patientId,
      password: fieldVals?.newPassword,
      send_credentials: true,
    };

    dispatch(addPatientPasswordFunc(passwordData, patientToken));

    setErrorMsg(false);
  };

  useEffect(() => {
    if (patientPassword?.ok) {
      setShowCompleteSetup(true);
      dispatch(resetPatientPassword());
    } else if (error) {
      setShowError(true);
    }
  }, [patientPassword, error]);

  return (
    <>
      {showError && (
        <AlertMessage
          message={error}
          severity="error"
          autoHideDuration={6000}
          open={showError}
          handleClose={handleAlertClose}
        />
      )}

      <BgBox>
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          height="100%"
          gap="24px"
          flex="1"
        >
          {showCompleteSetup ? (
            <PatientLogoHeader title="You are all set!" />
          ) : (
            <PatientLogoHeader
              title="Setup Your Password"
              description="This password will be used to login to the app"
              width="269px"
            />
          )}
          {showCompleteSetup ? (
            <DownloadApp />
          ) : (
            <Stack
              gap="24px"
              boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
              padding="24px 16px"
              borderRadius="8px"
              bgcolor="#fff"
              maxWidth="570px"
              width="100%"
            >
              <Typography variant="body1" color="secondary">
                Password requirements: Minimum 8 characters, 1 lowercase & 1
                uppercase letter, 1 number and 1symbol.
              </Typography>
              <Stack gap="20px">
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  value={fieldVals?.newPassword}
                  name="newPassword"
                  helperText={!validPassword && "Enter valid password"}
                  error={!validPassword}
                  onChange={changePasswordHandler}
                  type={showPassword ? "text" : "password"}
                />
                <TextField
                  value={fieldVals?.confirmNewPassword}
                  name="confirmNewPassword"
                  onChange={changeConfirmPasswordHandler}
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  helperText={errorMsg && "Password does not match"}
                  error={errorMsg}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Box alignSelf="flex-end">
                <LoadingButton
                  loading={loading}
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={
                    !fieldVals?.newPassword ||
                    !fieldVals?.confirmNewPassword ||
                    !validPassword
                  }
                  onClick={submitPasswordHandler}
                >
                  SIGNUP
                </LoadingButton>
              </Box>
            </Stack>
          )}
        </Stack>
        <VitatraxFooter />
      </BgBox>
    </>
  );
};

export default SetupPassword;
