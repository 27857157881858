import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import LoadingAutoComplete from "../../LoadingAutoComplete";
import {
  clearContact,
  formatPhoneNumber,
  validateEmail,
} from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { getPatientDetailByPatientId } from "../../../actions/Patients";
import { useSelector } from "react-redux";
import RectangleSkeletons from "../../RectangleSkeletons";
import InsuranceFields from "../../InsuranceFields";

const AddCaseStep = ({
  activeStep,
  steps,
  formValues,
  setFormValues,
  onSubmitNewCase,
  onHandleBack,
  diagnosisCodes,
  setDiagnosisCodes,
  reEnroll,
  selectedPatient,
  isFilledValues,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const dispatch = useDispatch();

  const patientDetailByPatientId = useSelector(
    (state) => state.patientDetailByPatientId
  );
  const { loading, error, patientDetail } = patientDetailByPatientId;

  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const submitAddCaseHandler = async (e) => {
    e.preventDefault();

    // if (!formValues?.primary_insurance_name) {
    //   return;
    // }

    setIsTouched(true);

    onSubmitNewCase();
  };

  const handleBack = () => {
    onHandleBack();
  };

  useEffect(() => {
    if (reEnroll) {
      dispatch(getPatientDetailByPatientId(selectedPatient?.id));
    }
  }, []);

  useEffect(() => {
    setFormValues({
      ...formValues,
      primary_insurance_name: isFilledValues
        ? formValues?.primary_insurance_name
        : patientDetail?.patient?.primary_insurance_provider_name
        ? patientDetail?.patient?.primary_insurance_provider_name
        : "",
      secondary_insurance_name: isFilledValues
        ? formValues?.secondary_insurance_name
        : patientDetail?.patient?.secondary_insurance_provider_name
        ? patientDetail?.patient?.secondary_insurance_provider_name
        : "",
      policy_number: isFilledValues
        ? formValues?.policy_number
        : patientDetail?.patient?.policy_number
        ? patientDetail?.patient?.policy_number
        : "",
      group_number: isFilledValues
        ? formValues?.group_number
        : patientDetail?.patient?.group_number
        ? patientDetail?.patient?.group_number
        : "",
      primary_policy_holder_name: isFilledValues
        ? formValues?.primary_policy_holder_name
        : patientDetail?.patient?.primary_policy_holder_name
        ? patientDetail?.patient?.primary_policy_holder_name
        : "",
      primary_dob: isFilledValues
        ? formValues?.primary_dob
        : patientDetail?.patient?.primary_policy_holder_dob
        ? patientDetail?.patient?.primary_policy_holder_dob
        : null,
      policy_relation: isFilledValues
        ? formValues?.policy_relation
        : patientDetail?.patient?.primary_policy_holder_relation
        ? patientDetail?.patient?.primary_policy_holder_relation
        : "",
      primary_contact: isFilledValues
        ? formValues?.primary_contact
        : patientDetail?.patient?.primary_policy_holder_contact
        ? patientDetail?.patient?.primary_policy_holder_contact
        : "",
      primary_email: isFilledValues
        ? formValues?.primary_email
        : patientDetail?.patient?.primary_policy_holder_email
        ? patientDetail?.patient?.primary_policy_holder_email
        : "",
      medical_notes: isFilledValues
        ? formValues?.medical_notes
        : patientDetail?.patient?.medical_history
        ? patientDetail?.patient?.medical_history
        : "",
    });
    setDiagnosisCodes(
      isFilledValues
        ? diagnosisCodes
        : patientDetail?.patient?.diagnostic_codes
        ? patientDetail?.patient?.diagnostic_codes
        : []
    );
  }, [patientDetail]);

  return (
    <>
      <DialogContent sx={{ padding: "32px !important" }}>
        <Stack gap="24px">
          <Stack gap="8px">
            <Typography
              variant="h6"
              fontSize="16px"
              color="secondary"
              textTransform="uppercase"
            >
              Insurance Information{" "}
            </Typography>
          </Stack>

          {reEnroll ? (
            loading ? (
              <Stack gap="24px">
                <RectangleSkeletons totalRows={1} height={56} />
                <RectangleSkeletons totalRows={1} height={56} />
                <Box display="flex" alignItems="center" width="100%" gap="16px">
                  <RectangleSkeletons totalRows={1} height={56} />
                  <RectangleSkeletons totalRows={1} height={56} />
                </Box>
                <Box display="flex" alignItems="center" width="100%" gap="16px">
                  <RectangleSkeletons totalRows={1} height={56} />
                  <RectangleSkeletons totalRows={1} height={56} />
                </Box>
                <RectangleSkeletons totalRows={1} height={56} />
                <Box display="flex" alignItems="center" width="100%" gap="16px">
                  <RectangleSkeletons totalRows={1} height={56} />
                  <RectangleSkeletons totalRows={1} height={56} />
                </Box>
              </Stack>
            ) : (
              <InsuranceFields
                formValues={formValues}
                setFormValues={setFormValues}
                isTouched={isTouched}
                emailError={emailError}
                setEmailError={setEmailError}
                diagnosisCodes={diagnosisCodes}
                setDiagnosisCodes={setDiagnosisCodes}
              />
            )
          ) : (
            <InsuranceFields
              formValues={formValues}
              setFormValues={setFormValues}
              isTouched={isTouched}
              emailError={emailError}
              setEmailError={setEmailError}
              diagnosisCodes={diagnosisCodes}
              setDiagnosisCodes={setDiagnosisCodes}
            />
          )}
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          padding: "16px 32px",
          boxShadow: "0px -2px 16px 0px rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: reEnroll ? "flex-end" : "space-between",
        }}
      >
        {reEnroll ? (
          <></>
        ) : (
          <Button
            color="primary"
            onClick={handleBack}
            sx={{ textTransform: "uppercase", fontSize: "15px" }}
          >
            Go back
          </Button>
        )}

        <LoadingButton
          id="new-patient-detail-submit"
          variant="contained"
          loading={false}
          onClick={submitAddCaseHandler}
          disabled={emailError}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Continue"}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AddCaseStep;
