import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import LoadingAutoComplete from "../LoadingAutoComplete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  clearContact,
  formatPhoneNumber,
  validateEmail,
} from "../../utils/helpers";

const InsuranceFields = ({
  formValues,
  setFormValues,
  isTouched,
  emailError,
  setEmailError,
  diagnosisCodes,
  setDiagnosisCodes,
}) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const contactNumberHandler = (e) => {
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setFormValues({
      ...formValues,
      primary_contact: formattedNumber,
    });
  };

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;

    setFormValues({ ...formValues, primary_email: emailInputValue });

    const emailIsValid = validateEmail(emailInputValue);
    if (emailInputValue && !emailIsValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  return (
    <>
      {" "}
      <TextField
        id="primary_insurance_name"
        label="Primary Insurance Provider Name"
        value={formValues.primary_insurance_name}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            primary_insurance_name: e.target.value,
          })
        }
        name="primary_insurance_name"
        variant="outlined"
        error={isTouched && !formValues.primary_insurance_name}
      />
      <TextField
        id="secondary_insurance_name"
        label="Secondary Insurance Provider Name (if applicable)"
        value={formValues.secondary_insurance_name}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            secondary_insurance_name: e.target.value,
          })
        }
        name="secondary_insurance_name"
        variant="outlined"
      />
      <Box display="flex" alignItems="center" gap="24px" width="100%">
        <TextField
          id="policy_number"
          name="policy_number"
          value={formValues.policy_number}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              policy_number: e.target.value,
            })
          }
          label="Policy Number/Subscription ID"
          variant="outlined"
          fullWidth
        />
        <TextField
          id="group_number"
          name="group_number"
          value={formValues.group_number}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              group_number: e.target.value,
            })
          }
          label="Group Number/Name"
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box display="flex" alignItems="center" gap="24px" width="100%">
        <TextField
          id="primary_policy_holder_name"
          label="Primary Policyholder's Name"
          value={formValues.caseTitle}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              primary_policy_holder_name: e.target.value,
            })
          }
          fullWidth
          name="primary_policy_holder_name"
          variant="outlined"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Primary Policyholder's Date of Birth"
            name="dateofbirth"
            disableFuture
            value={formValues.primary_dob}
            onChange={(newDate) =>
              setFormValues({ ...formValues, primary_dob: newDate })
            }
            onOpen={() => setIsPickerOpen(true)}
            onClose={() => setIsPickerOpen(false)}
            open={isPickerOpen}
            slotProps={{
              textField: {
                fullWidth: true,
                error: false,
                onClick: () => setIsPickerOpen(true),
              },
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box display="flex" alignItems="center" gap="24px">
        <TextField
          id="policy_relation"
          name="policy_relation"
          value={formValues.policy_relation}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              policy_relation: e.target.value,
            })
          }
          label="Relationship of Patient to Policyholder (e.g., self, spouse, child)"
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box display="flex" alignItems="center" gap="24px" width="100%">
        <TextField
          id="primary_contact"
          name="primary_contact"
          value={formValues.primary_contact}
          onChange={contactNumberHandler}
          label="Primary Policyholder's Phone"
          variant="outlined"
          fullWidth
        />
        <TextField
          id="primary_email"
          name="primary_email"
          value={formValues.primary_email}
          onChange={emailChangeHandler}
          error={emailError}
          helperText={emailError && "Please enter valid email"}
          label="Primary Policyholder's Email"
          variant="outlined"
          fullWidth
        />
      </Box>
      <Typography
        variant="h6"
        fontSize="16px"
        color="secondary"
        sx={{ textTransform: "uppercase" }}
      >
        Medical History{" "}
      </Typography>
      <Box width="100%">
        <LoadingAutoComplete
          medicalCode={diagnosisCodes}
          setMedicalCode={setDiagnosisCodes}
        />
        {/* <Autocomplete
multiple
options={codesArray}
getOptionLabel={(option) =>
  `${option.code} - ${option.description}`
}
renderInput={(params) => (
  <TextField
    {...params}
    label="Select a code"
    variant="outlined"
  />
)}
value={formValues.diagnosis_codes}
onChange={(event, newValue) =>
  setFormValues({ ...formValues, diagnosis_codes: newValue })
}
fullWidth
/> */}
      </Box>
      <TextField
        label="Medical History Notes"
        variant="outlined"
        name="medical_notes"
        multiline
        rows={3}
        value={formValues.medical_notes}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            medical_notes: e.target.value,
          })
        }
      />
    </>
  );
};

export default InsuranceFields;
