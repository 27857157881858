import React from "react";
import AppMobileInfoCard from "../AppMobileInfoCard";
import { Alert, Stack } from "@mui/material";

const ActiveIconContent = ({
  firstLogin,
  lastAppOpened,
  appVersion,
  osVersion,
  patientName,
}) => {
  return (
    <Stack gap="16px">
      <Alert severity="success">
        {patientName} is enrolled and actively using the mobile app.
      </Alert>

      <AppMobileInfoCard
        firstLogin={firstLogin}
        lastAppOpened={lastAppOpened}
        appVersion={appVersion}
        osVersion={osVersion}
      />
    </Stack>
  );
};

export default ActiveIconContent;
