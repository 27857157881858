import axios from "axios";
import {
  CANCEL_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CREATE_BILLING_SUBSCRIPTION_FAIL,
  CREATE_BILLING_SUBSCRIPTION_REQUEST,
  CREATE_BILLING_SUBSCRIPTION_SUCCESS,
  GET_BILLING_SUBSCRIPTION_FAIL,
  GET_BILLING_SUBSCRIPTION_REQUEST,
  GET_BILLING_SUBSCRIPTION_SUCCESS,
  RESET_BILLING_SUBSCRIPTION,
  RESET_CREATE_BILLING_SUBSCRIPTION,
  RESET_UPDATE_BILLING_SUBSCRIPTION,
  UPDATE_BILLING_SUBSCRIPTION_FAIL,
  UPDATE_BILLING_SUBSCRIPTION_REQUEST,
  UPDATE_BILLING_SUBSCRIPTION_SUCCESS,
} from "../../assets/constants/billingSubscription";
import { BASE_URL } from "..";
import Cookies from "js-cookie";

// ================== GET BILLING SUBSCRIPTION ACTIONS  =========================
export const getBillingSubscriptionFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BILLING_SUBSCRIPTION_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(`${BASE_URL}/subscription/get`, config);

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_BILLING_SUBSCRIPTION_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_BILLING_SUBSCRIPTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== CREATE  BILLING SUBSCRIPTION ACTIONS  =========================
export const createBillingSubscriptionFunc =
  (billingObj) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_BILLING_SUBSCRIPTION_REQUEST });

      const token = Cookies.get("token");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${BASE_URL}/subscription/create`,
          billingObj,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: CREATE_BILLING_SUBSCRIPTION_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_BILLING_SUBSCRIPTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ================== UPDATE BILLING SUBSCRIPTION ACTIONS  =========================
export const updateBillingSubscriptionFunc =
  (billingObj) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BILLING_SUBSCRIPTION_REQUEST });

      const token = Cookies.get("token");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${BASE_URL}/subscription/update`,
          billingObj,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: UPDATE_BILLING_SUBSCRIPTION_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_BILLING_SUBSCRIPTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ================== CANCEL BILLING SUBSCRIPTION ACTIONS  =========================
export const cancelBillingSubscriptionFunc = () => async (dispatch) => {
  try {
    dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/subscription/delete`,
        { agency_id: agencyId },
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: CANCEL_SUBSCRIPTION_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CANCEL_SUBSCRIPTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetGetBillingSubscription = () => (dispatch) =>
  dispatch({ type: RESET_BILLING_SUBSCRIPTION });

export const resetCreateBillingSubscription = () => (dispatch) =>
  dispatch({ type: RESET_CREATE_BILLING_SUBSCRIPTION });

export const resetUpdateBillingSubscription = () => (dispatch) =>
  dispatch({ type: RESET_UPDATE_BILLING_SUBSCRIPTION });
