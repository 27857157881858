import {
  ADD_PASSWORD_FAIL,
  ADD_PASSWORD_REQUEST,
  ADD_PASSWORD_SUCCESS,
  OTP_VERIFY_FAIL,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  RESEND_CLIENT_CREDENTIALS_FAIL,
  RESEND_CLIENT_CREDENTIALS_REQUEST,
  RESEND_CLIENT_CREDENTIALS_SUCCESS,
  RESET_LOGIN,
  RESET_OTP_VERIFY,
  RESET_RESEND_CLIENT_CREDENTIALS,
  RESET_UPDATE_FORGOTTEN_PASSWORD,
  RESET_USER_FORGOT,
  RESET_USER_REGISTER,
  UPDATE_FORGOTTEN_PASSWORD_FAIL,
  UPDATE_FORGOTTEN_PASSWORD_REQUEST,
  UPDATE_FORGOTTEN_PASSWORD_SUCCESS,
  USER_FORGOT_FAIL,
  USER_FORGOT_REQUEST,
  USER_FORGOT_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from "../../assets/constants/userConstants";
import axios from "axios";
import { BASE_URL } from "..";
import Cookies from "js-cookie";
import {
  segmentIdentify,
  segmentTrack,
} from "../../utils/helpers/tracking_utils";
import { RESET_ALL_CLIENTS } from "../../assets/constants/agencyConstants";
import { useNavigate } from "react-router-dom";

// token, agent_id, agency_id

// ======================================== LOG IN REDUCER ========================================
export const login = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        "Cache-Control": "no-cache",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/login`,
      formData,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    // if (!response?.data?.ok && response?.data?.statusCode === 401) {
    //   throw new Error("Email or password is incorrect!");
    // }

    // if (!response?.data?.ok) {
    //   console.log("RES", response);
    //   throw new Error(response?.data?.message);
    // }

    const payloadData = {
      token: response?.data?.token,
      agentId: response?.data?.agent?.id,
      agencyId: response?.data?.agent?.agencies[0]?.id,
      agent: response?.data?.agent,
    };

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: payloadData,
    });

    Cookies.set("token", response?.data?.token, { path: "/" });
    Cookies.set("agent_id_vita", response?.data?.agent?.id, { path: "/" });
    Cookies.set("agency_id_vita", response?.data?.agent?.agencies[0]?.id, {
      path: "/",
    });
    Cookies.set(
      "org_id_vita",
      response?.data?.agent?.agencies[0]?.organization_id,
      {
        path: "/",
      }
    );

    if (response?.data?.agent?.agencies?.length) {
      const agency_config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${response?.data?.token}`,
        },
      };

      const agency_response = await axios.get(
        `${BASE_URL}/agency/get?agency_id=${response?.data?.agent?.agencies[0]?.id}`,
        agency_config
      );

      if (agency_response?.data?.agency && response?.data?.agent) {
        segmentIdentify({
          agent: response?.data?.agent,
          agency: agency_response?.data?.agency,
        });
        segmentTrack({
          event: "logged_in",
          agent: response?.data?.agent,
          agency: agency_response?.data?.agency,
        });
      }
    }

    // localStorage.setItem(
    //   "userInfo",
    //   JSON.stringify({
    //     token: response?.data?.token,
    //     agent_id: response?.data?.agent?.id,
    //     agency_id: response?.data?.agent?.agency_id,
    //     userEmail: response?.data?.agent?.email,
    //   })
    // );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error?.response &&
        error?.response?.status &&
        error?.response?.status === 404
          ? error?.message
          : error?.response?.status === 401
          ? "Email or password is incorrect!"
          : error?.message,
    });
  }
};

export const resetLogin = () => (dispatch) => {
  dispatch({ type: RESET_LOGIN });
};

// ======================================== REGISTER ACTION ========================================
export const register = (registerObj) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/register-temp`,
      registerObj,
      config
    );

    dispatch({ type: USER_REGISTER_SUCCESS, payload: response?.data });
    Cookies.set("token", response?.data?.token);
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

// ================== USER LOGOUT =====================
export const logout = () => async (dispatch, getState) => {
  const token = Cookies.get("token");
  const agency_id = Cookies.get("agency_id_vita");

  Cookies.remove("token");
  Cookies.remove("agent_id_vita");
  Cookies.remove("route");
  Cookies.remove("agency_id_vita");
  Cookies.remove("org_id_vita");
  Cookies.remove("selectedIndex");
  localStorage.removeItem("failedMessages");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: RESET_ALL_CLIENTS });

  const agency_config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${token}`,
    },
  };

  const agency_response = await axios.get(
    `${BASE_URL}/agency/get?agency_id=${agency_id}`,
    agency_config
  );

  const { agentProfile } = getState();
  const agent = agentProfile?.agentUser?.agent;

  if (agency_response?.data?.agency && agent) {
    segmentTrack({
      event: "logged_out",
      agent: agent,
      agency: agency_response?.data?.agency,
    });
  }
};

// ================= OTP VERIFY ACTION ==================
export const otpVerifyFunc = (email, otp) => async (dispatch) => {
  try {
    dispatch({ type: OTP_VERIFY_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/verify-otp`,
      { email, otp },
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({ type: OTP_VERIFY_SUCCESS, payload: response?.data });
    Cookies.set("token", response?.data?.token);
    Cookies.set("agent_id_vita", response?.data?.agent?.id);

    // localStorage.setItem(
    //   "agentToken",
    //   JSON.stringify({
    //     token: response?.data?.token,
    //   })
    // );

    // localStorage.setItem(
    //   "agentId",
    //   JSON.stringify({
    //     agentId: response?.data?.agent?.id,
    //   })
    // );

    // dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: response?.data,
    // });

    // localStorage.setItem(
    //   "userInfo",
    //   JSON.stringify({
    //     token: response?.data?.token,
    //     agent_id: response?.data?.agent?.id,
    //     agency_id: response?.data?.agent?.agency_id,
    //     userEmail: response?.data?.agent?.email,
    //   })
    // );
  } catch (error) {
    dispatch({
      type: OTP_VERIFY_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

// ======================================== FORGOT PASSWORD ACTION ========================================
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_FORGOT_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/send-otp`,
      { email },
      config
    );

    // if (data.ok === false) {
    //   dispatch({
    //     type: USER_LOGIN_FAIL,
    //     payload: data?.message,
    //   });
    // }

    dispatch({ type: USER_FORGOT_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: USER_FORGOT_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

export const resetForgotPassword = () => (dispatch) => {
  dispatch({ type: RESET_USER_FORGOT });
};

// ======================================== UPDATE FORGOT PASSWORD ACTION ========================================
export const updateForgotPasswordFunc =
  (email, password) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_FORGOTTEN_PASSWORD_REQUEST });

      // const {
      //   userLogin: { userInfo },
      // } = getState();

      // const { token } = JSON.parse(localStorage.getItem("agentToken"));
      const token = Cookies.get("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/update-forgotten-password`,
        { email, password },
        config
      );

      // if (data.ok === false) {
      //   dispatch({
      //     type: USER_LOGIN_FAIL,
      //     payload: data?.message,
      //   });
      // }

      dispatch({ type: UPDATE_FORGOTTEN_PASSWORD_SUCCESS, payload: response });
    } catch (error) {
      dispatch({
        type: UPDATE_FORGOTTEN_PASSWORD_FAIL,
        payload:
          error?.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
      });
    }
  };

  
export const resetupdateForgotPassword = () => (dispatch) => {
  dispatch({ type: RESET_UPDATE_FORGOTTEN_PASSWORD });
};

// ======================================== ADD PASSWORD ACTION ========================================
export const addpasswordFunc =
  (agent_id, password) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_PASSWORD_REQUEST });

      // const {
      //   userLogin: { userInfo },
      // } = getState();

      // const agentToken = JSON.parse(localStorage.getItem("agentToken"));
      const token = Cookies.get("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/add-password`,
        { agent_id, password },
        config
      );

      // if (data.ok === false) {
      //   dispatch({
      //     type: USER_LOGIN_FAIL,
      //     payload: data?.message,
      //   });
      // }

      dispatch({ type: ADD_PASSWORD_SUCCESS, payload: response });
    } catch (error) {
      dispatch({
        type: ADD_PASSWORD_FAIL,
        payload:
          error?.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
      });
    }
  };

// ============= RESEND CLIENT CREDENTIALS ACTION ==========================
export const resendClientCredentialsFunc = (patientObj) => async (dispatch) => {
  try {
    dispatch({ type: RESEND_CLIENT_CREDENTIALS_REQUEST });

    const token = Cookies.get("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${BASE_URL}/patient/resend-credentials`,
      patientObj,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({
      type: RESEND_CLIENT_CREDENTIALS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: RESEND_CLIENT_CREDENTIALS_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

export const resetOtpVerify = () => async (dispatch) => {
  dispatch({ type: RESET_OTP_VERIFY });
};

export const resetRegister = () => (dispatch) => {
  dispatch({ type: RESET_USER_REGISTER });
};

export const resetResendClientCredsState = () => (dispatch) =>
  dispatch({
    type: RESET_RESEND_CLIENT_CREDENTIALS,
  });

// ======================================== FORGOT EMAIL VERIFY ACTION ========================================
// export const forgotEmailVerify = (email, otp) => async (dispatch) => {
//   try {
//     dispatch({ type: FORGOT_EMAIL_VERIFY_REQUEST });

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };

//     const response = await axios.post(
//       `${BASE_URL}/agent/verify-otp`,
//       { email, otp },
//       config
//     );

//     dispatch({ type: FORGOT_EMAIL_VERIFY_SUCCESS, payload: response });
//   } catch (error) {
//     dispatch({
//       type: FORGOT_EMAIL_VERIFY_FAIL,
//       payload:
//         error?.response && error?.response?.data?.message
//           ? error?.response?.data?.message
//           : error?.message,
//     });
//   }
// };
