import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import SuccessImportBanner from "../../assets/images/successImportBanner.png";
import { useNavigate } from "react-router-dom";
import {
  resetAssignBulk,
  resetImportStatus,
} from "../../actions/ImportFileActions";
import { useDispatch } from "react-redux";

const SuccessImportPage = ({ totalCount, download_url, importedCount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Stack
      //   padding="20px 40px"
      //   paddingBottom="40px"
      height="100%"
      gap="32px"
      alignItems="center"
      justifyContent="center"
    >
      <Box width="391px" height="322px">
        <img
          src={SuccessImportBanner}
          width="100%"
          height="100%"
          alt="import patients banner"
        />
      </Box>
      <Stack gap="16px" alignItems="center" justifyContent="center">
        <Typography variant="h4" fontSize="24px">
          {importedCount} out {totalCount} of Eligible Patients Imported
          Successfully
        </Typography>

        {download_url && (
          <Typography variant="body1" fontSize="1rem" color="#212121">
            <Typography
              component="a"
              href={download_url}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              color="primary"
            >
              {" "}
              Download
            </Typography>{" "}
            list of patient which are not imported
          </Typography>
        )}

        <Box>
          <Button
            variant="text"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              navigate("/patients?tab=all");
              dispatch(resetAssignBulk());
              dispatch(resetImportStatus());
            }}
          >
            Take me back to listing
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default SuccessImportPage;
