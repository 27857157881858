import React, { useEffect, useState } from "react";

// import clsx from "clsx";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import Style from "./style";
import Textfield from "../../../../common/Textfield";
import Button from "../../../../common/Button";
import Dropdown from "../../../../common/Dropdown";
import CheckBoxrow from "../../../../common/checkBoxRow";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../../../common/AlertMessage";
import Cookies from "js-cookie";
import {
  getAgentProfile,
  getAgencyDetailFunc,
} from "../../../../actions/Agency";
import { validateEmail } from "../../../../utils/helpers";
import TeamInfoIcon from "../../../../components/TeamInfoIcon";
import { segmentTrack } from "../../../../utils/helpers/tracking_utils";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
// const Styles = makeStyles((theme) => Style(theme));

const StyledStack = styled(Stack)(({ theme }) => ({
  width: "748px !important",
  [theme.breakpoints.down("md")]: {
    width: "500px !important",
    padding: "1rem !important",
  },
}));

const StyledPosRelative = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  position: "relative",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  width: "75px",
  textAlign: "center",
}));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  width: "100%",
  "& label": {
    paddingBottom: ".5rem",
    width: "100%",
    display: "flex",
    margin: "0",
    justifyContent: "space-between",
  },
}));

const userTypes = [
  "Owner",
  "Provider",
  "Care Coordinator",
  "Organization Admin",
];

const keyLabelConfig = {
  access_patient_details: "Patient Details",
  // can_chat_client: "Can Chat with Clients",
  can_access_billing: "Can Access Billing",
};

export default function InviteDrawer({
  anchor,
  open,
  onClose,
  getAgentsList,
  update,
  setUpdate,
  agent,
  status,
  setStatus,
}) {
  //   const classes = Styles();

  // var userInfo = localStorage.getItem("userInfo");

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);

  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [userType, setUserType] = useState(userTypes[2]);
  const [chatWithClients, setChatWithClients] = useState(false);
  const [administration, setAdministration] = useState(false);
  const [requestDocumentClient, setRequestDocumentClient] = useState(true);
  const [permissions, setPermissions] = useState({
    access_patient_details: {
      create: true,
      read: true,
      edit: true,
      delete: true,
    },
    // can_chat_client: true,
    can_access_billing: false,
  });

  // console.log("Permissions", permissions);
  // console.log("AGENT PERMISSSIONS", JSON.parse(agent?.permissions));
  // console.log("AGENT", agent);

  useEffect(() => {
    if (update) {
      setFirstName(agent?.first_name);
      setLastName(agent?.last_name);
      setFullName(agent?.name);
      setEmail(agent?.email);
      setUserType(agent?.role);
      setPermissions(agent?.permissions);
      // setChatWithClients(agent);
    }
  }, [open]);

  const agentToken = Cookies.get("token");
  const cookieAgentId = Cookies.get("agent_id_vita");
  const cookieAgencyId = Cookies.get("agency_id_vita");

  useEffect(() => {
    // if (userInfo) {
    //   userInfo = JSON.parse(userInfo);
    //   setToken(userInfo.token);
    //   setAgencyID(userInfo.agency_id);
    //   setAgentID(userInfo.agent_id);
    // }

    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;

    setEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);

    if (!emailIsValid) {
      setEmailError(true);
      return;
    }

    setEmailError(false);
  };

  const inviteAgentHandler = async () => {
    if (!userType) return;

    setStatus({
      type: "",
      message: "",
    });

    setLoading(true);

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (update) {
        // UPDATING EXISTING AGENT
        const agentData = {
          agent_id: agent?.id,
          agent_profile: {
            first_name: firstName,
            last_name: lastName,
            name: firstName + " " + lastName,
            email,
            role: userType,
            permissions: permissions,
            // admin: administration,
            // chat_with_clients: chatWithClients,
            // request_documents: requestDocumentClient,
          },
        };

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/agent/update`,
          agentData,
          config
        );

        if (!res?.data?.ok) {
          throw Error(res?.data?.message);
        }

        setLoading(false);

        setStatus({
          type: "success",
          message: "Agent details updated!",
        });

        getAgentsList();
        dispatch(getAgentProfile());

        // console.log(agentData);
        // setUpdate(false);

        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "member_updated",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      } else {
        // ADD NEW AGENT
        const agentData = {
          agency_id: agencyID,
          send_credentials: true,
          agent_obj: {
            first_name: firstName,
            last_name: lastName,
            name: lastName ? firstName + " " + lastName : firstName,
            email,
            role: userType,
            permissions: permissions,
            // admin: administration,
            // chat_with_clients: chatWithClients,
            // request_documents: requestDocumentClient,
          },
        };

        // console.log(agentData);

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/agent/invite-agent`,
          agentData,
          config
        );

        if (!res?.data?.ok) {
          throw Error(res?.data?.message);
        }

        setLoading(false);

        getAgentsList();
        setStatus({
          type: "success",
          message: "Agent invite sent!",
        });

        setFirstName("");
        setLastName("");
        setEmail("");
        setPermissions({
          access_patient_details: {
            create: true,
            read: true,
            edit: true,
            delete: true,
          },

          // can_chat_client: true,
          can_access_billing: false,
        });
        // setChatWithClients(false);
        // setRequestDocumentClient(true);

        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "new_member_created",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      }

      setLoading(false);
      onClose(false);
      setUpdate(false);
      setAlertOpen(true);
    } catch (error) {
      setStatus({
        type: "error",
        message:
          error.data && error.data.message ? error.data.message : error.message,
      });
      setLoading(false);
      // onClose(false);
      setAlertOpen(true);
      // console.log(error);
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      {!loading && (status?.type === "success" || status?.type === "error") && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}

      <Drawer
        anchor={anchor}
        open={open}
        onClose={() => {
          onClose();
          setUpdate(false);
        }}
      >
        <StyledStack p={4} gap={3} height="100%">
          <Stack gap={3} flex="1" sx={{ overflowY: "auto" }}>
            <Stack
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Typography variant="h5">
                {update ? "Edit Team Member" : "Invite Team Member"}
              </Typography>
              <IconButton size="small" onClick={onClose}>
                <CancelOutlinedIcon />
              </IconButton>
            </Stack>
            <Typography
              variant="boxHeading"
              color="disabled.boxHeading"
              sx={{ textTransform: "uppercase" }}
            >
              new team member's information
            </Typography>
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              //   className={clsx(classes.fields__space)}
              spacing={2}
            >
              <Textfield
                placeholder="Enter First Name"
                // error={isTouched && !!error}
                // helperText={error?.message}
                width="100%"
                label="First Name"
                variant="outlined"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                // value={value || ''}
                // onChange={onChange}
              />

              <Textfield
                placeholder="Enter Last Name"
                label="Last Name"
                variant="outlined"
                width="100%"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                // error={isTouched && !!error}
                // helperText={error?.message}
                // value={value || ''}
                // onChange={onChange}
              />
            </Stack>
            <Stack>
              <Textfield
                placeholder="Enter Email"
                margin="dense"
                label="Email Address"
                variant="outlined"
                name="email"
                value={email}
                onChange={emailChangeHandler}
                errorContent={emailError && "Enter valid email"}
                helperText={emailError && "Enter valid email"}
                readOnly={update && true}
                // onChange={onChange}
              />
            </Stack>
            <Typography
              variant="h4"
              color="disabled.customs"
              sx={{ fontWeight: 400 }}
            >
              Auto-generated password shared on invite, changeable after first
              login.
            </Typography>

            <Autocomplete
              id="userType"
              disableClearable
              options={userTypes}
              value={userType}
              // freeSolo
              onChange={(e, value) => {
                setUserType(value);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="User Type" />
              )}
            />

            {/* <Dropdown label="City" /> */}
            <Divider />

            <Typography
              variant="boxHeading"
              color="disabled.boxHeading"
              sx={{ textTransform: "uppercase" }}
            >
              Permissions Manager
            </Typography>

            <Stack>
              <Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Typography
                    variant="h4"
                    color="disabled.customs"
                    sx={{ fontWeight: 600 }}
                    pb={3}
                  >
                    Permission name
                  </Typography>
                  <Stack direction="row" gap="24px">
                    <StyledPosRelative>
                      <StyledTypography
                        variant="h4"
                        color="disabled.customs"
                        sx={{ fontWeight: 600 }}
                      >
                        Create
                      </StyledTypography>
                      <TeamInfoIcon
                        title={`Users with the "Create" permission can add new data to the system.`}
                        maxWidth="212px"
                      />
                    </StyledPosRelative>

                    <StyledPosRelative>
                      <StyledTypography
                        variant="h4"
                        color="disabled.customs"
                        sx={{ fontWeight: 600 }}
                      >
                        View
                      </StyledTypography>

                      <TeamInfoIcon
                        title={`Users with the "View" permission can access and see
data that already exists in the system.`}
                        maxWidth="262px"
                      />
                    </StyledPosRelative>

                    <StyledPosRelative>
                      <StyledTypography
                        variant="h4"
                        color="disabled.customs"
                        sx={{ fontWeight: 600 }}
                      >
                        Edit
                      </StyledTypography>
                      <TeamInfoIcon
                        title={`Users with the "Edit" permission can
                        modify and update existing data.`}
                        maxWidth="212px"
                      />
                    </StyledPosRelative>

                    <StyledPosRelative>
                      <StyledTypography
                        variant="h4"
                        color="disabled.customs"
                        sx={{ fontWeight: 600 }}
                      >
                        Delete
                      </StyledTypography>

                      <TeamInfoIcon
                        title={`Users with the "Delete" permission have the authority
                        to remove data from the system. This includes
                        permanently erasing records.`}
                        maxWidth="261px"
                      />
                    </StyledPosRelative>
                  </Stack>
                </Stack>
                {Object.keys(permissions)
                  .filter(
                    (permission_key) =>
                      typeof permissions[permission_key] === "object"
                  )
                  .map((key) => (
                    <CheckBoxrow
                      key={key}
                      label={keyLabelConfig[key]}
                      permissions={permissions[key]}
                      setPermissions={(newPermission) => {
                        setPermissions({
                          ...permissions,
                          [key]: { ...newPermission },
                        });
                      }}
                    />
                  ))}
                <FormControl component="fieldset">
                  <StyledFormGroup aria-label="position" row>
                    {Object.keys(permissions)
                      .filter(
                        (permission_key) =>
                          typeof permissions[permission_key] === "boolean"
                      )
                      .map((key) => (
                        <FormControlLabel
                          key={key}
                          name={key}
                          value={permissions[key]}
                          control={
                            <Switch
                              color="primary"
                              value={permissions[key]}
                              checked={permissions[key]}
                              onChange={(e) =>
                                setPermissions({
                                  ...permissions,
                                  [key]: e.target.checked,
                                })
                              }
                            />
                          }
                          label={keyLabelConfig[key]}
                          labelPlacement="start"
                        />
                      ))}
                  </StyledFormGroup>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems="flex-end" pt={1.4}>
            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              onClick={inviteAgentHandler}
              disabled={!firstName || !email || emailError}
              // height="40px"
              // width="140px"
            >
              {update ? "Save Changes" : "Send Invite"}
            </LoadingButton>
          </Stack>
        </StyledStack>
      </Drawer>
    </>
  );
}
