import React, { useEffect, useState } from "react";
import BgBox from "../../common/BgBox";
import HeaderText from "../../common/HeaderText";
import CardUI from "../../common/Card";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Textfield from "../../common/Textfield";

import { Link as LinkTo, useLocation, useNavigate } from "react-router-dom";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordConstraint from "../../common/PasswordConstraint";
import { useDispatch, useSelector } from "react-redux";
import { resetupdateForgotPassword, updateForgotPasswordFunc } from "../../actions/Auth";
import AlertMessage from "../../common/AlertMessage";
import CircularProgressLoading from "../../common/CircularProgress";
import MccLogo from "../../components/MccLogo";
import { LoadingButton } from "@mui/lab";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.?])[A-Za-z\d!@#$%^&*.?]{8,}$/;

const ResetPassword = () => {
  const [fieldVals, setFieldVals] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [validPassword, setValidPassword] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  // console.log("LOCATION", location.state);

  useEffect(() => {
    if (!location?.state?.finalEmail) {
      navigate(-1);
    }
  }, []);

  const [errorMsg, setErrorMsg] = useState(null);

  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const dispatch = useDispatch();
  const updateForgotPassword = useSelector(
    (state) => state.updateForgotPassword
  );
  const { loading, error, res } = updateForgotPassword;

  // const userLogin = useSelector((state) => state.userLogin);

  const changePasswordHandler = (event) => {
    const { name, value } = event?.target;
    setFieldVals({ ...fieldVals, [name]: value });
    setValidPassword(passwordRegex.test(event.target.value));
  };

  useEffect(() => {
    if (!error && res?.data?.ok) {
      navigate("/password-changed", { state: { passwordChanged: true } });
      dispatch(resetupdateForgotPassword())
    }
  }, [res]);

  const submitPasswordHandler = (e) => {
    e.preventDefault();

    if (fieldVals.newPassword.length < 8) {
      setErrorMsg("Password should be at least 8 characters");
      return;
    }

    if (fieldVals.newPassword !== fieldVals.confirmNewPassword) {
      setErrorMsg("Passwords should match");
      return;
    }

    dispatch(
      updateForgotPasswordFunc(
        location?.state?.finalEmail,
        fieldVals.newPassword
      )
    );
    setErrorMsg(null);
    setOpen(true);
  };

  if (!location.state?.finalEmail) {
    return;
  }

  return (
    <>
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {!loading && errorMsg && (
        <AlertMessage
          message={errorMsg}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <BgBox>
        <CardUI paddingTop="86px">
          {/* MCC LOGO */}
          <MccLogo />

          <Grid container component="main">
            <Box
              component="form"
              onSubmit={submitPasswordHandler}
              // item
              xs={12}
              display="flex"
              gap="40px"
              flexDirection="column"
              width="100%"
            >
              <Box textAlign="center">
                <HeaderText
                  variant="h3"
                  fontSize={34}
                  textContent="Create new password"
                  lineHeight="24px"
                  textAlign="center"
                />
                <Typography
                  variant="body1"
                  className="d-flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize={16}
                  width="75%"
                  margin="0 auto"
                  mt="16px"
                  color="#566875"
                >
                  Your new password must be different from previously used
                  passwords
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" gap="16px">
                <Box>
                  <Textfield
                    fullWidth
                    variant="outlined"
                    errorContent={!validPassword && "Enter valid password"}
                    helperTextContent={<PasswordConstraint />}
                    name="newPassword"
                    value={fieldVals?.newPassword}
                    onChange={(e) => changePasswordHandler(e)}
                    placeholder="New Password"
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    width="100%"
                    // onKeyDown={(e) => e.key === 'Enter' && onSignIn()}
                  />
                </Box>
                <Textfield
                  autoComplete
                  placeholder="Confirm New Password"
                  label="Confirm New Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        //   onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={fieldVals?.confirmNewPassword}
                  name="confirmNewPassword"
                  onChange={(e) => changePasswordHandler(e)}
                  type={showPassword ? "text" : "password"}
                  width="100%"
                  // onKeyDown={(e) => e.key === 'Enter' && onSignIn()}
                />
              </Box>

              <LoadingButton
                type="submit"
                loading={loading}
                fullWidth
                variant="contained"
                sx={{ padding: "8px 22px", fontSize: 15 }}
                disabled={!validPassword}
              >
                Reset Password
              </LoadingButton>
            </Box>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
};

export default ResetPassword;
