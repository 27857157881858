import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Profile from "../pages/Profile";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import EmailSent from "../pages/EmailSent";
import ChangedPassword from "../pages/ChangedPassword";
import OtpVerification from "../pages/Signup/OtpVerification";
import JoinWorkspace from "../pages/Signup/JoinWorkspace";
import CreateWorkspace from "../pages/Signup/CreateWorkspace";
import CreatePassword from "../pages/Signup/CreatePassword";
import PasswordCreated from "../pages/Signup/PasswordCreated";
import AppLayout from "../components/AppLayout";
import Settings from "../pages/Settings";
import DashboardComponent from "../components/Dashboard";

import CircularProgressLoading from "../common/CircularProgress";
import { useSelector } from "react-redux";
import CaseOverview from "../pages/Patients/PatientOverview/index.js";
import CreateAgentName from "../pages/Signup/CreateAgentName";
import { getMyValue } from "../utils/helpers";
import ProtectedRoute from "../ProtectedRoute";
import LoadingSpinner from "../components/LoadingSpinner";
import CrashScreen from "../components/CrashScreen.js";
import AccountType from "../pages/Signup/AccountType/index.jsx";
import TermsOfService from "../pages/Signup/TermsOfService/index.jsx";
import SignUpCareManagement from "../pages/Signup/SignUpCareManagement/index.jsx";
import Cookies from "js-cookie";
import ImportPatients from "../pages/ImportPatients/index.jsx";
import OrganizationPortal from "../pages/OrganizationPortal/index.jsx";
import BillingCardDetails from "../pages/Signup/BillingCardDetails/index.jsx";
import AssignPatients from "../pages/AssignPatients/index.jsx";
import AgencyQrCode from "../pages/AgencyQrCode/index.jsx";
import PatientDetails from "../pages/PatientDetails/index.jsx";
import SetupPassword from "../pages/SetupPassword/index.jsx";
import DownloadVitatraxApp from "../pages/DownloadVitatraxApp/index.jsx";
import Reports from "../pages/Reports/index.jsx";

// import Layout from '../Layout';
// import Protected from './protected';
// import Loader from '../common/Loader';
// import UnProtected from './unProtected';

const Signup = lazy(() => import("../pages/Signup"));
const Signin = lazy(() => import("../pages/Signin"));
const Patients = lazy(() => import("../pages/Patients"));

const MainRouts = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [showPdfSummary, setShowPdfSummary] = useState(false);

  const inviteAgent = useSelector((state) => state.inviteAgent);
  const {
    loading: loadingAgent,
    error: errorAgent,
    invitedAgent,
  } = inviteAgent;

  const [cookieValues, setCookieValues] = useState({
    token: "",
    agentId: "",
    agencyId: "",
  });
  const routeExist = Cookies.get("route");

  useEffect(() => {
    setCookieValues((prevState) => ({
      // ...prevState,
      // token: userInfo?.token,
      // agentId: userInfo?.agent?.id,
      // agencyId: userInfo?.agent?.agencyId,
      ...prevState,
      token: getMyValue(userInfo?.token),
      agentId: getMyValue(userInfo?.agentId),
      agencyId: getMyValue(userInfo?.agencyId),
    }));
  }, [userInfo, routeExist, invitedAgent]);

  const isAuthenticated = routeExist
    ? false
    : cookieValues?.token && cookieValues?.agentId && cookieValues?.agencyId;

  // console.log("ROUTE", routeExist);
  // console.log("ISSSS", isAuthenticated);
  // console.log("TOKEN", isAuthenticated);
  // console.log("AGENT ID", cookieValues?.agentId);
  // console.log("AGENCY ID", cookieValues?.agencyId);

  // const [userInfo, setUserInfo] = useState({
  //   token: "",
  //   agencyId: "",
  //   agentId: "",
  // });

  // useEffect(() => {
  //   const token = Cookies.get("token");
  //   const agentId = Cookies.get("agentId");

  //   if (
  //     token !== userInfo.token ||
  //     agencyId !== userInfo.agencyId ||
  //     agentId !== userInfo.agentId
  //   ) {
  //     setUserInfo({
  //       token: token || "",
  //       agencyId: agencyId || "",
  //       agentId: agentId || "",
  //     });
  //   }
  // }, []);

  // console.log("USERINFO", userInfo);

  return (
    <BrowserRouter>
      <Suspense fallback={<CircularProgressLoading />}>
        <Routes>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route
              exact
              path="/"
              element={isAuthenticated ? <DashboardComponent /> : <Signin />}
            />
            <Route
              exact
              path="/patients"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Patients />
                </Suspense>
              }
            />
            <Route exact path="/patient/:id" element={<CaseOverview />} />
            <Route exact path="/import-patients" element={<ImportPatients />} />
            <Route exact path="/assign-patients" element={<AssignPatients />} />
            <Route exact path="/reports" element={<Reports />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route
              exact
              element={isAuthenticated ? <OrganizationPortal /> : <Signin />}
              path="/organization-portal"
            />
          </Route>

          <Route path="/practice_detail" element={<AgencyQrCode />} />
          <Route path="/patient-details" element={<PatientDetails />} />
          <Route path="/setup-password" element={<SetupPassword />} />
          <Route
            path="/download-mobile-app"
            element={<DownloadVitatraxApp />}
          />
          <Route
            path="/signin"
            element={
              isAuthenticated ? (
                <Navigate to="/" />
              ) : (
                <Suspense fallback={<LoadingSpinner />}>
                  <Signin />
                </Suspense>
              )
            }
          />
          <Route
            path="/forgot-password"
            element={isAuthenticated ? <Navigate to="/" /> : <ForgotPassword />}
          />
          {/* <Route path="/checkmail" element={<EmailSent />} /> */}
          <Route path="/password-changed" element={<ChangedPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/account-type"
            element={
              isAuthenticated ? (
                <Navigate to="/" />
              ) : (
                <Suspense fallback={<LoadingSpinner />}>
                  <AccountType />
                </Suspense>
              )
            }
          />
          <Route
            path="/register"
            element={
              isAuthenticated ? (
                <Navigate to="/" />
              ) : (
                <Suspense fallback={<LoadingSpinner />}>
                  <Signup />
                </Suspense>
              )
            }
          />
          <Route
            path="/register-care-management"
            element={
              isAuthenticated ? (
                <Navigate to="/" />
              ) : (
                <Suspense fallback={<LoadingSpinner />}>
                  <SignUpCareManagement />
                </Suspense>
              )
            }
          />
          <Route path="/create-password" element={<CreatePassword />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route path="/join-workspace" element={<JoinWorkspace />} />
          <Route path="/create-workspace" element={<CreateWorkspace />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/create-agentname" element={<CreateAgentName />} />
          <Route path="/billing-details" element={<BillingCardDetails />} />
          <Route path="/password-created" element={<PasswordCreated />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default MainRouts;
