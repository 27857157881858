import {
  ASSIGN_BULK_PATIENTS_FAIL,
  ASSIGN_BULK_PATIENTS_REQUEST,
  ASSIGN_BULK_PATIENTS_SUCCESS,
  EXPORT_BULK_EVIDENCE_FAIL,
  EXPORT_BULK_EVIDENCE_REQUEST,
  EXPORT_BULK_EVIDENCE_SUCCESS,
  GET_IMPORT_PATIENTS_FAIL,
  GET_IMPORT_PATIENTS_REQUEST,
  GET_IMPORT_PATIENTS_SUCCESS,
  IMPORT_PATIENT_FILE_FAIL,
  IMPORT_PATIENT_FILE_REQUEST,
  IMPORT_PATIENT_FILE_SUCCESS,
  IMPORT_STATUS_FAIL,
  IMPORT_STATUS_REQUEST,
  IMPORT_STATUS_SUCCESS,
  RESET_ASSIGN_BULK_PATIENTS,
  RESET_EXPORT_BULK_EVIDENCE,
  RESET_GET_IMPORT_PATIENTS,
  RESET_IMPORT_PATIENT_FILE,
  RESET_IMPORT_STATUS,
  SET_IMPORT_ID,
} from "../../assets/constants/importFileConstants";

//================   IMPORT PATIENT FILE REDUCER ================
export const importPatientFileReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_PATIENT_FILE_REQUEST:
      return { loading: true };
    case IMPORT_PATIENT_FILE_SUCCESS:
      return { loading: false, importedPatientFile: action.payload };
    case IMPORT_PATIENT_FILE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_IMPORT_PATIENT_FILE:
      return {};
    default:
      return state;
  }
};

export const setImportIdReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_IMPORT_ID:
      return { ...state, importId: action.payload };
    default:
      return state;
  }
};

//================   GET IMPORT PATIENTS LIST REDUCER ================
export const getImportPatientsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_IMPORT_PATIENTS_REQUEST:
      return { loading: true };
    case GET_IMPORT_PATIENTS_SUCCESS:
      return { loading: false, importedPatientsList: action.payload };
    case GET_IMPORT_PATIENTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_IMPORT_PATIENTS:
      return {};
    default:
      return state;
  }
};

//================  ASSIGN MEMEBRS TO PATIENTS REDUCER ================
export const assignBulkPatientsReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSIGN_BULK_PATIENTS_REQUEST:
      return { loading: true };
    case ASSIGN_BULK_PATIENTS_SUCCESS:
      return { loading: false, assignedBulkMembers: action.payload };
    case ASSIGN_BULK_PATIENTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ASSIGN_BULK_PATIENTS:
      return {};
    default:
      return state;
  }
};

//================  IMPORT BULK EVIDENCE REDUCER ================
export const exportBulkEvidenceReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPORT_BULK_EVIDENCE_REQUEST:
      return { loading: true };
    case EXPORT_BULK_EVIDENCE_SUCCESS:
      return { loading: false, exportedBulkEvidence: action.payload };
    case EXPORT_BULK_EVIDENCE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_EXPORT_BULK_EVIDENCE:
      return {};
    default:
      return state;
  }
};

//================  IMPORT STATUS REDUCER ================
export const checkImportStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_STATUS_REQUEST:
      return { loading: true };
    case IMPORT_STATUS_SUCCESS:
      return { loading: false, importStatus: action.payload };
    case IMPORT_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_IMPORT_STATUS:
      return {};
    default:
      return state;
  }
};
