import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import PinnedNoteCard from "../PinnedNoteCard";
import NoteLogsCard from "../NoteLogsCard";

const PinnedNotesModalContent = ({
  pinnedNotes,
  unPinnedNoteHandler,
  noteId,
  loadingUpdateNote,
  setOpenPinnedNotesModal,
}) => {
  const [pinnedNotesArr, setPinnedNotesArr] = useState(pinnedNotes || []);

  const dismissNoteHandler = (item) => {
    setPinnedNotesArr((prevNotes) =>
      prevNotes?.filter((note) => note?.id !== item?.id)
    );
  };

  useEffect(() => {
    if (pinnedNotesArr?.length === 0) setOpenPinnedNotesModal(false);
  }, [pinnedNotesArr]);

  return (
    <Stack gap="16px">
      {pinnedNotesArr?.map((item, index) => (
        <NoteLogsCard
          key={index}
          time={item?.created_at}
          startTime={item?.start_time}
          endTime={item?.end_time}
          type={item?.note_category}
          notesText={item?.note}
          agentId={item?.agent_id}
          pinnedNote={item?.pinned}
          pinnedModal={true}
          unPinnedNoteHandler={() => unPinnedNoteHandler(item)}
          loadingUpdateNote={item?.id === noteId ? true : false}
          dismissNoteHandler={() => dismissNoteHandler(item)}
        />
      ))}
    </Stack>
  );
};

export default PinnedNotesModalContent;
