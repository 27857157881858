import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { EocErrorIcon } from "../../assets/constants";
import { shortDateFormat } from "../../utils/helpers";

const EOCErrorCard = ({ item, date }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      padding="8px 12px"
      borderRadius="8px"
      bgcolor="#FDEDED"
    >
      <Box bgcolor="rgba(255, 89, 89, 0.08)" borderRadius="7px" padding="10px">
        <EocErrorIcon />{" "}
      </Box>

      <Stack flex="1" gap="2px" height="100%" justifyContent="space-evenly">
        <Typography variant="body2" color="#5F2120">
          EOC Export failed
        </Typography>
        <Typography variant="body2" color="#212121">
          Exp By: {item?.agent?.name}{" "}
        </Typography>
      </Stack>

      <Stack gap="2px" alignItems="flex-end">
        {item?.number_of_subreports && (
          <Typography variant="caption" color="#212121">
            {item?.number_of_subreports} Patients
          </Typography>
        )}

        <Typography variant="caption" color="secondary">
          {shortDateFormat(date)}
        </Typography>
      </Stack>
    </Box>
  );
};

export default EOCErrorCard;
