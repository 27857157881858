import { Box, Checkbox, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { shortDateFormat } from "../../utils/helpers";
import { EvidenceDownloadIcon } from "../../assets/constants";

const AutoGeneratedCard = ({
  name,
  date,
  selectedReports,
  id,
  year,
  month,
  item,
  downloadReportHandler,
  onToggleReport,
}) => {
  const isChecked = selectedReports?.some((item) => item?.id === id);

  const fileName = `${name} - ${month} ${year}`;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      padding="8px 12px"
      borderRadius="8px"
      bgcolor={isChecked ? "rgba(12, 128, 243, 0.05)" : "#FCFCFC"}
    >
      <IconButton>
        <Checkbox
          sx={{ padding: 0 }}
          checked={isChecked}
          onChange={() => onToggleReport(item)}
        />
      </IconButton>
      <Stack flex="1">
        <Typography variant="body2" color="rgba(0, 0, 0, 0.87)">
          {fileName}
        </Typography>
        <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
          Exp By: System
        </Typography>
      </Stack>
      <Stack gap="2px" alignItems="flex-end">
        <Typography variant="caption" color="#212121">
          {name}
        </Typography>
        <Typography variant="caption" color="secondary">
          {shortDateFormat(date)}
        </Typography>
      </Stack>

      <Box>
        <IconButton
          disabled={selectedReports?.length ? true : false}
          onClick={() => downloadReportHandler(item)}
        >
          <EvidenceDownloadIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AutoGeneratedCard;
