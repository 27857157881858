import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Alert,
  Chip,
  Autocomplete,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  DatePicker,
  LocalizationProvider,
  renderDigitalClockTimeView,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarIcon from "../../icons/CalendarIcon";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import dayjs from "dayjs";
import {
  amPmOptions,
  calculateElapsedTime,
  hoursOptions,
  logValueOptions,
  minutesOptions,
  quickTimeOptions,
} from "../../utils/helpers";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import CustomTimerDropdown from "../CustomTimerDropdown";

const AddTimeDrawerContent = ({
  onClose,
  timeDetails,
  patientDetail,
  onSubmitEditTime,
  onSubmitAddTimeEntry,
  fromDashboard,
  reviewedPatients,
}) => {
  // const [startTimeValue, setStartTimeValue] = useState(null);
  // const [endTimeValue, setEndTimeValue] = useState(dayjs());
  const [dateValue, setDateValue] = useState(null);
  const [selectValue, setSelectValue] = useState("");
  const [notesValue, setNotesValue] = useState("");
  const [changedTime, setChangedTime] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [communicationInteraction, setCommunicationInteraction] =
    useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [openStartTimePicker, setOpenStartTimePicker] = useState(false);
  const [openEndTimePicker, setOpenEndTimePicker] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [selectedQuickTimeValue, setSelectedQuickTimeValue] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const [startTimeOption, setStartTimeOption] = useState({
    hours: "",
    minutes: "",
    amPm: "",
  });
  const [endTimeOption, setEndTimeOption] = useState({
    hours: "",
    minutes: "",
    amPm: "",
  });

  const agencyId = Cookies.get("agency_id_vita");
  const dispatch = useDispatch();

  const updateTimeEntry = useSelector((state) => state.updateTimeEntry);
  const { loading: loadingUpdateTimeEntry } = updateTimeEntry;

  const createTimeEntry = useSelector((state) => state.createTimeEntry);
  const { loading: loadingCreateTimeEntry } = createTimeEntry;

  const createBulkTime = useSelector((state) => state.createBulkTime);
  const { loading: loadingBulkTime } = createBulkTime;

  const currentMonth = dayjs().month();
  const currentYear = dayjs().year();

  useEffect(() => {
    setSelectValue("Therapeutic Data Review");
    const currentTime = dayjs();
    const startTime = currentTime?.subtract(1, "minutes");
    setStartTimeOption({
      hours: startTime.format("hh"),
      minutes: startTime.format("mm"),
      amPm: startTime.format("A"),
    });
    setEndTimeOption({
      hours: currentTime.format("hh"),
      minutes: currentTime.format("mm"),
      amPm: currentTime.format("A"),
    });
  }, []);

  useEffect(() => {
    const startTimeVal = dayjs()
      ?.hour(
        startTimeOption?.hours === "12"
          ? startTimeOption?.amPm === "AM"
            ? 0
            : 12
          : startTimeOption?.amPm === "PM"
          ? parseInt(startTimeOption?.hours, 10) + 12
          : parseInt(startTimeOption?.hours, 10)
      )
      ?.minute(parseInt(startTimeOption?.minutes, 10))
      ?.second(0);

    const endTimeVal = dayjs()
      ?.hour(
        endTimeOption?.hours === "12"
          ? endTimeOption?.amPm === "AM"
            ? 0
            : 12
          : endTimeOption?.amPm === "PM"
          ? parseInt(endTimeOption?.hours, 10) + 12
          : parseInt(endTimeOption?.hours, 10)
      )
      ?.minute(parseInt(endTimeOption?.minutes, 10))
      ?.second(0);

    if (startTimeVal?.isSame(endTimeVal)) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [startTimeOption, endTimeOption]);

  useEffect(() => {
    if (timeDetails?.id) {
      // setStartTimeValue(dayjs(timeDetails?.start_time));
      // setEndTimeValue(dayjs(timeDetails?.end_time));
      setStartTimeOption((prev) => ({
        ...prev,
        hours: dayjs(timeDetails?.start_time)?.format("hh"),
        minutes: dayjs(timeDetails?.start_time)?.format("mm"),
        amPm: dayjs(timeDetails?.start_time)?.format("A"),
      }));
      setEndTimeOption((prev) => ({
        ...prev,
        hours: dayjs(timeDetails?.end_time)?.format("hh"),
        minutes: dayjs(timeDetails?.end_time)?.format("mm"),
        amPm: dayjs(timeDetails?.end_time)?.format("A"),
      }));
      setDateValue(dayjs(timeDetails?.date));
      setNotesValue(timeDetails?.notes);
      setSelectValue(timeDetails?.category);
      setElapsedTime(timeDetails?.time_in_seconds);
      setCommunicationInteraction(timeDetails?.contains_interaction);
    } else {
      setDateValue(dayjs(timeDetails));
    }
  }, []);

  const saveTimeHandler = () => {
    const startTimeVal = dayjs()
      ?.hour(
        startTimeOption?.hours === "12"
          ? startTimeOption?.amPm === "AM"
            ? 0
            : 12
          : startTimeOption?.amPm === "PM"
          ? parseInt(startTimeOption?.hours, 10) + 12
          : parseInt(startTimeOption?.hours, 10)
      )
      ?.minute(parseInt(startTimeOption?.minutes, 10))
      ?.second(0);

    const endTimeVal = dayjs()
      ?.hour(
        endTimeOption?.hours === "12"
          ? endTimeOption?.amPm === "AM"
            ? 0
            : 12
          : endTimeOption?.amPm === "PM"
          ? parseInt(endTimeOption?.hours, 10) + 12
          : parseInt(endTimeOption?.hours, 10)
      )
      ?.minute(parseInt(endTimeOption?.minutes, 10))
      ?.second(0);

    if (endTimeVal && startTimeVal) {
      const fixedDate = "1970-01-01";
      const normalizedStartTime = dayjs(
        `${fixedDate} ${dayjs(startTimeVal)?.format("HH:mm")}`
      );
      const normalizedEndTime = dayjs(
        `${fixedDate} ${dayjs(endTimeVal)?.format("HH:mm")}`
      );

      if (normalizedEndTime.isBefore(normalizedStartTime)) {
        setEndTimeError(true);
        return;
      }
    }

    setEndTimeError(false);

    if (timeDetails?.id) {
      const fixedDate = "1970-01-01";
      const start = dayjs(`${fixedDate} ${dayjs(startTimeVal).format("HH:mm")}`)
        .set("second", 0)
        .set("millisecond", 0);
      const end = dayjs(`${fixedDate} ${dayjs(endTimeVal).format("HH:mm")}`)
        .set("second", 0)
        .set("millisecond", 0);

      const timeEntryObj = {
        time_entry_id: timeDetails?.id,
        agency_id: agencyId,
        date: dateValue?.utcOffset(0, true),
        start_time: changedTime
          ? dayjs(startTimeVal)?.set("second", 0).set("millisecond", 0)
          : startTimeVal,
        end_time: changedTime
          ? dayjs(endTimeVal)?.set("second", 0).set("millisecond", 0)
          : endTimeVal,
        time_in_seconds: changedTime
          ? calculateElapsedTime(start, end)
          : elapsedTime,
        category: selectValue,
        notes: notesValue,
        manually_added: true,
        contains_interaction: communicationInteraction,
      };

      onSubmitEditTime(timeEntryObj);
    } else {
      let timeEntryObj;
      if (fromDashboard) {
        timeEntryObj = {
          patient_ids: reviewedPatients?.map((item) => item?.id),
          agency_id: agencyId,
          date: dateValue?.utcOffset(0, true),
          start_time: startTimeVal,
          end_time: endTimeVal,
          time_in_seconds: calculateElapsedTime(startTimeVal, endTimeVal),
          category: selectValue,
          notes: notesValue,
          manually_added: true,
          contains_interaction: communicationInteraction,
        };
      } else {
        timeEntryObj = {
          patient_id: patientDetail?.patient?.id,
          agency_id: agencyId,
          date: dateValue?.utcOffset(0, true),
          start_time: startTimeVal,
          end_time: endTimeVal,
          time_in_seconds: calculateElapsedTime(startTimeVal, endTimeVal),
          category: selectValue,
          notes: notesValue,
          manually_added: true,
          contains_interaction: communicationInteraction,
        };
      }

      onSubmitAddTimeEntry(timeEntryObj);
    }
  };

  const closeDrawerHandler = () => {
    setEndTimeError(false);

    onClose();
  };

  const disbalePreviousDateHandler = (date) => {
    const selectedYear = dayjs(date)?.year();
    const selectedMonth = dayjs(date)?.month();

    if (selectedYear < currentYear) {
      return true;
    }

    if (selectedYear === currentYear && selectedMonth < currentMonth) {
      return true;
    }

    return false;
  };

  const selectQuickTimeHandler = (item) => {
    setSelectedQuickTimeValue(item?.value);
    const newEndTime = dayjs();
    const quickStartTime = newEndTime?.subtract(item?.value, "minutes");

    setStartTimeOption((prev) => ({
      ...prev,
      hours: dayjs(quickStartTime)?.format("hh"),
      minutes: dayjs(quickStartTime)?.format("mm"),
      amPm: dayjs(quickStartTime)?.format("A"),
    }));
    setEndTimeOption((prev) => ({
      ...prev,
      hours: dayjs(newEndTime)?.format("hh"),
      minutes: dayjs(newEndTime)?.format("mm"),
      amPm: dayjs(newEndTime)?.format("A"),
    }));
    // setStartTimeValue(quickStartTime);
    // setEndTimeValue(newEndTime);
  };

  // const hoursChangeHandler = (e) => {
  //   const input = e.target.value;
  //   if (/^\d{0,2}$/.test(input) && Number(input) <= 12 && Number(input) >= 1) {
  //     const formattedValue = input?.padStart(2, "0");
  //     setStartTimeOption((prev) => ({ ...prev, hours: formattedValue }));
  //   }
  // };

  // const isSaveDisabled =
  //   startTimeValue?.format("HH:mm A") === endTimeValue?.format("HH:mm A");

  return (
    <>
      {" "}
      <Stack flex="1" sx={{ overflowY: "auto" }} gap="24px" height="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          paddingBottom="32px"
        >
          <Typography variant="h5">
            {timeDetails?.id ? "Edit Time Log" : "Log Time"}
          </Typography>
          <IconButton size="small" onClick={closeDrawerHandler}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>

        <Stack gap="21px">
          {fromDashboard && (
            <Alert severity="info" sx={{ color: "#0288D1" }}>
              This time log will be added for{" "}
              <Box
                component="span"
                fontWeight={500}
                // sx={{ textDecoration: "underline" }}
              >
                {" "}
                {reviewedPatients?.length}{" "}
                {reviewedPatients?.length == 1 ? "patient" : "patients"}
              </Box>
            </Alert>
          )}

          <Stack>
            {/* <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              gap="24px"
              width="100%"
              paddingBottom="4px"
            >
              <Stack sx={{ width: "50%" }}>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.56)">
                  Start Time
                </Typography>
                <Box display="flex" alignItems="center" gap="3px">
                  <CustomSelectFilter
                    height={300}
                    menuItems={hoursOptions}
                    changeSelectValueHandler={(e) => {
                      setChangedTime(true);
                      setEndTimeError(false);
                      setStartTimeOption((prev) => ({
                        ...prev,
                        hours: e.target.value,
                      }));
                    }}
                    selectValue={startTimeOption?.hours}
                    id="ampm"
                    minWidth={75}
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 65 * 4.5 + 8,
                        },
                      },
                    }}
                  />
                  <CustomSelectFilter
                    height={300}
                    menuItems={minutesOptions}
                    changeSelectValueHandler={(e) => {
                      setChangedTime(true);
                      setEndTimeError(false);
                      setStartTimeOption((prev) => ({
                        ...prev,
                        minutes: e.target.value,
                      }));
                    }}
                    selectValue={startTimeOption?.minutes}
                    id="ampm"
                    minWidth={75}
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 65 * 4.5 + 8,
                        },
                      },
                    }}
                  />
                  <CustomSelectFilter
                    menuItems={amPmOptions}
                    changeSelectValueHandler={(e) => {
                      setChangedTime(true);
                      setEndTimeError(false);
                      setStartTimeOption((prev) => ({
                        ...prev,
                        amPm: e.target.value,
                      }));
                    }}
                    selectValue={startTimeOption?.amPm}
                    id="ampm"
                    minWidth={75}
                    size="small"
                  />
                </Box>
              </Stack>
              <Stack sx={{ width: "50%" }}>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.56)">
                  End Time
                </Typography>
                <Box display="flex" alignItems="center" gap="3px">
                  <CustomSelectFilter
                    height={300}
                    menuItems={hoursOptions}
                    changeSelectValueHandler={(e) => {
                      setChangedTime(true);
                      setEndTimeError(false);
                      setEndTimeOption((prev) => ({
                        ...prev,
                        hours: e.target.value,
                      }));
                    }}
                    selectValue={endTimeOption?.hours}
                    id="ampm"
                    minWidth={75}
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 65 * 4.5 + 8,
                        },
                      },
                    }}
                  />
                  <CustomSelectFilter
                    height={300}
                    menuItems={minutesOptions}
                    changeSelectValueHandler={(e) => {
                      setChangedTime(true);
                      setEndTimeError(false);
                      setEndTimeOption((prev) => ({
                        ...prev,
                        minutes: e.target.value,
                      }));
                    }}
                    selectValue={endTimeOption?.minutes}
                    id="ampm"
                    minWidth={75}
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 65 * 4.5 + 8,
                        },
                      },
                    }}
                  />
                  <CustomSelectFilter
                    menuItems={amPmOptions}
                    changeSelectValueHandler={(e) => {
                      setChangedTime(true);
                      setEndTimeError(false);
                      setEndTimeOption((prev) => ({
                        ...prev,
                        amPm: e.target.value,
                      }));
                    }}
                    selectValue={endTimeOption?.amPm}
                    id="ampm"
                    minWidth={75}
                    size="small"
                  />
                </Box>
                {endTimeError && (
                  <Typography
                    width="80%"
                    padding="3px 14px"
                    variant="caption"
                    color="#D32F2F"
                  >
                    End time cannot be earlier than start time.
                  </Typography>
                )}
              </Stack>
            </Box> */}
            <CustomTimerDropdown
              startTimeOption={startTimeOption}
              setStartTimeOption={setStartTimeOption}
              endTimeOption={endTimeOption}
              setEndTimeOption={setEndTimeOption}
              endTimeError={endTimeError}
              setEndTimeError={setEndTimeError}
              setChangedTime={setChangedTime}
              setSelectedQuickTimeValue={setSelectedQuickTimeValue}
            />
            <Stack gap="8px">
              <Typography variant="body2" color="secondary">
                Quick Time
              </Typography>
              <Box display="flex" alignItems="center" gap="4px">
                {quickTimeOptions?.map((item, index) => (
                  <Chip
                    key={index}
                    label={item?.label}
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => selectQuickTimeHandler(item)}
                    sx={{
                      marginLeft: 0,
                      padding: "4px",
                      color:
                        selectedQuickTimeValue === item?.value
                          ? "#fff"
                          : "#2196F3",
                      bgcolor:
                        selectedQuickTimeValue === item?.value
                          ? "#2196F3 !important"
                          : "transparent",
                      border:
                        selectedQuickTimeValue === item?.value ? "none" : "",
                    }}
                  />
                ))}
              </Box>
            </Stack>
          </Stack>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dateValue}
              label="Date"
              disabled={fromDashboard ? true : false}
              disableFuture
              onChange={(newValue) => setDateValue(newValue)}
              slots={{ openPickerIcon: CalendarIcon }}
              onOpen={() => setIsPickerOpen(true)}
              onClose={() => setIsPickerOpen(false)}
              shouldDisableDate={disbalePreviousDateHandler}
              open={isPickerOpen}
              slotProps={{
                textField: {
                  // fullWidth: true,
                  error: false,
                  onClick: () => setIsPickerOpen(true),
                },
              }}
            />
          </LocalizationProvider>

          <CustomSelectFilter
            menuItems={logValueOptions}
            changeSelectValueHandler={(e) => setSelectValue(e.target.value)}
            selectValue={selectValue}
            id="log_type"
            label="Log Type"
            minWidth="fullWidth"
            size="medium"
          />

          <FormControlLabel
            sx={{ marginRight: "0", flex: "1" }}
            control={
              <Checkbox
                checked={communicationInteraction}
                onChange={(e) => setCommunicationInteraction(e.target.checked)}
              />
            }
            label="Interacted with the patient in this session."
          />

          <TextField
            multiline
            rows={6}
            label="Notes"
            value={notesValue}
            onChange={(e) => setNotesValue(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack alignItems="flex-end" pt={1.4}>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={saveTimeHandler}
          loading={
            loadingUpdateTimeEntry || loadingCreateTimeEntry || loadingBulkTime
          }
          disabled={!selectValue || isSaveDisabled}
          // disabled={!firstName || !email || emailError}
          // height="40px"
          // width="140px"
        >
          {timeDetails?.id ? "Save Changes" : "Save"}
        </LoadingButton>
      </Stack>{" "}
    </>
  );
};

export default AddTimeDrawerContent;
