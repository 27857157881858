import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import {
  painDurationValues,
  painLevelBackgroundColor,
  timeFormat,
} from "../../utils/helpers";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "16px",
  height: "16px",
  color: "#fff",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
}));

const ReadingDetailPainCard = ({
  name,
  description,
  duration,
  pain_intensity,
  medicine_adherence,
  time,
}) => {
  return (
    <Stack padding="12px" bgcolor="#fff" borderRadius="8px" gap="12px">
      <Stack gap="1px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="body1"
            fontWeight={500}
            textTransform="capitalize"
          >
            {name}
          </Typography>
          {medicine_adherence === "no" && (
            <Box bgcolor="#FE7878" borderRadius="4px" padding="0 4px">
              <Typography variant="body2" color="#fff">
                Medication missed
              </Typography>
            </Box>
          )}
          {medicine_adherence === "na" && (
            <Box bgcolor="#F1B62E" borderRadius="4px" padding="0 4px">
              <Typography variant="body2" color="#fff">
                Medication not prescribed{" "}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" color="secondary">
            Duration: {painDurationValues[duration]}
          </Typography>
          <Typography variant="body1" fontSize="14px" color="secondary">
            Pain Intensity :{" "}
            <StyledBox
              component="span"
              sx={{
                background: painLevelBackgroundColor(pain_intensity),
                padding: "10px",
              }}
            >
              {pain_intensity}
            </StyledBox>
          </Typography>
        </Box>
      </Stack>
      <Stack>
        {description && (
          <Typography variant="body2" color="secondary">
            {description}
          </Typography>
        )}
        <Box alignSelf="flex-end">
          <Typography color="rgba(0, 0, 0, 0.38)" variant="body2">
            {timeFormat(time)}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ReadingDetailPainCard;
