import axios from "axios";
import {
  CREATE_WORKSPACE_FAIL,
  CREATE_WORKSPACE_REQUEST,
  CREATE_WORKSPACE_SUCCESS,
  GET_AGENCY_FAIL,
  GET_AGENCY_REQUEST,
  GET_AGENCY_SUCCESS,
  GET_ALL_AGENCIES_FAIL,
  GET_ALL_AGENCIES_REQUEST,
  GET_ALL_AGENCIES_SUCCESS,
  GET_ALL_CLIENTS_FAIL,
  GET_ALL_CLIENTS_REQUEST,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_UNPAGINATED_FAIL,
  GET_ALL_CLIENTS_UNPAGINATED_REQUEST,
  GET_ALL_CLIENTS_UNPAGINATED_SUCCESS,
  RESET_CREATE_WORKSPACE,
  UPDATE_AGENCY_FAIL,
  UPDATE_AGENCY_REQUEST,
  UPDATE_AGENCY_SUCCESS,
} from "../../assets/constants/agencyConstants";
import { BASE_URL } from "..";
import {
  ADD_NEW_CLIENT_FAIL,
  ADD_NEW_CLIENT_REQUEST,
  ADD_NEW_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  GET_AGENT_LISTS_FAIL,
  GET_AGENT_LISTS_REQUEST,
  GET_AGENT_LISTS_SUCCESS,
  GET_AGENT_PROFILE_FAIL,
  GET_AGENT_PROFILE_REQUEST,
  GET_AGENT_PROFILE_SUCCESS,
  GET_CLIENT_DATA_FAIL,
  GET_CLIENT_DATA_REQUEST,
  GET_CLIENT_DATA_SUCCESS,
  RESET_UPDATE_AGENT_TEAM_MEMBERS,
  RESET_DELETE_CLIENT,
  UPDATE_AGENT_PROFILE_FAIL,
  UPDATE_AGENT_PROFILE_REQUEST,
  UPDATE_AGENT_PROFILE_SUCCESS,
  UPDATE_AGENT_TEAM_MEMBERS_FAIL,
  UPDATE_AGENT_TEAM_MEMBERS_REQUEST,
  UPDATE_AGENT_TEAM_MEMBERS_SUCCESS,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  RESET_AGENT_LISTS,
  INVITE_AGENT_REQUEST,
  INVITE_AGENT_SUCCESS,
  INVITE_AGENT_FAIL,
  CHECK_PATIENT_EMAIL_REQUEST,
  CHECK_PATIENT_EMAIL_SUCCESS,
  CHECK_PATIENT_EMAIL_FAIL,
  RESET_CHECK_PATIENT_EMAIL,
  RESET_INVITE_AGENT,
  CHECK_PATIENT_PHONE_REQUEST,
  CHECK_PATIENT_PHONE_SUCCESS,
  CHECK_PATIENT_PHONE_FAIL,
  RESET_CHECK_PATIENT_PHONE,
} from "../../assets/constants/agentConstants";
import { USER_LOGIN_SUCCESS } from "../../assets/constants/userConstants";
import Cookies from "js-cookie";

// ================== GET AGENT PROFILE =========================
export const getAgentProfile = () => async (dispatch) => {
  try {
    dispatch({ type: GET_AGENT_PROFILE_REQUEST });

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    // var userInfo = localStorage.getItem("userInfo");
    const token = Cookies.get("token");
    const agentId = Cookies.get("agent_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/agent/get?agent_id=${agentId}`,
        config
      );

      dispatch({ type: GET_AGENT_PROFILE_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_AGENT_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== INVITE AGENT ACTION =========================
export const inviteAgentFunc = (agentObj) => async (dispatch) => {
  try {
    dispatch({ type: INVITE_AGENT_REQUEST });

    const token = Cookies.get("token");
    const agentId = Cookies.get("agent_id_vita");
    const agencyId = Cookies.get("agency_id_vita");

    // const agentId = Cookies.get("agent_id_vita");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/invite-agent`,
        agentObj,
        config
      );

      dispatch({ type: INVITE_AGENT_SUCCESS, payload: response?.data });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: {
          token: token,
          agencyId: agencyId,
          agentId: agentId,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: INVITE_AGENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetAgentInvite = () => (dispatch) =>
  dispatch({ type: RESET_INVITE_AGENT });

// =========================  UPDATE AGENT PROFILE =====================
export const updateAgentProfile = (agent) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_AGENT_PROFILE_REQUEST });

    const token = Cookies.get("token");
    const agentId = Cookies.get("agent_id_vita");
    const agencyId = Cookies.get("agency_id_vita");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/update`,
      agent,
      config
    );

    dispatch({ type: UPDATE_AGENT_PROFILE_SUCCESS, payload: response?.data });
    // dispatch({ type: GET_AGENT_PROFILE_SUCCESS, payload: data });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: {
        token: token,
        agencyId: agencyId,
        agentId: agentId,
      },
    });
  } catch (error) {
    dispatch({
      type: UPDATE_AGENT_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================= CREATE WORKSPACE/AGENCY REDUCER ==================
export const createWorkspaceFunc = (name) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_WORKSPACE_REQUEST });

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    // const agentToken = JSON.parse(localStorage.getItem("agentToken"));
    // const { agentId } = JSON.parse(localStorage.getItem("agentId"));
    const token = Cookies.get("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agency/create`,
      name,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({ type: CREATE_WORKSPACE_SUCCESS, payload: response });
    Cookies.set("agency_id_vita", response?.data?.agency?.id);
    Cookies.set("org_id_vita", response?.data?.agency?.organization_id);
  } catch (error) {
    dispatch({
      type: CREATE_WORKSPACE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetCreateWorkSpace = () => (dispatch) =>
  dispatch({ type: RESET_CREATE_WORKSPACE });

// ================== GET ALL AGENTS LIST ACTION  =========================
export const getAgentsListFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_AGENT_LISTS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/agent/get-list?agency_id=${agencyId}`,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: GET_AGENT_LISTS_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_AGENT_LISTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET ALL AGENCIES LIST ACTION  =========================
export const getAllAgenciesListFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_AGENCIES_REQUEST });

    const token = Cookies.get("token");
    const agentId = Cookies.get("agent_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/agency/get-by-agent?agent_id=${agentId}`,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: GET_ALL_AGENCIES_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_AGENCIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET AGENCY ACTION  =========================
export const getAgencyDetailFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_AGENCY_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/agency/get?agency_id=${agencyId}`,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: GET_AGENCY_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_AGENCY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== UPDATE AGENCY ACTION  =========================
export const updateAgencyFunc = (agencyData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_AGENCY_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agency/update`,
        agencyData,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: UPDATE_AGENCY_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_AGENCY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== UPDATE ASSIGN AGENT TEAM MEMBERS ACTION  =========================
export const updateAgentTeamMembersFunc = (agentData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_AGENT_TEAM_MEMBERS_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient/update-assigned-members`,
        agentData,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: UPDATE_AGENT_TEAM_MEMBERS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_AGENT_TEAM_MEMBERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== CHECK PATIENT EMAIL ACTION  =========================
export const checkPatientEmailFunc = (email) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_PATIENT_EMAIL_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/patient/check-email-existence?email=${email}`,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: CHECK_PATIENT_EMAIL_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: CHECK_PATIENT_EMAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetPatientEmailFunc = () => async (dispatch) => {
  dispatch({ type: RESET_CHECK_PATIENT_EMAIL });
};

// ================== CHECK PATIENT PHONE ACTION  =========================
export const checkPatientPhoneFunc = (phone) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_PATIENT_PHONE_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const encodedPhone = encodeURIComponent(phone);

      const response = await axios.get(
        `${BASE_URL}/patient/check-contact-number-existence?contact_number=${encodedPhone}`,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: CHECK_PATIENT_PHONE_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: CHECK_PATIENT_PHONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetPatientPhoneFunc = () => async (dispatch) => {
  dispatch({ type: RESET_CHECK_PATIENT_PHONE });
};

// ================== GET ALL CLIENTS  =========================
export const getAllClientsFunc = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_CLIENTS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      // const { data } = await axios.get(
      //   `https://staging-api.mycasecare.com/agent/get-clients-list?agency_id=${agencyID}`,
      //   config
      // );

      const response = await axios.get(
        `${BASE_URL}/agent/get-clients-list?agency_id=${agencyId}`,
        config
      );

      dispatch({ type: GET_ALL_CLIENTS_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_CLIENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET ALL CLIENTS UN PAGINATED  =========================
export const getAllClientsUnpaginatedFunc =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ALL_CLIENTS_UNPAGINATED_REQUEST });

      const token = Cookies.get("token");
      const agencyId = Cookies.get("agency_id_vita");
      if (token) {
        // userInfo = JSON.parse(userInfo);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/patient/get-list-unpaginated?agency_id=${agencyId}`,
          config
        );

        dispatch({
          type: GET_ALL_CLIENTS_UNPAGINATED_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_CLIENTS_UNPAGINATED_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ================== GET CLIENT DATA  =========================
export const getClientDataFunc = (clientId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CLIENT_DATA_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/agent/get-client?client_id=${clientId}`,
        config
      );

      dispatch({ type: GET_CLIENT_DATA_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_CLIENT_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== UPDATE CLIENT FUNCTION  =========================
export const updateClientFunc = (clientData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CLIENT_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      // const { data } = await axios.get(
      //   `https://staging-api.mycasecare.com/agent/get-clients-list?agency_id=${agencyID}`,
      //   config
      // );

      const response = await axios.post(
        `${BASE_URL}/patient/update`,
        clientData,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: UPDATE_CLIENT_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_CLIENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== ADD NEW CLIENT FUNCTION  =========================
export const addNewClientFunc = (clientData) => async (dispatch) => {
  try {
    dispatch({ type: ADD_NEW_CLIENT_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      // const { data } = await axios.get(
      //   `https://staging-api.mycasecare.com/agent/get-clients-list?agency_id=${agencyID}`,
      //   config
      // );

      const response = await axios.post(
        `${BASE_URL}/patient/invite`,
        clientData,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: ADD_NEW_CLIENT_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: ADD_NEW_CLIENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== DELETE CLIENT FUNCTION  =========================
export const deleteClientFunc = (clientData) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CLIENT_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      // const { data } = await axios.get(
      //   `https://staging-api.mycasecare.com/agent/get-clients-list?agency_id=${agencyID}`,
      //   config
      // );

      const response = await axios.post(
        `${BASE_URL}/agent/delete-client`,
        clientData,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: DELETE_CLIENT_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: DELETE_CLIENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetDeleteClient = () => (dispatch) =>
  dispatch({
    type: RESET_DELETE_CLIENT,
  });
export const resetAgentsList = () => (dispatch) =>
  dispatch({ type: RESET_AGENT_LISTS });

export const resetUpdateClientFunc = () => async (dispatch) => {
  dispatch({ type: UPDATE_CLIENT_SUCCESS, payload: { ok: false } });
};

export const resetUpdateAgencyFunc = () => async (dispatch) => {
  dispatch({ type: UPDATE_AGENCY_SUCCESS, payload: { ok: false } });
};

export const resetUpdateAgentProfile = () => async (dispatch) => {
  dispatch({ type: UPDATE_AGENT_PROFILE_SUCCESS, payload: { ok: false } });
};

export const resetNewClientFunc = () => async (dispatch) => {
  dispatch({ type: ADD_NEW_CLIENT_SUCCESS, payload: { ok: false } });
};

export const resetUpdatedTeamMember = () => (dispatch) =>
  dispatch({
    type: RESET_UPDATE_AGENT_TEAM_MEMBERS,
  });
