import React, { useEffect, useState } from "react";
import CardUI from "../../../../common/Card";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAgencyDetailFunc,
  updateAgencyFunc,
} from "../../../../actions/Agency";
import Cookies from "js-cookie";
import RectangleSkeletons from "../../../../components/RectangleSkeletons";
import AlertMessage from "../../../../common/AlertMessage";
import { LoadingButton } from "@mui/lab";

const Patients = () => {
  const [painDays, setPainDays] = useState("");
  const [error, setError] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,

    agencyDetail,
  } = getAgencyDetail;

  const updateAgency = useSelector((state) => state.updateAgency);
  const {
    loading: loadingAgency,

    updatedAgency,
  } = updateAgency;

  const dispatch = useDispatch();
  const cookieAgencyId = Cookies.get("agency_id_vita");

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  useEffect(() => {
    if (!agencyDetail) {
      dispatch(getAgencyDetailFunc());
    }

    if (agencyDetail) {
      setPainDays(agencyDetail?.agency?.number_of_days_for_77);
    }
  }, [agencyDetail]);

  const painDaysHanlder = () => {
    if (painDays === "" || painDays === "0" || painDays > 31) {
      setAlertOpen(false);
      setError("Please put a number from 1 to 31.");
      return;
    } else {
      setError("");

      const agencyData = {
        agency_id: cookieAgencyId,
        agency_settings: {
          number_of_days_for_77: Number(painDays),
        },
      };
      dispatch(updateAgencyFunc(agencyData));
      setAlertOpen(true);
    }
  };

  const resetPainlogDaysHandler = () => {
    setPainDays(agencyDetail?.agency?.number_of_days_for_77);
    setError("");
  };

  return (
    <>
      {!loadingAgency && updatedAgency?.ok && (
        <AlertMessage
          message="Your changes have been saved successfully"
          severity="success"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      <CardUI
        maxWidth="741px"
        padding="24px !important"
        boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
      >
        <Stack gap="12px">
          <Stack>
            <Typography
              variant="boxHeading"
              color="secondary"
              sx={{ textTransform: "uppercase" }}
            >
              CPT Code 98977 Settings
            </Typography>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
              Configure requirements for CPT Code 98977{" "}
            </Typography>
          </Stack>
          <Box
            display="flex"
            alignItems={"flex-start"}
            justifyContent="space-between"
            gap="8px"
            width="100%"
          >
            <Box display="flex" alignItems="center" height="40px">
              <Typography variant="body1">
                Total number of required patient interactions
              </Typography>
            </Box>
            {loadingAgencyDetail ? (
              <Box>
                <RectangleSkeletons height={46} totalRows={1} width="143px" />
              </Box>
            ) : (
              <FormControl
                sx={{ margin: "8px 8px 0 8px", width: "145px" }}
                variant="outlined"
              >
                <OutlinedInput
                  type="number"
                  size="small"
                  value={painDays}
                  onChange={(e) => setPainDays(e.target.value)}
                  error={Boolean(error)}
                  sx={{
                    "& input[type=number]": {
                      MozAppearance: "textfield", // Firefox
                      "&::-webkit-outer-spin-button": {
                        WebkitAppearance: "none", // Chrome, Safari, Edge
                        margin: 0,
                      },
                      "&::-webkit-inner-spin-button": {
                        WebkitAppearance: "none", // Chrome, Safari, Edge
                        margin: 0,
                      },
                    },
                  }}
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">
                      <span style={{ color: error ? "#D32F2F" : "inherit" }}>
                        Days
                      </span>
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
                {error && (
                  <FormHelperText
                    sx={{ color: "#D32F2F", marginRight: "0" }}
                    id="outlined-weight-helper-text"
                  >
                    {error}
                  </FormHelperText>
                )}
              </FormControl>
              // <TextField

              //   error={Boolean(error)}
              //   helperText={error}
              //   id="pain-days"
              //   value={painDays}
              //   onChange={painDaysHanlder}
              //   sx={{
              //     width: "145px",
              //     "& input[type=number]": {
              //       "-mozAppearance": "textfield", // Firefox
              //       "&::-webkit-outer-spin-button": {
              //         "-webkitAppearance": "none", // Chrome, Safari, Edge
              //         margin: 0,
              //       },
              //       "&::-webkit-inner-spin-button": {
              //         "-webkitAppearance": "none", // Chrome, Safari, Edge
              //         margin: 0,
              //       },
              //     },
              //   }}
              // />
            )}
          </Box>
          <Box
            gap="12px"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button variant="text" onClick={resetPainlogDaysHandler}>
              Cancel
            </Button>
            <LoadingButton
              loading={loadingAgency}
              variant="contained"
              onClick={painDaysHanlder}
            >
              Save
            </LoadingButton>
          </Box>
        </Stack>
      </CardUI>
    </>
  );
};

export default Patients;
