import { Alert, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { EmailMarketingIcon, SmsIcon } from "../../assets/constants";
import CallIcon from "../../icons/CallIcon";
import { LoadingButton } from "@mui/lab";
import AppContactDetailCard from "../AppContactDetailCard";

const PendingIconContent = ({
  is_enrolled,
  patientName,
  patientId,
  patientContactNumber,
  patientEmail,
}) => {
  return (
    <Stack gap="24px">
      {is_enrolled ? (
        <>
          {" "}
          <Alert severity="warning">
            An invite to the mobile app has been sent, but {patientName} has not
            logged in yet.
          </Alert>
          <AppContactDetailCard
            status="pending"
            patientId={patientId}
            patientContactNumber={patientContactNumber}
            patientEmail={patientEmail}
          />
        </>
      ) : (
        <Alert severity="warning">
          {patientName}'s mobile app access has been revoked as they have been
          un-enrolled from the RTM program.
        </Alert>
      )}
    </Stack>
  );
};

export default PendingIconContent;
