import React from "react";
import MccLogo from "../../assets/images/mcc-logo.png";
import { Box, Stack, Typography, useTheme } from "@mui/material";

const PatientLogoHeader = ({ title, description, width, mb }) => {
  const theme = useTheme();

  return (
    <Stack
      width="100%"
      gap="8px"
      alignItems="center"
      justifyContent="center"
      height="100%"
      marginBottom={mb}
    >
      <Box width="83px" height="80px">
        <img src={MccLogo} alt="mcc-logo" width="100%" height="100%" />
      </Box>

      <Stack alignItems="center">
        <Typography
          variant="h1"
          fontSize="22px"
          fontWeight={500}
          color="#0D0D0D"
        >
          {title}
        </Typography>
        <Typography
          sx={{
            [theme.breakpoints.down("sm")]: {
              width: width && width,
            },
          }}
          textAlign="center"
          variant="body1"
          color="secondary"
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PatientLogoHeader;
