import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const SendMessageDrawer = ({
  closeDrawerHandler,
  reviewedPatients,
  messageValue,
  setMessageValue,
  submitMessageHandler,
  loadingBulkMessages,
}) => {
  return (
    <>
      {" "}
      <Stack flex="1" sx={{ overflowY: "auto" }} gap="24px" height="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          paddingBottom="32px"
        >
          <Typography variant="h5">Send Message</Typography>
          <IconButton size="small" onClick={closeDrawerHandler}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>

        <Alert severity="info" sx={{ color: "#0288D1" }}>
          This message will be sent to{" "}
          <Box
            component="span"
            fontWeight={500}
            // sx={{ textDecoration: "underline" }}
          >
            {" "}
            {reviewedPatients?.length} {reviewedPatients?.length == 1 ? 'patient' : 'patients'}
          </Box>
        </Alert>

        <Stack>
          <TextField
            multiline
            rows={5}
            value={messageValue}
            onChange={(e) => setMessageValue(e.target.value)}
            name="message"
            label="Compose Message"
          />
        </Stack>
      </Stack>
      <Stack alignItems="flex-end" pt={1.4}>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={!messageValue}
          onClick={submitMessageHandler}
          loading={loadingBulkMessages}
          //   onClick={saveTimeHandler}
          //   loading={loadingUpdateTimeEntry || loadingCreateTimeEntry}
          //   disabled={!startTimeValue || !endTimeValue || !selectValue}
          // disabled={!firstName || !email || emailError}
          // height="40px"
          // width="140px"
        >
          Send Message
        </LoadingButton>
      </Stack>{" "}
    </>
  );
};

export default SendMessageDrawer;
