import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StepCountDetailCard from "../StepCountDetailCard";
import ChatTabTableCard from "../ChatTabTableCard";
import PainLogsDate from "../PainLogsDate";
import PainLogDetail from "../PainLogDetail";
import AddDiagnosisCodeSection from "../AddDiagnosisCodeSection";
import PushNotificationsCard from "../PushNotificationsCard";
import EmptyBox from "../../assets/images/empty-box.png";
import { useSelector } from "react-redux";
import WaitingClockIcon from "../../icons/WaitingClockIcon";
import dayjs from "dayjs";

const tabOptions = [
  {
    title: "Pain Logs",
    value: "pain_logs",
    borderColor: "#1669BB",
    bgColor: "#E3F1FF",
    hoverBgColor: "#1669BB",
  },
  {
    title: "Push Notifications",
    value: "push_notifications",
    borderColor: "#1669BB",
    bgColor: "#E3F1FF",
    hoverBgColor: "#1669BB",
  },
];

// const LinearProgressWithLabel = (props) => {
//   return (
//     <Box display="flex" alignItems="center" gap="4px">
//       <Box sx={{ width: "100%" }}>
//         <LinearProgress variant="determinate" {...props} />
//       </Box>
//       <Box>
//         <Typography
//           variant="body2"
//           fontSize="12px"
//           color="secondary"
//         >{`${Math.round(props.value)}%`}</Typography>
//       </Box>
//     </Box>
//   );
// };

const Cpt98977Content = ({ cptCodeDetail }) => {
  const [selectedLogsFilters, setSelectedLogsFilters] = useState("pain_logs");
  const [showBox, setShowBox] = useState(true);

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  const clickFilterHandler = (value) => {
    setSelectedLogsFilters(value);
  };

  const hideBoxHandler = () => {
    setShowBox((prev) => !prev);
  };

  const cptTransmissions = JSON.parse(cptCodeDetail?.transmissions);
  const distinctDates = [
    ...new Set(cptTransmissions?.map((item) => item?.date)),
  ];

  // MERGED SAME DATES INTO A SINGLE ENTRY AND SHOW BODY PARTS

  let mergedEntries = cptCodeDetail?.pain_entries?.reduce((acc, entry) => {
    const parsedBodyPartsData = entry?.pain_entry_body_parts || [];

    const existingEntry = acc.find((e) => e?.date === entry?.date);
    const existingMedicineEntry = cptCodeDetail?.medicine_entries?.find(
      (e) => e?.date === entry?.date
    );

    const bodyPartsData = [...parsedBodyPartsData];

    if (
      existingMedicineEntry &&
      !bodyPartsData.includes(existingMedicineEntry)
    ) {
      bodyPartsData.push(existingMedicineEntry);
    }

    if (existingEntry) {
      existingEntry?.body_parts_data.push(...parsedBodyPartsData);
    } else {
      acc.push({
        ...entry,
        body_parts_data: bodyPartsData,
      });
    }

    return acc;
  }, []);

  if (!cptCodeDetail?.pain_entries?.length) {
    mergedEntries = cptCodeDetail?.medicine_entries?.map((entry) => ({
      ...entry,
      body_parts_data: [entry],
    }));
  }

  // mergedEntries?.forEach((entry) => {
  //   for (const bodyPart of entry?.body_parts_data) {
  //     if (bodyPart?.side && bodyPart?.name) {
  //       entry.medicine_entry_only = false;
  //       break;
  //     }
  //   }
  // });

  // const inProgressPerc =
  //   (distinctDates?.length / agencyDetail?.agency?.number_of_days_for_77) * 100;

  return (
    <Stack gap="16px">
      <Typography
        variant="body1"
        fontWeight={500}
        color="secondary"
        textTransform="uppercase"
      >
        Code requirements{" "}
      </Typography>
      <Stack
        border="1px solid #EBEBEB"
        bgcolor="#fff"
        borderRadius="8px"
        padding="8px"
        gap="24px"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="12px"
          bgcolor="#FCFCFC"
          borderRadius="8px"
          paddingLeft="8px"
        >
          <FormControlLabel
            sx={{ marginRight: "0", flex: "1" }}
            control={
              distinctDates?.length >=
              agencyDetail?.agency?.number_of_days_for_77 ? (
                <Checkbox
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  checked={
                    distinctDates?.length >=
                    agencyDetail?.agency?.number_of_days_for_77
                  }
                  color="success"
                  onChange={(e) => e.preventDefault()}
                />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  padding="9px"
                >
                  <WaitingClockIcon color="#F87E4A" />
                </Box>
              )
            }
            label={`${agencyDetail?.agency?.number_of_days_for_77} days of data`}
          />

          {/* <LinearProgressWithLabel
            color="success"
            value={
              distinctDates?.length
                ? inProgressPerc >= 100
                  ? 100
                  : inProgressPerc
                : 0
            }
            sx={{ width: "126px", borderRadius: "10px" }}
          /> */}
          <Button
            variant="text"
            sx={{ textTransform: "initial", cursor: "pointer" }}
            onClick={hideBoxHandler}
          >
            {showBox ? "Hide details" : "Show details"}
          </Button>
        </Box>

        {showBox && (
          <Stack gap="24px">
            <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
              {tabOptions?.map((item, index) => (
                <Box
                  key={index}
                  padding="3px 10px"
                  borderRadius="4px"
                  bgcolor={
                    selectedLogsFilters?.includes(item?.value)
                      ? `${item?.bgColor}`
                      : "#F4F6F8"
                  }
                  color="#0D0D0D"
                  sx={{
                    boxShadow: selectedLogsFilters?.includes(item?.value)
                      ? `inset 0 0 0 1.5px ${item.borderColor}`
                      : ``,
                    "&:hover": {
                      background: `${item?.hoverBgColor}`,
                      color: "#fff !important",
                      transition: "background 300ms ease-out",
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => clickFilterHandler(item.value)}
                >
                  <Typography variant="body2" fontSize="12px">
                    {" "}
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>

            {selectedLogsFilters === "pain_logs" && (
              <Stack gap="12px">
                {cptCodeDetail?.pain_entries?.length ||
                cptCodeDetail?.medicine_entries?.length ? (
                  mergedEntries
                    ?.sort(
                      (a, b) =>
                        dayjs(b?.date)?.valueOf() - dayjs(a?.date)?.valueOf()
                    )
                    ?.map((data, i) => (
                      <React.Fragment key={i}>
                        {data?.body_parts_data?.length ? (
                          <Box
                            key={i}
                            display="flex"
                            gap="24px"
                            bgcolor="#FCFCFC"
                            padding="16px"
                            borderRadius="8px"
                            width="100%"
                          >
                            <PainLogsDate date={data?.date} />
                            <Box
                              sx={{
                                width: "100%",
                                padding: "0 !important",
                                // paddingLeft: "84px !important",
                                paddingBottom: "12px !important",
                                paddingRight: "16px !important",
                              }}
                            >
                              {data?.body_parts_data?.map((bodyPart, i) => (
                                <PainLogDetail
                                  key={i}
                                  index={i}
                                  bodyPart={bodyPart}
                                  data={data}
                                  parse={false}
                                />
                              ))}
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))
                ) : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="348px"
                  >
                    <Avatar
                      src={EmptyBox}
                      alt="no time entries"
                      sx={{
                        width: "128px",
                        height: "128px",
                        paddingBottom: "24px",
                      }}
                    />
                    <Typography variant="body1" color="secondary" pb={2}>
                      No data to show{" "}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}

            {selectedLogsFilters === "push_notifications" && (
              <Stack gap="8px">
                {cptCodeDetail?.notifications?.length === 0 ? (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="348px"
                  >
                    <Avatar
                      src={EmptyBox}
                      alt="no time entries"
                      sx={{
                        width: "128px",
                        height: "128px",
                        paddingBottom: "24px",
                      }}
                    />
                    <Typography variant="body1" color="secondary" pb={2}>
                      No data to show{" "}
                    </Typography>
                  </Stack>
                ) : (
                  cptCodeDetail?.notifications?.map((item, index) => (
                    <PushNotificationsCard
                      key={index}
                      title={item?.title}
                      description={item?.body}
                      date={item?.created_at}
                    />
                  ))
                )}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Cpt98977Content;
