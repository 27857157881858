export const GET_BILLING_SUBSCRIPTION_REQUEST =
  "GET_BILLING_SUBSCRIPTION_REQUEST";
export const GET_BILLING_SUBSCRIPTION_SUCCESS =
  "GET_BILLING_SUBSCRIPTION_SUCCESS";
export const GET_BILLING_SUBSCRIPTION_FAIL = "GET_BILLING_SUBSCRIPTION_FAIL";
export const RESET_BILLING_SUBSCRIPTION = "RESET_BILLING_SUBSCRIPTION";

export const CREATE_BILLING_SUBSCRIPTION_REQUEST =
  "CREATE_BILLING_SUBSCRIPTION_REQUEST";
export const CREATE_BILLING_SUBSCRIPTION_SUCCESS =
  "CREATE_BILLING_SUBSCRIPTION_SUCCESS";
export const CREATE_BILLING_SUBSCRIPTION_FAIL =
  "CREATE_BILLING_SUBSCRIPTION_FAIL";
export const RESET_CREATE_BILLING_SUBSCRIPTION =
  "RESET_CREATE_BILLING_SUBSCRIPTION";

export const UPDATE_BILLING_SUBSCRIPTION_REQUEST =
  "UPDATE_BILLING_SUBSCRIPTION_REQUEST";
export const UPDATE_BILLING_SUBSCRIPTION_SUCCESS =
  "UPDATE_BILLING_SUBSCRIPTION_SUCCESS";
export const UPDATE_BILLING_SUBSCRIPTION_FAIL =
  "UPDATE_BILLING_SUBSCRIPTION_FAIL";
export const RESET_UPDATE_BILLING_SUBSCRIPTION =
  "RESET_UPDATE_BILLING_SUBSCRIPTION";

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL";
export const RESET_CANCEL_SUBSCRIPTION = "RESET_CANCEL_SUBSCRIPTION";
