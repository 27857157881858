import { Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import HeaderText from "../../common/HeaderText";
import {
  dateTimeFormat,
  painDurationValues,
  painLevelBackgroundColor,
} from "../../utils/helpers";
import Ellipse from "../../icons/Ellipse";
import styled from "@emotion/styled";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "16px",
  height: "16px",
  color: "#fff",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
}));

const PainLogDetail = ({ index, bodyPart, data, filtered, parse = false }) => {
  const isTablet = useMediaQuery("(max-width:1325px)");

  const lastIndex = parse
    ? JSON.parse(data?.body_parts_data)?.length - 1
    : data?.body_parts_data?.length - 1;

  return (
    <Stack gap="4px" width="100%">
      {isTablet ? (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="8px"
        >
          <Box display="flex" alignItems="flex-start" gap="8px">
            <Stack gap="2px">
              <Typography
                variant="h6"
                fontSize="16px"
                color="#000"
                sx={{ textTransform: "capitalize" }}
              >
                {bodyPart?.side} {bodyPart?.name}
              </Typography>
              <Typography variant="body1" fontSize="14px" color="secondary">
                Pain Intensity :{" "}
                <StyledBox
                  component="span"
                  sx={{
                    background: painLevelBackgroundColor(bodyPart?.pain_scale),
                    padding: "10px",
                  }}
                >
                  {bodyPart?.pain_scale}
                </StyledBox>
              </Typography>
            </Stack>
          </Box>
          <Stack gap="4px" alignItems="flex-end">
            {bodyPart?.medicine_adherence === "no" && (
              <Box bgcolor="#FE7878" borderRadius="4px" padding="0 4px">
                <Typography variant="body2" color="#fff">
                  Medication missed
                </Typography>
              </Box>
            )}
            {bodyPart?.medicine_adherence === "na" && (
              <Box bgcolor="#F1B62E" borderRadius="4px" padding="0 4px">
                <Typography variant="body2" color="#fff">
                  Medication not prescribed{" "}
                </Typography>
              </Box>
            )}
            <HeaderText
              textContent={dateTimeFormat(bodyPart?.time)}
              color="secondary"
              variant="body1"
              fontSize="14px"
              fontWeight="400"
            />
          </Stack>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="8px"
        >
          <Box display="flex" alignItems="flex-start" gap="8px">
            <Stack gap="2px">
              <Typography
                variant="h6"
                fontSize="16px"
                color="#000"
                sx={{ textTransform: "capitalize" }}
              >
                {bodyPart?.side} {bodyPart?.name}
              </Typography>
              <Typography variant="body1" fontSize="14px" color="secondary">
                Pain Intensity :{" "}
                <StyledBox
                  component="span"
                  sx={{
                    background: painLevelBackgroundColor(bodyPart?.pain_scale),
                    padding: "10px",
                  }}
                >
                  {bodyPart?.pain_scale}
                </StyledBox>
              </Typography>
            </Stack>

            {bodyPart?.medicine_adherence === "no" && (
              <Box display="flex" alignItems="center" gap="8px">
                <Ellipse />

                <Box bgcolor="#FE7878" borderRadius="4px" padding="0 4px">
                  <Typography variant="body2" color="#fff">
                    Medication missed
                  </Typography>
                </Box>
              </Box>
            )}
            {bodyPart?.medicine_adherence === "na" && (
              <Box display="flex" alignItems="center" gap="8px">
                <Ellipse />

                <Box bgcolor="#F1B62E" borderRadius="4px" padding="0 4px">
                  <Typography variant="body2" color="#fff">
                    Medication not prescribed{" "}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            <HeaderText
              textContent={dateTimeFormat(bodyPart?.time)}
              color="secondary"
              variant="body1"
              fontSize="14px"
              fontWeight="400"
            />
          </Box>
        </Box>
      )}

      {data?.duration && (
        <Typography variant="body2" color="secondary">
          Duration: {painDurationValues[data?.duration]}
        </Typography>
      )}

      <Box>
        <Typography fontSize="16px" variant="body1">
          {bodyPart?.description}
        </Typography>
      </Box>
      {index !== lastIndex && <Divider sx={{ margin: "12px 0" }} />}
    </Stack>
  );
};

export default PainLogDetail;
