import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import { reportMonthFilterOptions } from "../../utils/helpers";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

import dayjs from "dayjs";

const ExportEvidenceReportContent = ({
  selectedMonthValue,
  setSelectedMonthValue,
  enrolledOn,
  painLogsCheckedValue,
  setPainLogsCheckedValue,
  stepCountsCheckedValue,
  setStepCountsCheckedValue,
  DetailedChatCheckedValue,
  setDetailedChatCheckedValue,
  timeSheetCheckedValue,
  setTimeSheetCheckedValue,
  appointmentsCheckedValue,
  setAppointmentsCheckedValue,
  notesCheckedValue,
  setNotesCheckedValue,
  painRemindersCheckedValue,
  setPainRemindersCheckedValue,
}) => {
  const changeMonthFilterHandler = (e) => {
    setSelectedMonthValue(e.target.value);
  };

  return (
    <Stack>
      <FormControl>
        <FormLabel
          id="export-report-radio-buttons-group"
          sx={{
            paddingBottom: "12px",
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 500,
          }}
        >
          Output Configurations
        </FormLabel>
      </FormControl>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="16px"
      >
        <Typography variant="body2">Reporting Period</Typography>
        <CustomSelectFilter
          menuItems={reportMonthFilterOptions(enrolledOn)}
          iconColor="#0D0D0D"
          fontSize="12px"
          IconComponent={ExpandMoreRoundedIcon}
          changeSelectValueHandler={changeMonthFilterHandler}
          selectValue={selectedMonthValue}
          id="export-report-month"
          minWidth={124}
          size="small"
        />
      </Box>

      {/* PAIN LOGS */}
      <FormControlLabel
        control={
          <Checkbox
            checked={painLogsCheckedValue}
            onChange={(e) => setPainLogsCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>
            Include detailed daily pain logs
          </span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />

      {/* STEP COUNT */}
      <FormControlLabel
        control={
          <Checkbox
            value={stepCountsCheckedValue}
            onChange={(e) => setStepCountsCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>
            Include detailed daily step count logs
          </span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />

      {/* CHAT */}
      <FormControlLabel
        control={
          <Checkbox
            value={DetailedChatCheckedValue}
            onChange={(e) => setDetailedChatCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>Include detailed chat</span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
      {/* Include TIME SHEET  */}
      <FormControlLabel
        control={
          <Checkbox
            value={timeSheetCheckedValue}
            onChange={(e) => setTimeSheetCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={<span style={{ marginRight: "auto" }}>Include time sheet </span>}
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
      {/* APPOINTMENT LOGS  */}
      <FormControlLabel
        control={
          <Checkbox
            value={appointmentsCheckedValue}
            onChange={(e) => setAppointmentsCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>
            Include detailed appointments logs
          </span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
      {/* INCLUDE NOTES */}
      <FormControlLabel
        control={
          <Checkbox
            value={notesCheckedValue}
            onChange={(e) => setNotesCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={<span style={{ marginRight: "auto" }}>Include notes</span>}
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
      {/* PAIN REMINDER */}
      <FormControlLabel
        control={
          <Checkbox
            value={painRemindersCheckedValue}
            onChange={(e) => setPainRemindersCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>
            Include pain reminder logs
          </span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
    </Stack>
  );
};

export default ExportEvidenceReportContent;
