import {
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { formatElapsedTime } from "../../utils/helpers";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const DateTimeCard = ({
  heading,
  checkedBox = false,
  id,
  notesValue,
  startTime,
  endTime,
  elapsedTime,
  deleteTimeEntryHandler,
  timeEntryId,
  timeDetails,
  openTimeDrawerHandler,
  agentId,
  showMoreVertIcon,
}) => {
  const getUnpaginatedAgentLists = useSelector(
    (state) => state.getUnpaginatedAgentLists
  );
  const {
    loading: loadingAgentsList,
    error: errorAgentsList,
    unPaginatedAgents: allAgents,
  } = getUnpaginatedAgentLists;

  const getLoggedUserByName = allAgents?.agents_list?.find(
    (item) => item?.id === agentId
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const openDeleteModalHandler = () => {
    setAnchorEl(null);
    deleteTimeEntryHandler(timeDetails);
  };

  const openEditDrawerHandler = () => {
    setAnchorEl(null);
    openTimeDrawerHandler(timeDetails);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="#FCFCFC"
      padding="12px"
      borderRadius="8px"
      gap="8px"
    >
      <Box display="flex" alignItems="center" gap="12px">
        <label
          htmlFor={`timesheet-${id}`}
          style={{ display: "flex", alignItems: "center", gap: "12px" }}
        >
          {checkedBox && (
            <Checkbox
              id={`timesheet-${id}`}
              // checked={checkedBox}
              // onChange={handleCheckboxChange}
            />
          )}
          <Stack width="225px" component="div">
            <Typography variant="body1" fontWeight={500}>
              {heading}
            </Typography>
            <Typography variant="caption" color="secondary">
              {notesValue}
            </Typography>
          </Stack>
        </label>
      </Box>

      {/*  */}
      <Stack>
        <Typography variant="body2" color="secondary">
          Start time{" "}
        </Typography>
        <Typography variant="caption" color="#212121">
          {dayjs(startTime)?.format("hh:mm A")}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="body2" color="secondary">
          End Time{" "}
        </Typography>
        <Typography variant="caption" color="#212121">
          {dayjs(endTime)?.format("hh:mm A")}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="body2" color="secondary">
          Logged by{" "}
        </Typography>
        <Typography variant="body2" color="#212121">
          {getLoggedUserByName?.name}
        </Typography>
      </Stack>

      <Box display="flex" alignItems="center" gap="8px">
        <Typography
          fontSize="19px"
          variant="body2"
          color="#4B7DAE"
          fontWeight={500}
        >
          {formatElapsedTime(elapsedTime * 1000)}
        </Typography>
        {showMoreVertIcon && (
          <Box>
            <IconButton
              aria-label="timesheet-menu"
              id="timesheet-menu"
              aria-controls={open ? "timesheet-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="timesheet-menu"
              MenuListProps={{
                "aria-labelledby": "timesheet-menu",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              //   PaperProps={{
              //     style: {
              //       maxHeight: ITEM_HEIGHT * 4.5,
              //       width: "20ch",
              //     },
              //   }}
            >
              <MenuItem onClick={openEditDrawerHandler}>Edit</MenuItem>
              <MenuItem
                sx={{ color: "#FF2D2D" }}
                onClick={openDeleteModalHandler}
              >
                Delete
              </MenuItem>
            </Menu>{" "}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DateTimeCard;
