import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ImportButtonActions from "../../components/ImportButtonActions";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { stringAvatar } from "../../utils/helpers";
import {
  getAgentsListFunc,
  getUnpaginatedAgentsListFunc,
} from "../../actions/Agency";
import { useDispatch } from "react-redux";
import {
  assignBulkPatientsFunc,
  checkImportStatusFunc,
  getImportPatientsFunc,
} from "../../actions/ImportFileActions";
import SuccessImportPage from "../../components/SuccessImportPage";
import Cookies from "js-cookie";
import { debounce } from "lodash";
import LoadingBulkPatients from "../../components/LoadingBulkPatients";
import { setToggleMinimize } from "../../actions/AppConfig";

const AssignPatients = () => {
  const dispatch = useDispatch();
  const agencyId = Cookies.get("agency_id_vita");

  const [assignedTeamMembers, setAssignedTeamMembers] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalPatients, setTotalPatients] = useState(0);
  const [showSuccessState, setShowSuccessState] = useState(false);

  const intervalRef = useRef(null);

  const getUnpaginatedAgentLists = useSelector(
    (state) => state.getUnpaginatedAgentLists
  );
  const {
    loading: loadingAgent,
    error: errorAgent,
    unPaginatedAgents: allAgents,
  } = getUnpaginatedAgentLists;

  const getImportPatients = useSelector((state) => state.getImportPatients);
  const {
    loading: loadingPatients,
    error: errorPatients,
    importedPatientsList,
  } = getImportPatients;

  const checkImportStatus = useSelector((state) => state.checkImportStatus);
  const {
    loading: loadingImportStatus,
    error: errorImportStatus,
    importStatus,
  } = checkImportStatus;

  const getImportId = useSelector((state) => state.getImportId);
  const {
    loading: loadingImportId,
    error: errorImportId,
    importId,
  } = getImportId;

  const assignBulkMembers = useSelector((state) => state.assignBulkMembers);
  const {
    loading: loadingAssignedMembers,
    error: errorMembers,
    assignedBulkMembers,
  } = assignBulkMembers;

  const allAgentsOptions =
    allAgents?.agents_list.length > 0
      ? allAgents?.agents_list?.filter(
          (agent) => agent?.agent_type !== "mcc_support"
        )
      : [];

  useEffect(() => {
    dispatch(getUnpaginatedAgentsListFunc());
  }, []);

  // useEffect(() => {
  //   dispatch(
  //     getImportPatientsFunc(
  //       importId,
  //       paginationModel?.page + 1,
  //       paginationModel?.pageSize
  //     )
  //   );
  // }, [paginationModel]);

  // const debouncedDispatch = useCallback(
  //   debounce(() => {
  //     dispatch(
  //       getImportPatientsFunc(
  //         importId,
  //         paginationModel?.page + 1,
  //         paginationModel?.pageSize
  //       )
  //     );
  //   }, ),
  //   [paginationModel.page, paginationModel.pageSize]
  // );

  useEffect(() => {
    if (!loadingAssignedMembers && !assignedBulkMembers?.ok) {
      dispatch(
        getImportPatientsFunc(
          importId,
          paginationModel?.page + 1,
          paginationModel?.pageSize
        )
      );
    }
  }, [paginationModel.page, paginationModel.pageSize]);

  const patientColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 156,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 182,
      // flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact Number",
      minWidth: 143,
      // flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 235,
      // flex: 1,
    },
    {
      field: "diagnosis_code",
      headerName: "Diagnosis Code",
      minWidth: 143,

      flex: 1,
    },
    {
      field: "assigned_to",
      headerName: "Assigned to",
      minWidth: 132,

      flex: 1,
    },
  ];

  const rows = useMemo(() => {
    if (importedPatientsList?.temp_patients?.length === 0) return [];
    return importedPatientsList?.temp_patients?.map((ptItem) => {
      // setAppStatus(appStatus);
      return {
        id: ptItem.id,
        name: ptItem?.name,
        email: ptItem?.email,
        contact: ptItem?.contact_number,
        address: ptItem?.address,
        diagnosis_code: ptItem?.imported_diagnostic_codes,
        assigned_to: "-",
      };
    });
  }, [importedPatientsList]);

  useEffect(() => {
    if (importedPatientsList?.ok) {
      setTotalPatients(importedPatientsList?.count || 0);
    }
  }, [importedPatientsList, paginationModel?.page, paginationModel?.pageSize]);

  const assignMembersHandler = () => {
    const membersArr = assignedTeamMembers?.map((item) => item?.id);

    const bulkData = {
      agency_id: agencyId,
      import_id: importId,
      members: membersArr,
    };

    dispatch(assignBulkPatientsFunc(bulkData));
  };

  useEffect(() => {
    if (assignedBulkMembers?.ok) {
      if (
        importStatus?.import_status !== "completed" &&
        importStatus?.import_status !== "in_progress"
      ) {
        dispatch(checkImportStatusFunc(importId));
      }

      if (importStatus?.import_status !== "completed") {
        intervalRef.current = setInterval(() => {
          dispatch(checkImportStatusFunc(importId));
        }, 5000);
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [assignedBulkMembers, importStatus]);

  // useEffect(() => {
  //   if (
  //     assignedBulkMembers?.ok &&
  //     importStatus?.import_status !== "completed"
  //   ) {
  //     intervalRef.current = setInterval(() => {
  //       dispatch(checkImportStatusFunc(importId));
  //     }, 5000);

  //     return () => {
  //       if (intervalRef.current) {
  //         clearInterval(intervalRef.current);
  //       }
  //     };
  //   }
  // }, [importStatus]);

  useEffect(() => {
    if (importStatus?.ok && importStatus?.import_status === "completed") {
      setShowSuccessState(true);
      dispatch(setToggleMinimize(false));

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [importStatus]);

  return (
    <Stack padding="20px 40px" paddingBottom="40px" height="100%" gap="24px">
      <Box>
        <Typography variant="h5" fontSize="30px" fontWeight={500}>
          Import Patients
        </Typography>
      </Box>

      <Stack
        height="100%"
        border="1px solid #D9D9D9"
        bgcolor="#fff"
        borderRadius="8px"
        overflow="hidden"
        width="100%"
        gap="32px"
        padding="32px"
        paddingBottom="0"
      >
        {loadingAssignedMembers ||
        loadingImportStatus ||
        importStatus?.import_status === "in_progress" ? (
          <LoadingBulkPatients />
        ) : showSuccessState || importStatus?.import_status === "completed" ? (
          <SuccessImportPage
            totalCount={importedPatientsList?.count}
            download_url={assignedBulkMembers?.not_imported_file_url}
            importedCount={assignedBulkMembers?.imported_count}
            // setShowSuccessState={setShowSuccessState}
          />
        ) : (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack gap="4px">
                <Typography variant="h5" fontSize="20px" fontWeight={500}>
                  Assign Patients
                </Typography>
                <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
                  Patients should be assigned to Team Members before importing
                  to the Patient CRM
                </Typography>
              </Stack>
              <ImportButtonActions
                nextStepHandler={assignMembersHandler}
                disabled={
                  !importedPatientsList?.temp_patients?.length ||
                  !assignedTeamMembers?.length
                }
              />
            </Box>
            <Stack height="100%" gap="16px" overflow="hidden">
              <Box
                width="258px"
                display="flex"
                alignSelf="flex-end"
                padding="6px 0"
              >
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  id="assignTeamMembers"
                  options={allAgentsOptions}
                  getOptionLabel={(option) => (option.name ? option.name : [])}
                  filterSelectedOptions
                  value={assignedTeamMembers}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  defaultValue={assignedTeamMembers}
                  onChange={(e, value) => {
                    setAssignedTeamMembers(value && value);
                  }}
                  renderTags={(value, props) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...props({ index })}
                        style={{
                          fontSize: "12px",
                          color: "#2196F3",
                          background: "#35A0F426",
                        }}
                        sx={{
                          "& .MuiChip-deleteIcon": {
                            color: "#2196F3",
                          },
                        }}
                      />
                    ))
                  }
                  sx={{ width: "100%" }}
                  renderOption={(props, option) => {
                    const optionKey = `${option?.id}-${option?.name}`;

                    return (
                      <Box
                        key={optionKey}
                        component="li"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        {...props}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          maxWidth="250px"
                          width="100%"
                        >
                          {option.profile_picture_url ? (
                            <Avatar
                              sx={{ width: "24px", height: "24px" }}
                              src={
                                option.profile_picture_url &&
                                option.profile_picture_url
                              }
                              alt={option?.name}
                            />
                          ) : (
                            <Avatar
                              sx={{
                                width: "24px",
                                height: "24px",
                                fontSize: "12px",
                                backgroundColor: option?.avatar_color,
                              }}
                              {...stringAvatar(option?.name)}
                            />
                          )}
                          <Typography
                            variant="body2"
                            id={`assignee-${option.name}`}
                          >
                            {" "}
                            {option.name}
                          </Typography>
                        </Box>
                        {/* <Typography variant="caption" color="secondary">
                          {option.role}
                        </Typography> */}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="assign-team-member"
                      label="Assigned to"
                      // helperText={
                      //   !teamMembersId.length &&
                      //   assignMemberError &&
                      //   "Please add team member"
                      // }
                      // error={!teamMembersId.length && assignMemberError}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "assign-team-member",
                      }}
                    />
                  )}
                />
              </Box>

              <Stack
                sx={{
                  // height: loadingPatients
                  //   ? "calc(100% - 70px)"
                  //   : "calc(100% - 152px)",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <DataGrid
                  rows={rows || []}
                  columns={patientColumns || []}
                  rowCount={totalPatients || 0}
                  showGridToolbar={false}
                  loading={
                    loadingPatients || importedPatientsList === undefined
                  }
                  disableColumnMenu={true}
                  pagination
                  paginationMode="server"
                  page={paginationModel?.page}
                  pageSize={paginationModel?.pageSize}
                  paginationModel={paginationModel}
                  onPaginationModelChange={(newPaginationModel) => {
                    setPaginationModel(newPaginationModel);
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10]}
                  disableRowSelectionOnClick
                  //   onRowClick={caseDetailHandler}

                  sx={{
                    "&": { border: "none" },
                    "& .MuiDataGrid-columnHeader": {
                      // backgroundColor: "#eee",
                      // padding: "0 16px",
                      // display: "flex",
                      // alignItems: "center",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-virtualScrollerRenderZone": {
                      background: "none !important",
                    },

                    cursor: "pointer !important",
                  }}
                />
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default AssignPatients;
