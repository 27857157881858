import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BillingCard from "../BillingCard";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getBillBreakdownFunc,
  getBillInvoicesFunc,
  getCurrentMonthBillFunc,
  getOrgPaymentDetailsFunc,
  getPaymentInvoiceFunc,
  resetPaymentInvoiceFunc,
} from "../../../actions/OrganizationActions";
import RectangleSkeletons from "../../RectangleSkeletons";
import dayjs from "dayjs";
import { BillingReportIcon, DownloadBillIcon } from "../../../assets/constants";
import CaseTableSkeleton from "../../CaseTableSkeleton";
import { invoiceStatus, paymentText } from "../../../utils/helpers";

const StyledTableCell = styled(TableCell)(({}) => {
  return {
    padding: "8px 16px",
  };
});

const cardsPerView = 2;

const columns = [
  { id: "name", label: "Practice", minWidth: 302 },
  { id: "number_of_patients", label: "Patients", minWidth: 80 },
  {
    id: "amount",
    label: (
      <Stack>
        <Typography variant="body2">Bill amount</Typography>
        <Typography variant="body2" fontSize="13px" color="rgba(0, 0, 0, 0.38)">
          $30 per patient
        </Typography>
      </Stack>
    ),
    minWidth: 165,
    align: "right",
  },

  // {
  //   id: "practice_id",
  //   label: "Practice ID",
  //   minWidth: 165,

  //   // renderCell: ({ value }) => (
  //   //   <Typography
  //   //     display="flex"
  //   //     alignItems="center"
  //   //     // variant="body2"
  //   //     color="secondary"
  //   //     height="100%"
  //   //   >
  //   //     {value}
  //   //   </Typography>
  //   // ),
  // },

  {
    id: "actions",
    // label: "Actions",
    minWidth: 32,
    // align: "right",
    // format: (value) => value.toFixed(2),
  },
];

const BillingTab = ({ showTable = true }) => {
  const dispatch = useDispatch();

  const [invoiceRows, setInvoiceRows] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [invoicesArr, setInvoicesArr] = useState([]);
  const [snackbarData, setSnackbarData] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const getCurrentMonthBill = useSelector((state) => state.getCurrentMonthBill);
  const {
    loading: loadingBill,
    error: errorBill,
    currentMonthBill,
  } = getCurrentMonthBill;

  const getOrganization = useSelector((state) => state.getOrganization);
  const {
    loading: loadingOrg,
    error: errorOrg,
    organizationDetail,
  } = getOrganization;

  const getBillBreakdown = useSelector((state) => state.getBillBreakdown);
  const {
    loading: loadingBreakdown,
    error: errorBreakdown,
    billBreakdown,
  } = getBillBreakdown;

  const getBillInvoices = useSelector((state) => state.getBillInvoices);
  const {
    loading: loadingInvoices,
    error: errorInvoices,
    invoiceDetails,
  } = getBillInvoices;

  const getPaymentInvoice = useSelector((state) => state.getPaymentInvoice);
  const {
    loading: loadingInvoice,
    error: errorInvoice,
    paymentInvoice,
  } = getPaymentInvoice;

  const getOrgPaymentDetails = useSelector(
    (state) => state.getOrgPaymentDetails
  );
  const {
    loading: loadingPaymentDetails,
    error: errorPaymentDetails,
    orgPaymentDetails,
  } = getOrgPaymentDetails;

  const activePractices = organizationDetail?.organization?.agencies?.length;

  useEffect(() => {
    dispatch(getCurrentMonthBillFunc());
    dispatch(getOrgPaymentDetailsFunc());
    dispatch(getBillBreakdownFunc());
    dispatch(getBillInvoicesFunc());
  }, []);

  useEffect(() => {
    if (invoiceDetails?.ok) {
      setInvoicesArr(invoiceDetails?.invoices);
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (billBreakdown?.ok) {
      setInvoiceRows(billBreakdown?.agencies);
      setSelectedItemIndex(null);
    }
  }, [billBreakdown]);

  const currentMonthName = dayjs().format("MMMM");
  const currentMonthDueDate = dayjs().format("MMM, YYYY");
  const nextMonthDueDate = dayjs()
    .add(1, "month")
    .date(25)
    .format("DD MMM, YYYY");

  const downloadMonthInvoiceHandler = (item) => {
    window.open(item?.invoice?.invoice_url, "_blank");
  };

  const downloadInvoiceHandler = (row) => {
    let startDate, endDate;
    if (selectedItemIndex === null) {
      startDate = dayjs()?.startOf("month")?.format("YYYY-MM-DD");
      endDate = dayjs()?.format("YYYY-MM-DD");

      dispatch(getPaymentInvoiceFunc(row?.agency_id, null, startDate, endDate));
    } else {
      const fullMonthName = `${selectedItem?.invoice?.month} ${selectedItem?.invoice?.year}`;
      startDate = dayjs(fullMonthName, "MMMM YYYY")
        ?.startOf("month")
        ?.format("YYYY-MM-DD");
      endDate = dayjs(fullMonthName, "MMMM YYYY")
        ?.endOf("month")
        ?.format("YYYY-MM-DD");
      dispatch(
        getPaymentInvoiceFunc(
          row?.agency_id,
          row?.invoice_id,
          startDate,
          endDate
        )
      );
    }

    setOpenSnackbar(true);
    setSnackbarData({
      textContent: `Downloading Invoice Breakdown`,
      horizontal: "center",
      vertical: "top",
      severity: "success",
      color: "info",
    });
  };

  useEffect(() => {
    if (paymentInvoice?.ok) {
      const link = document.createElement("a");
      link.href = paymentInvoice?.report_url;
      link.download = `paymentInvoice`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(resetPaymentInvoiceFunc());
    }
  }, [paymentInvoice]);

  const editPaymentHandler = () => {
    window.open(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL, "_blank");
  };

  const reversedInvoices = [...invoicesArr].reverse();

  const nextCardHandler = () => {
    if (visibleStartIndex > 0) {
      setVisibleStartIndex((prev) => prev - 1);
    }
  };

  const previousCardHandler = () => {
    if (visibleStartIndex + cardsPerView < reversedInvoices?.length) {
      setVisibleStartIndex((prev) => prev + 1);
    }
  };

  return (
    <>
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={closeSnackbarHandler}
          anchorOrigin={{
            vertical: snackbarData.vertical,
            horizontal: snackbarData.horizontal,
          }}
        >
          <Alert
            onClose={closeSnackbarHandler}
            severity={snackbarData.severity}
            color={snackbarData.color}
            sx={{ width: "100%" }}
          >
            {snackbarData.textContent}
          </Alert>
        </Snackbar>
      )}

      <Stack gap="18px" height="100%">
        {/* 1st ROW 3 Cards */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <BillingCard
              title={`${currentMonthName}'s Bill`}
              subtitle={
                loadingBill ? (
                  <Box display="flex" alignItems="center" gap="2px">
                    Active Patients :{" "}
                    <RectangleSkeletons
                      height={14}
                      width="50px"
                      totalRows={1}
                    />
                  </Box>
                ) : (
                  `Active Patients : ${
                    currentMonthBill?.number_of_patients || 0
                  }`
                )
              }
              numValue={
                loadingBill ? (
                  <Box display="flex" alignItems="center" gap="2px">
                    <RectangleSkeletons
                      height={32}
                      width="100px"
                      totalRows={1}
                    />
                  </Box>
                ) : (
                  `$${currentMonthBill?.amount_due?.toFixed(2) || 0}`
                )
              }
              loadingBill={loadingBill}
            />
          </Grid>
          <Grid item xs={4}>
            <BillingCard
              title="Bill Due on"
              subtitle={`For ${currentMonthDueDate}`}
              numValue={nextMonthDueDate}
            />
          </Grid>
          <Grid item xs={4}>
            <BillingCard
              padding="20px 24px"
              border="none"
              bgColor="#F6F6FD"
              // maxWidth="332px"
            >
              <Stack gap="10px">
                <Typography variant="body1" color="#014361" fontWeight={500}>
                  Plan details
                </Typography>
                <Typography variant="h5" color="#014361" fontWeight={600}>
                  $30/Active Pt./month
                </Typography>
                <Stack component="ul" paddingLeft="24px">
                  <Typography component="li" variant="body1" color="#014361">
                    Billed in arrears (Net 30 days).
                  </Typography>
                  <Typography component="li" variant="body1" color="#014361">
                    Invoices sent 7 days before auto-draft.
                  </Typography>
                </Stack>
              </Stack>
            </BillingCard>
          </Grid>
          {/* <Grid item xs={8}>
          <div>xs=8</div>
        </Grid> */}
        </Grid>
        {/* 2nd Row BILLING DETAIL CARD */}
        <Grid
          container
          spacing={2}
          height="100%"
          alignItems="flex-start"
          paddingBottom="20px"
          // sx={{
          //   "@media(min-width:1300px)": {
          //     flexWrap: "nowrap",
          //   },
          // }}
        >
          {/* CARD 1 755px  */}
          <Grid
            item
            lg={8}
            md={12}
            // sx={{ flexShrink: 1, minWidth: "755px" }}
          >
            <Stack
              // minWidth="755px"
              width="100%"
              bgcolor="#fff"
              border="1px solid #EBEBEB"
              height="100%"
              padding="24px"
              borderRadius="8px"
              gap="24px"
              marginBottom="20px"
            >
              <Stack>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  color="secondary"
                  textTransform="uppercase"
                >
                  {" "}
                  Billing Details
                </Typography>
                <Typography variant="body1" color="rgba(0, 0, 0, 0.38)">
                  {" "}
                  Monthly Practice-Wise Billing Details at a Glance
                </Typography>
              </Stack>
              <Stack gap="16px">
                <Box display="flex" alignItems="flex-start" gap="18px">
                  <Stack
                    bgcolor="#F7FBFF"
                    borderRadius="8px"
                    padding="16px"
                    gap="10px"
                    width="256px"
                    sx={{ cursor: "pointer" }}
                    boxShadow={
                      selectedItemIndex === null && "inset 0 0 1px 2px #0C80F3"
                    }
                    onClick={() => {
                      setInvoiceRows(billBreakdown?.agencies);
                      setSelectedItemIndex(null);
                    }}
                  >
                    <Box>
                      <Chip
                        label="Current Month"
                        variant="filled"
                        sx={{
                          // width:""
                          backgroundColor: "rgba(12, 128, 243, 0.08)",
                          color: "#0C80F3",
                          // fontWeight: "bold",
                          borderRadius: "4px",
                          padding: "2px 8px",
                          height: "auto",
                          "& .MuiChip-label": {
                            padding: "2px",
                          },
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h6"
                      fontSize="20px"
                      color="#0C80F3"
                      lineHeight="32px"
                    >
                      {currentMonthDueDate}
                    </Typography>
                    <Divider sx={{ background: "#E1F0FF" }} />
                    <Stack>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="8px"
                      >
                        <Typography variant="caption" color="secondary">
                          Active Practices:
                        </Typography>
                        <Typography variant="caption" color="secondary">
                          {activePractices}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="8px"
                      >
                        <Typography variant="caption" color="secondary">
                          Active Patients:
                        </Typography>
                        <Typography variant="caption" color="secondary">
                          {loadingBill ? (
                            <RectangleSkeletons
                              totalRows={1}
                              width="20px"
                              height={20}
                              component="span"
                            />
                          ) : (
                            currentMonthBill?.number_of_patients || 0
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                    <Divider sx={{ background: "#E1F0FF" }} />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="8px"
                    >
                      <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
                        Total Bill:
                      </Typography>
                      <Typography variant="body2" color="#0D0D0D">
                        {loadingBill ? (
                          <RectangleSkeletons
                            totalRows={1}
                            width="20px"
                            height={20}
                            component="span"
                          />
                        ) : (
                          `$${currentMonthBill?.amount_due?.toFixed(2) || 0}`
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                  {/* OTHER MONTH's CARD */}
                  {reversedInvoices?.length ? (
                    <Stack gap="4px" width="calc(100% - 256px)">
                      <Box
                        display="flex"
                        gap="4px"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Button
                          variant="text"
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontWeight: 400,
                            padding: "0",
                            textTransform: "capitalize",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          onClick={nextCardHandler}
                          disabled={visibleStartIndex === 0}
                        >
                          Next
                        </Button>
                        <Button
                          variant="text"
                          sx={{
                            padding: "0",
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontWeight: 400,
                            textTransform: "capitalize",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          onClick={previousCardHandler}
                          disabled={
                            visibleStartIndex + cardsPerView >=
                            reversedInvoices?.length
                          }
                        >
                          Previous
                        </Button>
                      </Box>
                      <Box overflow="hidden">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            // width: `${
                            //   monthInvoices?.length * (100 / cardsPerView)
                            // }%`,
                            flexWrap: "nowrap",
                            width: "100%",
                            transition: "transform 0.5s ease",
                            transform: `translateX(-${
                              (visibleStartIndex * 100) / cardsPerView
                            }%)`, // Move the cards
                            display: "flex",
                          }}
                        >
                          {reversedInvoices?.map((item, index) => {
                            const { label, bgColor, color, text } =
                              invoiceStatus(item?.invoice?.status);
                            const fullMonthName = `${item?.invoice?.month} ${item?.invoice?.year}`;
                            return (
                              <Grid
                                item
                                md={6}
                                key={index}
                                sx={{
                                  cursor: "pointer",
                                  flexShrink: 0,
                                  width: "100%",
                                }}
                                onClick={() => {
                                  setInvoiceRows(item?.practice_breakdown);
                                  setSelectedItemIndex(index);
                                  setSelectedItem(item);
                                }}
                                // sx={{ flexShrink: 1, minWidth: "755px" }}
                              >
                                <Stack
                                  // boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                                  padding="16px"
                                  borderRadius="8px"
                                  bgcolor="#FAFAFA"
                                  gap="10px"
                                  boxShadow={
                                    selectedItemIndex === index &&
                                    "inset 0 0 1px 2px #0C80F3"
                                  }
                                >
                                  <Box
                                    display="flex"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    gap="8px"
                                  >
                                    <Stack gap="4px">
                                      <Typography
                                        variant="h6"
                                        color="#07417A"
                                        fontWeight={500}
                                        fontSize="1.125rem"
                                      >
                                        {dayjs(
                                          `${item?.invoice?.month} 01, ${item?.invoice?.year}`,
                                          "MMM DD, YYYY"
                                        ).format("MMM")}
                                        , {item?.invoice?.year}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="#07417A"
                                      >
                                        $
                                        {(
                                          item?.invoice?.total_amount / 100
                                        ).toFixed(2)}{" "}
                                      </Typography>
                                    </Stack>
                                    {item?.invoice?.invoice_url && (
                                      <IconButton
                                        sx={{ padding: "6px" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          downloadMonthInvoiceHandler(item);
                                        }}
                                      >
                                        <BillingReportIcon />
                                      </IconButton>
                                    )}
                                  </Box>
                                  <Stack>
                                    <Typography
                                      variant="caption"
                                      color="secondary"
                                    >
                                      {text}:{" "}
                                      {item?.invoice?.status === "paid"
                                        ? dayjs(
                                            item?.invoice?.paid_at ||
                                              item?.invoice?.created_at
                                          )?.format("DD MMM, YYYY")
                                        : item?.invoice?.status === "unpaid"
                                        ? dayjs(
                                            item?.invoice?.last_attempted_at ||
                                              item?.invoice?.created_at
                                          )?.format("DD MMM, YYYY")
                                        : dayjs(fullMonthName, "MMMM YYYY")
                                            .add(1, "month")
                                            .date(25)
                                            .format("DD MMM, YYYY")}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="secondary"
                                    >
                                      Active Patients:{" "}
                                      {item?.invoice?.number_of_patients}
                                    </Typography>
                                  </Stack>
                                  <Box>
                                    <Chip
                                      label={label}
                                      variant="filled"
                                      sx={{
                                        // width:""
                                        backgroundColor: bgColor,
                                        color: color,
                                        // fontWeight: "bold",
                                        borderRadius: "4px",
                                        padding: "2px 8px",
                                        height: "auto",
                                        "& .MuiChip-label": {
                                          padding: "2px",
                                        },
                                      }}
                                    />
                                  </Box>
                                </Stack>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    </Stack>
                  ) : (
                    <></>
                  )}
                </Box>
                {showTable && (
                  <Stack>
                    {loadingBreakdown ? (
                      <CaseTableSkeleton
                        padding="8px 16px"
                        marginTop="0"
                        headers={[
                          "Practice",
                          "Patients",
                          <Stack textAlign="right">
                            <Typography variant="body2">Bill amount</Typography>
                            <Typography
                              variant="body2"
                              fontSize="13px"
                              color="rgba(0, 0, 0, 0.38)"
                            >
                              $30 per patient
                            </Typography>
                          </Stack>,
                          "",
                        ]}
                        numRows={6}
                        cellWidths={[302, 80, 165, 32]}
                      />
                    ) : (
                      <TableContainer
                        sx={{
                          height: "340px",

                          // maxHeight: "100%",
                          borderRadius: "8px",
                          border: "1px solid #EBEBEB",
                        }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns?.map((column) => (
                                <StyledTableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoiceRows?.map((row, index) => {
                              const isLastRow =
                                index === invoiceRows?.length - 1;
                              return (
                                <TableRow tabIndex={-1} key={index}>
                                  <StyledTableCell
                                    sx={{
                                      color: "#566875 !important",
                                      borderBottom: isLastRow && "none",
                                    }}
                                  >
                                    <Stack>
                                      <Typography
                                        variant="body1"
                                        color="rgba(0, 0, 0, 0.87)"
                                      >
                                        {row?.name}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        color="secondary"
                                      >
                                        Practice ID : {row?.agency_id}
                                      </Typography>
                                    </Stack>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    sx={{
                                      color: "#566875 !important",
                                      borderBottom: isLastRow && "none",
                                    }}
                                  >
                                    {row?.number_of_patients}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    sx={{
                                      color: "#566875 !important",
                                      borderBottom: isLastRow && "none",
                                    }}
                                    align="right"
                                  >
                                    ${row?.amount?.toFixed(2)}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    sx={{ borderBottom: isLastRow && "none" }}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        downloadInvoiceHandler(row)
                                      }
                                    >
                                      <DownloadBillIcon />
                                    </IconButton>
                                  </StyledTableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
          {/* ROW 2  CARD 2  */}
          <Grid
            item
            lg={4}
            md={12}
            // paddingBottom="20px"
            // sx={{ flexShrink: 0, maxWidth: "calc(100% - 755px)" }}
          >
            <Stack
              // maxWidth="332px"
              width="100%"
              height="388px"
              bgcolor="#fff"
              border="1px solid #EBEBEB"
              padding="24px"
              borderRadius="8px"
              gap="12px"
            >
              <Typography
                variant="body1"
                fontWeight={500}
                color="secondary"
                textTransform="uppercase"
              >
                {" "}
                Payment Details
              </Typography>
              <Stack padding="8px 0" gap="12px">
                <Box display="flex" alignItems="flex-start">
                  <Typography
                    minWidth="138px"
                    variant="body1"
                    color="secondary"
                    fontWeight={500}
                  >
                    Billing name
                  </Typography>
                  {loadingPaymentDetails ? (
                    <RectangleSkeletons
                      component="span"
                      totalRows={1}
                      height={20}
                      width="100%"
                    />
                  ) : (
                    <Typography
                      minWidth="138px"
                      variant="body2"
                      color="#0D0D0D"
                    >
                      {orgPaymentDetails?.payment_method?.billing_details?.name
                        ? orgPaymentDetails?.payment_method?.billing_details
                            ?.name
                        : "-"}
                    </Typography>
                  )}
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Typography
                    minWidth="138px"
                    variant="body1"
                    color="secondary"
                    fontWeight={500}
                  >
                    Billing address
                  </Typography>
                  {loadingPaymentDetails ? (
                    <RectangleSkeletons
                      component="span"
                      totalRows={1}
                      height={20}
                      width="100%"
                    />
                  ) : orgPaymentDetails?.payment_method?.billing_details
                      ?.address?.line1 ||
                    orgPaymentDetails?.payment_method?.billing_details?.address
                      ?.line2 ||
                    orgPaymentDetails?.payment_method?.billing_details?.address
                      ?.city ||
                    orgPaymentDetails?.payment_method?.billing_details?.address
                      ?.state ||
                    orgPaymentDetails?.payment_method?.billing_details?.address
                      ?.postal_code ? (
                    <Typography
                      minWidth="138px"
                      variant="body2"
                      color="#0D0D0D"
                    >
                      {orgPaymentDetails?.payment_method?.billing_details
                        ?.address?.address?.line1 &&
                        orgPaymentDetails?.payment_method?.billing_details
                          ?.address?.address?.line1}{" "}
                      {orgPaymentDetails?.payment_method?.billing_details
                        ?.address?.address?.line2 &&
                        orgPaymentDetails?.payment_method?.billing_details
                          ?.address?.address?.line2}
                      {orgPaymentDetails?.payment_method?.billing_details
                        ?.address?.city &&
                        orgPaymentDetails?.payment_method?.billing_details
                          ?.address?.city}
                      {orgPaymentDetails?.payment_method?.billing_details
                        ?.address?.state &&
                        orgPaymentDetails?.payment_method?.billing_details
                          ?.address?.city &&
                        ","}{" "}
                      {orgPaymentDetails?.payment_method?.billing_details
                        ?.address?.state &&
                        orgPaymentDetails?.payment_method?.billing_details
                          ?.address?.state}{" "}
                      {orgPaymentDetails?.payment_method?.billing_details
                        ?.address?.postal_code &&
                        orgPaymentDetails?.payment_method?.billing_details
                          ?.address?.postal_code}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="#0D0D0D">
                      -
                    </Typography>
                  )}
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    minWidth="138px"
                    variant="body1"
                    color="secondary"
                    fontWeight={500}
                  >
                    Billing ph. number
                  </Typography>
                  {loadingPaymentDetails ? (
                    <RectangleSkeletons
                      component="span"
                      totalRows={1}
                      height={20}
                      width="100%"
                    />
                  ) : orgPaymentDetails?.payment_method?.billing_details
                      ?.phone ? (
                    <Typography
                      minWidth="138px"
                      variant="body2"
                      color="#0D0D0D"
                    >
                      {
                        orgPaymentDetails?.payment_method?.billing_details
                          ?.phone
                      }
                    </Typography>
                  ) : (
                    <Typography
                      minWidth="138px"
                      variant="body2"
                      color="#0D0D0D"
                    >
                      -
                    </Typography>
                  )}
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    minWidth="138px"
                    variant="body1"
                    color="secondary"
                    fontWeight={500}
                  >
                    Payment Method
                  </Typography>
                  {loadingPaymentDetails ? (
                    <RectangleSkeletons
                      totalRows={1}
                      height={20}
                      width="100%"
                    />
                  ) : orgPaymentDetails?.payment_method?.type ? (
                    <Typography
                      minWidth="138px"
                      variant="body2"
                      color="#0D0D0D"
                    >
                      {paymentText(orgPaymentDetails?.payment_method?.type)}
                    </Typography>
                  ) : (
                    <Typography
                      minWidth="138px"
                      variant="body2"
                      color="#0D0D0D"
                    >
                      -
                    </Typography>
                  )}
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    minWidth="138px"
                    variant="body1"
                    color="secondary"
                    fontWeight={500}
                  >
                    Last Payment
                  </Typography>
                  {loadingPaymentDetails ? (
                    <RectangleSkeletons
                      totalRows={1}
                      height={20}
                      width="100%"
                    />
                  ) : orgPaymentDetails?.subscription?.last_payment_date ? (
                    <Typography
                      minWidth="138px"
                      variant="body2"
                      color="secondary"
                    >
                      {dayjs(
                        orgPaymentDetails?.subscription?.last_payment_date
                      )?.format("D MMM YYYY")}
                    </Typography>
                  ) : (
                    <Typography
                      minWidth="138px"
                      variant="body2"
                      color="secondary"
                    >
                      -
                    </Typography>
                  )}
                </Box>
              </Stack>
              <Box pt="20px">
                <Button
                  sx={{ padding: "4px 5px" }}
                  onClick={editPaymentHandler}
                >
                  Edit Payment details
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default BillingTab;
