import React from "react";
import { Button } from "@mui/material";
import CircularProgress from "../CircularProgress/index";

export default function index({
  id,
  title,
  fontSize = "16px",
  color,
  width,
  height,
  variant,
  onClick,
  loading,
  disabled,
  startIcon,
  borderRadius,
  loadingColor,
  disableLabelPadding,
  className,
  children,
  noBoxShadow,
  textTransform = "uppercase",
  textDecoration,
  size,
  disableRipple = false,
  type = "submit",
}) {
  return (
    <Button
      id={id}
      className={className}
      color={color}
      type={type}
      size={size}
      disableRipple={disableRipple}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      fontSize={fontSize}
      sx={{
        width: width,
        height: height,
        textTransform: textTransform,
        textDecoration: textDecoration && textDecoration,
        borderRadius: borderRadius ? borderRadius : "4px",
        "&:hover": {
          textDecoration: textDecoration,
        },
        // boxShadow:
        //   "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
      }}
    >
      <span
        style={{
          // paddingTop: disableLabelPadding ? "0" : startIcon ? ".2rem" : "0",
          fontSize: `${fontSize}`,
        }}
      >
        {title}{" "}
        {loading && (
          <CircularProgress color={loadingColor || "primary"} size={15} />
        )}
      </span>
      {children}
    </Button>
  );
}
