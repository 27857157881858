import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import RectangleSkeletons from "../RectangleSkeletons";
import { AttentionIcon } from "../../assets/constants";
import { useNavigate } from "react-router-dom";
import { setAssignedTo } from "../../actions/Patients";
import { useDispatch } from "react-redux";

const AttentionCard = ({ title, tab, assignedToId, selectedView }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      bgcolor="#FFF6F6"
      borderRadius="8px"
      padding="12px"
      sx={{ cursor: "pointer" }}
      onClick={() => {
        if (selectedView === "dashboard") {
          dispatch(setAssignedTo([assignedToId]));
        }
        navigate(`/patients?tab=${tab}`);
      }}
    >
      <Box width="40px" height="40px">
        <AttentionIcon />
      </Box>
      <Stack flex="1" gap="4px">
        <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
          {title}
        </Typography>
      </Stack>
    </Box>
  );
};

export default AttentionCard;
