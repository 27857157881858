// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  @page {
    size: A4;
  }
  body {
    print-color-adjust: exact; /* Standard property */
    -webkit-print-color-adjust: exact;
  }
}

.img-preview {
  overflow: hidden;
}

.square_ripple {
  border-radius: 4px !important;
}

.chat_scroll {
  padding: 6px;
}

/* Adjust height for horizontal scrollbars */
/* ::-webkit-scrollbar {
  width: 12px;  
  height: 8px; 
} */

/* Scrollbar track * /* Track color */ /* Optional for rounded corners */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
} */

/* Scrollbar thumb */ /* Thumb color */ /* Optional for rounded corners */ /* Creates padding around thumb */
/* ::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
} */

/* Scrollbar thumb on hover */ /* Darker color when hovering */
/* ::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
} */

/* #custom-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  padding: 8px;
  pointer-events: none;  
  z-index: 10;  
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,QAAQ;EACV;EACA;IACE,yBAAyB,EAAE,sBAAsB;IACjD,iCAAiC;EACnC;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA,4CAA4C;AAC5C;;;GAGG;;AAEH,qCAAqC,EAAE,iCAAiC;AACxE;;;GAGG;;AAEH,oBAAoB,EAAE,gBAAgB,EAAE,iCAAiC,EAAE,iCAAiC;AAC5G;;;;GAIG;;AAEH,6BAA6B,EAAE,+BAA+B;AAC9D;;GAEG;;AAEH;;;;;;;;GAQG","sourcesContent":["@media print {\n  @page {\n    size: A4;\n  }\n  body {\n    print-color-adjust: exact; /* Standard property */\n    -webkit-print-color-adjust: exact;\n  }\n}\n\n.img-preview {\n  overflow: hidden;\n}\n\n.square_ripple {\n  border-radius: 4px !important;\n}\n\n.chat_scroll {\n  padding: 6px;\n}\n\n/* Adjust height for horizontal scrollbars */\n/* ::-webkit-scrollbar {\n  width: 12px;  \n  height: 8px; \n} */\n\n/* Scrollbar track * /* Track color */ /* Optional for rounded corners */\n/* ::-webkit-scrollbar-track {\n  background: #f1f1f1; \n  border-radius: 10px;\n} */\n\n/* Scrollbar thumb */ /* Thumb color */ /* Optional for rounded corners */ /* Creates padding around thumb */\n/* ::-webkit-scrollbar-thumb {\n  background-color: #888;\n  border-radius: 10px;\n  border: 3px solid #f1f1f1;\n} */\n\n/* Scrollbar thumb on hover */ /* Darker color when hovering */\n/* ::-webkit-scrollbar-thumb:hover {\n  background-color: #555; \n} */\n\n/* #custom-tooltip {\n  position: absolute;\n  background: rgba(0, 0, 0, 0.8);\n  color: white;\n  border-radius: 4px;\n  padding: 8px;\n  pointer-events: none;  \n  z-index: 10;  \n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
