// LISTING TABLE STATES
export const SET_LISTING_TAB = "SET_LISTING_TAB";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_ASSIGNED_TO = "SET_ASSIGNED_TO";
export const SET_ENROLLMENT_STATUS = "SET_ENROLLMENT_STATUS";
export const SET_QUERY = "SET_QUERY";
export const SET_CPT_CODES_ITEMS = "SET_CPT_CODES_ITEMS";
export const SET_SORTED_ITEMS = "SET_SORTED_ITEMS";
export const RESET_LISTING_STATES = "RESET_LISTING_STATES";
export const SET_NEXT_PATIENT_OBJECT = "SET_NEXT_PATIENT_OBJECT";

export const GET_ALL_CASES_REQUEST = "GET_ALL_CASES_REQUEST";
export const GET_ALL_CASES_SUCCESS = "GET_ALL_CASES_SUCCESS";
export const GET_ALL_CASES_FAIL = "GET_ALL_CASES_FAIL";
export const RESET_ALL_CASES = "RESET_ALL_CASES";

export const GET_NEXT_PATIENT_REQUEST = "GET_NEXT_PATIENT_REQUEST";
export const GET_NEXT_PATIENT_SUCCESS = "GET_NEXT_PATIENT_SUCCESS";
export const GET_NEXT_PATIENT_FAIL = "GET_NEXT_PATIENT_FAIL";
export const RESET_NEXT_PATIENT = "RESET_NEXT_PATIENT";

export const GET_AGENCY_PATIENTS_REQUEST = "GET_AGENCY_PATIENTS_REQUEST";
export const GET_AGENCY_PATIENTS_SUCCESS = "GET_AGENCY_PATIENTS_SUCCESS";
export const GET_AGENCY_PATIENTS_FAIL = "GET_AGENCY_PATIENTS_FAIL";
export const RESET_GET_AGENCY_PATIENTS = "RESET_GET_AGENCY_PATIENTS";

export const GET_UNPAGINATED_CASES_REQUEST = "GET_UNPAGINATED_CASES_REQUEST";
export const GET_UNPAGINATED_CASES_SUCCESS = "GET_UNPAGINATED_CASES_SUCCESS";
export const GET_UNPAGINATED_CASES_FAIL = "GET_UNPAGINATED_CASES_FAIL";
export const RESET_UNPAGINATED_CASES = "RESET_UNPAGINATED_CASES";

export const GET_A_CASE_BY_CID_REQUEST = "GET_A_CASE_BY_CID_REQUEST";
export const GET_A_CASE_BY_CID_SUCCESS = "GET_A_CASE_BY_CID_SUCCESS";
export const GET_A_CASE_BY_CID_FAIL = "GET_A_CASE_BY_CID_FAIL";
export const RESET_A_CASE_BY_CID = "RESET_A_CASE_BY_CID";

export const ADD_RECORD_CONSENT_REQUEST = "ADD_RECORD_CONSENT_REQUEST";
export const ADD_RECORD_CONSENT_SUCCESS = "ADD_RECORD_CONSENT_SUCCESS";
export const ADD_RECORD_CONSENT_FAIL = "ADD_RECORD_CONSENT_FAIL";
export const RESET_RECORD_CONSENT = "RESET_RECORD_CONSENT";

export const UNENROLL_PATIENT_REQUEST = "UNENROLL_PATIENT_REQUEST";
export const UNENROLL_PATIENT_SUCCESS = "UNENROLL_PATIENT_SUCCESS";
export const UNENROLL_PATIENT_FAIL = "UNENROLL_PATIENT_FAIL";
export const RESET_UNENROLL_PATIENT = "RESET_UNENROLL_PATIENT";

export const GET_TABS_COUNT_REQUEST = "GET_TABS_COUNT_REQUEST";
export const GET_TABS_COUNT_SUCCESS = "GET_TABS_COUNT_SUCCESS";
export const GET_TABS_COUNT_FAIL = "GET_TABS_COUNT_FAIL";
export const RESET_GET_TABS_COUNT = "RESET_GET_TABS_COUNT";

export const GET_CASE_BY_AGENT_REQUEST = "GET_CASE_BY_AGENT_REQUEST";
export const GET_CASE_BY_AGENT_SUCCESS = "GET_CASE_BY_AGENT_SUCCESS";
export const GET_CASE_BY_AGENT_FAIL = "GET_CASE_BY_AGENT_FAIL";

export const GET_CLIENT_PAINLOGS_REQUEST = "GET_CLIENT_PAINLOGS_REQUEST";
export const GET_CLIENT_PAINLOGS_SUCCESS = "GET_CLIENT_PAINLOGS_SUCCESS";
export const GET_CLIENT_PAINLOGS_FAIL = "GET_CLIENT_PAINLOGS_FAIL";

export const GET_CHART_PAINLOGS_REQUEST = "GET_CHART_PAINLOGS_REQUEST";
export const GET_CHART_PAINLOGS_SUCCESS = "GET_CHART_PAINLOGS_SUCCESS";
export const GET_CHART_PAINLOGS_FAIL = "GET_CHART_PAINLOGS_FAIL";

export const ADD_NEW_CASE_REQUEST = "ADD_NEW_CASE_REQUEST";
export const ADD_NEW_CASE_SUCCESS = "ADD_NEW_CASE_SUCCESS";
export const ADD_NEW_CASE_FAIL = "ADD_NEW_CASE_FAIL";

export const UPDATE_CASE_REQUEST = "UPDATE_CASE_REQUEST";
export const UPDATE_CASE_SUCCESS = "UPDATE_CASE_SUCCESS";
export const UPDATE_CASE_FAIL = "UPDATE_CASE_FAIL";
export const UPDATE_CASE_RESET = "UPDATE_CASE_RESET";

export const DELETE_CASE_REQUEST = "DELETE_CASE_REQUEST";
export const DELETE_CASE_SUCCESS = "DELETE_CASE_SUCCESS";
export const DELETE_CASE_FAIL = "DELETE_CASE_FAIL";
export const RESET_DELETE_CASE = "RESET_DELETE_CASE";

export const GET_CASE_CLIENT_DOCUMENTS_REQUEST =
  "GET_CASE_CLIENT_DOCUMENTS_REQUEST";
export const GET_CASE_CLIENT_DOCUMENTS_SUCCESS =
  "GET_CASE_CLIENT_DOCUMENTS_SUCCESS";
export const GET_CASE_CLIENT_DOCUMENTS_FAIL = "GET_CASE_CLIENT_DOCUMENTS_FAIL";
export const RESET_CASE_CLIENTS_DOCUMENTS = "RESET_CASE_CLIENTS_DOCUMENTS";

export const GENERATE_EVIDENCE_REQUEST = "GENERATE_EVIDENCE_REQUEST";
export const GENERATE_EVIDENCE_SUCCESS = "GENERATE_EVIDENCE_SUCCESS";
export const GENERATE_EVIDENCE_FAIL = "GENERATE_EVIDENCE_FAIL";
export const RESET_GENERATE_EVIDENCE = "RESET_GENERATE_EVIDENCE";

// DASHBOARD ANALYTICS
export const GET_DASHBOARD_ANALYTICS_REQUEST =
  "GET_DASHBOARD_ANALYTICS_REQUEST";
export const GET_DASHBOARD_ANALYTICS_SUCCESS =
  "GET_DASHBOARD_ANALYTICS_SUCCESS";
export const GET_DASHBOARD_ANALYTICS_FAIL = "GET_DASHBOARD_ANALYTICS_FAIL";

export const GET_DASHBOARD_ALERTS_REQUEST = "GET_DASHBOARD_ALERTS_REQUEST";
export const GET_DASHBOARD_ALERTS_SUCCESS = "GET_DASHBOARD_ALERTS_SUCCESS";
export const GET_DASHBOARD_ALERTS_FAIL = "GET_DASHBOARD_ALERTS_FAIL";

export const GET_HIGHRISK_CASES_REQUEST = "GET_HIGHRISK_CASES_REQUEST";
export const GET_HIGHRISK_CASES_SUCCESS = "GET_HIGHRISK_CASES_SUCCESS";
export const GET_HIGHRISK_CASES_FAIL = "GET_HIGHRISK_CASES_FAIL";

export const GET_UNREAD_CHAT_MESSAGES_REQUEST =
  "GET_UNREAD_CHAT_MESSAGES_REQUEST";
export const GET_UNREAD_CHAT_MESSAGES_SUCCESS =
  "GET_UNREAD_CHAT_MESSAGES_SUCCESS";
export const GET_UNREAD_CHAT_MESSAGES_FAIL = "GET_UNREAD_CHAT_MESSAGES_FAIL";

export const GET_PIE_CHART_STATS_REQUEST = "GET_PIE_CHART_STATS_REQUEST";
export const GET_PIE_CHART_STATS_SUCCESS = "GET_PIE_CHART_STATS_SUCCESS";
export const GET_PIE_CHART_STATS_FAIL = "GET_PIE_CHART_STATS_FAIL";
export const RESET_PIE_CHART_STATS = "RESET_PIE_CHART_STATS";

export const ADD_QUESTION_TO_CASE_REQUEST = "ADD_QUESTION_TO_CASE_REQUEST";
export const ADD_QUESTION_TO_CASE_SUCCESS = "ADD_QUESTION_TO_CASE_SUCCESS";
export const ADD_QUESTION_TO_CASE_FAIL = "ADD_QUESTION_TO_CASE_FAIL";
export const RESET_ADD_QUESTION_TO_CASE = "RESET_ADD_QUESTION_TO_CASE";

export const ADD_NOTE_TO_CASE_REQUEST = "ADD_NOTE_TO_CASE_REQUEST";
export const ADD_NOTE_TO_CASE_SUCCESS = "ADD_NOTE_TO_CASE_SUCCESS";
export const ADD_NOTE_TO_CASE_FAIL = "ADD_NOTE_TO_CASE_FAIL";
export const RESET_ADD_NOTE_TO_CASE = "RESET_ADD_NOTE_TO_CASE";
