import React, { useEffect, useState } from "react";
// import clsx from "clsx";
import {
  Grid,
  InputAdornment,
  Link,
  IconButton,
  Box,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import Style from "./style";
// import Button from "../../common/Button";
import Textfield from "../../../common/Textfield";
// import { GoogleBtn } from "../../assets/constants";
import HeaderText from "../../../common/HeaderText";
import { Link as LinkTo, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { login, resetRegister } from "../../../actions/Auth";
import AlertMessage from "../../../common/AlertMessage";
import MccLogo from "../../../components/MccLogo";
import { getAgencyDetailFunc } from "../../../actions/Agency";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import CardUI from "../../../common/Card";
import BgBox from "../../../common/BgBox";
import IndependentImage from "../../../assets/images/independent_image.png";
import GroupImage from "../../../assets/images/group_image.png";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

const buttons = [
  {
    title: "Single Practice",
    value: "independent",
    image: IndependentImage,
  },
  { title: "Multi Practice Organization", value: "group", image: GroupImage },
];

export default function AccountType() {
  const tz = "America/New_York";

  const [fieldValues, setFieldValues] = useState({
    email: "",
    password: "",
  });

  const [selectedButton, setSelectedButton] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitAccountTypeHandler = async (e) => {
    e.preventDefault();
    dispatch(resetRegister());

    if (selectedButton === "independent") {
      navigate("/register", { state: { accountType: "individual" } });
    } else {
      navigate("/register-care-management");
    }
  };

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  return (
    <>
      {/* {!loading && !error && (
        <AlertMessage
          message={userInfo?.data?.message}
          severity="success"
          open={open}
          handleClose={handleClose}
        />
      )} */}

      <BgBox>
        <CardUI>
          {/* MCC LOGO */}
          <MccLogo />
          <Grid container component="main">
            <Box
              component="form"
              onSubmit={submitAccountTypeHandler}
              xs={12}
              display="flex"
              gap="40px"
              flexDirection="column"
              width="100%"
            >
              <Stack>
                <HeaderText
                  variant="h3"
                  fontSize={34}
                  textContent="What Describes You Best?"
                  lineHeight="24px"
                  textAlign="center"
                />
                <Typography
                  variant="body1"
                  className="d-flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize={16}
                  mt="16px"
                  color="#566875"
                >
                  Please select your account type
                </Typography>
              </Stack>

              <Box display="flex" flexDirection="column" gap="16px">
                {buttons?.map((btn, index) => (
                  <Button
                    key={index}
                    fullWidth
                    size="large"
                    onClick={() => handleButtonClick(btn.value)}
                    startIcon={
                      <img
                        src={btn.image}
                        alt={`${btn.title} icon`}
                        style={{ width: 32, height: 32 }}
                      />
                    }
                    variant="outlined"
                    style={{
                      height: "55px",
                      border: "none",
                      textTransform: "capitalize",
                      color: "rgba(0, 0, 0, 0.87)",
                      fontWeight: "400",

                      boxShadow:
                        selectedButton === btn.value
                          ? `inset 0 0 0 2px #0C80F3`
                          : "inset 0 0 0 1px rgba(192, 192, 192, 0.88)",
                    }}
                  >
                    {btn?.title}
                  </Button>
                ))}

                <LoadingButton
                  id="account-type"
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={!selectedButton}
                  sx={{ padding: "8px 22px", fontSize: 15 }}
                >
                  Continue{" "}
                </LoadingButton>
              </Box>

              <Typography textAlign="center">
                Already have an account?
                <Link
                  component={LinkTo}
                  to="/signin"
                  textAlign="center"
                  underline="none"
                >
                  {" "}
                  Sign in
                </Link>
              </Typography>
            </Box>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
}
