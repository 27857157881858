import React, { useEffect } from "react";
import { isAndroid, isIOS, isMacOs, isWindows } from "react-device-detect";

const DownloadVitatraxApp = () => {
  useEffect(() => {
    const storeLink =
      isAndroid || isWindows
        ? "https://play.google.com/store/apps/details?id=com.vitatrax"
        : "https://apps.apple.com/us/app/vitatrax/id6587574641";

    window.location.href = storeLink;
  }, []);

  return <></>;
};

export default DownloadVitatraxApp;
