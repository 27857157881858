import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const CaseHeaderSkeleton = () => {
  return (
    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
      <Stack gap="8px" width={267}>
        <Skeleton variant="rounded" height={37} />
        <Skeleton variant="rounded" height={24} />
      </Stack>
      <Box display="flex" alignItems="center" gap="16px" width={341}>
        <Skeleton variant="rounded" width="75%" height={24} />
        <Skeleton variant="rounded" width="25%" height={24} />
      </Box>
    </Box>
  );
};

export default CaseHeaderSkeleton;
