export const GET_AGENT_PROFILE_REQUEST = "GET_AGENT_PROFILE_REQUEST";
export const GET_AGENT_PROFILE_SUCCESS = "GET_AGENT_PROFILE_SUCCESS";
export const GET_AGENT_PROFILE_FAIL = "GET_AGENT_PROFILE_FAIL";

export const INVITE_AGENT_REQUEST = "INVITE_AGENT_REQUEST";
export const INVITE_AGENT_SUCCESS = "INVITE_AGENT_SUCCESS";
export const INVITE_AGENT_FAIL = "INVITE_AGENT_FAIL";
export const RESET_INVITE_AGENT = "RESET_INVITE_AGENT";

export const UPDATE_AGENT_PROFILE_REQUEST = "UPDATE_AGENT_PROFILE_REQUEST";
export const UPDATE_AGENT_PROFILE_SUCCESS = "UPDATE_AGENT_PROFILE_SUCCESS";
export const UPDATE_AGENT_PROFILE_FAIL = "UPDATE_AGENT_PROFILE_FAIL";

export const UPDATE_AGENT_TEAM_MEMBERS_REQUEST =
  "UPDATE_AGENT_TEAM_MEMBERS_REQUEST";
export const UPDATE_AGENT_TEAM_MEMBERS_SUCCESS =
  "UPDATE_AGENT_TEAM_MEMBERS_SUCCESS";
export const UPDATE_AGENT_TEAM_MEMBERS_FAIL = "UPDATE_AGENT_TEAM_MEMBERS_FAIL";
export const RESET_UPDATE_AGENT_TEAM_MEMBERS =
  "RESET_UPDATE_AGENT_TEAM_MEMBERS";

export const GET_AGENT_LISTS_REQUEST = "GET_AGENT_LISTS_REQUEST";
export const GET_AGENT_LISTS_FAIL = "GET_AGENT_LISTS_FAIL";
export const GET_AGENT_LISTS_SUCCESS = "GET_AGENT_LISTS_SUCCESS";
export const RESET_AGENT_LISTS = "RESET_AGENT_LISTS";

export const CHECK_PATIENT_EMAIL_REQUEST = "CHECK_PATIENT_EMAIL_REQUEST";
export const CHECK_PATIENT_EMAIL_SUCCESS = "CHECK_PATIENT_EMAIL_SUCCESS";
export const CHECK_PATIENT_EMAIL_FAIL = "CHECK_PATIENT_EMAIL_FAIL";
export const RESET_CHECK_PATIENT_EMAIL = "RESET_CHECK_PATIENT_EMAIL";

export const CHECK_PATIENT_PHONE_REQUEST = "CHECK_PATIENT_PHONE_REQUEST";
export const CHECK_PATIENT_PHONE_SUCCESS = "CHECK_PATIENT_PHONE_SUCCESS";
export const CHECK_PATIENT_PHONE_FAIL = "CHECK_PATIENT_PHONE_FAIL";
export const RESET_CHECK_PATIENT_PHONE = "RESET_CHECK_PATIENT_PHONE";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";

export const ADD_NEW_CLIENT_REQUEST = "ADD_NEW_CLIENT_REQUEST";
export const ADD_NEW_CLIENT_SUCCESS = "ADD_NEW_CLIENT_SUCCESS";
export const ADD_NEW_CLIENT_FAIL = "ADD_NEW_CLIENT_FAIL";

export const GET_CLIENT_DATA_REQUEST = "GET_CLIENT_DATA_REQUEST";
export const GET_CLIENT_DATA_SUCCESS = "GET_CLIENT_DATA_SUCCESS";
export const GET_CLIENT_DATA_FAIL = "GET_CLIENT_DATA_FAIL";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";
export const RESET_DELETE_CLIENT = "RESET_DELETE_CLIENT";
