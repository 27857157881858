export const IMPORT_PATIENT_FILE_REQUEST = "IMPORT_PATIENT_FILE_REQUEST";
export const IMPORT_PATIENT_FILE_SUCCESS = "IMPORT_PATIENT_FILE_SUCCESS";
export const IMPORT_PATIENT_FILE_FAIL = "IMPORT_PATIENT_FILE_FAIL";
export const RESET_IMPORT_PATIENT_FILE = "RESET_IMPORT_PATIENT_FILE";

export const SET_IMPORT_ID = "SET_IMPORT_ID";

export const GET_IMPORT_PATIENTS_REQUEST = "GET_IMPORT_PATIENTS_REQUEST";
export const GET_IMPORT_PATIENTS_SUCCESS = "GET_IMPORT_PATIENTS_SUCCESS";
export const GET_IMPORT_PATIENTS_FAIL = "GET_IMPORT_PATIENTS_FAIL";
export const RESET_GET_IMPORT_PATIENTS = "RESET_GET_IMPORT_PATIENTS";

export const IMPORT_STATUS_REQUEST = "IMPORT_STATUS_REQUEST";
export const IMPORT_STATUS_SUCCESS = "IMPORT_STATUS_SUCCESS";
export const IMPORT_STATUS_FAIL = "IMPORT_STATUS_FAIL";
export const RESET_IMPORT_STATUS = "RESET_IMPORT_STATUS";

export const ASSIGN_BULK_PATIENTS_REQUEST = "ASSIGN_BULK_PATIENTS_REQUEST";
export const ASSIGN_BULK_PATIENTS_SUCCESS = "ASSIGN_BULK_PATIENTS_SUCCESS";
export const ASSIGN_BULK_PATIENTS_FAIL = "ASSIGN_BULK_PATIENTS_FAIL";
export const RESET_ASSIGN_BULK_PATIENTS = "RESET_ASSIGN_BULK_PATIENTS";

export const EXPORT_BULK_EVIDENCE_REQUEST = "EXPORT_BULK_EVIDENCE_REQUEST";
export const EXPORT_BULK_EVIDENCE_SUCCESS = "EXPORT_BULK_EVIDENCE_SUCCESS";
export const EXPORT_BULK_EVIDENCE_FAIL = "EXPORT_BULK_EVIDENCE_FAIL";
export const RESET_EXPORT_BULK_EVIDENCE = "RESET_EXPORT_BULK_EVIDENCE";
