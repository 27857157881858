import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HelpIcon, LogoutIcon, UserTagIcon } from "../../assets/constants";
import { useLocation, useNavigate } from "react-router-dom";

import HeaderText from "../../common/HeaderText";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/Auth";
import {
  getAgentProfile,
  resetUpdateAgentProfile,
  getAgencyDetailFunc,
  resetAgentsList,
  resetAgentInvite,
  resetUnpaginatedAgentsList,
} from "../../actions/Agency";
import { segmentTrack } from "../../utils/helpers/tracking_utils";
import Cookies from "js-cookie";
// import NotificationDrawer from "../NotificationDrawer";
import {
  allNotificationsFunc,
  openTimeModal,
  resetAlertNotifications,
  resetAllBodyParts,
  resetAllNotifications,
  resetChatNotifications,
  resetTimerItemsFunc,
  resetUserActivityNotifications,
  resetUserFeedNotifications,
  setEndTime,
  setGoBackFunc,
  setIsStopped,
  setLogoutClickedFunc,
  setNavigateItemFunc,
  setShowReviewedScreenFunc,
} from "../../actions/AppConfig";
import {
  resetAllPatients,
  resetUnpaginatedPatients,
} from "../../actions/Patients";
import { resetGetBillingSubscription } from "../../actions/BillingSubscription";
import styled from "@emotion/styled";
import { resetStepCounts } from "../../actions/StepsCountActions";
import dayjs from "dayjs";
import {
  resetCreateTimeEntry,
  resetGetTimeEntry,
} from "../../actions/TimeEntry";
import { stringAvatar } from "../../utils/helpers";
import OrganizationImage from "../../assets/images/organizationImage.png";

const page_size = 10;

const StyledAppBar = styled(AppBar)(({ theme, padding }) => ({
  background: "#fcfcfc !important",
  position: "fixed",
  top: "0",
  padding: padding || "1rem",
  left: "auto",
  display: "flex",
  justifyContent: "space-between",
  zIndex: "100",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    left: "0",
  },
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  padding: "16px !important",
}));

const NavbarUI = ({ drawerWidth }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location?.pathname;

  const timerModal = useSelector((state) => state.timerModal);
  const { isRunning, isPaused } = timerModal;

  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  // const {userInfo} = useSelector((state) => state.userLogin);
  const agentProfile = useSelector((state) => state.agentProfile);
  const { agentUser } = agentProfile;

  const getAllNotifications = useSelector((state) => state.getAllNotifications);
  const {
    loading: loadingAllNotifications,

    allNotifications,
  } = getAllNotifications;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const { agencyDetail } = getAgencyDetail;

  // const userInfo = localStorage.getItem('userInfo')

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openStopTimeModalHandler = () => {
    dispatch(setIsStopped(false));
    dispatch(setGoBackFunc(false));
    // if (!isPaused) {
    //   dispatch(setIsRunning(false));
    //   dispatch(setPauseStartTime(Date.now()));
    //   dispatch(setPausedTime(0));
    // }
    dispatch(setEndTime(dayjs()));
    dispatch(openTimeModal());
    // setSelectValue("chat_communication");
  };

  const logoutHandler = () => {
    dispatch(setLogoutClickedFunc(true));

    if (!isRunning && !isPaused) {
      setAnchorEl(null);
      dispatch(logout());
      dispatch(resetCreateTimeEntry());
      dispatch(resetUserFeedNotifications());
      dispatch(resetAllNotifications());
      dispatch(resetAllPatients());
      dispatch(resetAllBodyParts());
      dispatch(resetUnpaginatedPatients());
      dispatch(resetAgentsList());
      dispatch(resetUnpaginatedAgentsList());
      dispatch(resetGetBillingSubscription());
      dispatch(resetStepCounts());
      dispatch(resetTimerItemsFunc());
      dispatch(resetGetTimeEntry());
      dispatch(resetAgentInvite());
      dispatch(setShowReviewedScreenFunc(false));
      navigate("/");
    } else {
      openStopTimeModalHandler();
    }
  };

  const navigateProfileHandler = () => {
    dispatch(resetUpdateAgentProfile());
    if (!isRunning && !isPaused) {
      setAnchorEl(null);
      navigate("/profile");
    } else {
      dispatch(setNavigateItemFunc("/profile"));
      openStopTimeModalHandler();
    }
  };

  // const navigateBillingHandler = () => {
  //   setAnchorEl(null);
  //   navigate("/settings?tab=billing");
  // };

  useEffect(() => {
    dispatch(getAgentProfile());
    dispatch(getAgencyDetailFunc());
    // dispatch(allNotificationsFunc(page, page_size));
  }, []);

  useEffect(() => {
    if (!loadingAllNotifications && allNotifications?.ok) {
      setTotalNotifications(allNotifications?.number_of_unread_notifications);
    }
  }, [allNotifications]);

  // const [user, setUser] = useState({});

  // const getUser = async (userInfo) => {
  //   const config = {
  //     headers: {
  //       "Content-type": "application/json",
  //       Authorization: `Bearer ${userInfo.token}`,
  //     },
  //   };
  //   const { data } = await axios.get(
  //     `https://staging-api.mycasecare.com/agent/get?agent_id=${userInfo.agent_id}`,
  //     config
  //   );

  //   // console.log("DATA NAVBAR", data);

  //   setUser(data?.agent);
  // };

  // useEffect(() => {
  //   // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   // getUser(userInfo);
  //   // const cookieAgentId = Cookies.get("agentId");

  //   if (agentToken) {
  //     dispatch(getAgentProfile());
  //   }
  // }, []);

  const helpLinkHandler = () => {
    window.open(
      "https://forms.office.com/r/jy45eSaqAg",
      "_blank"
    );
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "help",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // const openNotificationsHandler = () => {
  //   setOpenNotificationDrawer((prevState) => !prevState);
  //   dispatch(resetAllNotifications());
  //   dispatch(resetChatNotifications());
  //   dispatch(resetAlertNotifications());
  //   dispatch(resetUserActivityNotifications());
  // };

  return (
    <>
      {/* <NotificationDrawer
        anchor="right"
        openNotificationDrawer={openNotificationDrawer}
        setOpenNotificationDrawer={setOpenNotificationDrawer}
        setTotalNotifications={setTotalNotifications}
        totalNotifications={totalNotifications}
        onClose={() => setOpenNotificationDrawer(false)}
      /> */}

      <StyledAppBar
        padding={pathname === "/organization-portal" ? "16px 40px" : "1rem"}
        sx={{
          width: `calc(100% - ${drawerWidth})`,
        }}
        elevation={0}
      >
        {pathname === "/organization-portal" ? (
          <Box padding="8px 16px" display="flex" alignItems="center" gap="8px">
            <Box width="24px" height="24px">
              <img
                width="100%"
                src={OrganizationImage}
                alt="organization portal"
              />
            </Box>
            <Typography color="rgba(0, 0, 0, 0.87)" variant="body1">
              Organization Portal
            </Typography>
          </Box>
        ) : (
          <Stack
            pr={3}
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Tooltip title="Help">
              <IconButton size="small" onClick={helpLinkHandler}>
                <HelpIcon />
              </IconButton>
            </Tooltip>

            {/* <Tooltip title="Notifications">
            <Badge
              color="primary"
              badgeContent={totalNotifications}
              sx={{
                "& .MuiBadge-badge": {
                  right: "4px",
                  top: 0,
                },
              }}
            >
              <IconButton size="small" onClick={openNotificationsHandler}>
                <NotificationIcon />
              </IconButton>
            </Badge>
          </Tooltip> */}

            <>
              <Stack sx={{ cursor: "pointer" }}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  disableRipple
                >
                  {/* <ProfilePicture
                  src={userProfile}
                  alt="user profile"
                  width="40px"
                  height="40px"
                /> */}
                  {agentUser?.agent?.profile_picture_url ? (
                    <Avatar
                      alt="Profile Picture"
                      src={agentUser?.agent?.profile_picture_url}
                    />
                  ) : (
                    <Avatar
                      {...stringAvatar(agentUser?.agent?.name)}
                      sx={{ background: agentUser?.agent?.avatar_color }}
                    />
                  )}
                </IconButton>
              </Stack>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleClose}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    padding="10px 0"
                  >
                    {/* <ProfilePicture
                    src={userProfile}
                    alt="user profile"
                    width="69px"
                    height="69px"
                  /> */}
                    {agentUser?.agent?.profile_picture_url ? (
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        alt="Profile Picture"
                        src={agentUser?.agent?.profile_picture_url}
                      />
                    ) : (
                      <Avatar
                        {...stringAvatar(agentUser?.agent?.name)}
                        sx={{
                          background: agentUser?.agent?.avatar_color,
                          width: 40,
                          height: 40,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    <Box display="flex" flexDirection="column" gap="1px">
                      <HeaderText
                        variant="h3"
                        fontSize="16px"
                        textContent={agentUser?.agent?.name}
                        textAlign="inherit"
                        color="#000"
                      />
                      <HeaderText
                        variant="h6"
                        fontSize="12px"
                        fontWeight="400"
                        textContent={agentUser?.agent?.email}
                        textAlign="inherit"
                      />
                    </Box>
                  </Box>
                </MenuItem>

                <Divider />
                {/* <StyledMenuItem onClick={navigateBillingHandler}>
                <ListItemIcon sx={{ marginRight: "10px" }}>
                  <BillingIcon fontSize="small" />
                </ListItemIcon>
                Billing{" "}
              </StyledMenuItem> */}
                <StyledMenuItem onClick={navigateProfileHandler}>
                  <ListItemIcon sx={{ marginRight: "10px" }}>
                    <UserTagIcon fontSize="small" />
                  </ListItemIcon>
                  User Account
                </StyledMenuItem>
                <StyledMenuItem onClick={logoutHandler}>
                  <ListItemIcon sx={{ marginRight: "10px" }}>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </StyledMenuItem>
              </Menu>
            </>
          </Stack>
        )}
      </StyledAppBar>
    </>
  );
};

export default NavbarUI;
