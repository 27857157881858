import Cookies from "js-cookie";
import {
  GET_MEDICINE_FAIL,
  GET_MEDICINE_REQUEST,
  GET_MEDICINE_SUCCESS,
  GET_STEPSCOUNT_FAIL,
  GET_STEPSCOUNT_REQUEST,
  GET_STEPSCOUNT_SUCCESS,
  RESET_MEDICINE,
  RESET_STEPSCOUNT,
} from "../../assets/constants/stepsCountConstants";
import axios from "axios";
import { BASE_URL } from "..";

// ================== GET STEPS COUNT ACTIONS  =========================
export const getStepsCountFunc =
  (patientId, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: GET_STEPSCOUNT_REQUEST });

      const token = Cookies.get("token");
      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/step-count/get?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_STEPSCOUNT_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_STEPSCOUNT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetStepCounts = () => async (dispatch) => {
  dispatch({ type: RESET_STEPSCOUNT });
};

// ================== GET MEDICINES STATS ACTIONS  =========================
export const getMedicinesStatsFunc =
  (patientId, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: GET_MEDICINE_REQUEST });

      const token = Cookies.get("token");
      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/medicine-entry/get-medicine-adherence-counts?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_MEDICINE_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_MEDICINE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetMedicinesStats = () => async (dispatch) => {
  dispatch({ type: RESET_MEDICINE });
};
