import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import AvatarWithName from "../AvatarWithName";
import { timeFormat } from "../../utils/helpers";

const PatientTimeCard = ({
  profile_url,
  avatarColor,
  name,
  lastReadingTime,
}) => {
  return (
    <React.Fragment>
      <Box
        display="flex"
        padding="8px 0"
        alignItems="center"
        justifyContent="space-between"
      >
        <AvatarWithName
          name={name}
          size={24}
          avatarFontSize={14}
          imageURL={profile_url}
          avatarBgColor={avatarColor}
        />
        <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
          {timeFormat(lastReadingTime)}
        </Typography>
      </Box>
      <Divider sx={{ borderColor: "rgba(160, 160, 160, 0.12)" }} />
    </React.Fragment>
  );
};

export default PatientTimeCard;
