import {
  CANCEL_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CREATE_BILLING_SUBSCRIPTION_FAIL,
  CREATE_BILLING_SUBSCRIPTION_REQUEST,
  CREATE_BILLING_SUBSCRIPTION_SUCCESS,
  GET_BILLING_SUBSCRIPTION_FAIL,
  GET_BILLING_SUBSCRIPTION_REQUEST,
  GET_BILLING_SUBSCRIPTION_SUCCESS,
  RESET_BILLING_SUBSCRIPTION,
  RESET_CANCEL_SUBSCRIPTION,
  RESET_CREATE_BILLING_SUBSCRIPTION,
  RESET_UPDATE_BILLING_SUBSCRIPTION,
  UPDATE_BILLING_SUBSCRIPTION_FAIL,
  UPDATE_BILLING_SUBSCRIPTION_REQUEST,
  UPDATE_BILLING_SUBSCRIPTION_SUCCESS,
} from "../../assets/constants/billingSubscription";

// GET BILLING SUBSCRIPTION REDUCER
export const getBillingSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BILLING_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case GET_BILLING_SUBSCRIPTION_SUCCESS:
      return { loading: false, billingSubscription: action.payload };
    case GET_BILLING_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };
    case RESET_BILLING_SUBSCRIPTION:
      return {};
    default:
      return state;
  }
};

// CREATE BILLING SUBSCRIPTION REDUCER
export const createBillingSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BILLING_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case CREATE_BILLING_SUBSCRIPTION_SUCCESS:
      return { loading: false, createdBillingSubscription: action.payload };
    case CREATE_BILLING_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CREATE_BILLING_SUBSCRIPTION:
      return {};
    default:
      return state;
  }
};

// UPDATE BILLING SUBSCRIPTION REDUCER
export const updateBillingSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BILLING_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case UPDATE_BILLING_SUBSCRIPTION_SUCCESS:
      return { loading: false, updatedBillingSubscription: action.payload };
    case UPDATE_BILLING_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UPDATE_BILLING_SUBSCRIPTION:
      return {};
    default:
      return state;
  }
};

// CANCEL BILLING SUBSCRIPTION REDUCER
export const cancelBillingSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case CANCEL_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return { loading: false, cancelledSubscription: action.payload };
    case CANCEL_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CANCEL_SUBSCRIPTION:
      return {};
    default:
      return state;
  }
};
