import React from "react";

const PendingEnrollVector = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="32"
      viewBox="0 0 67 32"
      fill="none"
    >
      <path
        d="M2 31L3.3125 28.0157C4.625 25.0313 7.25 19.0626 9.875 19.7868C12.5 20.5109 15.125 27.9279 17.75 25.7318C20.375 23.5357 23 11.7266 25.625 9.22364C28.25 6.72067 30.875 13.5239 33.5 18.0406C36.125 22.5574 38.75 24.7878 41.375 25.0633C44 25.3388 46.625 23.6594 49.25 23.1079C51.875 22.5565 54.5 23.133 57.125 19.6363C59.75 16.1397 62.375 8.56984 63.6875 4.78492L65 1"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
};

export default PendingEnrollVector;
