import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import AvatarWithName from "../AvatarWithName";

const PinnedNoteCard = ({}) => {
  return (
    <Stack
      width="100%"
      bgcolor="rgba(12, 128, 243, 0.04)"
      border="1px solid #0C80F3"
      padding="16px"
      borderRadius="8px"
      gap="16px"
    >
      <Box display="flex" alignItems="center" gap="7px">
        <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
          11:00am, 27 July
        </Typography>
        <AvatarWithName
          name="Arlene McCoy"
          size={16}
          avatarFontSize="8px"
          columnGap="4px"
        />
      </Box>
      <Typography variant="body1" color="secondary">
        Requested a call back on February 5, 2025, at 3:00 PM for further
        clarification.
      </Typography>
      <Box display="flex" alignSelf="flex-end" gap="7px">
        <Button variant="text" color="primary" sx={{ fontSize: "13px" }}>
          Unmark as pinned & Close
        </Button>
        <Button variant="outlined" color="primary" sx={{ fontSize: "13px" }}>
          Dismiss
        </Button>
      </Box>
    </Stack>
  );
};

export default PinnedNoteCard;
