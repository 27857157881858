import React from "react";
import CommonDrawer from "../../common/CommonDrawer";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Cpt98975Content from "../Cpt98975Content";
import Cpt98980Content from "../Cpt98980Content";
import CptDrawerContent from "../CptDrawerContent";
import AddTimeDrawerContent from "../AddTimeDrawerContent";

const CptDrawer = ({
  openDrawer,
  onClose,
  anchor,
  setOpenDrawer,
  cptCodeDetail,
  patientDetail,
  drawerType,
  timeDetails,
  onSubmitEditTime,
  onSubmitAddTimeEntry,
  selectValue,
  allCptCodesArr,
  fromDashboard = false,
  reviewedPatients,
}) => {
  // const updateCptCode = useSelector((state) => state.updateCptCode);
  // const { loading: loadingUpdatedCptCode, error } = updateCptCode;

  return (
    <CommonDrawer
      open={openDrawer}
      onClose={onClose}
      anchor={anchor}
      setOpen={setOpenDrawer}
      height="100%"
    >
      {drawerType === "cpt_drawer" ? (
        <CptDrawerContent
          cptCodeDetail={cptCodeDetail}
          patientDetail={patientDetail}
          onClose={onClose}
          selectValue={selectValue}
          allCptCodesArr={allCptCodesArr}
        />
      ) : (
        <AddTimeDrawerContent
          onClose={onClose}
          reviewedPatients={reviewedPatients}
          fromDashboard={fromDashboard}
          timeDetails={timeDetails}
          patientDetail={patientDetail}
          onSubmitEditTime={onSubmitEditTime}
          onSubmitAddTimeEntry={onSubmitAddTimeEntry}
        />
      )}
    </CommonDrawer>
  );
};

export default CptDrawer;
