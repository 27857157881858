import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import bellIcon from "../../assets/images/bell-img2.png";
import DashboardCard from "../DashboardCard";
import FolderImg from "../../assets/images/folder-img.png";
import PaperImg from "../../assets/images/paper-img.png";
import NetworkImg from "../../assets/images/network-img.png";
import {
  AddNoteIcon,
  AddTimeIcon,
  InfoCircle,
  RiskCaseEmptyState,
  SendMessageIcon,
} from "../../assets/constants";
import NewMessageCard from "../NewMessageCard";
import RiskCasesCard from "../RiskCasesCard";
import EmptyBox from "../../assets/images/empty-box.png";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAlertsFunc,
  getDashboardAnalyticsFunc,
  getUnreadChatMessagesFunc,
} from "../../actions/Patients";
import { getAgencyDetailFunc } from "../../actions/Agency";
import CircularProgressLoading from "../../common/CircularProgress";
import AlertMessage from "../../common/AlertMessage";
import {
  calculateBulkTimeMinutes,
  calculateElapsedTime,
  dateTimeFormat,
  humanReadingNames,
  humanReadingTime,
  stringAvatar,
  timeDateFormat,
} from "../../utils/helpers";
import { segmentTrack } from "../../utils/helpers/tracking_utils";
import UserFeedCard from "../UserFeedCard";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  actionsPerformedFunc,
  addBulkNotesFunc,
  getCurrentPatientIndexFunc,
  getReviewedPatientsFunc,
  getUnreviewedPatientsFunc,
  getWebNotificationsFunc,
  markPatientReviewedFunc,
  resetAddBulkMessagesFunc,
  resetAddBulkNotesFunc,
  resetMarkPatientReviewedFunc,
  sendBulkMessagesFunc,
  setShowReviewedScreenFunc,
  userFeedNotificationsFunc,
} from "../../actions/AppConfig";
import Cookies from "js-cookie";
import SkeletonGrid from "../SkeletonGrid";
import RectangleSkeletons from "../RectangleSkeletons";
import dashboardImage from "../../assets/images/dashboard-img.png";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import PatientVector from "../../icons/PatientVector";
import PendingEnrollVector from "../../icons/PendingEnrollVector";
import InactivePatientVector from "../../icons/InactivePatientVector";
import AttentionCard from "../AttentionCard";
import CaughtUpImage from "../../assets/images/caughtupImage.png";
import styled from "@emotion/styled";
import PatientTimeCard from "../PatientTimeCard";
import ReadingDetailPainCard from "../ReadingDetailPainCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import caughtupImage from "../../assets/images/reading_caughtup.png";
import CptDrawer from "../CptDrawer";
import CommonDrawer from "../../common/CommonDrawer";
import SendMessageDrawer from "../SendMessageDrawer";
import AlertDialog from "../AlertDialog";
import AddBulkNotesModalContent from "../AddBulkNotesModalContent";
import { LoadingButton } from "@mui/lab";
import LikeImage from "../../assets/images/likeImage.png";
import { createBulkTimeFunc, resetBulkTimeFunc } from "../../actions/TimeEntry";
import dayjs from "dayjs";

const page_size = 10;

const StyledButton = styled(Button)(
  ({ background, width = "156px", border = "1px solid #fff" }) => ({
    width: width,
    padding: "6px 8px",
    textTransform: "capitalize",
    background: background || "none",
    borderRadius: "8px",
    border: border,
    color: "#fff",
    "&:hover": {
      boxShadow: border === "none" && "inset 0 0 1px #fff",
      background:
        border !== "none" &&
        "linear-gradient(180deg, #0C80F3 0%, #5865E1 100%)",
    },
  })
);

const DashboardComponent = () => {
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [selectedView, setSelectedView] = useState("dashboard");
  const [userFeedNotifications, setUserFeedNotifications] = useState([]);
  const [renderUserFeed, setRenderUserFeed] = useState(true);
  const [openTimeDrawer, setOpenTimeDrawer] = useState(false);
  const [openMessageDrawer, setOpenMessageDrawer] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [checkedTimeNote, setCheckedTimeNote] = useState(true);
  const [bulkNoteValue, setBulkNoteValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  // const [unReviewedPatientsList, setUnReviewedPatientsList] = useState([]);
  // const [reviewedPatientsList, setReviewedPatientsList] = useState([]);
  const [allPatientsList, setAllPatientsList] = useState([]);
  const [openReviewedConfirmModal, setOpenReviewedConfirmModal] =
    useState(false);
  const [modalContentData, setModalContentData] = useState(null);
  const [patientIds, setPatientIds] = useState([]);
  const [allReviewed, setAllReviewed] = useState(false);
  const [minuteValue, setMinuteValue] = useState("1_minute");
  const [finishReviewingClicked, setFinishReviewingClicked] = useState(false);
  const [allReviewedClicked, setAllReviewedClicked] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [successTextMessage, setSuccessTextMessage] = useState(null);
  const [bulkNoteButtonClicked, setBulkNoteButtonClicked] = useState(false);
  const [savedSeconds, setSavedSeconds] = useState(0);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenSuccessMessage(false);
  };

  const dispatch = useDispatch();

  const changedAgencyId = useSelector((state) => state.changedAgencyId);
  const { changed } = changedAgencyId;

  const getCurrentPatientIndex = useSelector(
    (state) => state.getCurrentPatientIndex
  );
  const { currentPatientIndex: currentIndex } = getCurrentPatientIndex;

  const isActionPerformedDone = useSelector(
    (state) => state.isActionPerformedDone
  );
  const { isActionPerformed: actionsPerformed } = isActionPerformedDone;

  const getShowReviewedScreen = useSelector(
    (state) => state.getShowReviewedScreen
  );
  const { showReviewedScreen } = getShowReviewedScreen;

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const agencyId = Cookies.get("agency_id_vita");

  const dashboardAnalytics = useSelector((state) => state.dashboardAnalytics);
  const { loading, error, dashboardAnalytic } = dashboardAnalytics;

  const markPatientReviewed = useSelector((state) => state.markPatientReviewed);
  const {
    loading: loadingMarkPatientReviewed,
    error: errorMarkPatientReviewed,
    markAsReviewed,
  } = markPatientReviewed;

  const getUnreviewedPatients = useSelector(
    (state) => state.getUnreviewedPatients
  );
  const {
    loading: loadingUnreviewedPatients,
    error: errorUnreviewedPatients,
    unreviewedPatients,
  } = getUnreviewedPatients;

  const getReviewedPatients = useSelector((state) => state.getReviewedPatients);
  const {
    loading: loadingReviewedPatients,
    error: errorReviewedPatients,
    reviewedPatients,
  } = getReviewedPatients;

  const createBulkTime = useSelector((state) => state.createBulkTime);
  const { loading: loadingBulkTime, bulkedTime } = createBulkTime;

  const addBulkNotes = useSelector((state) => state.addBulkNotes);
  const { loading: loadingBulkNotes, bulkedNotes } = addBulkNotes;

  const sendBulkMessages = useSelector((state) => state.sendBulkMessages);
  const { loading: loadingBulkMessages, bulkedMessages } = sendBulkMessages;

  const dashboardAlerts = useSelector((state) => state.dashboardAlerts);
  const {
    loading: loadingAlerts,
    error: errorAlerts,
    dashboardAlert,
  } = dashboardAlerts;

  const getUnreadChatMessages = useSelector(
    (state) => state.getUnreadChatMessages
  );
  const {
    loading: loadingUnreadChatMessages,
    error: errorUnreadChatMessages,
    unreadChatMessages,
  } = getUnreadChatMessages;

  // USER FEED NOTIFICATIONS
  const getUserFeedNotifications = useSelector(
    (state) => state.getUserFeedNotifications
  );
  const {
    loading: loadingUserFeeds,
    error: errorUserFeeds,
    userFeeds,
  } = getUserFeedNotifications;

  const changeViewHandler = (e) => {
    setSelectedView(e.target.value);
  };

  useEffect(() => {
    const reviewedPatients = allPatientsList?.filter((p) => p?.is_reviewed);

    const allReviewedPatients =
      reviewedPatients?.length === allPatientsList?.length;

    setAllReviewed(allReviewedPatients);
  }, [allPatientsList]);

  useEffect(() => {
    if (!agencyDetail) {
      dispatch(getAgencyDetailFunc());
    }
    // dispatch(userFeedNotificationsFunc(page, page_size));
  }, [agencyId, changed]);

  const getUnReviewedAndReviewedPatients = () => {
    if (selectedView === "dashboard") {
      dispatch(getUnreviewedPatientsFunc(agentUser?.agent?.id));
      dispatch(getReviewedPatientsFunc(agentUser?.agent?.id));
    } else {
      dispatch(getUnreviewedPatientsFunc("all"));
      dispatch(getReviewedPatientsFunc("all"));
    }
  };

  useEffect(() => {
    if (agentUser?.agent?.id) {
      getUnReviewedAndReviewedPatients();

      if (selectedView === "dashboard") {
        dispatch(getDashboardAnalyticsFunc(agentUser?.agent?.id));
        dispatch(getDashboardAlertsFunc(agentUser?.agent?.id));
        dispatch(getUnreadChatMessagesFunc(agentUser?.agent?.id));
        // dispatch(getUnreviewedPatientsFunc(agentUser?.agent?.id));
        // dispatch(getReviewedPatientsFunc(agentUser?.agent?.id));
      } else {
        dispatch(getDashboardAnalyticsFunc("all"));
        dispatch(getDashboardAlertsFunc("all"));
        dispatch(getUnreadChatMessagesFunc("all"));
        // dispatch(getUnreviewedPatientsFunc("all"));
        // dispatch(getReviewedPatientsFunc("all"));
      }
    }
  }, [agentUser?.agent?.id, selectedView, agencyId, agencyDetail, changed]);

  const patientsInSession = JSON.parse(
    sessionStorage.getItem("reviewedPatients")
  );

  useEffect(() => {
    if (unreviewedPatients?.ok && reviewedPatients?.ok) {
      const reviewedPatientsFromSession = JSON.parse(
        sessionStorage.getItem("reviewedPatients") || "[]"
      );

      const combinedPatients = [
        ...reviewedPatientsFromSession?.map((p) => ({
          ...p,
          is_reviewed: true,
        })),
        ...unreviewedPatients?.unreviewed_patients?.map((p) => ({
          ...p,
          is_reviewed: false,
        })),
      ];

      const nextUnreviewedIndex = reviewedPatientsFromSession.length;
      if (nextUnreviewedIndex === 0) {
        dispatch(getCurrentPatientIndexFunc(0));
      } else {
        dispatch(getCurrentPatientIndexFunc(nextUnreviewedIndex - 1));
      }

      // const index = allPatientsList?.findIndex((patient) =>
      //   unreviewedPatients?.unreviewed_patients?.some(
      //     (unreviewed) => unreviewed?.id === patient?.id
      //   )
      // );

      // if (index !== -1) {

      //   dispatch(getCurrentPatientIndexFunc(index));
      // } else {
      //   dispatch(getCurrentPatientIndexFunc(0));
      // }

      const allReviewed = allPatientsList?.every((item) => item?.is_reviewed);
      if (allReviewed && patientsInSession?.length) {
        dispatch(setShowReviewedScreenFunc(true));
      }

      setAllPatientsList(combinedPatients);
    }
  }, [unreviewedPatients, reviewedPatients]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUnReviewedAndReviewedPatients();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [agentUser?.agent?.id, selectedView]);

  // const fetchMoreData = () => {
  //   if (hasNext) {
  //     const nextPage = page + 1;

  //     dispatch(userFeedNotificationsFunc(nextPage, page_size));

  //     setPage(nextPage);
  //   }
  // };

  // useEffect(() => {
  //   if (!loadingUserFeeds && userFeeds?.ok) {
  //     setUserFeedNotifications((prevState) => [
  //       ...prevState,
  //       ...userFeeds?.user_feed,
  //     ]);
  //     setHasNext(userFeeds?.has_next);
  //   }
  // }, [userFeeds]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // setRenderUserFeed(false);
  //     setPage(1);
  //     // setUserFeedNotifications([]);
  //     // dispatch(userFeedNotificationsFunc(page, page_size));
  //   }, 30000);

  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "dashboard",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  const currentViewPatient = allPatientsList[currentIndex];
  const unReviewedPatientsList =
    allPatientsList?.filter((p) => !p.is_reviewed) ?? [];
  const reviewedPatientsList =
    allPatientsList?.filter((p) => p.is_reviewed) ?? [];
  const allPainEntries =
    currentViewPatient?.pain_entries?.flatMap(
      (entry) => entry?.pain_entry_body_parts ?? []
    ) ?? [];

  const allMedicineEntries =
    currentViewPatient?.medicine_entries?.flatMap((entry) => entry ?? []) ?? [];

  const allBodyParts = [...allPainEntries, ...allMedicineEntries];

  const markAsReviewedFunc = (patientIds) => {
    const patientIdsObj = { patient_ids: patientIds };

    setPatientIds(patientIds);
    dispatch(markPatientReviewedFunc(patientIdsObj));
  };

  useEffect(() => {
    if (markAsReviewed?.ok) {
      const updatedAllPatientsList = [...allPatientsList];

      // Mark the patients as reviewed based on the patientIds
      updatedAllPatientsList.forEach((patient) => {
        if (patientIds?.includes(patient?.id)) {
          patient.is_reviewed = true;
        }
      });

      // Update reviewed patients in session storage
      const reviewedPatients = updatedAllPatientsList?.filter(
        (p) => p?.is_reviewed
      );
      sessionStorage.setItem(
        "reviewedPatients",
        JSON.stringify(reviewedPatients)
      );

      // UPDATE ALL PATIENTS LIST STATE
      setAllPatientsList(updatedAllPatientsList);

      if (currentIndex === allPatientsList?.length - 1 || allReviewedClicked) {
        getUnReviewedAndReviewedPatients();
        dispatch(setShowReviewedScreenFunc(true));
      } else if (finishReviewingClicked) {
        dispatch(setShowReviewedScreenFunc(true));
      } else {
        dispatch(getCurrentPatientIndexFunc(currentIndex + 1));
      }
      dispatch(resetMarkPatientReviewedFunc());
    }
  }, [markAsReviewed]);

  const nextPatientHandler = () => {
    const currentPatient = allPatientsList[currentIndex];

    if (!currentPatient?.is_reviewed) {
      const patientIds = [currentPatient?.id];
      markAsReviewedFunc(patientIds);
    } else if (currentIndex < allPatientsList?.length - 1) {
      dispatch(getCurrentPatientIndexFunc(currentIndex + 1));
      // setCurrentIndex(currentIndex + 1);
    } else {
      dispatch(setShowReviewedScreenFunc(true));
    }
  };

  const backPatientHandler = () => {
    if (currentIndex > 0) {
      // Move to the previous patient in the main list
      dispatch(getCurrentPatientIndexFunc(currentIndex - 1));

      // setCurrentIndex(currentIndex - 1);
    }
  };

  const reviewNextPatientHandler = () => {
    setOpenReviewedConfirmModal(true);
    setModalContentData({
      title: "Wait a second!",
      subtitle:
        "Once you continue, you will not be able to perform any further actions on the patients you just reviewed.",
    });
  };

  const onSubmitAddTimeEntryHandler = (timeObj) => {
    dispatch(createBulkTimeFunc(timeObj));
    dispatch(actionsPerformedFunc(true));

    setSavedSeconds(timeObj?.time_in_seconds);
  };

  useEffect(() => {
    if (bulkedTime?.ok) {
      setOpenTimeDrawer(false);
      setOpenNoteModal(false);

      if (bulkNoteButtonClicked) {
        setOpenSuccessMessage(true);
        setSuccessTextMessage({ title: "Note added successfully" });
      } else {
        setOpenSuccessMessage(true);
        setSuccessTextMessage({
          title: `${humanReadingTime(savedSeconds)} ${humanReadingNames(
            patientsInSession
          )}`,
        });
      }
    }

    dispatch(resetBulkTimeFunc());
  }, [bulkedTime]);

  const addBulkNotesHandler = () => {
    const bulkNotesObj = {
      patient_ids: patientsInSession?.map((item) => item?.id),
      agency_id: agencyId,
      note: bulkNoteValue,
    };

    dispatch(addBulkNotesFunc(bulkNotesObj));
    dispatch(actionsPerformedFunc(true));
  };

  useEffect(() => {
    if (bulkedNotes?.ok && checkedTimeNote) {
      const { startTime, endTime } = calculateBulkTimeMinutes(minuteValue);

      const bulkTimeObj = {
        patient_ids: patientsInSession?.map((item) => item?.id),
        agency_id: agencyId,
        date: dayjs(),
        start_time: startTime,
        end_time: endTime,
        time_in_seconds: calculateElapsedTime(startTime, endTime),
        category: "Therapeutic Data Review",
        notes: "",
        manually_added: true,
        contains_interaction: false,
      };

      dispatch(createBulkTimeFunc(bulkTimeObj));
      dispatch(resetAddBulkNotesFunc());
    } else if (bulkedNotes?.ok) {
      setOpenSuccessMessage(true);
      setSuccessTextMessage({ title: "Note added successfully" });
      setOpenNoteModal(false);
      dispatch(resetAddBulkNotesFunc());
    }
  }, [bulkedNotes]);

  const submitMessageHandler = () => {
    const messageObj = {
      content: { message: messageValue },
      senderDisplayName: agentUser?.agent?.name,
      agencyId: agencyId,
      patientIds: patientsInSession?.map((item) => item?.id),
      createdOn: new Date(),
    };

    dispatch(sendBulkMessagesFunc(messageObj));
    dispatch(actionsPerformedFunc(true));
  };

  useEffect(() => {
    if (bulkedMessages?.ok) {
      setOpenMessageDrawer(false);
      setOpenSuccessMessage(true);
      setSuccessTextMessage({ title: "Message added successfully" });
      setMessageValue("");
      dispatch(resetAddBulkMessagesFunc());
    }
  }, [bulkedMessages]);

  const removeReviewedPatientsHandler = () => {
    setOpenReviewedConfirmModal(false);
    setFinishReviewingClicked(false);
    setAllReviewedClicked(false);
    dispatch(setShowReviewedScreenFunc(false));
    if (currentIndex !== allPatientsList?.length - 1) {
      dispatch(getCurrentPatientIndexFunc(currentIndex + 1));
    } else {
      dispatch(getCurrentPatientIndexFunc(0));
    }
    getUnReviewedAndReviewedPatients();
    sessionStorage.removeItem("reviewedPatients");
    dispatch(actionsPerformedFunc(false));
  };

  const finishReviewingHandler = () => {
    setFinishReviewingClicked(true);
    const currentPatient = allPatientsList[currentIndex];

    if (!currentPatient?.is_reviewed) {
      const patientIds = [currentPatient?.id];
      markAsReviewedFunc(patientIds);
    } else {
      dispatch(setShowReviewedScreenFunc(true));
    }
  };

  const markAllReviewedHandler = () => {
    const allUnreviewedPatients = allPatientsList?.filter(
      (item) => !item?.is_reviewed
    );
    const allUnreviewedPatientIds = allUnreviewedPatients?.map(
      (item) => item?.id
    );
    setAllReviewedClicked(true);
    markAsReviewedFunc(allUnreviewedPatientIds);
  };

  return (
    <>
      {/* {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )} */}

      {openReviewedConfirmModal && (
        <AlertDialog
          openModal={openReviewedConfirmModal}
          closeOverlay={false}
          closeModal={() => setOpenReviewedConfirmModal(false)}
          data={modalContentData}
          submitText="Continue anyway"
          formSubmitFunction={removeReviewedPatientsHandler}
        />
      )}
      {openNoteModal && (
        <AlertDialog
          isLoading={loadingBulkNotes || loadingBulkTime}
          disabled={!bulkNoteValue}
          closeOverlay={false}
          openModal={openNoteModal}
          closeModal={() => {
            setOpenNoteModal(false);
            setBulkNoteValue("");
            setCheckedTimeNote(true);
            setMinuteValue("1_minute");
            setBulkNoteButtonClicked(false);
          }}
          data={{ title: "Add Note" }}
          submitText="Save"
          padding="24px"
          titlePadding="24px !important"
          maxWidth="682px"
          height="872px"
          formSubmitFunction={addBulkNotesHandler}
        >
          <AddBulkNotesModalContent
            reviewedPatients={patientsInSession}
            setMinuteValue={setMinuteValue}
            minuteValue={minuteValue}
            checkedTimeNote={checkedTimeNote}
            setCheckedTimeNote={setCheckedTimeNote}
            bulkNoteValue={bulkNoteValue}
            setBulkNoteValue={setBulkNoteValue}
          />
        </AlertDialog>
      )}

      <CptDrawer
        openDrawer={openTimeDrawer}
        onClose={() => setOpenTimeDrawer(false)}
        anchor={"right"}
        setOpenDrawer={setOpenTimeDrawer}
        fromDashboard="true"
        // cptCodeDetail={cptCodeDetail}
        // patientDetail={patientDetail}
        // timeDetails={timeDetails}
        drawerType={"add_time"}
        patientIds={patientsInSession?.map((item) => item?.id)}
        // onSubmitEditTime={onSubmitEditTimeHandler}
        onSubmitAddTimeEntry={onSubmitAddTimeEntryHandler}
      />

      <CommonDrawer
        open={openMessageDrawer}
        onClose={() => {
          setOpenMessageDrawer(false);
          setMessageValue("");
        }}
        anchor={"right"}
        setOpen={setOpenMessageDrawer}
        height="100%"
      >
        <SendMessageDrawer
          reviewedPatients={patientsInSession}
          closeDrawerHandler={() => {
            setOpenMessageDrawer(false);
            setMessageValue("");
          }}
          submitMessageHandler={submitMessageHandler}
          messageValue={messageValue}
          loadingBulkMessages={loadingBulkMessages}
          setMessageValue={setMessageValue}
        />
      </CommonDrawer>

      {!loadingAgentProfile && errorAgentProfile && (
        <AlertMessage
          message={errorAgentProfile}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}
      {/* {!loadingHighRiskCases && errorHighRiskcases && (
        <AlertMessage
          message={errorHighRiskcases}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )} */}
      {!loadingUnreadChatMessages && errorUnreadChatMessages && (
        <AlertMessage
          message={errorUnreadChatMessages}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {openSuccessMessage && (
        <AlertMessage
          width="304px"
          autoHideDurationTime={7000}
          textAlign="initial"
          message={successTextMessage?.title}
          severity="success"
          bgcolor="#EDF7ED"
          open={openSuccessMessage}
          handleClose={handleClose}
        />
      )}

      <Stack flexDirection="column" gap="28px" padding="20px 37px">
        {/* 1st ROW DASHBOARD MAIN*/}

        {/* 1st BOX WELCOME */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
        >
          {loadingAgentProfile ? (
            <Stack gap="17px">
              <Skeleton variant="rounded" width={230} height={23} />
              <Skeleton variant="rounded" width={395} height={42} />
            </Stack>
          ) : (
            <Stack maxWidth="399px" gap="2px">
              <Typography
                id="welcome-text"
                color="#0C3D6D"
                // fontSize="28px"
                variant="h5"
                fontWeight="500"
              >
                Hello, {agentUser?.agent?.name}
              </Typography>
              <Typography variant="body1" color="rgba(0, 0, 0, 0.38)">
                Track patient progress and stay updated with real-time alerts
                and messages.
              </Typography>
            </Stack>
          )}

          <CustomSelectFilter
            menuItems={[
              { title: "My dashboard", value: "dashboard" },
              { title: "Practice dashboard", value: "all" },
            ]}
            changeSelectValueHandler={changeViewHandler}
            selectValue={selectedView}
            id="dashboard-view"
            minWidth={177}
            size="small"
          />
        </Box>

        {/* 2nd BOX USER FEED */}
        {/* <Stack
            boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
            padding="24px"
            borderRadius="8px"
            maxWidth="347px"
            width="100%"
            sx={{ flexGrow: "1" }}
          >
            <Stack gap="16px" height="418px" sx={{ flexGrow: 1 }}>
              <Box
                display="flex"
                justifyContent="flex-start"
                gap="4px"
                alignItems="flex-start"
              >
                <Typography
                  variant="h2"
                  fontSize="16px"
                  fontWeight="500"
                  color="secondary"
                  textTransform="uppercase"
                  lineHeight="28px"
                >
                  User Feed
                </Typography>
                <Tooltip
                  placement="top"
                  arrow
                  PopperProps={{
                    sx: {
                      marginBottom: "-7px !important",
                    },
                  }}
                >
                  <InfoCircle />
                </Tooltip>
              </Box>
              {loadingUserFeeds && userFeedNotifications?.length === 0 ? (
                <RectangleSkeletons height={67} />
              ) : userFeedNotifications?.length > 0 ? (
                <div
                  id="scrollableDiv"
                  style={{ overflow: "auto", height: "400px" }}
                >
                  <InfiniteScroll
                    dataLength={userFeedNotifications.length || []}
                    next={fetchMoreData}
                    hasMore={hasNext}
                    loader={
                      <CircularProgressLoading size={20} overflow="hidden" />
                    }
                    scrollableTarget="scrollableDiv"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {userFeedNotifications?.map((data, index) => (
                      <UserFeedCard data={data} key={index} />
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <Stack
                  gap="16px"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Box width="128px" height="128px">
                    <img src={EmptyBox} alt="empty box" width="100%" />
                  </Box>
                  <Typography variant="body1">No user feeds yet</Typography>
                </Stack>
              )}
            </Stack>
            <Box
                position="absolute"
                top="-23px"
                right="-23px"
                width="215px"
                sx={{ transform: "rotate(17.166deg)" }}
              >
                <img src={bellIcon} alt="notification" width="100%" />
              </Box>

          <Stack
                alignItems="flex-start"
                justifyContent="flex-end"
                gap="24px"
              >

                <Typography variant="body1" fontSize="18px">
                  Instant Case Updates Through Push Notifications!
                </Typography>
                <Button
                  href="/marketing?tab=push_notifications"
                  variant="contained"
                  size="large"
                >
                  Set up push notifications
                </Button>
              </Stack>
          </Stack> */}

        {/* 2nd ROW CARDS CASES */}
        <Box
          display="flex"
          alignItems="flex-start"
          width="100%"
          justifyContent="space-between"
          gap="25px"
        >
          <Stack gap="24px" width="100%">
            <Box display="flex" alignItems="center" gap="18px">
              <DashboardCard
                headerText="Eligible Patients"
                number={dashboardAnalytic?.metrics?.eligible_patients_count}
                imgName={<PatientVector />}
                loading={loading || loadingAgentProfile}
              />
              <DashboardCard
                headerText="Enrolled Patients"
                number={dashboardAnalytic?.metrics?.enrolled_patients_count}
                imgName={<PendingEnrollVector />}
                loading={loading || loadingAgentProfile}
              />
              <DashboardCard
                headerText="Active Patients"
                number={dashboardAnalytic?.metrics?.active_patients_count}
                imgName={<InactivePatientVector />}
                // borderRadius={true}
                loading={loading || loadingAgentProfile}
              />
            </Box>

            {/* LATEST READINGS CARD */}
            <Stack
              border="1px solid #EBEBEB"
              height="483px"
              width="100%"
              padding="20px 16px"
              bgcolor="#fff"
              borderRadius="8px"
              gap="20px"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle1" fontSize="18px" color="#0D0D0D">
                  Readings{" "}
                  <Box component="span" color="rgba(0, 0, 0, 0.38)">
                    in last 24 hours
                  </Box>
                </Typography>
                {(!loadingUnreviewedPatients || !loadingReviewedPatients) &&
                unreviewedPatients?.unreviewed_patients?.length ? (
                  <LoadingButton
                    variant="text"
                    loading={loadingMarkPatientReviewed && allReviewedClicked}
                    color="primary"
                    sx={{ textTransform: "initial" }}
                    onClick={markAllReviewedHandler}
                  >
                    Mark all as read
                  </LoadingButton>
                ) : (
                  <></>
                )}
              </Box>

              {/* REVIEWED/UNREVIEWED PATIENTS AND DETAIL CARD */}
              {loadingUnreviewedPatients ||
              loadingReviewedPatients ||
              loadingAgency ||
              loadingAgentProfile ? (
                <RectangleSkeletons totalRows={1} height="400px" width="100%" />
              ) : unreviewedPatients?.unreviewed_patients?.length ||
                patientsInSession?.length ? (
                <Box
                  display="flex"
                  alignItems="flex-start"
                  gap="10px"
                  height="100%"
                >
                  {/*  !unReviewedPatientsList?.length && */}
                  {showReviewedScreen && patientsInSession?.length ? (
                    <Stack
                      borderRadius="8px"
                      alignItems="center"
                      justifyContent="center"
                      height="384px"
                      padding="12px"
                      sx={{
                        background:
                          "linear-gradient(305deg, #0C80F3 21.99%, #2954F2 72.03%)",
                      }}
                      flex="1"
                      gap="10px"
                      position="relative"
                    >
                      {/* {unReviewedPatientsList?.length ? (
                        <Box position="absolute" top="16px" right="36px">
                          <StyledButton
                            variant="text"
                            border="none"
                            onClick={() => {
                              dispatch(setShowReviewedScreenFunc(false));
                              setAllReviewedClicked(false);
                              setFinishReviewingClicked(false);
                            }}
                          >
                            Continue Reviewing
                          </StyledButton>
                        </Box>
                      ) : (
                        <></>
                      )} */}
                      <Stack alignItems="center" height="92px">
                        <Box width="43px" height="43px">
                          <img
                            width="100%"
                            height="100%"
                            src={LikeImage}
                            alt="like image"
                          />
                        </Box>
                        <Typography
                          color="#fff"
                          variant="body1"
                          fontWeight={500}
                          fontSize="20px"
                        >
                          {patientsInSession?.length} out{" "}
                          {unReviewedPatientsList?.length +
                            reviewedPatientsList?.length}{" "}
                          patients reviewed
                        </Typography>
                      </Stack>
                      <Stack
                        gap="10px"
                        bgcolor="rgba(13, 13, 13, 0.15)"
                        borderRadius="14px"
                        padding="16px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body2" color="#fff">
                          Perform Actions on reviewed Patients
                        </Typography>
                        <Box display="flex" alignItems="center" gap="6px">
                          <StyledButton
                            disableElevation
                            startIcon={<AddNoteIcon />}
                            variant="contained"
                            onClick={() => {
                              setOpenNoteModal(true);
                              setBulkNoteButtonClicked(true);
                            }}
                          >
                            Add Note
                          </StyledButton>
                          <StyledButton
                            disableElevation
                            startIcon={<SendMessageIcon />}
                            variant="contained"
                            onClick={() => setOpenMessageDrawer(true)}
                          >
                            Send Message
                          </StyledButton>
                          <StyledButton
                            disableElevation
                            startIcon={<AddTimeIcon />}
                            variant="contained"
                            onClick={() => setOpenTimeDrawer(true)}
                          >
                            Add Time
                          </StyledButton>
                        </Box>
                      </Stack>
                      <Stack gap="11px" alignItems="center">
                        <Typography variant="body2" color="#fff">
                          or
                        </Typography>
                        {!actionsPerformed ? (
                          <StyledButton
                            variant="conatined"
                            onClick={() => {
                              dispatch(setShowReviewedScreenFunc(false));
                              setAllReviewedClicked(false);
                              setFinishReviewingClicked(false);
                            }}
                          >
                            Continue Reviewing
                          </StyledButton>
                        ) : (
                          <StyledButton
                            disableElevation
                            variant="contained"
                            width="100%"
                            background="#1366D1"
                            onClick={reviewNextPatientHandler}
                          >
                            Review Next Patients
                          </StyledButton>
                        )}
                      </Stack>
                      {/* <Typography
                        variant="body2"
                        color="#fff"
                        width="334px"
                        textAlign="center"
                      >
                        To add time for reviewing pain charts, use Bulk Actions
                        from the top-right corner.
                      </Typography> */}
                    </Stack>
                  ) : (
                    <Stack
                      flex="1"
                      gap="4px"
                      width="100%"
                      height="384px"
                      bgcolor="#F7F7F7"
                      borderRadius="8px"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="4px"
                        padding="12px"
                      >
                        <Box display="flex" alignItems="flex-start" gap="10px">
                          {currentViewPatient?.profile_picture_url ? (
                            <Avatar
                              alt={currentViewPatient?.name}
                              src={currentViewPatient?.profile_picture_url}
                            />
                          ) : (
                            <Avatar
                              alt={currentViewPatient?.name}
                              {...stringAvatar(currentViewPatient?.name)}
                              sx={{
                                // fontSize: avatarFontSize,
                                backgroundColor:
                                  currentViewPatient?.avatar_color,
                              }}
                            />
                          )}

                          <Stack>
                            <Typography variant="body1" fontWeight={500}>
                              {currentViewPatient?.name}
                            </Typography>
                            {currentViewPatient?.diagnostic_codes?.length ? (
                              <Typography variant="body2" color="secondary">{`${
                                currentViewPatient?.diagnostic_codes?.length > 1
                                  ? currentViewPatient?.diagnostic_codes?.map(
                                      (item) => ` ${item?.code}`
                                    )
                                  : `${
                                      currentViewPatient?.diagnostic_codes[0]
                                        ?.code
                                    } ${
                                      currentViewPatient?.diagnostic_codes[0]
                                        ?.description
                                        ? ` - ${currentViewPatient?.diagnostic_codes[0]?.description}`
                                        : ""
                                    }`
                              }`}</Typography>
                            ) : (
                              <></>
                            )}
                          </Stack>
                        </Box>
                        <Typography
                          variant="body1"
                          color="secondary"
                          fontWeight={500}
                          padding="10px"
                          borderRadius="4px"
                          bgcolor="#fff"
                        >
                          {currentIndex + 1}/{allPatientsList?.length}
                        </Typography>
                      </Box>

                      <Stack
                        gap="10px"
                        height="100%"
                        overflow="auto"
                        padding="0 12px 12px"
                      >
                        {allBodyParts?.map((item, index) => (
                          <ReadingDetailPainCard
                            key={index}
                            name={`${item?.side} ${item?.name}`}
                            description={item?.description}
                            duration={item?.duration}
                            pain_intensity={item?.pain_level}
                            medicine_adherence={
                              item?.independent_of_pain_entry
                                ? item?.medicine_adherence
                                : item?.medicine_entry?.medicine_adherence
                            }
                            time={item?.time}
                            item={item}
                            missed_medication_reason={item?.reason}
                          />
                        ))}
                      </Stack>
                      <Box
                        padding="12px 12px"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        gap="8px"
                      >
                        <LoadingButton
                          sx={{ minWidth: "134px" }}
                          // fullWidth
                          disabled={currentIndex === 0}
                          background="#E0E0E0"
                          disableElevation
                          startIcon={<ChevronLeftIcon />}
                          variant="contained"
                          onClick={backPatientHandler}
                        >
                          BACK
                        </LoadingButton>

                        <LoadingButton
                          sx={{
                            background: "#E0E0E0",
                            flex: "1",
                            color: "#566875",
                            "&:hover": {
                              background: "#eee",
                            },
                          }}
                          // color="secondary"
                          loading={
                            loadingMarkPatientReviewed && finishReviewingClicked
                          }
                          background="#E0E0E0"
                          disableElevation
                          variant="contained"
                          onClick={finishReviewingHandler}
                        >
                          Finish Reviewing
                        </LoadingButton>

                        <LoadingButton
                          sx={{ minWidth: "133px" }}
                          loading={
                            loadingMarkPatientReviewed &&
                            !finishReviewingClicked &&
                            !allReviewedClicked
                          }
                          disableElevation
                          endIcon={<ChevronRightIcon />}
                          variant="contained"
                          onClick={nextPatientHandler}
                        >
                          {currentIndex === allPatientsList?.length - 1
                            ? "Finish"
                            : "Next Patient"}
                        </LoadingButton>
                      </Box>
                    </Stack>
                  )}

                  {/* <Stack width="282px" gap="8px">
                    <Stack
                      border="0.5px solid #EBEBEB"
                      borderRadius="8px"
                      padding="12px"
                      gap="12px"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="caption"
                          color="secondary"
                          fontWeight={500}
                        >
                          Bulk Actions on reviewed patients
                        </Typography>
                        <Typography
                          variant="caption"
                          color="secondary"
                          fontWeight={500}
                        >
                          {reviewedPatientsList?.length}/
                          {allPatientsList?.length}
                        </Typography>
                      </Box>
                      <Box display="flex" flexWrap="wrap" gap="6px">
                        <StyledButton
                          disableElevation
                          startIcon={<AddNoteIcon />}
                          variant="contained"
                          onClick={() => setOpenNoteModal(true)}
                        >
                          Add Note
                        </StyledButton>
                        <StyledButton
                          disableElevation
                          startIcon={<SendMessageIcon />}
                          variant="contained"
                          onClick={() => setOpenMessageDrawer(true)}
                        >
                          Send Message
                        </StyledButton>
                        <StyledButton
                          disableElevation
                          startIcon={<AddTimeIcon />}
                          variant="contained"
                          onClick={() => setOpenTimeDrawer(true)}
                        >
                          Add Time
                        </StyledButton>
                      </Box>
                    </Stack>

                    <Stack
                      gap="16px"
                      padding="6px 12px"
                      height="251px"
                      overflow="auto"
                    >
                      {unReviewedPatientsList?.length ? (
                        <Stack>
                          <Typography
                            variant="caption"
                            color="secondary"
                            fontWeight={500}
                          >
                            New Updates
                          </Typography>
                          {unReviewedPatientsList?.map((item, index) => (
                            <PatientTimeCard
                              key={index}
                              profile_url={item?.profile_picture_url}
                              name={item?.name}
                              lastReadingTime={item?.last_pain_reading_time}
                              avatarColor={item?.avatar_color}
                            />
                          ))}
                        </Stack>
                      ) : (
                        <></>
                      )}

                      {reviewedPatientsList?.length ? (
                        <Stack>
                          <Typography
                            variant="caption"
                            color="secondary"
                            fontWeight={500}
                          >
                            Reviewed
                          </Typography>
                          {reviewedPatientsList?.map((item, index) => (
                            <PatientTimeCard
                              key={index}
                              profile_url={item?.profile_picture_url}
                              name={item?.name}
                              lastReadingTime={item?.last_pain_reading_time}
                              avatarColor={item?.avatar_color}
                            />
                          ))}
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Stack> */}
                </Box>
              ) : (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Box height="193px" width="193px">
                    <img
                      width="100%"
                      height="100%"
                      src={caughtupImage}
                      alt="all caught up"
                    />
                  </Box>
                  <Typography variant="body2" fontSize="17px">
                    You are all caught up!
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack
            gap="19px"
            maxWidth="347px"
            width="100%"
            sx={{ flexGrow: "1" }}
          >
            {/* 1st BOX UNREAD MESSAGES CARD */}
            <Stack borderRadius="8px" border="1px solid #EBEBEB" height="462px">
              <Stack height="418px" sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="500"
                  color="#0D0D0D"
                  textTransform="capitialize"
                  lineHeight="28px"
                  padding="20px 16px"
                >
                  Unread Messages
                </Typography>

                <Stack
                  gap="8px"
                  overflow="auto"
                  padding="0 16px"
                  paddingBottom="20px"
                  height="100%"
                >
                  {loadingUnreadChatMessages ||
                  loadingAgency ||
                  loadingAgentProfile ? (
                    <RectangleSkeletons totalRows={3} height={64} />
                  ) : unreadChatMessages?.unreadMessages?.length > 0 ? (
                    <Stack gap="8px" overflow="auto">
                      {unreadChatMessages?.unreadMessages?.map((message, i) => (
                        <NewMessageCard
                          key={i}
                          senderProfile={message?.patient?.profile_picture_url}
                          senderName={message?.senderDisplayName}
                          chatDate={dateTimeFormat(message?.createdOn)}
                          messageContent={message?.content}
                          patient_id={message?.patient?.id}
                          avatarColor={message?.patient?.avatar_color}
                        />
                      ))}
                    </Stack>
                  ) : (
                    <Stack
                      gap="8px"
                      alignItems="center"
                      justifyContent="center"
                      height="80%"
                    >
                      <Box width="100px">
                        <img
                          width="100%"
                          height="100%"
                          src={CaughtUpImage}
                          alt="no alerts"
                        />
                      </Box>
                      <Typography variant="body2">
                        You are all caught up!
                      </Typography>
                    </Stack>
                  )}
                </Stack>

                {/* NEED YOUR ATTENTION CARD */}
                {/* {loadingUserFeeds && userFeedNotifications?.length === 0 ? (
                <RectangleSkeletons height={67} />
              ) : userFeedNotifications?.length > 0 ? (
                <div
                  id="scrollableDiv"
                  style={{ overflow: "auto", height: "400px" }}
                >
                  <InfiniteScroll
                    dataLength={userFeedNotifications.length || []}
                    next={fetchMoreData}
                    hasMore={hasNext}
                    loader={
                      <CircularProgressLoading size={20} overflow="hidden" />
                    }
                    scrollableTarget="scrollableDiv"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {userFeedNotifications?.map((data, index) => (
                      <UserFeedCard data={data} key={index} />
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <Stack
                  gap="16px"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Box width="128px" height="128px">
                    <img src={EmptyBox} alt="empty box" width="100%" />
                  </Box>
                  <Typography variant="body1">No user feeds yet</Typography>
                </Stack>
              )} */}
              </Stack>
            </Stack>

            {/* 2nd BOX ATTENTION CARD */}
            {/* <Stack
               borderRadius="8px"
              border="1px solid #EBEBEB"
              height="462px"
            >
              <Stack height="418px" sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight="500"
                  color="#0D0D0D"
                  textTransform="capitialize"
                  lineHeight="28px"
                  padding="20px 16px"
                >
                  Need your attention
                </Typography> */}

            {/* <Stack
                  gap="8px"
                  overflow="auto"
                  padding="0 16px"
                  paddingBottom="20px"
                  height="100%"
                >
                  {loadingAlerts || loadingAgency || loadingAgentProfile ? (
                    <RectangleSkeletons totalRows={3} height={64} />
                  ) : dashboardAlert?.alerts
                      ?.pending_enrollment_patients_count ||
                    dashboardAlert?.alerts?.inactive_patients_count ||
                    dashboardAlert?.alerts
                      ?.pending_interactive_communication_patients_count ? (
                    <>
                      {" "}
                      {dashboardAlert?.alerts
                        ?.pending_enrollment_patients_count ? (
                        <AttentionCard
                          assignedToId={agentUser?.agent?.id}
                          selectedView={selectedView}
                          tab="pending_enrollment"
                          title={`${
                            dashboardAlert?.alerts
                              ?.pending_enrollment_patients_count
                          } ${
                            dashboardAlert?.alerts
                              ?.pending_enrollment_patients_count === 1
                              ? "patient"
                              : "patients"
                          }  ${
                            dashboardAlert?.alerts
                              ?.pending_enrollment_patients_count === 1
                              ? "has"
                              : "have"
                          } been added but their enrollment is incomplete.`}
                        />
                      ) : (
                        <></>
                      )}
                      {dashboardAlert?.alerts?.inactive_patients_count ? (
                        <AttentionCard
                          selectedView={selectedView}
                          assignedToId={agentUser?.agent?.id}
                          tab="inactive"
                          title={`${
                            dashboardAlert?.alerts?.inactive_patients_count
                          } ${
                            dashboardAlert?.alerts?.inactive_patients_count ===
                            1
                              ? "Patient"
                              : "Patients"
                          } ${
                            dashboardAlert?.alerts?.inactive_patients_count ===
                            1
                              ? "hasn't"
                              : "haven't"
                          } logged pain in last 7 days.`}
                        />
                      ) : (
                        <></>
                      )}
                      {dashboardAlert?.alerts
                        ?.pending_interactive_communication_patients_count ? (
                        <AttentionCard
                          selectedView={selectedView}
                          assignedToId={agentUser?.agent?.id}
                          tab="pending_communication"
                          title={`You have pending interactive communications for ${
                            dashboardAlert?.alerts
                              ?.pending_interactive_communication_patients_count
                          }  ${
                            dashboardAlert?.alerts
                              ?.pending_interactive_communication_patients_count ===
                            1
                              ? "patient"
                              : "patients"
                          }.`}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <Stack
                      gap="8px"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                    >
                      <Box width="100px">
                        <img
                          width="100%"
                          height="100%"
                          src={CaughtUpImage}
                          alt="no alerts"
                        />
                      </Box>
                      <Typography variant="body2">
                        All Set, Nothing to Worry
                      </Typography>
                    </Stack>
                  )}
                </Stack> */}

            {/* NEED YOUR ATTENTION CARD */}
            {/* {loadingUserFeeds && userFeedNotifications?.length === 0 ? (
                <RectangleSkeletons height={67} />
              ) : userFeedNotifications?.length > 0 ? (
                <div
                  id="scrollableDiv"
                  style={{ overflow: "auto", height: "400px" }}
                >
                  <InfiniteScroll
                    dataLength={userFeedNotifications.length || []}
                    next={fetchMoreData}
                    hasMore={hasNext}
                    loader={
                      <CircularProgressLoading size={20} overflow="hidden" />
                    }
                    scrollableTarget="scrollableDiv"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {userFeedNotifications?.map((data, index) => (
                      <UserFeedCard data={data} key={index} />
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <Stack
                  gap="16px"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Box width="128px" height="128px">
                    <img src={EmptyBox} alt="empty box" width="100%" />
                  </Box>
                  <Typography variant="body1">No user feeds yet</Typography>
                </Stack>
              )} */}
            {/* </Stack>
            </Stack> */}
          </Stack>
        </Box>
      </Stack>

      {/* 3rd ROW CARDS CHAT AND CASES */}
      {/* <Grid container spacing={3}>
          NEW MESSAGES BOX
          <Grid item md={6}>
            <Box
              boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
              borderRadius="8px"
              bgcolor="#FFF"
              height="348px"
              padding="32px"
            >
              <Typography
                variant="h2"
                fontSize="16px"
                fontWeight="500"
                color="secondary"
                textTransform="uppercase"
                lineHeight="28px"
                paddingBottom="24px"
              >
                New Messages
              </Typography>

              {loadingUnreadChatMessages ? (
                <RectangleSkeletons height={78} totalRows={2} />
              ) : unreadChatMessages?.unreadMessages?.length > 0 ? (
                <Stack gap="8px" overflow="auto" height="256px">
                  {unreadChatMessages?.unreadMessages.map((message, i) => (
                    <NewMessageCard
                      key={i}
                      senderProfile={message?.case?.client?.profile_picture_url}
                      senderName={message?.senderDisplayName}
                      chatDate={dateTimeFormat(message?.createdOn)}
                      messageContent={message?.content}
                      case_id={message?.case?.id}
                    />
                  ))}
                </Stack>
              ) : (
                <Stack gap="16px" alignItems="center" justifyContent="center">
                  <Box width="128px" height="128px">
                    <img src={EmptyBox} alt="empty box" width="100%" />
                  </Box>
                  <Typography variant="body1">
                    No chat notifications yet
                  </Typography>
                </Stack>
              )}
            </Box>
          </Grid>

          AT RISK CASES BOX
          <Grid item md={6}>
            <Box
              boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
              borderRadius="8px"
              bgcolor="#FFF"
              height="348px"
              padding="32px"
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                gap="4px"
                alignItems="flex-start"
                paddingBottom="24px"
              >
                <Typography
                  variant="h2"
                  fontSize="16px"
                  fontWeight="500"
                  color="secondary"
                  textTransform="uppercase"
                  lineHeight="28px"
                >
                  At Risk cases
                </Typography>
                <Tooltip
                  title={
                    "Cases with no activity for the last 7 consecutive days are considered 'at risk' and are listed here"
                  }
                  placement="top"
                  arrow
                  PopperProps={{
                    sx: {
                      marginBottom: "-7px !important",
                    },
                  }}
                >
                  <InfoCircle />
                </Tooltip>
              </Box>

              {loadingHighRiskCases ? (
                <RectangleSkeletons height={78} totalRows={2} />
              ) : highRiskCases?.high_risk_cases?.length === 0 ? (
                <Stack gap="16px" alignItems="center" justifyContent="center">
                  <RiskCaseEmptyState />
                  <Typography variant="body1" width="291px" textAlign="center">
                    All your cases are in the clear, no at-risk cases in sight.
                  </Typography>
                </Stack>
              ) : (
                <Stack width="100%" gap="8px" overflow="auto" height="256px">
                  {highRiskCases?.high_risk_cases?.map((patientDetail, i) => (
                    <RiskCasesCard
                      key={i}
                      caseTitle={patientDetail?.title}
                      caseStatus={patientDetail?.status}
                      caseUpdate={timeDateFormat(patientDetail?.updated_at)}
                      profilePhoto={patientDetail?.client?.profile_picture_url}
                      clientName={patientDetail?.client?.name}
                      caseId={patientDetail?.id}
                    />
                  ))}
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid> */}
    </>
  );
};

export default DashboardComponent;
