import { Avatar, Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  getNotesBackgroundColor,
  getNotesColor,
  shortDateTimeFormat,
  showNotesType,
} from "../../utils/helpers";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const NoteLogsCard = ({
  time,
  startTime,
  endTime,
  type,
  notesText,
  agentId,
}) => {
  const getUnpaginatedAgentLists = useSelector(
    (state) => state.getUnpaginatedAgentLists
  );
  const { unPaginatedAgents: allAgents } = getUnpaginatedAgentLists;

  const agentDetail = allAgents?.agents_list?.find(
    (item) => item?.id === agentId
  );

  return (
    <Stack
      gap="7px"
      border="1px solid #EBEBEB"
      bgcolor="#FCFCFC"
      padding="16px"
      borderRadius="8px"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap="7px" width="100%">
          <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
            {shortDateTimeFormat(time)}
          </Typography>
          <Stack gap="4px" direction="row" alignItems="center">
            <Avatar
              alt="name"
              sx={{ width: 16, height: 16 }}
              src={agentDetail?.profile_picture_url}
            />
            {agentDetail?.name?.length >= 22 ? (
              <Tooltip title={agentDetail?.name}>
                <Typography
                  variant="body2"
                  sx={{
                    textOverflow: "ellipsis !important",
                    whiteSpace: "nowrap !important",
                    width: "176px !important",
                    overflow: "hidden !important",
                  }}
                >
                  {agentDetail?.name}
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant="body2">{agentDetail?.name}</Typography>
            )}
          </Stack>
          <Box textAlign="end" flex="1">
            <Chip
              size="small"
              label={
                type === "time_entry"
                  ? `${dayjs(startTime)?.format("hh:mm A")} to ${dayjs(
                      endTime
                    )?.format("hh:mm A")}`
                  : showNotesType(type)
              }
              // color={getNotesColor(type)}
              sx={{
                color: getNotesColor(type),
                bgcolor: getNotesBackgroundColor(type),
                border: "none",
              }}
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
      <Typography variant="body2">{notesText}</Typography>
    </Stack>
  );
};

export default NoteLogsCard;
