import React from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import IncreaseImg from "../../assets/images/increase-img.png";

const DashboardCard = ({
  headerText,
  number,
  imgName,
  alt,
  increaseNum,
  borderRadius,
  loading,
}) => {
  return (
    <Box
      boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
      padding="32px"
      height="141px"
      borderRadius="8px"
      position="relative"
      overflow="hidden"
      // display="flex"
      // alignItems="flex-end"
      // justifyContent="space-between"
      // gap="4px"
      width="100%"
      sx={{ background: "linear-gradient(180deg, #0C80F3 0%, #5865E1 100%)" }}
    >
      <Stack alignItems="flex-start" justifyContent="space-between" gap={2}>
        <Typography
          variant="body1"
          fontSize="14px"
          fontWeight="500"
          color="#fff"
        >
          {headerText}
        </Typography>
        {loading ? (
          <Skeleton variant="rounded" width={87} height={36} />
        ) : (
          <Typography
            variant="h3"
            fontSize="50px"
            fontWeight="700"
            color="#fff"
          >
            {number}
          </Typography>
        )}

        {/* <Box display="flex" gap="4px" alignItems="center">
          <img src={IncreaseImg} alt="increase bar" />
          <Typography variant="body1" fontSize="14px" color="secondary">
            <Box component="span" fontWeight="500" color="rgba(0, 0, 0, 0.87)">
              +{increaseNum}%{" "}
            </Box>{" "}
            than last month
          </Typography>
        </Box> */}
      </Stack>
      <Box
        position="absolute"
        bottom="22px"
        right="22px"
        sx={
          borderRadius && {
            width: "138px",
            height: "138px",
            borderRadius: "90px",
            background: "rgba(86, 104, 117, 0.06)",
            padding: "25px",
            bottom: "-27px",
            right: "-18px",
          }
        }
      >
        {imgName}
      </Box>
    </Box>
  );
};

export default DashboardCard;
