import { Box, Skeleton, Typography, Avatar } from "@mui/material";
import { stringAvatar } from "../../utils/helpers";

const AvatarWithName = ({
  loading,
  name,
  imageURL,
  size = 32,
  columnGap = "8px",
  avatarBgColor,
  fontSize = "0.875rem",
  color = "inherit",
  avatarFontSize = "16px",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: columnGap,
        height: "100%",
      }}
    >
      {loading ? (
        <>
          <Skeleton variant="circular">
            <Avatar sx={{ width: size, height: size }} />
          </Skeleton>
          <Skeleton>
            <Typography sx={{ fontSize: "0.875rem" }}></Typography>
          </Skeleton>
        </>
      ) : (
        <>
          {imageURL ? (
            <Avatar
              alt={name}
              src={imageURL}
              sx={{ width: size, height: size }}
            />
          ) : (
            <Avatar
              alt={name}
              {...stringAvatar(name)}
              sx={{
                width: size,
                height: size,
                fontSize: avatarFontSize,
                backgroundColor: avatarBgColor,
              }}
            />
          )}
          <Typography
            color={color}
            id={`client-name-${name}`}
            sx={{ fontSize: fontSize }}
          >
            {name}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default AvatarWithName;
