import JSZip from "jszip";
import React from "react";
import { saveAs } from "file-saver";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const DownloadSelectedReportButton = ({
  setOpenSnackbar,
  setSnackbarData,
  selectedReports,
  activeTab,
  patientName,
}) => {
  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const { agencyDetail } = getAgencyDetail;

  const practiceName = agencyDetail?.agency?.name;

  const downloadReportHandler = async (item) => {
    try {
      const response = await fetch(item?.download_url, {
        method: "GET",
        mode: "cors",
      });

      if (!response?.ok) {
        throw new Error("Failed to download");
      }

      const timestamp =
        activeTab === 0 || activeTab === 1
          ? activeTab === 0
            ? item?.file_type === "zip"
              ? `EOC - ${practiceName}`
              : `EOC - ${item?.patient?.name} - ${dayjs(
                  item?.created_at
                )?.format("YYYYMMDDhhmmss")}`
            : `${item?.patient?.name} - ${item?.month} ${item?.year}`
          : `${patientName} - ${item?.month} ${item?.year}`;

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${timestamp}`;
      document.body.appendChild(link);
      link.click();
      setOpenSnackbar(false);

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  const downloadSelectedReportsHandler = async () => {
    setOpenSnackbar(true);
    setSnackbarData({
      textContent: `${
        selectedReports?.length === 1
          ? "Downloading report"
          : "Downloading reports"
      }`,
      horizontal: "center",
      vertical: "top",
      severity: "success",
      color: "info",
    });
    if (selectedReports?.length === 1) {
      downloadReportHandler(selectedReports[0]);
    } else {
      const zip = new JSZip();

      const fetchPromises = selectedReports?.map(async (file) => {
        try {
          const response = await fetch(file?.download_url);
          if (!response?.ok) throw new Error("Failed to download reports");

          const timestamp = activeTab
            ? activeTab === 0
              ? `${dayjs(file?.created_at)?.format("YYYYMMDDhhmmss")}`
              : `${file?.month} ${file?.year}`
            : `${file?.month} ${file?.year}`;

          const blob = await response.blob();

          if (file?.file_type === "pdf") {
            zip.file(
              `${
                patientName ? patientName : file?.patient?.name
              } - ${timestamp}.pdf`,
              blob
            );
          } else if (file?.file_type === "zip") {
            const zipData = await JSZip.loadAsync(blob);
            const extractedFiles = Object.keys(zipData?.files);

            for (const fileName of extractedFiles) {
              const fileBlob = await zipData?.files[fileName].async("blob");
              zip.file(fileName, fileBlob);
            }
          }
        } catch (error) {
          console.log("Error downloading reports", error);
        }
      });

      await Promise.all(fetchPromises);
      const zipBlob = await zip.generateAsync({ type: "blob" });
      setOpenSnackbar(false);
      saveAs(zipBlob, `EOC - ${practiceName}.zip`);
    }
  };

  //   bgcolor="rgba(12, 128, 243, 0.08)"
  //   borderRadius="4px"
  //   padding="4px"
  //   sx={{ cursor: "pointer" }}

  return (
    <>
      {selectedReports?.length ? (
        <Button
          disableElevation
          variant="contained"
          sx={{
            fontSize: "13px",
            background: "rgba(12, 128, 243, 0.08)",
            textTransform: "capitalize",
            padding: "4px",
            "&:hover": {
              background: "rgba(12, 128, 243, 0.1)",
            },
          }}
          onClick={downloadSelectedReportsHandler}
        >
          <Typography variant="body2" fontSize="13px" color="#0C80F3">
            Download Selected
          </Typography>
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

export default DownloadSelectedReportButton;
