export const page_size = 10;

export const OPEN_TIME_MODAL = "OPEN_TIME_MODAL";
export const CLOSE_TIME_MODAL = "CLOSE_TIME_MODAL";
export const SET_PATIENT_ID = "SET_PATIENT_ID";
export const SET_START_TIME = "SET_START_TIME";
export const SET_END_TIME = "SET_END_TIME";
export const SET_TIME_CHANGED = "SET_TIME_CHANGED";
export const SET_LOG_TYPE_VALUE = "SET_LOG_TYPE_VALUE";
export const SET_DATE_VALUE = "SET_DATE_VALUE";
export const SET_NOTES_VALUE = "SET_NOTES_VALUE";
export const SET_COMM_INTERACTION = "SET_COMM_INTERACTION";
export const SET_IS_RUNNING = "SET_IS_RUNNING";
export const SET_IS_PAUSED = "SET_IS_PAUSED";
export const SET_IS_STOPPED = "SET_IS_STOPPED";
export const SET_GO_BACK = "SET_GO_BACK";
export const SET_PREVIOUS_PAGE = "SET_PREVIOUS_PAGE";
export const SET_ELAPSED_TIME = "SET_ELAPSED_TIME";
export const SET_PAUSED_TIME = "SET_PAUSED_TIME";
export const SET_PAUSED_START_TIME = "SET_PAUSED_START_TIME";
export const SET_NAVIGATE_ITEM = "SET_NAVIGATE_ITEM";
export const RESET_TIMER_ITEMS = "RESET_TIMER_ITEMS";
export const SET_CLICKED_LOGOUT = "SET_CLICKED_LOGOUT";
export const CHANGED_AGENCY_ID = "CHANGED_AGENCY_ID";
export const SET_GO_NEXT_PATIENT = "SET_GO_NEXT_PATIENT";

export const DISABLE_APP_ACCESS_REQUEST = "DISABLE_APP_ACCESS_REQUEST";
export const DISABLE_APP_ACCESS_SUCCESS = "DISABLE_APP_ACCESS_SUCCESS";
export const DISABLE_APP_ACCESS_FAIL = "DISABLE_APP_ACCESS_FAIL";
export const RESET_APP_ACCESS_STATE = "RESET_APP_ACCESS_STATE";

export const ENABLE_APP_ACCESS_REQUEST = "ENABLE_APP_ACCESS_REQUEST";
export const ENABLE_APP_ACCESS_SUCCESS = "ENABLE_APP_ACCESS_SUCCESS";
export const ENABLE_APP_ACCESS_FAIL = "ENABLE_APP_ACCESS_FAIL";

export const GET_UNREVIEWED_PATIENTS_REQUEST =
  "GET_UNREVIEWED_PATIENTS_REQUEST";
export const GET_UNREVIEWED_PATIENTS_SUCCESS =
  "GET_UNREVIEWED_PATIENTS_SUCCESS";
export const GET_UNREVIEWED_PATIENTS_FAIL = "GET_UNREVIEWED_PATIENTS_FAIL";
export const RESET_UNREVIEWED_PATIENTS = "RESET_UNREVIEWED_PATIENTS";

export const GET_REVIEWED_PATIENTS_REQUEST = "GET_REVIEWED_PATIENTS_REQUEST";
export const GET_REVIEWED_PATIENTS_SUCCESS = "GET_REVIEWED_PATIENTS_SUCCESS";
export const GET_REVIEWED_PATIENTS_FAIL = "GET_REVIEWED_PATIENTS_FAIL";
export const RESET_REVIEWED_PATIENTS = "RESET_REVIEWED_PATIENTS";

export const MARK_PATIENT_REVIEWED_REQUEST = "MARK_PATIENT_REVIEWED_REQUEST";
export const MARK_PATIENT_REVIEWED_SUCCESS = "MARK_PATIENT_REVIEWED_SUCCESS";
export const MARK_PATIENT_REVIEWED_FAIL = "MARK_PATIENT_REVIEWED_FAIL";
export const RESET_MARK_PATIENT_REVIEWED = "RESET_MARK_PATIENT_REVIEWED";

export const CURRENT_PATIENT_INDEX = "CURRENT_PATIENT_INDEX";
export const SHOW_REVIEWED_SCREEN = "SHOW_REVIEWED_SCREEN";
export const IS_ACTION_PERFORMED = "IS_ACTION_PERFORMED";

export const ADD_BULK_NOTES_REQUEST = "ADD_BULK_NOTES_REQUEST";
export const ADD_BULK_NOTES_SUCCESS = "ADD_BULK_NOTES_SUCCESS";
export const ADD_BULK_NOTES_FAIL = "ADD_BULK_NOTES_FAIL";
export const RESET_ADD_BULK_NOTES = "RESET_ADD_BULK_NOTES";

export const SEND_BULK_MESSAGES_REQUEST = "SEND_BULK_MESSAGES_REQUEST";
export const SEND_BULK_MESSAGES_SUCCESS = "SEND_BULK_MESSAGES_SUCCESS";
export const SEND_BULK_MESSAGES_FAIL = "SEND_BULK_MESSAGES_FAIL";
export const RESET_SEND_BULK_MESSAGES = "RESET_SEND_BULK_MESSAGES";

export const USER_FEED_NOTIFICATIONS_REQUEST =
  "USER_FEED_NOTIFICATIONS_REQUEST";
export const USER_FEED_NOTIFICATIONS_SUCCESS =
  "USER_FEED_NOTIFICATIONS_SUCCESS";
export const USER_FEED_NOTIFICATIONS_FAIL = "USER_FEED_NOTIFICATIONS_FAIL";
export const RESET_USER_FEED_NOTIFICATIONS = "RESET_USER_FEED_NOTIFICATIONS";

export const SEND_INACTIVE_EMAIL_REQUEST = "SEND_INACTIVE_EMAIL_REQUEST";
export const SEND_INACTIVE_EMAIL_SUCCESS = "SEND_INACTIVE_EMAIL_SUCCESS";
export const SEND_INACTIVE_EMAIL_FAIL = "SEND_INACTIVE_EMAIL_FAIL";
export const RESET_SEND_INACTIVE_EMAIL = "RESET_SEND_INACTIVE_EMAIL";

export const SEND_PENDING_EMAIL_REQUEST = "SEND_PENDING_EMAIL_REQUEST";
export const SEND_PENDING_EMAIL_SUCCESS = "SEND_PENDING_EMAIL_SUCCESS";
export const SEND_PENDING_EMAIL_FAIL = "SEND_PENDING_EMAIL_FAIL";
export const RESET_SEND_PENDING_EMAIL = "RESET_SEND_PENDING_EMAIL";

export const ALL_NOTIFICATIONS_REQUEST = "ALL_NOTIFICATIONS_REQUEST";
export const ALL_NOTIFICATIONS_SUCCESS = "ALL_NOTIFICATIONS_SUCCESS";
export const ALL_NOTIFICATIONS_FAIL = "ALL_NOTIFICATIONS_FAIL";
export const RESET_ALL_NOTIFICATIONS = "RESET_ALL_NOTIFICATIONS";

export const CHAT_NOTIFICATIONS_REQUEST = "CHAT_NOTIFICATIONS_REQUEST";
export const CHAT_NOTIFICATIONS_SUCCESS = "CHAT_NOTIFICATIONS_SUCCESS";
export const CHAT_NOTIFICATIONS_FAIL = "CHAT_NOTIFICATIONS_FAIL";
export const RESET_CHAT_NOTIFICATIONS = "RESET_CHAT_NOTIFICATIONS";

export const ALERT_NOTIFICATIONS_REQUEST = "ALERT_NOTIFICATIONS_REQUEST";
export const ALERT_NOTIFICATIONS_SUCCESS = "ALERT_NOTIFICATIONS_SUCCESS";
export const ALERT_NOTIFICATIONS_FAIL = "ALERT_NOTIFICATIONS_FAIL";
export const RESET_ALERT_NOTIFICATIONS = "RESET_ALERT_NOTIFICATIONS";

export const MARK_NOTIFICATIONS_REQUEST = "MARK_NOTIFICATIONS_REQUEST";
export const MARK_NOTIFICATIONS_SUCCESS = "MARK_NOTIFICATIONS_SUCCESS";
export const MARK_NOTIFICATIONS_FAIL = "MARK_NOTIFICATIONS_FAIL";
export const RESET_MARK_NOTIFICATIONS = "RESET_MARK_NOTIFICATIONS";

export const USER_ACTIVITY_NOTIFICATIONS_REQUEST =
  "USER_ACTIVITY_NOTIFICATIONS_REQUEST";
export const USER_ACTIVITY_NOTIFICATIONS_SUCCESS =
  "USER_ACTIVITY_NOTIFICATIONS_SUCCESS";
export const USER_ACTIVITY_NOTIFICATIONS_FAIL =
  "USER_ACTIVITY_NOTIFICATIONS_FAIL";
export const RESET_USER_ACTIVITY_NOTIFICATIONS =
  "RESET_USER_ACTIVITY_NOTIFICATIONS";

export const GET_BODY_PARTS_REQUEST = "GET_BODY_PARTS_REQUEST";
export const GET_BODY_PARTS_SUCCESS = "GET_BODY_PARTS_SUCCESS";
export const GET_BODY_PARTS_FAIL = "GET_BODY_PARTS_FAIL";
export const RESET_BODY_PARTS = "RESET_BODY_PARTS";

export const GET_CASE_SUMMARY_LINK_REQUEST = "GET_CASE_SUMMARY_LINK_REQUEST";
export const GET_CASE_SUMMARY_LINK_SUCCESS = "GET_CASE_SUMMARY_LINK_SUCCESS";
export const GET_CASE_SUMMARY_LINK_FAIL = "GET_CASE_SUMMARY_LINK_FAIL";
export const RESET_CASE_SUMMARY_LINK = "RESET_CASE_SUMMARY_LINK";

export const SEND_PAIN_LOG_REMINDER_REQUEST = "SEND_PAIN_LOG_REMINDER_REQUEST";
export const SEND_PAIN_LOG_REMINDER_SUCCESS = "SEND_PAIN_LOG_REMINDER_SUCCESS";
export const SEND_PAIN_LOG_REMINDER_FAIL = "GET_SEND_PAIN_LOG_REMINDER_FAIL";
export const RESET_SEND_PAIN_LOG_REMINDER = "RESET_SEND_PAIN_LOG_REMINDER";

export const GET_EXPENSE_DETAIL_REQUEST = "GET_EXPENSE_DETAIL_REQUEST";
export const GET_EXPENSE_DETAIL_SUCCESS = "GET_EXPENSE_DETAIL_SUCCESS";
export const GET_EXPENSE_DETAIL_FAIL = "GET_GET_EXPENSE_DETAIL_FAIL";
export const RESET_GET_EXPENSE_DETAIL = "RESET_GET_EXPENSE_DETAIL";

export const CREATE_CUSTOM_NOTE_REQUEST = "CREATE_CUSTOM_NOTE_REQUEST";
export const CREATE_CUSTOM_NOTE_SUCCESS = "CREATE_CUSTOM_NOTE_SUCCESS";
export const CREATE_CUSTOM_NOTE_FAIL = "GET_CREATE_CUSTOM_NOTE_FAIL";
export const RESET_CREATE_CUSTOM_NOTE = "RESET_CREATE_CUSTOM_NOTE";

export const GET_MOBILE_STATUS_REQUEST = "GET_MOBILE_STATUS_REQUEST";
export const GET_MOBILE_STATUS_SUCCESS = "GET_MOBILE_STATUS_SUCCESS";
export const GET_MOBILE_STATUS_FAIL = "GET_GET_MOBILE_STATUS_FAIL";
export const RESET_GET_MOBILE_STATUS = "RESET_GET_MOBILE_STATUS";
