import { Box, Checkbox, Chip, IconButton, Typography } from "@mui/material";
import React from "react";
import { EvidenceDownloadIcon } from "../../assets/constants";
import { shortDateFormat } from "../../utils/helpers";

const EvidenceReportCard = ({
  date,
  downloadUrl,
  selectedReports,
  onToggleReport,
  id,
  item,
  downloadReportHandler,
  patientName,
}) => {
  const isChecked = selectedReports.some((item) => item?.id === id);

  const fullName = `${patientName} - ${item?.month} ${item?.year}`;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      padding="8px 12px"
      borderRadius="8px"
      bgcolor={isChecked ? "rgba(12, 128, 243, 0.05)" : "#FCFCFC"}
    >
      <IconButton>
        <Checkbox
          sx={{ padding: 0 }}
          checked={isChecked}
          onChange={() => onToggleReport(item)}
        />
      </IconButton>
      <Box flex="1">
        <Typography
          minWidth="282px"
          variant="body2"
          color="rgba(0, 0, 0, 0.87)"
        >
          {fullName}
        </Typography>
      </Box>
      <Box flex="1">
        <Chip
          label="Auto Generated"
          size="small"
          sx={{
            marginLeft: 0,
            padding: "4px",
            color: "#3DC583",
            background: "rgba(61, 197, 131, 0.15)",
          }}
        />
      </Box>
      <Box flex="1">
        <Typography variant="body2" color="secondary">
          {shortDateFormat(date)}
        </Typography>
      </Box>
      <Box>
        <IconButton
          disabled={selectedReports?.length ? true : false}
          onClick={() => downloadReportHandler(item)}
        >
          <EvidenceDownloadIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default EvidenceReportCard;
