import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { TimeLineClockIcon } from "../../assets/constants";
import TimelineCheckedIcon from "../../assets/images/timeline-checked-icon.png";
import Ellipse from "../../icons/Ellipse";
import { dateTimeFormat } from "../../utils/helpers";
import dayjs from "dayjs";

const EnrolmentJourneyCard = ({ patientDetail, resendNum, resendCredsArr }) => {
  const [seeMoreClicked, setSeeMoreClicked] = useState(false);

  const seeMoreHandler = () => {
    setSeeMoreClicked((prev) => !prev);
  };

  const sortedInvitations = useMemo(() => {
    return [...(resendCredsArr || [])]?.sort(
      (a, b) => dayjs(b.created_at)?.valueOf() - dayjs(a.created_at)?.valueOf()
    );
  }, [resendCredsArr]);

  const displayedInvitations = seeMoreClicked
    ? sortedInvitations
    : [sortedInvitations[0]];

  return (
    <Stack gap="16px" padding="16px" bgcolor="#F4F6F8">
      <Typography variant="body1" color="#212121" fontWeight="500">
        Enrolment Journey
      </Typography>
      <Timeline
        sx={{
          padding: "0",
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator
            sx={{ ".MuiTimelineConnector-root": { background: "#E9ECEE" } }}
          >
            <Box
              my="2px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {patientDetail?.patient?.patient_invitations?.length ||
              resendNum > 0 ? (
                <Box width="20px" height="20px">
                  <img
                    width="100%"
                    src={TimelineCheckedIcon}
                    alt="checked icon"
                  />
                </Box>
              ) : (
                <TimeLineClockIcon />
              )}
            </Box>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ padding: "0 10px 16px" }}>
            <Typography
              variant="body2"
              fontWeight={500}
              color={
                resendNum > 0 ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.38)"
              }
            >
              Mobile app Credentials
            </Typography>

            {resendNum > 0 ? (
              <Stack>
                <Typography variant="body2" color="#0D0D0D">
                  Sent {resendNum} {resendNum === 1 ? "time" : "times"}{" "}
                  <Box
                    component="span"
                    color="#0C80F3"
                    onClick={seeMoreHandler}
                    sx={{ cursor: "pointer" }}
                  >
                    - {seeMoreClicked ? "See less" : "See all"}
                  </Box>
                </Typography>
                {displayedInvitations?.map((item, index) => (
                  <Box display="flex" alignItems="center" gap="4px" key={index}>
                    <Typography
                      variant="body2"
                      color="rgba(0, 0, 0, 0.38)"
                      fontWeight={500}
                    >
                      Done
                    </Typography>
                    <Ellipse />

                    <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
                      {" "}
                      {dateTimeFormat(item?.created_at)}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            ) : (
              <Typography
                variant="body2"
                fontWeight={500}
                color="rgba(0, 0, 0, 0.38)"
              >
                Pending{" "}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator
            sx={{ ".MuiTimelineConnector-root": { background: "#E9ECEE" } }}
          >
            <Box
              my="2px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {patientDetail?.patient?.equipment_setup ? (
                <Box width="20px" height="20px">
                  <img
                    width="100%"
                    src={TimelineCheckedIcon}
                    alt="checked icon"
                  />
                </Box>
              ) : (
                <TimeLineClockIcon />
              )}
            </Box>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ padding: "0 10px 16px" }}>
            <Typography
              variant="body2"
              fontWeight={500}
              color={
                patientDetail?.patient?.equipment_setup
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(0, 0, 0, 0.38)"
              }
            >
              Patient logged in the app{" "}
            </Typography>
            {patientDetail?.patient?.equipment_setup ? (
              <Box display="flex" alignItems="center" gap="4px">
                <Typography
                  variant="body2"
                  color="rgba(0, 0, 0, 0.38)"
                  fontWeight={500}
                >
                  Done
                </Typography>
                <Ellipse />
                <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
                  {" "}
                  {dateTimeFormat(patientDetail?.patient?.equipment_setup_on)}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="body2"
                fontWeight={500}
                color="rgba(0, 0, 0, 0.38)"
              >
                Pending{" "}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator
            sx={{ ".MuiTimelineConnector-root": { background: "#E9ECEE" } }}
          >
            <Box
              my="2px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {patientDetail?.patient?.tracking_consent_given ? (
                <Box width="20px" height="20px">
                  <img
                    width="100%"
                    src={TimelineCheckedIcon}
                    alt="checked icon"
                  />
                </Box>
              ) : (
                <TimeLineClockIcon />
              )}
            </Box>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ padding: "0 10px 16px" }}>
            <Typography
              variant="body2"
              fontWeight={500}
              color={
                patientDetail?.patient?.tracking_consent_given
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(0, 0, 0, 0.38)"
              }
            >
              Patient consented for RTM{" "}
            </Typography>
            {patientDetail?.patient?.tracking_consent_given ? (
              <Box display="flex" alignItems="center" gap="4px">
                <Typography
                  variant="body2"
                  color="rgba(0, 0, 0, 0.38)"
                  fontWeight={500}
                >
                  Done
                </Typography>
                <Ellipse />

                <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
                  {" "}
                  {dateTimeFormat(patientDetail?.patient?.consent_taken_on)}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="body2"
                fontWeight={500}
                color="rgba(0, 0, 0, 0.38)"
              >
                Pending{" "}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <Box
              my="2px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {patientDetail?.patient?.education_given ? (
                <Box width="20px" height="20px">
                  <img
                    width="100%"
                    src={TimelineCheckedIcon}
                    alt="checked icon"
                  />
                </Box>
              ) : (
                <TimeLineClockIcon />
              )}
            </Box>
          </TimelineSeparator>
          <TimelineContent sx={{ padding: "0 10px 16px" }}>
            <Typography
              variant="body2"
              fontWeight={500}
              color={
                patientDetail?.patient?.education_given
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(0, 0, 0, 0.38)"
              }
            >
              Patient device/Mobile App Education
            </Typography>
            {patientDetail?.patient?.education_given ? (
              <Box display="flex" alignItems="center" gap="4px">
                <Typography
                  variant="body2"
                  color="rgba(0, 0, 0, 0.38)"
                  fontWeight={500}
                >
                  Done
                </Typography>
                <Ellipse />

                <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
                  {" "}
                  {dateTimeFormat(patientDetail?.patient?.education_given_on)}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="body2"
                fontWeight={500}
                color="rgba(0, 0, 0, 0.38)"
              >
                Pending{" "}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Stack>
  );
};

export default EnrolmentJourneyCard;
