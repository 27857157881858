import { BASE_URL } from "..";
import {
  CREATE_BULK_TIME_FAIL,
  CREATE_BULK_TIME_REQUEST,
  CREATE_BULK_TIME_SUCCESS,
  CREATE_TIME_ENTRY_FAIL,
  CREATE_TIME_ENTRY_REQUEST,
  CREATE_TIME_ENTRY_SUCCESS,
  DELETE_TIME_ENTRY_FAIL,
  DELETE_TIME_ENTRY_REQUEST,
  DELETE_TIME_ENTRY_SUCCESS,
  GET_NOTES_FAIL,
  GET_NOTES_REQUEST,
  GET_NOTES_SUCCESS,
  GET_TIME_ENTRY_FAIL,
  GET_TIME_ENTRY_REQUEST,
  GET_TIME_ENTRY_SUCCESS,
  RESET_CREATE_BULK_TIME,
  RESET_CREATE_TIME_ENTRY,
  RESET_DELETE_TIME_ENTRY,
  RESET_GET_NOTES,
  RESET_GET_TIME_ENTRY,
  RESET_UPDATE_TIME_ENTRY,
  UPDATE_TIME_ENTRY_FAIL,
  UPDATE_TIME_ENTRY_REQUEST,
  UPDATE_TIME_ENTRY_SUCCESS,
} from "../../assets/constants/timeEntryConstants";
import Cookies from "js-cookie";
import axios from "axios";

// ================== CREATE TIME ENTRY ACTIONS  =========================
export const createTimeEntryFunc = (timeEntryObj) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TIME_ENTRY_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/time-entry/create`,
        timeEntryObj,
        config
      );

      if (!response?.data?.ok) {
        throw response?.data;
      }

      dispatch({
        type: CREATE_TIME_ENTRY_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    if (error && typeof error === "object") {
      const errorMessage = error.msg || "An error occurred";
      const overlapEntries = error.overlap_entries || [];

      dispatch({
        type: CREATE_TIME_ENTRY_FAIL,
        payload: {
          message: errorMessage,
          overlapEntries: overlapEntries,
        },
      });
    } else {
      dispatch({
        type: CREATE_TIME_ENTRY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
};

export const resetCreateTimeEntry = () => async (dispatch) => {
  dispatch({ type: RESET_CREATE_TIME_ENTRY });
};

// ================== CREATE BULK TIME ENTRY ACTIONS  =========================
export const createBulkTimeFunc = (timeEntryObj) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_BULK_TIME_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/time-entry/create-bulk`,
        timeEntryObj,
        config
      );

      if (!response?.data?.ok) {
        throw response?.data;
      }

      dispatch({
        type: CREATE_BULK_TIME_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_BULK_TIME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetBulkTimeFunc = () => async (dispatch) => {
  dispatch({ type: RESET_CREATE_BULK_TIME });
};

// ================== GET TIME ENTRY ACTIONS  =========================
export const getTimeEntryFunc =
  (patientId, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: GET_TIME_ENTRY_REQUEST });

      const token = Cookies.get("token");
      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/time-entry/get?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_TIME_ENTRY_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_TIME_ENTRY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetGetTimeEntry = () => async (dispatch) => {
  dispatch({ type: RESET_GET_TIME_ENTRY });
};

// ================== DELETE TIME ENTRY ACTIONS  =========================
export const deleteTimeEntryFunc = (deleteTimeEntryObj) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TIME_ENTRY_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/time-entry/delete`,
        deleteTimeEntryObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: DELETE_TIME_ENTRY_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_TIME_ENTRY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetDeleteTimeEntry = () => async (dispatch) => {
  dispatch({ type: RESET_DELETE_TIME_ENTRY });
};

// ================== UPDATE TIME ENTRY ACTIONS  =========================
export const updateTimeEntryFunc = (updateTimeEntryObj) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TIME_ENTRY_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/time-entry/update`,
        updateTimeEntryObj,
        config
      );

      if (!response?.data?.ok) {
        throw response?.data;
      }

      dispatch({
        type: UPDATE_TIME_ENTRY_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    if (error && typeof error === "object") {
      const errorMessage = error.msg || "An error occurred";
      const overlapEntries = error.overlap_entries || [];

      dispatch({
        type: UPDATE_TIME_ENTRY_FAIL,
        payload: {
          message: errorMessage,
          overlapEntries: overlapEntries,
        },
      });
    } else {
      dispatch({
        type: UPDATE_TIME_ENTRY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
};

export const resetUpdateTimeEntry = () => async (dispatch) => {
  dispatch({ type: RESET_UPDATE_TIME_ENTRY });
};

// ================== GET NOTES ACTIONS  =========================
export const getNotesFunc = (patientId) => async (dispatch) => {
  try {
    dispatch({ type: GET_NOTES_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/patient/get-notes?agency_id=${agencyId}&patient_id=${patientId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_NOTES_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_NOTES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetGetNotes = () => async (dispatch) => {
  dispatch({ type: RESET_GET_NOTES });
};
