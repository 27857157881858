import React from "react";
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { timeNotesOptions } from "../../utils/helpers";

const AddBulkNotesModalContent = ({
  reviewedPatients,
  minuteValue,
  setMinuteValue,
  checkedTimeNote,
  setCheckedTimeNote,
  bulkNoteValue,
  setBulkNoteValue,
}) => {
  return (
    <Stack gap="24px" height="100%">
      <Alert severity="info" sx={{ color: "#0288D1" }}>
        This note will be added for{" "}
        <Box
          component="span"
          fontWeight={500}
          // sx={{ textDecoration: "underline" }}
        >
          {" "}
          {reviewedPatients?.length} {reviewedPatients?.length == 1 ? 'patient' : 'patients'}
        </Box>
      </Alert>
      <TextField
        multiline
        value={bulkNoteValue}
        onChange={(e) => setBulkNoteValue(e.target.value)}
        variant="standard"
        InputProps={{
          style: {
            minHeight: "320px",
            overflow: "auto", // Prevent scrollbar
            alignItems: "flex-start",
          },
        }}
        sx={{
          flex: "1",
          border: "1px solid #EBEBEB",
          borderRadius: "8px",
          background: "#F4F6F8",
          padding: "16px",
          "& .MuiInputBase-input": {
            minHeight: "320px", // Approx. 20 lines based on font size
            lineHeight: "1.5", // Adjust line height for better spacing
            display: "flex",
            alignItems: "flex-start",
          },
          "& .MuiInput-underline:before": {
            borderBottom: "none",
            height: "100%",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${e.target.scrollHeight}px`;
        }}
      />

      <Stack gap="6px">
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedTimeNote}
              onChange={(e) => setCheckedTimeNote(e.target.checked)}
            />
          }
          label="log time for adding this note"
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          padding="0 8px 0 38px"
        >
          <Typography
            variant="body2"
            color="secondary"
            component="label"
            htmlFor="time_notes"
          >
            Select the number of minutes you want to add for every patient
          </Typography>

          <CustomSelectFilter
            disabled={!checkedTimeNote}
            menuItems={timeNotesOptions}
            changeSelectValueHandler={(e) => setMinuteValue(e.target.value)}
            selectValue={minuteValue}
            id="export-report-month"
            minWidth={145}
            size="small"
            //   fontSize="12px"
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default AddBulkNotesModalContent;
