import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";

// import Style from "./style";
import Team from "./Components/Team";
import General from "./Components/General";
import {
  GeneralTabIcon,
  SettingPatientIcon,
  TeamTabIcon,
} from "../../assets/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUpdateAgencyFunc,
  getAgencyDetailFunc,
} from "../../actions/Agency";
import { segmentTrack } from "../../utils/helpers/tracking_utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetAllCases } from "../../actions/Cases";
import styled from "@emotion/styled";
import Patients from "./Components/Patients";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "transparent",
  // boxShadow: 'none',
  padding: "0",
  zIndex: "1",
  position: "relative",
  borderRadius: "0",
  minHeight: "30px !important",
  width: "max-content",
  "& .MuiTabScrollButton-root": {
    color: "rgba(109, 125, 139, 1)",
    borderBottom: "3px solid #CCD2E3",
    opacity: "1 !important",
  },
  "& .MuiTabScrollButton-root.Mui-disabled": {
    color: "rgba(109, 125, 139, .3)",
  },
  "& .MuiTabs-scroller": {
    "& .MuiTabs-flexContainer": {
      justifyContent: "flex-start",
      "& .MuiTab-root": {
        minHeight: "30px",
        width: "max-content",
        maxWidth: "max-content",
        minWidth: "max-content",
        textTransform: "capitalize",
        color: "rgba(109, 125, 139, 1)",
        opacity: "1",
        borderBottom: "0",
        fontSize: "13px",
        padding: ".8rem 1.4rem",
        margin: "0",
      },
      "& .Mui-selected": {
        color: theme.palette.primary.main,
        zIndex: 1,
        "& svg": {
          "& path": {
            stroke: theme.palette.primary.main,
          },
        },
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, height: "100%" }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Settings() {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams?.get("tab") || "general";

  const navigate = useNavigate();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const { agencyDetail } = getAgencyDetail;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "settings",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  useEffect(() => {
    if (selectedTab === "general") {
      setActiveTab(0);
    } else if (selectedTab === "patients") {
      setActiveTab(1);
    } else if (selectedTab === "team") {
      setActiveTab(2);
    }
  }, [selectedTab]);

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      searchParams.set("tab", "general");
    } else if (newValue === 1) {
      searchParams.set("tab", "patients");
    } else {
      searchParams.set("tab", "team");
    }

    navigate({ search: `?${searchParams.toString()}` });
    setActiveTab(newValue);
    dispatch(resetUpdateAgencyFunc());
    dispatch(resetAllCases());
  };

  const allTabs = [
    {
      icon: <GeneralTabIcon />,
      title: "General",
      component: <General />,
    },
    {
      icon: <SettingPatientIcon />,
      title: "Patients",
      component: <Patients />,
    },
    {
      icon: <TeamTabIcon />,
      title: "Team",
      component: <Team />,
    },
    // {
    //   icon: <CasesTabIcon />,
    //   title: "Case",
    //   component: <Cases />,
    // },

    // {
    //   icon: <BillingIcon />,
    //   title: "Billing",
    //   component: <Billing />,
    // },
  ];
  return (
    <Stack padding="20px 40px" paddingBottom="40px">
      <Typography variant="h5">Practice Settings</Typography>
      <Stack pt={2}>
        <StyledTabs
          value={activeTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {allTabs?.map(
            (tab, key) =>
              tab.title && (
                <Tab
                  iconPosition="start"
                  icon={tab.icon}
                  label={tab.title}
                  key={tab.title}
                  {...a11yProps(key++)}
                />
              )
          )}
        </StyledTabs>
        <TabPanel
          value={activeTab}
          index={activeTab}
          //   className={clsx(classes.tab__panel)}
        >
          {allTabs[activeTab].component}
        </TabPanel>
      </Stack>
    </Stack>
  );
}
