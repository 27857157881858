import React, { useState, useEffect } from "react";
import AddClientStep from "../../../components/NewCaseSteps/AddClientStep";
import AddCaseStep from "../../../components/NewCaseSteps/AddCaseStep";
import AssignTeamMemberStep from "../../../components/NewCaseSteps/AssignTeamMemberStep";
import { useDispatch, useSelector } from "react-redux";
import {
  resetNewClientFunc,
  resetUpdateClientFunc,
  getAgencyDetailFunc,
  checkPatientPhoneFunc,
  resetPatientPhoneFunc,
} from "../../../actions/Agency";
import AlertDialog from "../../../components/AlertDialog";
import DialogTitleHeader from "../../../common/DialogTitleHeader";
import { segmentTrack } from "../../../utils/helpers/tracking_utils";

import AlertMessage from "../../../common/AlertMessage";

const NewPatientForm = ({
  handleClose,
  reEnroll = false,
  selectedPatient,
  setOpenReEnrollMessage,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [goBack, setGoBack] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const [openModal, setOpenModal] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [showPatientMessage, setShowPatientMessage] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [isFilledValues, setIsFilledValues] = useState(false);

  const steps = reEnroll
    ? ["Insurance Details", "Consent Acknowledgements"]
    : ["Patient Details", "Insurance Details", "Consent Acknowledgements"];

  const [openErrModal, setOpenErrModal] = useState(false);

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: null,
    address: "",
    contactNumber: "",
    state: null,
    city: "",
    zipCode: "",
    notes: "",

    primary_insurance_name: "",
    secondary_insurance_name: "",
    policy_number: "",
    group_number: "",
    primary_policy_holder_name: "",
    primary_dob: null,
    policy_relation: "",
    primary_contact: "",
    primary_email: "",
    medical_notes: "",

    sendCredentials: true,
  });

  const [emailError, setEmailError] = useState(false);

  const [diagnosisCodes, setDiagnosisCodes] = useState([]);

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const checkPatientPhone = useSelector((state) => state.checkPatientPhone);
  const {
    loading: loadingPatientPhone,
    error: errorPatientPhone,
    patientPhoneExists,
  } = checkPatientPhone;

  const addDiagnosisCode = useSelector((state) => state.addDiagnosisCode);
  const {
    loading: loadingDiagnosisCode,
    error: errorDiagnosisCode,
    diagnosisCodeAdded,
  } = addDiagnosisCode;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const addNewClient = useSelector((state) => state.addNewClient);
  const {
    loading: loadingNewClient,
    error: errorNewClient,
    newClient,
  } = addNewClient;

  const updateClient = useSelector((state) => state.updateClient);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    updatedClient,
  } = updateClient;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "new_case_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  // STEPPER FUNCTIONS
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    setIsFilledValues(true);
  };

  const goPreviousStepFormHandler = async () => {
    setGoBack(true);
    dispatch(resetUpdateClientFunc());
    dispatch(resetNewClientFunc());
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const submitNewCaseDataHandler = () => {
    handleNext();
  };

  const invitePatientHandler = () => {
    // dispatch(checkPatientEmailFunc(formValues?.email));
    dispatch(checkPatientPhoneFunc(formValues?.contactNumber));

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "client_added_for_new_case",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  useEffect(() => {
    if (patientPhoneExists?.ok && patientPhoneExists?.contact_number_exists) {
      setShowPatientMessage(true);
      dispatch(resetPatientPhoneFunc());
    } else if (
      patientPhoneExists?.ok &&
      !patientPhoneExists?.contact_number_exists
    ) {
      dispatch(resetPatientPhoneFunc());
      handleNext();
    }
  }, [patientPhoneExists]);

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  const closeFormHandler = () => {
    handleClose();
  };

  const closeFormDialogHandler = () => {
    setOpenModal(true);
    setModalContentData({
      title: "Are you sure?",
      subtitle: "All the progress you made will be lost.",
    });
  };

  function getStepContent(step) {
    const adjustedStep = reEnroll ? step + 1 : step;

    switch (adjustedStep) {
      case 0:
        return (
          <AddClientStep
            activeStep={activeStep}
            steps={steps}
            onSubmitPatient={invitePatientHandler}
            showPatientMessage={showPatientMessage}
            setShowPatientMessage={setShowPatientMessage}
            formValues={formValues}
            setFormValues={setFormValues}
            goBack={goBack}
            emailError={emailError}
            setEmailError={setEmailError}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <AddCaseStep
            activeStep={activeStep}
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmitNewCase={submitNewCaseDataHandler}
            steps={steps}
            loadingUpdate={loadingUpdate}
            loadingDiagnosisCode={loadingDiagnosisCode}
            diagnosisCodes={diagnosisCodes}
            setDiagnosisCodes={setDiagnosisCodes}
            onHandleBack={goPreviousStepFormHandler}
            reEnroll={reEnroll}
            selectedPatient={selectedPatient}
            isFilledValues={isFilledValues}
          />
        );
      case 2:
        return (
          <AssignTeamMemberStep
            activeStep={activeStep}
            formValues={formValues}
            reEnroll={reEnroll}
            setActiveStep={setActiveStep}
            steps={steps}
            closeStepperForm={handleClose}
            diagnosisCodes={diagnosisCodes}
            setIsFilledValues={setIsFilledValues}
            selectedPatient={selectedPatient}
            setOpenReEnrollMessage={setOpenReEnrollMessage}
            // newPatientDetail={newPatientDetail}
          />
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <>
      {openModal && (
        <AlertDialog
          openModal={openModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          formSubmitFunction={closeFormHandler}
          color="error"
        />
      )}

      {!loadingNewClient && errorNewClient && (
        <AlertMessage
          message={errorNewClient}
          severity="error"
          open={openErrModal}
          handleClose={() => setOpenErrModal(false)}
        />
      )}

      {/* DIALOG TITLE HEADER */}
      <DialogTitleHeader
        headerTitle={reEnroll ? "Re-enroll Patient" : "New Patient"}
        activeStep={activeStep}
        steps={steps}
        isStepSkipped={isStepSkipped}
        closeFormDialogHandler={closeFormDialogHandler}
      />

      {activeStep === steps.length ? (
        <React.Fragment></React.Fragment>
      ) : (
        getStepContent(activeStep)
      )}
    </>
  );
};

export default NewPatientForm;
