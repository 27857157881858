import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BgBox from "../../common/BgBox";
import MobileAppImage from "../../assets/images/mobile_app_image.png";
import MccLogo from "../../assets/images/mcc-logo.png";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import GooglePlayImage from "../../assets/images/google_play_image.png";
import { useDispatch } from "react-redux";
import { addScanCodeFunc } from "../../actions/Agency";
import VitatraxFooter from "../../components/VitatraxFooter";
import { AppStoreImage } from "../../assets/constants";
import CommonStoreButton from "../../components/CommonStoreButton";

const AgencyQrCode = () => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const agencyId = searchParams.get("agency_id");
  const agencyName = searchParams.get("agency_name");

  useEffect(() => {
    const scanCodeObj = {
      agency_id: agencyId,
    };
    dispatch(addScanCodeFunc(scanCodeObj));
  }, []);

  const practiceDetailsHandler = () => {
    navigate(
      `/patient-details?agency_id=${agencyId}&agency_name=${agencyName}`
    );
  };

  return (
    <BgBox>
      <Box width="83px" height="80px">
        <img src={MccLogo} alt="mcc-logo" width="100%" height="100%" />
      </Box>

      <Box
        component="main"
        bgcolor="#fff"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="32px"
        height="100%"
        width="100%"
        boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
        borderRadius="16px"
        padding="92px 24px"
        sx={{
          [theme.breakpoints.down("md")]: {
            padding: "24px 24px",
            flexDirection: "column",
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="32px"
        >
          <Box
            width="408px"
            height="437px"
            sx={{
              [theme.breakpoints.down("md")]: {
                height: "388px",
              },
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Box
              component="img"
              width="100%"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  width: "85%",
                },
              }}
              height="100%"
              src={MobileAppImage}
              alt="vitatrax app"
            />
          </Box>
        </Box>

        <Stack gap="16px">
          <Typography
            variant="h1"
            color="#124273"
            fontSize="45px"
            sx={{
              [theme.breakpoints.down("sm")]: {
                fontSize: "38px",
              },
            }}
          >
            {agencyName}
          </Typography>
          <Typography
            variant="h5"
            color="rgba(0, 0, 0, 0.87)"
            fontSize="20px"
            fontWeight={500}
          >
            Follow the steps to get registered with us{" "}
          </Typography>
          <Stack padding="24px 0" gap="8px">
            <Timeline
              sx={{
                padding: "6px 0",
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <Box
                    width="24px"
                    height="24px"
                    borderRadius="50%"
                    bgcolor="#0C80F3"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="caption" color="#fff">
                      1
                    </Typography>
                  </Box>
                  <TimelineConnector sx={{ margin: "4px 0", width: "1px" }} />
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 8px",
                  }}
                >
                  <Typography variant="body2" fontWeight={500}>
                    Download and install our mobile application on your phone.
                  </Typography>
                  {/* <Box
                    display="flex"
                    alignItems="center"
                    gap="12px"
                    marginBottom="16px"
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        flexDirection: "column",
                        margin: "16px 0",
                      },
                    }}
                  >
                    <Box
                      component="a"
                      height="40px"
                      href="https://apps.apple.com/us/app/vitatrax/id6587574641"
                    >
                      <AppStoreImage />
                    </Box>
                    <Box
                      component="a"
                      href="https://play.google.com/store/apps/details?id=com.vitatrax"
                      height="40px"
                    >
                      <img
                        src={GooglePlayImage}
                        width="100%"
                        height="100%"
                        alt="google play"
                      />
                    </Box>
                  </Box> */}
                  <CommonStoreButton />
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                  <Box
                    width="24px"
                    height="24px"
                    borderRadius="50%"
                    bgcolor="#0C80F3"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="caption" color="#fff">
                      2
                    </Typography>
                  </Box>
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    padding: "0 8px",
                  }}
                >
                  <Typography variant="body2" fontWeight={500}>
                    Signup using our Practice ID {agencyId}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
            <Typography variant="body1" fontWeight={400}>
              or fill your details{" "}
              <Typography
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                component="span"
                color="#0C80F3"
                onClick={practiceDetailsHandler}
              >
                {" "}
                here
              </Typography>{" "}
              and directly login to the app.
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <VitatraxFooter />
    </BgBox>
  );
};

export default AgencyQrCode;
