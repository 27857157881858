import React, { useEffect, useState } from "react";
import {
  Box,
  Autocomplete,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import AlertMessage from "../../../common/AlertMessage";
import Cookies from "js-cookie";
import { resetNewPatient } from "../../../actions/Patients";
import {
  clearContact,
  formatPhoneNumber,
  validateEmail,
} from "../../../utils/helpers";
import { LoadingButton } from "@mui/lab";
import { statesWithCities } from "../../../utils/helpers/states_with_cities";
import { checkPatientEmailAction } from "../../../actions/Agency";

const states = Object.keys(statesWithCities);

//   AUTOCOMPLETE FIRST NAME FUNCTION
const filter = createFilterOptions();

const AddClientStep = ({
  activeStep,
  steps,
  formValues,
  setFormValues,
  onSubmitPatient,
  showPatientMessage,
  setShowPatientMessage,
  goBack,
  emailError,
  setEmailError,
  handleNext,
}) => {
  const dispatch = useDispatch();

  const getAllClients = useSelector((state) => state.getAllClientsUnpaginated);
  const { loading, error, clients } = getAllClients;

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [changedEmail, setChangedEmail] = useState(false);
  const [changedPhone, setChangedPhone] = useState(false);
  const updateClient = useSelector((state) => state.updateClient);
  const { loading: loadingUpdate, error: errorUpdate } = updateClient;

  const addNewClient = useSelector((state) => state.addNewClient);
  const {
    loading: loadingNewClient,
    error: errorNewClient,
    newClient,
  } = addNewClient;

  const checkPatientEmail = useSelector((state) => state.checkPatientEmail);
  const { loading: loadingPatientEmail } = checkPatientEmail;

  const checkPatientPhone = useSelector((state) => state.checkPatientPhone);
  const { loading: loadingPatientPhone } = checkPatientPhone;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const [warning, setWarning] = useState("");

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const contactNumberHandler = (e) => {
    setShowPatientMessage(false);
    setChangedPhone(true);
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setFormValues({ ...formValues, contactNumber: formattedNumber });
  };

  const emailChangeHandler = (e) => {
    setChangedEmail(true);
    setShowPatientMessage(false);
    setEmailError(false);

    const emailInputValue = e.target.value;
    setFormValues({ ...formValues, email: emailInputValue });

    const emailIsValid = validateEmail(emailInputValue);
    if (emailInputValue && !emailIsValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    setShowPatientMessage(false);
  };

  // SUBMIT  FORM
  const submitNewPatientFormHandler = async (e) => {
    e.preventDefault();

    dispatch(resetNewPatient());

    if (!formValues.firstName) {
      setWarning("Please enter the first name");
      setOpen(true);
      return;
    }
    // if (!formValues.email || emailError) {
    //   setWarning("Please enter the valid email address");
    //   setOpen(true);
    //   return;
    // }

    changedPhone ? onSubmitPatient() : handleNext();
  };

  return (
    <>
      <DialogContent sx={{ padding: "32px !important" }}>
        {!loadingUpdate && errorUpdate && (
          <AlertMessage
            message={errorUpdate}
            severity="error"
            open={open}
            handleClose={handleClose}
          />
        )}
        {!loadingNewClient && errorNewClient && (
          <AlertMessage
            message={errorNewClient}
            severity="error"
            open={open}
            handleClose={handleClose}
          />
        )}
        {warning && (
          <AlertMessage
            message={warning}
            severity="error"
            open={open}
            handleClose={handleClose}
          />
        )}
        <Stack gap="32px">
          <Stack gap="8px">
            <Typography
              variant="h6"
              fontSize="16px"
              color="secondary"
              textTransform="uppercase"
            >
              Basic information{" "}
            </Typography>
          </Stack>

          <Box display="flex" alignItems="center" width="100%" gap="24px">
            {/* FIRST NAME INPUT */}
            <TextField
              fullWidth
              id="firstName"
              required
              label="First Name"
              name="firstName"
              variant="outlined"
              value={formValues.firstName}
              onChange={(e) => {
                setFormValues({ ...formValues, firstName: e.target.value });
              }}
            />

            {/* LAST NAME FIELD */}
            <TextField
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              variant="outlined"
              value={formValues.lastName}
              onChange={(e) =>
                setFormValues({ ...formValues, lastName: e.target.value })
              }
            />
          </Box>

          <TextField
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            value={formValues.email}
            onChange={emailChangeHandler}
            error={emailError}
            helperText={emailError && "Please enter valid email"}
            // error={emailError || showEmailError}
            // helperText={
            //   (showEmailError &&
            //     "Email already exists! Please try different email.") ||
            //   (emailError && "Please enter valid email")
            // }
            // required
          />
          <Box display="flex" alignItems="flex-start" gap="24px">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Birth"
                name="dob"
                value={formValues.dob}
                // maxDate={dayjs()}
                disableFuture
                onChange={(newValue) =>
                  setFormValues({ ...formValues, dob: newValue })
                }
                onOpen={() => setIsPickerOpen(true)}
                onClose={() => setIsPickerOpen(false)}
                open={isPickerOpen}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: false,

                    onClick: () => setIsPickerOpen(true),
                  },
                }}
              />
            </LocalizationProvider>
            <TextField
              id="contactNumber"
              name="contactNumber"
              label="Contact Number"
              required
              variant="outlined"
              value={formValues.contactNumber}
              onChange={contactNumberHandler}
              fullWidth
              error={showPatientMessage}
              helperText={
                showPatientMessage &&
                "Phone number already exists! Please try different phone number."
              }
            />
          </Box>

          {/* ADDRESS FIELDS  */}
          <Typography variant="h6" fontSize="16px" color="secondary">
            ADDRESS
          </Typography>

          <TextField
            id="address"
            label="Address"
            name="address"
            value={formValues.address}
            onChange={(e) =>
              setFormValues({ ...formValues, address: e.target.value })
            }
            variant="outlined"
            fullWidth
          />

          <Box display="flex" alignItems="center" gap="24px" width="100%">
            <TextField
              id="city"
              placeholder="San Mateo"
              label="City"
              name="city"
              value={formValues.city}
              onChange={(e) =>
                setFormValues({ ...formValues, city: e.target.value })
              }
              variant="outlined"
              fullWidth
            />

            <Autocomplete
              options={states}
              fullWidth
              value={formValues.state}
              onChange={(event, newValue) =>
                setFormValues({ ...formValues, state: newValue })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State/Region"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              )}
              sx={{ marginTop: -1 }}
            />
          </Box>

          <Box display="flex" alignItems="center" gap="24px" width="100%">
            <TextField
              id="zipCode"
              name="zipCode"
              value={formValues.zipCode}
              onChange={(e) => {
                if (/^\d*$/g.test(e.target.value)) {
                  setFormValues({ ...formValues, zipCode: e.target.value });
                }
              }}
              label="Zip/Code"
              variant="outlined"
              fullWidth
            />
          </Box>

          {/* ADDITIONAL INFORMATION  */}
          {/* <Typography
            variant="h6"
            fontSize="16px"
            color="secondary"
            sx={{ textTransform: "uppercase" }}
          >
            ADDITIONAL INFORMATION
          </Typography>

          <TextField
            label="Notes/comments"
            variant="outlined"
            name="notes"
            multiline
            rows={3}
            value={formValues.notes}
            onChange={(e) =>
              setFormValues({ ...formValues, notes: e.target.value })
            }
          /> */}
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          padding: "16px 32px",
          boxShadow: "0px -2px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <LoadingButton
          id="new-case-client-select-submit"
          variant="contained"
          loading={loadingPatientPhone}
          onClick={submitNewPatientFormHandler}
          disabled={
            // !formValues.email ||
            emailError ||
            !formValues?.contactNumber ||
            formValues?.contactNumber?.length < 17 ||
            !formValues.firstName ||
            showPatientMessage
          }
        >
          {activeStep === steps.length - 1 ? "Finish" : "Continue"}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AddClientStep;
