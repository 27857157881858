import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { SmsIcon } from "../../assets/constants";
import EnrolmentJourneyCard from "../EnrolmentJourneyCard";
import CallIcon from "../../icons/CallIcon";
import { useDispatch } from "react-redux";
import {
  resetInactiveReminder,
  sendInactiveEmailReminderFunc,
} from "../../actions/AppConfig";
import { useSelector } from "react-redux";
import AlertMessage from "../../common/AlertMessage";
import Cookies from "js-cookie";
import {
  resendClientCredentialsFunc,
  resetResendClientCredsState,
} from "../../actions/Auth";
// import { getPatientDetailByPatientId } from "../../actions/Patients";
import dayjs from "dayjs";

// const StyledList = styled(Box)(({}) => ({
//   lineHeight: "26px",
//   fontSize: "14px",
// }));

const ConsentScreenContent = ({
  patientDetail,
  resendNum,
  setResendNum,
  resendCredsArr,
  setResendCredsArr,
}) => {
  const dispatch = useDispatch();

  const agencyId = Cookies.get("agency_id_vita");

  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const sendInactiveEmailReminder = useSelector(
    (state) => state.sendInactiveEmailReminder
  );
  const { loading, error, sentEmailReminder } = sendInactiveEmailReminder;

  const resendClientCredentials = useSelector(
    (state) => state.resendClientCredentials
  );
  const { loading: loadingCreds, clientCreds } = resendClientCredentials;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlertMessage(false);
  };

  const sendReminderHandler = () => {
    if (patientDetail?.patient?.equipment_setup) {
      // SEND INACTIVITY REMINDER API
      const patientObj = {
        patient_id: patientDetail?.patient?.id,
      };
      dispatch(sendInactiveEmailReminderFunc(patientObj));
    } else {
      // SEND RESHARE CREDS API
      const patientCredsObj = {
        patient_id: patientDetail?.patient?.id,
        agency_id: agencyId,
      };

      dispatch(resendClientCredentialsFunc(patientCredsObj));
    }
    setResendNum((prev) => prev + 1);
    setResendCredsArr((prev) => [...prev, { created_at: dayjs() }]);
  };

  useEffect(() => {
    if (sentEmailReminder?.ok) {
      setOpenAlertMessage(true);
      dispatch(resetInactiveReminder());
    }
  }, [sentEmailReminder]);

  useEffect(() => {
    if (clientCreds?.ok) {
      dispatch(resetResendClientCredsState());
    }
  }, [clientCreds]);

  return (
    <>
      {openAlertMessage && (
        <AlertMessage
          message={
            patientDetail?.patient?.equipment_setup
              ? patientDetail?.patient?.contact_number &&
                patientDetail?.patient?.email
                ? "Reminder email and text sent."
                : "Reminder text sent."
              : patientDetail?.patient?.email &&
                patientDetail?.patient?.contact_number
              ? `${patientDetail?.patient?.name}'s login credentials have been sent to email address (${patientDetail?.patient?.email}) and phone number (${patientDetail?.patient?.contact_number})`
              : patientDetail?.patient?.email
              ? `${patientDetail?.patient?.name}'s login credentials have been sent to email address : ${patientDetail?.patient?.email}`
              : `${patientDetail?.patient?.name}'s login credentials have been sent to phone number : ${patientDetail?.patient?.contact_number}`
          }
          autoHideDurationTime={5000}
          severity="success"
          open={openAlertMessage}
          handleClose={handleClose}
        />
      )}

      <Stack gap="24px">
        {/* ENROLLMENT JOURNEY  */}
        <EnrolmentJourneyCard
          patientDetail={patientDetail}
          resendNum={resendNum}
          setResendNum={setResendNum}
          resendCredsArr={resendCredsArr}
        />

        <Stack bgcolor="#F4F6F8" borderRadius="4px" padding="16px" gap="16px">
          {(patientDetail?.patient?.email ||
            patientDetail?.patient?.contact_number) && (
            <Typography variant="body1" fontWeight={500}>
              Contact Details
            </Typography>
          )}
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            {patientDetail?.patient?.contact_number && (
              <Box display="flex" alignItems="flex-start" gap="6px">
                <CallIcon />
                <Stack>
                  <Typography variant="body1" color="#212121">
                    Phone Number
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {patientDetail?.patient?.contact_number}
                  </Typography>
                </Stack>
              </Box>
            )}

            {patientDetail?.patient?.email && (
              <Box display="flex" alignItems="flex-start" gap="6px">
                <SmsIcon />
                <Stack>
                  <Typography variant="body1" color="#212121">
                    Email Address
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {patientDetail?.patient?.email}
                  </Typography>
                </Stack>
              </Box>
            )}
          </Box>
        </Stack>

        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          justifyContent="space-between"
        >
          <LoadingButton
            onClick={sendReminderHandler}
            loading={loading || loadingCreds}
            fullWidth
            variant="contained"
            sx={{ textTransform: "uppercase" }}
          >
            Send Reminder
          </LoadingButton>
        </Box>

        {/* PATIENT DETAILS FOR CONSENT */}
        {/* <Stack gap="8px" padding="8px" borderRadius="8px" bgcolor="#F4F6F8">
        <Typography variant="body1" color="#212121" fontWeight={500}>
          Patient Details
        </Typography>
        <Box display="flex" width="100%">
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              Name
            </Typography>
            <Typography variant="body1">
              {patientDetail?.patient?.name
                ? patientDetail?.patient?.name
                : "-"}
            </Typography>
          </Stack>
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              DOB
            </Typography>
            <Typography variant="body1">
              {" "}
              {patientDetail?.patient?.dob
                ? dateFormat(patientDetail?.patient?.dob)
                : "-"}
            </Typography>
          </Stack>
        </Box>
        <Box display="flex" width="100%">
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              Contact Number
            </Typography>
            <Typography variant="body1">
              {" "}
              {patientDetail?.patient?.contact_number
                ? patientDetail?.patient?.contact_number
                : "-"}
            </Typography>
          </Stack>
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              Email
            </Typography>

            {patientDetail?.patient?.email ? (
              patientDetail?.patient?.email?.length <= 24 ? (
                <Typography variant="body1" noWrap>
                  {patientDetail?.patient?.email}
                </Typography>
              ) : (
                <Tooltip
                  arrow
                  title={patientDetail?.patient?.email}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [-18, -14],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <Typography variant="body1" noWrap>
                    {" "}
                    {patientDetail?.patient?.email}
                  </Typography>
                </Tooltip>
              )
            ) : (
              <Typography variant="body1">-</Typography>
            )}
          </Stack>
        </Box>

        {patientDetail?.patient?.tracking_consent_given && (
          <Stack gap="8px">
            <Typography variant="body2" color="#212121" fontWeight={500}>
              Consent Details
            </Typography>
            <Box display="flex" width="100%">
              <Stack gap="4px" width="50%">
                <Typography color="secondary" variant="body2">
                  Consent obtained on
                </Typography>
                <Typography variant="body1">
                  {" "}
                  {patientDetail?.patient?.consent_taken_on
                    ? dateFormat(patientDetail?.patient?.consent_taken_on)
                    : "-"}
                </Typography>
              </Stack>

              <Stack gap="4px" width="50%">
                <Typography color="secondary" variant="body2">
                  {patientDetail?.patient.consent_taken_by_user_type ===
                  "patient"
                    ? "Consent obtained via"
                    : "Consent obtained by"}
                </Typography>
                <Typography variant="body1">
                  {" "}
                  {patientDetail?.patient.consent_taken_by_user_type ===
                  "patient"
                    ? "Mobile App"
                    : patientDetail?.patient?.consent_taken_by_name
                    ? patientDetail?.patient?.consent_taken_by_name
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        )}
      </Stack> */}

        {/* COMMENT OUT CONSENT CHECKED BOXES */}
        {/* <Stack gap="16px">
        {!patientDetail?.patient?.tracking_consent_given && (
          <Stack>
            <FormControlLabel
              sx={{ marginRight: "0", flex: "1", alignItems: "flex-start" }}
              control={
                <Checkbox
                  sx={{ padding: "1px 9px" }}
                  checked={tosConsentChecked}
                  disabled={patientDetail?.patient?.tracking_consent_given}
                  onChange={(e) => setTosContentChecked(e.target.checked)}
                />
              }
              label={
                <Typography>
                  The patient has read and agrees to the{" "}
                  <Box
                    component="a"
                    color="#0C80F3"
                    href="https://vitatrax.co/terms-of-service/"
                    target="_blank"
                  >
                    Terms of Service.
                  </Box>
                </Typography>
              }
            />
            <Stack marginLeft="32px" gap="2px">
              <Typography variant="body2" color="#13132B">
                By proceeding, the patient acknowledges and agrees to the
                following:
              </Typography>
              <Stack
                component="ul"
                paddingLeft="24px"
                gap="4px"
                color="rgba(0, 0, 0, 0.60)"
              >
                <StyledList component="li">
                  The patient agrees to participate in Remote Therapeutic
                  Monitoring (RTM).
                </StyledList>
                <StyledList component="li">
                  The patient understands that there may be a copay associated
                  with RTM services.
                </StyledList>
                <StyledList component="li">
                  The patient understands that RTM can be canceled at any time.
                </StyledList>
                <StyledList component="li">
                  Information related to the patient's care may be shared with
                  other healthcare providers.
                </StyledList>
                <StyledList component="li">
                  Only one practitioner will be able to bill for RTM services.
                </StyledList>
              </Stack>
            </Stack>
          </Stack>
        )}

        <FormControlLabel
          sx={{ marginRight: "0", flex: "1", alignItems: "flex-start" }}
          control={
            <Checkbox
              sx={{ padding: "1px 9px" }}
              checked={initSetupChecked}
              disabled={patientDetail?.patient?.equipment_setup}
              onChange={(e) => setInitSetupChecked(e.target.checked)}
            />
          }
          label="The initial setup of the equipment has been completed"
        />
        <FormControlLabel
          sx={{ marginRight: "0", flex: "1", alignItems: "flex-start" }}
          control={
            <Checkbox
              sx={{ padding: "1px 9px" }}
              checked={initSetup2Checked}
              disabled={patientDetail?.patient?.education_given}
              onChange={(e) => setInitSetup2Checked(e.target.checked)}
            />
          }
          label="The patient has been educated on how to use the equipment"
        />
      </Stack> */}
        {/* <TextField
        id="patient-interaction-notes"
        placeholder="Patient Interaction Notes"
        multiline
        rows={7}
        value={cptCodeNotes}
        onChange={(e) => setCptCodeNotes(e.target.value)}
      /> */}
      </Stack>
    </>
  );
};

export default ConsentScreenContent;
