import { Box, Stack, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import NavbarUI from "../NavbarUI";
import Sidedrawer from "../SideDrawer";
import styled from "@emotion/styled";
import AlertDialog from "../AlertDialog";
import EndTimeModalContent from "../EndTimeModalContent";
import { useSelector } from "react-redux";
import {
  closeTimeModal,
  elpasedTimeFunc,
  resetAllBodyParts,
  resetAllNotifications,
  resetTimerItemsFunc,
  resetUserFeedNotifications,
  setGoBackFunc,
  setGoNextPatientFunc,
  setIsPaused,
  setIsRunning,
  setIsStopped,
  setLogoutClickedFunc,
  setNavigateItemFunc,
  setPausedTime,
  setPauseStartTime,
  setPreviousPageFunc,
  timeChangedFunc,
} from "../../actions/AppConfig";
import { useDispatch } from "react-redux";
import AlertMessage from "../../common/AlertMessage";
import { logout } from "../../actions/Auth";
import {
  resetAllCases,
  resetCaseClientDocs,
  resetPatientListingStates,
  resetUnpaginatedCases,
} from "../../actions/Cases";
import { resetStepCounts } from "../../actions/StepsCountActions";
import { resetGetBillingSubscription } from "../../actions/BillingSubscription";
import { resetAgentsList } from "../../actions/Agency";
import dayjs from "dayjs";
import {
  createTimeEntryFunc,
  resetCreateTimeEntry,
} from "../../actions/TimeEntry";
import { calculateElapsedTime } from "../../utils/helpers";
import OverlapTimeEntryCard from "../OverlapTimeEntryCard";

const drawerWidth = "255px";
const StyledBox = styled(Box)(() => ({
  display: "flex",
  overflow: "auto",
  height: "100%",
  // background: "green",
}));

const StyledPage = styled(Box)(() => ({
  width: "100%",
  overflowX: "hidden",
  // padding: "20px 40px",
  // paddingBottom: "40px",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  toolbar: theme.mixins.toolbar,
}));

const AppLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const agencyId = Cookies.get("agency_id_vita");

  const [selectedPath, setSelectedPath] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showTimeEntryModal, setShowTimeEntryModal] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [overlapTimeEntries, setOverlapTimeEntries] = useState([]);

  const patientListingState = useSelector((state) => state.patientListingState);
  const { tab: activeTab, nextPatientObject } = patientListingState;

  const caseDetailByCaseId = useSelector((state) => state.caseDetailByCaseId);
  const { caseDetail } = caseDetailByCaseId;

  const createTimeEntry = useSelector((state) => state.createTimeEntry);
  const {
    loading: loadingCreateTimeEntry,
    error,
    createdTimeEntry,
  } = createTimeEntry;

  const timerModal = useSelector((state) => state.timerModal);
  const {
    openModal,
    elapsedTime,
    goBackClicked,
    goNextPatientClicked,
    pausedStartTime,
    pausedTime,
    navigateItem,
    isPaused,

    logoutClicked,
    startTimeValue,
    endTimeValue,
    logTypeValue,
    notesValue,
    communicationInteraction,
    timeChanged,
  } = timerModal;

  const saveTimerHandler = () => {
    // console.log("START", startTimeValue);
    // console.log("END", endTimeValue);

    if (endTimeValue && startTimeValue) {
      // const fixedDate = "1970-01-01";
      // const normalizedStartTime = dayjs(
      //   `${fixedDate} ${startTimeValue.format("HH:mm")}`
      // );
      // const normalizedEndTime = dayjs(
      //   `${fixedDate} ${endTimeValue.format("HH:mm")}`
      // );

      // if (normalizedEndTime.isBefore(normalizedStartTime)) {
      //   setEndTimeError(true);
      //   return;
      // }

      if (endTimeValue.isBefore(startTimeValue)) {
        setEndTimeError(true);
        return;
      }
    }

    setEndTimeError(false);

    // const fixedDate = "1970-01-01";
    // const start = dayjs(`${dayjs(startTimeValue).format("HH:mm")}`)
    //   .set("second", 0)
    //   .set("millisecond", 0);
    // const end = dayjs(`${dayjs(endTimeValue).format("HH:mm")}`)
    //   .set("second", 0)
    //   .set("millisecond", 0);

    const timeEntryObj = {
      patient_id: caseDetail?.patient?.id,
      agency_id: agencyId,
      date: dayjs()?.utcOffset(0, true),
      start_time: startTimeValue,
      end_time: endTimeValue,
      time_in_seconds: timeChanged
        ? calculateElapsedTime(startTimeValue, endTimeValue)
        : Math.floor(elapsedTime / 1000),
      category: logTypeValue,
      notes: notesValue,
      contains_interaction: communicationInteraction,
    };

    dispatch(createTimeEntryFunc(timeEntryObj));
  };

  useEffect(() => {
    if (createdTimeEntry?.ok) {
      setEndTimeError(false);
      // dispatch(
      //   getTimeEntryFunc(
      //     patientId,
      //     dayjs().subtract(31, "day").format("YYYY-MM-DD"),
      //     dayjs().format("YYYY-MM-DD")
      //   )
      // );
      dispatch(resetCreateTimeEntry());
      dispatch(resetTimerItemsFunc());
      dispatch(setIsRunning(false));
      dispatch(setIsPaused(false));
      dispatch(setIsStopped(true));
      dispatch(timeChangedFunc(false));

      // dispatch(getCaseDetailByCaseId(patientId));
      // const pauseDuration = Date.now() - pausedStartTime;
      // dispatch(setPausedTime(pausedTime + pauseDuration));
      // dispatch(setPauseStartTime(null));
      dispatch(elpasedTimeFunc(0));
      dispatch(closeTimeModal());
      // setShowSuccessMessage(true);
      // dispatch(setPreviousPageFunc(true));
      if (navigateItem === "/") {
        navigate("/");
        setSelectedPath(navigateItem);
      }
      if (navigateItem === `/patients?tab=${activeTab}`) {
        navigate(`/patients?tab=${activeTab}`);

        dispatch(resetStepCounts());
        setSelectedPath(navigateItem);
      }
      if (navigateItem === "/settings?tab=general") {
        navigate("/settings?tab=general");
        setSelectedPath(navigateItem);
      }
      if (navigateItem === "/profile") {
        navigate("/profile");
      }
      if (goBackClicked) {
        navigate(`/patients?tab=${activeTab}`);
        dispatch(resetStepCounts());
        return;
      }
      if (goNextPatientClicked) {
        navigate(`/patient/${nextPatientObject?.id}?tab=overview`);
        dispatch(resetStepCounts());
        return;
      }
    } else if (error?.message) {
      setOverlapTimeEntries(error?.overlapEntries);
      if (isPaused) {
        dispatch(setIsRunning(false));
        dispatch(setIsPaused(true));
        dispatch(setIsStopped(false));
      }

      setShowErrorMessage(true);
    }
  }, [createdTimeEntry, error]);

  const resetTimerHandler = () => {
    dispatch(setIsRunning(false));
    dispatch(resetTimerItemsFunc());
    dispatch(setIsPaused(false));
    dispatch(setIsStopped(true));
    dispatch(setPreviousPageFunc(false));
    dispatch(timeChangedFunc(false));
    dispatch(elpasedTimeFunc(0));
    dispatch(closeTimeModal());
    setShowTimeEntryModal(false);
    dispatch(resetCreateTimeEntry());

    if (goBackClicked) {
      navigate(`/patients?tab=${activeTab}`);
      dispatch(setGoBackFunc(false));
      dispatch(resetStepCounts());
      return;
    }
    if (goNextPatientClicked) {
      navigate(`/patient/${nextPatientObject?.id}?tab=overview`);
      dispatch(setGoNextPatientFunc(false));
      dispatch(resetStepCounts());
      return;
    }

    if (navigateItem === "/") {
      navigate("/");
      setSelectedPath(navigateItem);
      return;
    }

    if (navigateItem === "/profile") {
      navigate("/profile");
      setSelectedPath(navigateItem);
      return;
    }

    if (navigateItem === `/patients?tab=${activeTab}`) {
      navigate(`/patients?tab=${activeTab}`);
      setSelectedPath(navigateItem);
      dispatch(resetPatientListingStates());
      return;
    }

    if (navigateItem === "/integrations") {
      navigate("/integrations");
      setSelectedPath(navigateItem);
      return;
    }
    if (navigateItem === "/billing-report") {
      navigate("/billing-report");
      setSelectedPath(navigateItem);
      return;
    }

    if (navigateItem === "/settings?tab=general") {
      navigate("/settings");
      setSelectedPath(navigateItem);
      return;
    }

    if (logoutClicked) {
      dispatch(logout());
      dispatch(resetUserFeedNotifications());
      dispatch(resetAllNotifications());
      dispatch(resetAllCases());
      dispatch(resetCaseClientDocs());
      dispatch(resetAllBodyParts());
      dispatch(resetUnpaginatedCases());
      dispatch(resetAgentsList());
      dispatch(resetGetBillingSubscription());
      dispatch(resetStepCounts());
      dispatch(resetTimerItemsFunc());
      return;
    }
  };

  const showDiscardTimeEntryModalHandler = () => {
    setShowTimeEntryModal(true);
  };

  const isSaveDisabled =
    timeChanged &&
    startTimeValue?.format("HH:mm A") === endTimeValue?.format("HH:mm A");

  return (
    <>
      {/* OPEN STOP TIME MODAL */}
      {openModal && (
        <AlertDialog
          data={{ title: "End Session" }}
          disabled={isSaveDisabled}
          openModal={openModal}
          isLoading={loadingCreateTimeEntry}
          showDiscardTimeEntryModalHandler={showDiscardTimeEntryModalHandler}
          closeModal={() => {
            setEndTimeError(false);
            dispatch(timeChangedFunc(false));
            // if (goBackTimer) {
            // navigate("/patients");
            //   setIsRunning(false);
            //   setIsPaused(false);
            // } else {
            //   setIsRunning(true);
            //   setIsPaused(false);
            //   window.history.pushState({ showModal: false }, "");
            // dispatch(setIsRunning(true));
            if (!isPaused) {
              dispatch(setIsRunning(true));
              const pauseDuration = Date.now() - pausedStartTime;
              dispatch(setPausedTime(pausedTime + pauseDuration));
              dispatch(setPauseStartTime(null));
            } else {
              dispatch(setIsPaused(true));
            }
            dispatch(closeTimeModal());
            dispatch(setGoBackFunc(false));
            dispatch(setLogoutClickedFunc(false));
            dispatch(setNavigateItemFunc(null));

            // }
          }}
          moveFileDialog={true}
          formSubmitFunction={saveTimerHandler}
          submitText="Save"
          cancelText={
            goBackClicked || goNextPatientClicked
              ? "Discard and Leave"
              : "Discard"
          }
          maxWidth="500px"
          titlePadding="16px 24px 0 24px !important"
          titlePaddingTop="16px"
          closeOverlay={false}
          showCancelIcon={true}
          resetTime={true}
        >
          <EndTimeModalContent
            setEndTimeError={setEndTimeError}
            endTimeError={endTimeError}
            openModal={openModal}
          />
        </AlertDialog>
      )}

      {showSuccessMessage && (
        <AlertMessage
          message="Time log added successfully"
          severity="success"
          open={showSuccessMessage}
          handleClose={() => setShowSuccessMessage(false)}
        />
      )}

      {showErrorMessage && (
        <AlertMessage
          width="682px"
          severity="error"
          open={true}
          autoHideDurationTime={60000}
          handleClose={() => setShowErrorMessage(false)}
          textAlign="normal"
        >
          <Stack gap="14px">
            <Stack>
              <Typography variant="body1" fontWeight={500} color="#5F2120">
                Session can't be saved
              </Typography>
              <Typography variant="body2" color="#5F2120">
                {error?.message}{" "}
              </Typography>
            </Stack>
            <Stack
              gap="8px"
              height={overlapTimeEntries?.length <= 3 ? "auto" : "190px"}
              overflow="auto"
            >
              {overlapTimeEntries?.map((item, index) => (
                <OverlapTimeEntryCard
                  key={index}
                  heading={item?.category}
                  notesValue={item?.notes}
                  startTime={item?.start_time}
                  endTime={item?.end_time}
                />
              ))}
            </Stack>
          </Stack>{" "}
        </AlertMessage>
      )}

      {showTimeEntryModal && (
        <AlertDialog
          submitID="confirm-delete-time-entry"
          maxWidth="400px"
          openModal={showTimeEntryModal}
          closeModal={() => setShowTimeEntryModal(false)}
          data={{
            title: "Are you sure you want to discard this time entry?",
          }}
          formSubmitFunction={resetTimerHandler}
          color="error"
          fontWeight="400"
        />
      )}

      <StyledBox>
        {/* APP BAR */}

        <NavbarUI drawerWidth={drawerWidth} />

        {/* SIDE DRAWER */}

        <Sidedrawer
          drawerWidth={drawerWidth}
          selectedPath={selectedPath}
          setSelectedPath={setSelectedPath}
        />

        <StyledPage>
          <StyledToolbar></StyledToolbar>
          <Box height="calc(100vh - 64px)">
            <Outlet />
          </Box>
        </StyledPage>
      </StyledBox>
    </>
  );
};

export default AppLayout;
