import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Paper,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CardUI from "../../../common/Card";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import AddIcon from "@mui/icons-material/Add";
import Cookies from "js-cookie";

import {
  dateWithoutYearFormat,
  exportCsvData,
  getStepCountDaysText,
  onlyDateFormat,
} from "../../../utils/helpers";

import CurveChart from "../../../components/CurveChart";
import PainLogsDate from "../../../components/PainLogsDate";
import { useDispatch, useSelector } from "react-redux";
import {
  getChartPainlogsFunc,
  getClientPainlogsFunc,
} from "../../../actions/Cases";
import EmptyBox from "../../../assets/images/empty-box.png";
import PainLogDetail from "../../../components/PainLogDetail";
import dayjs from "dayjs";
import BodyPartsFilter from "../../../components/BodyPartsFilter";
import AlertDialog from "../../../components/AlertDialog";
import CustomPainDateRange from "../../../components/CustomPainDateRange";
import ExportIcon from "../../../icons/ExportIcon";
import CaseChartSkeleton from "../../../components/CaseChartSkeleton";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { resetAddCaseToSubscription } from "../../../actions/BillingSubscription";

import DoughnutChartUI from "../../../components/DoughnutChartUI";
import CollapsableUIBox from "../../../components/CollapsableUIBox";
import FilterDaysDropDown from "../../../components/FilterDaysDropDown";
import {
  resetSendPainLogReminder,
  sendPainLogReminderFunc,
} from "../../../actions/AppConfig";

import RectangleSkeletons from "../../../components/RectangleSkeletons";
import StepCountDetailCard from "../../../components/StepCountDetailCard";
import {
  getMedicinesStatsFunc,
  getStepsCountFunc,
  resetStepCounts,
} from "../../../actions/StepsCountActions";
import StepCountChart from "../../../components/StepCountChart";
import { RefreshIcon } from "../../../assets/constants";
// import EastRoundedIcon from "@mui/icons-material/EastRounded";
import RightArrowIcon from "../../../icons/RightArrowIcon";
import RightPainArrow from "../../../icons/RightPainArrow";
import CalendarIcon from "../../../icons/CalendarIcon";
import CalendarPainIcon from "../../../icons/CalendarPainIcon";

const OverviewTab = ({
  allBodyPartsData,
  billingActive,
  setShowBillingPlan,
  showBillingPlan,
  setBillingActive,
  caseAddedInPlan,
  setCaseAddedInPlan,
  // billingSubscription,
  // loadingBilling,
}) => {
  const caseDetailByCaseId = useSelector((state) => state.caseDetailByCaseId);
  const {
    loading: loadingCaseDetail,
    error: errorcaseDetail,
    caseDetail,
  } = caseDetailByCaseId;
  // const [painlogsFilter, setPainlogsFilter] = useState("all");
  const [painLogDays, setPainLogDays] = useState(14);
  const [stepCountDays, setStepCountDays] = useState(14);
  const [medicineDays, setMedicineDays] = useState(14);
  const [openCustomPainLogDays, setOpenCustomPainLogDays] = useState(false);
  const [openCustomStepCountDays, setOpenCustomStepCountDays] = useState(false);
  const [openCustomMedicineStatsDays, setOpenCustomMedicineStatsDays] =
    useState(false);
  const [bodyPartsAnchorEl, setBodyPartsAnchorEl] = useState(null);
  const [expenseTypeAnchorEl, setExpenseTypeAnchorEl] = useState(null);
  const [exportChartEl, setExportChartEl] = useState(null);
  const [loadingDate, setLoadingDate] = useState(null);

  const [todayStepCount, setTodayStepCount] = useState(null);
  const [stepCountsArr, setStepCountsArr] = useState([]);

  // const [selectedBodyPart, setSelectedBodyPart] = useState([]);
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);
  const [fromCustomDate, setFromCustomDate] = useState(null);
  const [toCustomDate, setToCustomDate] = useState(null);

  const [stepCountFromDate, setStepCountFromDate] = useState(null);
  const [stepCountToDate, setStepCountToDate] = useState(null);

  const [medicineCustomFromDate, setMedicineCustomFromDate] = useState(null);
  const [medicineCustomToDate, setMedicineCustomToDate] = useState(null);
  const [stepCountStartDate, setStepCountStartDate] = useState(null);
  const [stepCountEndDate, setStepCountEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [exportedCsvData, setExportedCsvData] = useState([]);
  // const [customDateError, setCustomDateError] = useState(null);
  const [isTouchedCustomDate, setIsTouchedCustomDate] = useState(false);
  const [isTouchedMedicineDate, setIsTouchedMedicineDate] = useState(false);
  const [isTouchedStepCountDate, setIsTouchedStepCountDate] = useState(false);
  const [medicineStatsStartDate, setMedicineStatsStartDate] = useState(null);
  const [medicineStatsEndDate, setMedicineStatsEndDate] = useState(null);
  const [loadingStepCount, setLoadingStepCount] = useState(false);

  const [medicinesTakenDays, setMedicinesTakenDays] = useState(0);
  const [medicinesNotTakenDays, setMedicinesNotTakenDays] = useState(0);
  const [medicinesNotPrescribedDays, setMedicinesNotPrescribedDays] =
    useState(0);

  const [endDate, setEndDate] = useState(null);
  const [selectedPain, setSelectedPain] = useState("average");
  const [painOptions] = useState([
    { value: "maximum", label: "Maximum" },
    { value: "latest", label: "Latest" },
    { value: "average", label: "Average" },
    // Add more options as needed
  ]);
  const [confirmPlan, setConfirmPlan] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarReminder, setOpenSnackbarReminder] = useState(false);

  const [showStepsCount, setShowStepsCount] = useState(false);

  const filterButtonRef = useRef();
  const chartRef = useRef();
  const stepCountChartRef = useRef();

  const patientTitle = caseDetail?.patient?.name;
  const agencyId = Cookies.get("agency_id_vita");

  const dispatch = useDispatch();
  const getClientPainlogs = useSelector((state) => state.getClientPainlogs);
  const { loading, error, painlogs } = getClientPainlogs;

  const getChartPainLogs = useSelector((state) => state.getChartPainLogs);
  const {
    loading: loadingChartLogs,
    error: errorChartLogs,
    chartPainLogs,
  } = getChartPainLogs;

  const getStepsCount = useSelector((state) => state.getStepsCount);
  const {
    loading: loadingStepsCount,
    error: errorStepsCount,
    stepsCount,
  } = getStepsCount;

  const getMedicinesStats = useSelector((state) => state.getMedicinesStats);
  const {
    loading: loadingMedicineStats,
    error: errorMedicineStats,
    medicineStats,
  } = getMedicinesStats;

  const sendPainLogReminder = useSelector((state) => state.sendPainLogReminder);
  const {
    loading: loadingSendReminder,
    errorSendReminder,
    sentReminder,
  } = sendPainLogReminder;

  const addCaseToSubscription = useSelector(
    (state) => state.addCaseToSubscription
  );
  const {
    loading: loadingAddCaseToSubsc,
    error: errorAddCaseToSubsc,
    caseAddedToSubscription,
  } = addCaseToSubscription;

  const isCurrentMonthAndYear = (date) => {
    const currentDate = dayjs();
    const painLogDate = dayjs(date);

    return (
      painLogDate?.year() === currentDate.year() &&
      painLogDate?.month() === currentDate.month()
    );
  };

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setBodyPartsAnchorEl(null);
  };
  const handleCloseExpenseType = () => {
    setExpenseTypeAnchorEl(null);
  };

  // EXPORT CHART AS PNG OR PDF HANDLER
  const openExportChart = Boolean(exportChartEl);
  const openExportListHandler = (event) => {
    setExportChartEl(event.currentTarget);
  };

  const exportChartCloseHandler = () => {
    setExportChartEl(null);
  };

  // SELECT BODY PARTS HANDLER
  const selectBodyPartsHandler = (event) => {
    setBodyPartsAnchorEl(event.currentTarget);
  };

  const isInsidePopover = (event) => {
    return bodyPartsAnchorEl && bodyPartsAnchorEl.contains(event.target);
  };

  const isInsidePopoverExpenseType = (e) => {
    return expenseTypeAnchorEl && expenseTypeAnchorEl.contains(e.target);
  };

  const handleOutsideClick = (event) => {
    if (!isInsidePopover(event)) {
      handleClose();
    }
  };
  const handleOutsideClickExpenseType = (event) => {
    if (!isInsidePopoverExpenseType(event)) {
      handleCloseExpenseType();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [bodyPartsAnchorEl]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClickExpenseType);
    return () => {
      document.removeEventListener("click", handleOutsideClickExpenseType);
    };
  }, [expenseTypeAnchorEl]);

  // DISPATCH PAIN LOGS FUNCTION
  useEffect(() => {
    if (painLogDays === "custom" && caseDetail?.patient?.id) {
      dispatch(
        getClientPainlogsFunc(
          caseDetail?.patient?.id,
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD"),
          "all"
        )
      );
    } else if (caseDetail?.patient?.id) {
      const endingDate = dayjs();
      setEndDate(endingDate);
      const startingDate = endingDate?.subtract(painLogDays, "day");
      setStartDate(startingDate);

      dispatch(
        getClientPainlogsFunc(
          caseDetail?.patient?.id,
          startingDate.format("YYYY-MM-DD"),
          endingDate.format("YYYY-MM-DD"),
          "all"
        )
      );
    }
  }, [caseDetail?.patient?.id, painLogDays]);

  // DISPATCH CHART PAIN LOGS FUNCTION
  useEffect(() => {
    if (painLogDays === "custom" && caseDetail?.patient?.id) {
      dispatch(
        getChartPainlogsFunc(
          caseDetail?.patient?.id,
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD"),
          selectedPain
        )
      );
    } else if (caseDetail?.patient?.id) {
      const endingDate = dayjs();
      setEndDate(endingDate);
      const startingDate = endingDate?.subtract(painLogDays, "day");
      setStartDate(startingDate);

      dispatch(
        getChartPainlogsFunc(
          caseDetail?.patient?.id,
          startingDate.format("YYYY-MM-DD"),
          endingDate.format("YYYY-MM-DD"),
          selectedPain
        )
      );
    }
  }, [caseDetail?.patient?.id, painLogDays, selectedPain]);

  useEffect(() => {
    if (stepCountDays === "custom" && caseDetail?.patient?.id) {
      dispatch(
        getStepsCountFunc(
          caseDetail?.patient?.id,
          stepCountStartDate?.format("YYYY-MM-DD"),
          stepCountEndDate?.format("YYYY-MM-DD")
        )
      );
    } else if (caseDetail?.patient?.id) {
      const stepEndingDate = dayjs();
      setStepCountEndDate(stepEndingDate);
      const stepStartingDate = stepEndingDate?.subtract(stepCountDays, "day");
      setStepCountStartDate(stepStartingDate);

      dispatch(
        getStepsCountFunc(
          caseDetail?.patient?.id,
          stepStartingDate?.format("YYYY-MM-DD"),
          stepEndingDate?.format("YYYY-MM-DD")
        )
      );
    }
  }, [caseDetail?.patient?.id, stepCountDays]);

  // EXPENSE FILTERS FUNCTION =========================

  // const filterExpenseDetailsByDays = (
  //   filteredExpenseDetails,
  //   expenseDaysValue
  // ) => {
  //   let startExpenseDate;
  //   let endExpenseDate;

  //   if (expenseDaysValue === "custom") {
  //     startExpenseDate = dayjs(expenseTypeFromDate);
  //     endExpenseDate = dayjs(expenseTypeToDate);
  //   } else {
  //     startExpenseDate = dayjs().subtract(expenseDaysValue, "day");
  //     endExpenseDate = dayjs();
  //   }

  //   const filteredExpenses = filteredExpenseDetails?.filter((expense) => {
  //     const expenseDate = dayjs(expense?.date);
  //     return expenseDate?.isBetween(
  //       startExpenseDate,
  //       endExpenseDate,
  //       null,
  //       "[]"
  //     );
  //   });

  //   if (isAllChecked) {
  //     return filteredExpenses;
  //   }

  //   // Filter by expense type
  //   const filteredExpensesType = filteredExpenses?.filter((expense) => {
  //     const isSelectedExpenseType =
  //       checkedItems[expenseTypeValues[expense.expense_type.toLowerCase()]];
  //     return isSelectedExpenseType;
  //   });

  //   return filteredExpensesType;
  // };

  // CUSTOM STEP COUNT DATE HANDLER
  const customStepCountDaysHandler = () => {
    setIsTouchedStepCountDate(true);

    if (!stepCountFromDate || !stepCountToDate) {
      return;
    }

    if (stepCountFromDate && stepCountToDate) {
      setStepCountStartDate(stepCountFromDate);
      setStepCountEndDate(stepCountToDate);

      dispatch(
        getStepsCountFunc(
          caseDetail?.patient?.id,
          stepCountFromDate.format("YYYY-MM-DD"),
          stepCountToDate.format("YYYY-MM-DD")
        )
      );

      setStepCountFromDate(null);
      setStepCountToDate(null);
      setIsTouchedStepCountDate(false);
      closeCustomPainLogDaysHandler();
    }

    // const customExpenseDetails = filterExpenseDetailsByDays(
    //   stepsCount?.step_counts,
    //   "custom"
    // );

    // // setExpenseDetails(customExpenseDetails);
    // closeCustomPainLogDaysHandler();
    // setStepCountFromDate(null);
    // setStepCountToDate(null);
    // setIsTouchedExpenseTypeDate(false);
  };

  // CUSTOM MEDICINE STATS DATE HANDLER
  const customMedicineStatsDaysHandler = () => {
    setIsTouchedMedicineDate(true);

    if (!medicineCustomFromDate || !medicineCustomToDate) {
      return;
    }

    // setIsTouchedCustomDate(false);

    if (
      medicineCustomFromDate &&
      medicineCustomToDate &&
      medicineDays === "custom"
    ) {
      setMedicineStatsStartDate(medicineCustomFromDate);
      setMedicineStatsEndDate(medicineCustomToDate);

      dispatch(
        getMedicinesStatsFunc(
          caseDetail?.patient?.id,
          medicineCustomFromDate.format("YYYY-MM-DD"),
          medicineCustomToDate.format("YYYY-MM-DD")
        )
      );

      setMedicineCustomFromDate(null);
      setMedicineCustomToDate(null);
      setIsTouchedMedicineDate(false);
      closeCustomPainLogDaysHandler();
    }
  };

  // DISPATCH EXPENSE DETAIL FUNCTION
  // useEffect(() => {
  //   dispatch(
  //     getExpensesDetailFunc(caseDetail?.case?.id, caseDetail?.case?.client?.id, )
  //   );
  // }, [caseDetail?.case?.id]);

  useEffect(() => {
    if (caseDetail?.patient?.id && medicineDays === "custom") {
      dispatch(
        getMedicinesStatsFunc(
          caseDetail?.patient?.id,
          medicineStatsStartDate?.format("YYYY-MM-DD"),
          medicineStatsEndDate?.format("YYYY-MM-DD")
        )
      );
    } else {
      const endingDate = dayjs();
      setMedicineStatsEndDate(endingDate);
      const startingDate = endingDate?.subtract(medicineDays, "day");
      setMedicineStatsStartDate(startingDate);

      dispatch(
        getMedicinesStatsFunc(
          caseDetail?.patient?.id,
          startingDate.format("YYYY-MM-DD"),
          endingDate.format("YYYY-MM-DD")
        )
      );
    }
  }, [caseDetail?.patient?.id, medicineDays]);

  // useEffect(() => {
  //   if (expenses?.ok) {
  //     // const filteredExpenseDetails = filterExpenseDetailsByDays(
  //     //   expenses?.expenses,
  //     //   expenseDays,
  //     //   checkedItems
  //     // );

  //     setExpenseDetails(filteredExpenseDetails);
  //   }
  // }, [expenses, expenseDays, checkedItems]);

  // MEDICINES USEEFFECT
  useEffect(() => {
    if (medicineStats?.ok) {
      setMedicinesTakenDays(medicineStats?.daysMedicineTaken);
      setMedicinesNotTakenDays(medicineStats?.daysMedicineNotTaken);
      setMedicinesNotPrescribedDays(medicineStats?.daysMedicineNotPrescribed);
    }
  }, [medicineStats]);

  const customPainDateHandler = () => {
    setIsTouchedCustomDate(true);

    if (!fromCustomDate || !toCustomDate) {
      return;
    }

    if (fromCustomDate && toCustomDate && painLogDays === "custom") {
      setStartDate(fromCustomDate);
      setEndDate(toCustomDate);

      dispatch(
        getClientPainlogsFunc(
          caseDetail?.patient?.id,
          fromCustomDate.format("YYYY-MM-DD"),
          toCustomDate.format("YYYY-MM-DD"),
          "all"
        )
      );

      dispatch(
        getChartPainlogsFunc(
          caseDetail?.patient?.id,
          fromCustomDate.format("YYYY-MM-DD"),
          toCustomDate.format("YYYY-MM-DD"),
          selectedPain
        )
      );

      // setFromCustomDate(null);
      // setToCustomDate(null);
      setIsTouchedCustomDate(false);
      closeCustomPainLogDaysHandler();
    }
  };

  // useEffect(() => {
  //   if (painlogs?.ok) {
  //     dispatch(resetClientPainlogs());
  //   }
  // }, [painlogs]);

  // Parse data without triggering a re-render
  const parsedDataArray = React.useMemo(() => {
    return painlogs?.pain_entries?.map((item) => {
      const bodyPartsData = JSON.parse(item?.body_parts_data);

      return {
        ...item,
        body_parts_data: bodyPartsData,
      };
    });
  }, [painlogs?.pain_entries]);

  const changeBodyPartsHandler = (e, bodyPart) => {
    e.stopPropagation();

    setSelectedBodyParts((prevSelected) => {
      if (prevSelected?.includes(bodyPart)) {
        return prevSelected?.filter((part) => part !== bodyPart);
      } else {
        return [...prevSelected, bodyPart];
      }
    });
  };

  // PREVIOUS FILTER -> ALL & BODY PARTS
  // const painlogsFilterSelectHandler = (e) => {
  //   const selectedValue = e.target.value;
  //   setSelectedBodyPart(selectedValue);

  //   // Filter the data based on the selected body part
  //   let filtered = parsedDataArray?.filter((item) => {
  //     return item?.body_parts_data?.some(
  //       (bodyPart) => `${bodyPart.side} ${bodyPart.name}` === selectedValue
  //     );
  //   });

  //   for (const pain_log of filtered) {
  //     var modified_body_parts_data = [];
  //     for (const body_part of pain_log.body_parts_data) {
  //       if (`${body_part.side} ${body_part.name}` === selectedValue) {
  //         modified_body_parts_data.push(body_part);
  //       }
  //     }
  //     pain_log.body_parts_data = modified_body_parts_data;
  //   }

  //   setFilteredPainLogData(filtered);
  // };

  const changePainDaysHandler = (e) => {
    if (e.target.value === "custom") {
      setOpenCustomPainLogDays(true);
      setFromCustomDate(null);
      setToCustomDate(null);
      setPainLogDays(e.target.value);
    } else {
      setPainLogDays(e.target.value);
      setOpenCustomPainLogDays(false);
    }
  };

  const changeStepCountDaysHandler = (e) => {
    if (e.target.value === "custom") {
      setOpenCustomStepCountDays(true);
      setStepCountDays(e.target.value);
    } else {
      setStepCountDays(e.target.value);
    }
  };
  const changeMedicineDaysHandler = (e) => {
    if (e.target.value === "custom") {
      setOpenCustomMedicineStatsDays(true);
      setMedicineDays(e.target.value);
    } else {
      setMedicineDays(e.target.value);
    }
  };

  const closeCustomPainLogDaysHandler = () => {
    setOpenCustomPainLogDays(false);
    setOpenCustomStepCountDays(false);
    setOpenCustomMedicineStatsDays(false);
  };

  const changePainHandler = (e) => {
    const selectedPainValue = e.target.value;
    setSelectedPain(selectedPainValue);
  };

  // GROUPED PAINLOGS BY DATE
  // const groupedPainLogs = {};
  // painlogs?.pain_entries?.forEach((painlog) => {
  //   const date = painlog.date;
  //   if (!groupedPainLogs[date]) {
  //     groupedPainLogs[date] = [];
  //   }
  //   groupedPainLogs[date].push(painlog);
  // });

  const targetDate = endDate?.subtract(painLogDays, "day");

  const datesInRange = [];
  let currentDate = endDate;
  while (
    currentDate?.isAfter(startDate, "day") ||
    currentDate?.isSame(startDate, "day")
  ) {
    datesInRange?.push(currentDate?.format("YYYY-MM-DD"));
    if (currentDate?.isSame(targetDate, "day")) {
      break;
    }
    currentDate = currentDate?.subtract(1, "day");
  }

  const filteredPains = datesInRange
    ?.map((date) => {
      const existingEntry = painlogs?.pain_entries?.find(
        (item) => item?.date === date
      );

      if (existingEntry?.date) {
        const bodyPartsData = JSON.parse(existingEntry?.body_parts_data);
        return {
          ...existingEntry,
          missed: false,
          body_parts_data: bodyPartsData,
        };
      } else {
        return {
          date,
          missed: true,
          agency_id: null,
          global_pain_level: null,
          body_parts_data: [],
        };
      }
    })
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      return dateA.getTime() - dateB.getTime();
    });

  const filteredChartPains = datesInRange
    ?.map((date) => {
      const existingEntry = chartPainLogs?.pain_entries?.find(
        (item) => item?.date === date
      );

      if (existingEntry?.date) {
        const bodyPartsData = JSON.parse(existingEntry?.body_parts_data);
        return {
          ...existingEntry,
          missed: false,
          body_parts_data: bodyPartsData,
        };
      } else {
        return {
          date,
          missed: true,
          agency_id: null,
          global_pain_level: null,
          body_parts_data: [],
        };
      }
    })
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      return dateA.getTime() - dateB.getTime();
    });

  const filteredPainsWithPainLevel = filteredChartPains?.filter(
    (item) => item.global_pain_level !== null
  );

  // *******************************     STEPS COUNT DAYS    **********************************
  const stepsTargetDate = stepCountEndDate?.subtract(stepCountDays, "day");

  const stepCountsDatesRange = [];
  let currentStepCountDate = stepCountEndDate;
  while (
    currentStepCountDate?.isAfter(stepCountStartDate, "day") ||
    currentStepCountDate?.isSame(stepCountStartDate, "day")
  ) {
    stepCountsDatesRange?.push(currentStepCountDate?.format("YYYY-MM-DD"));
    if (currentStepCountDate?.isSame(stepsTargetDate, "day")) {
      break;
    }
    currentStepCountDate = currentStepCountDate?.subtract(1, "day");
  }

  const stepCountFilteredList = stepCountsDatesRange
    ?.map((date) => {
      const existingEntry = stepsCount?.step_counts?.find(
        (item) => item?.date === date
      );

      if (existingEntry) {
        return {
          ...existingEntry,
          missed: false,
        };
      } else {
        return {
          date,
          missed: true,
          agency_id: null,
        };
      }
    })
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      return dateA.getTime() - dateB.getTime();
    });

  // ******************************* ENDING  STEPS COUNT DAYS    **********************************

  // ******************* MEDICINES MODULE *******************************************

  // const filterMedicinesByDays = (
  //   filteredMedicinesDetails,
  //   medicineDaysValue
  // ) => {
  //   let startExpenseDate;
  //   let endExpenseDate;

  //   if (medicineDaysValue === "custom") {
  //     startExpenseDate = dayjs(expenseTypeFromDate);
  //     endExpenseDate = dayjs(expenseTypeToDate);
  //   } else {
  //     startExpenseDate = dayjs().subtract(medicineDaysValue, "day");
  //     endExpenseDate = dayjs();
  //   }

  //   const filteredMedicinesStats = filteredMedicinesDetails?.filter(
  //     (expense) => {
  //       const expenseDate = dayjs(expense?.date);
  //       return expenseDate?.isBetween(
  //         startExpenseDate,
  //         endExpenseDate,
  //         null,
  //         "[]"
  //       );
  //     }
  //   );

  //   return filteredMedicinesStats;

  //   // Filter by expense type
  //   // const filteredExpensesType = filteredExpenses?.filter((expense) => {
  //   //   const isSelectedExpenseType =
  //   //     checkedItems[expenseTypeValues[expense.expense_type.toLowerCase()]];
  //   //   return isSelectedExpenseType;
  //   // });

  //   // return filteredExpensesType;
  // };

  // ******************* MEDICINES MODULE *******************************************

  useEffect(() => {
    if (stepsCount?.ok) {
      // setStepCountsArr(stepsCount?.step_counts);
      const todayDate = stepsCount?.step_counts?.find(
        (item) => item?.date === dayjs()?.format("YYYY-MM-DD")
      );

      if (todayDate) {
        setTodayStepCount(todayDate || 0);
      }

      dispatch(resetStepCounts());
    }
  }, [caseDetail?.case?.id, stepsCount]);

  const missedPainLogsEntries = filteredPains?.filter(
    (item) => item?.missed === false
  );

  // ================ EXPORTING FUNCTIONS =================

  const exportAsPngHandler = () => {
    if (!chartRef.current) return;

    const canvas = chartRef.current.canvas;
    const ctx = canvas.getContext("2d");

    ctx.save();

    // Set the background color to white
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Convert the canvas to a PNG image
    const dataUrl = canvas.toDataURL("image/png", 1);

    // Restore the original state of the canvas
    ctx.restore();

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = `${patientTitle}_chart.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setExportChartEl(null);
  };

  const exportAsPdfHandler = async () => {
    const chartElement = chartRef.current;

    if (!chartElement) {
      return;
    }

    const beginDate = onlyDateFormat(filteredPains[0]?.date);
    const endingDate = onlyDateFormat(
      filteredPains[filteredPains?.length - 1]?.date
    );

    try {
      const patientTitle = caseDetail?.patient?.name;

      // const caseNumber = `${
      //   caseDetail?.case?.case_type && caseDetail?.case?.case_type
      // } #${caseDetail?.case?.case_number}`;

      const painLogs = "Pain Logs";
      const dateRange = `${endingDate} - ${beginDate}`;
      const sourceText = "Source: VitaTrax";

      const dataUrl = chartElement.toBase64Image("image/png", 1);

      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      pdf.setFontSize(20);
      // pdf.setTextColor(0, 0, 0);
      // pdf.setTextAlpha(0.87);
      pdf.text(patientTitle, 15, 10);

      pdf.setFontSize(16);
      // pdf.text(caseNumber, 15, 18);
      pdf.text(painLogs, 15, 25);
      pdf.setTextColor("#566875");
      pdf.text(dateRange, 50, 25);

      pdf.setFontSize(10);
      pdf.addImage(
        dataUrl,
        "JPEG",
        15,
        30,
        pdf.internal.pageSize.width - 20,
        pdf.internal.pageSize.height - 40
      );

      pdf.setFontSize(12);
      pdf.text(
        sourceText,
        pdf.internal.pageSize.width - 50,
        pdf.internal.pageSize.height - 5
      );
      pdf.save(`${patientTitle}_chart.pdf`);
    } catch (error) {
      console.error("Error exporting to PDF:", error);
    }
    setExportChartEl(null);
  };

  const exportCsvHandler = () => {
    dispatch(
      getClientPainlogsFunc(
        caseDetail?.patient?.id,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        "all"
      )
    );
    setExportChartEl(null);
  };

  useEffect(() => {
    if (painlogs?.ok) {
      const csvData = exportCsvData(filteredPains);
      setExportedCsvData(csvData);
      // setFromCustomDate(null);
      // setToCustomDate(null);
    }
  }, [painlogs]);

  // const addCaseInBillingHandler = () => {
  //   const caseIdObj = {
  //     case_ID: caseDetail?.case?.id,
  //   };

  //   // dispatch(addCaseToBillingSubscriptionFunc(caseIdObj));
  // };

  useEffect(() => {
    if (caseAddedToSubscription?.ok) {
      setCaseAddedInPlan(true);
      setShowBillingPlan(false);
      setOpenSnackbar(true);
      setConfirmPlan(false);
      dispatch(resetAddCaseToSubscription());
    }
  }, [caseAddedToSubscription]);

  const sendPainReminderHandler = (date) => {
    setLoadingDate(date);
    const painObj = {
      agency_id: agencyId,
      patient_id: caseDetail?.patient?.id,
      date: dayjs(date).format("YYYY-MM-DD"),
    };

    dispatch(sendPainLogReminderFunc(painObj));
  };

  useEffect(() => {
    if (sentReminder?.ok) {
      setLoadingDate(null);
      dispatch(resetSendPainLogReminder());
      setOpenSnackbarReminder(true);
    }
  }, [sentReminder]);

  const refreshStepCountHandler = () => {
    setLoadingStepCount(true);
  };

  return (
    <>
      {openSnackbarReminder && (
        <Snackbar
          open={openSnackbarReminder}
          autoHideDuration={2000}
          onClose={() => setOpenSnackbarReminder(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={() => setOpenSnackbarReminder(false)}
            severity="success"
            color="success"
            sx={{ width: "100%" }}
          >
            Reminder sent
          </Alert>
        </Snackbar>
      )}

      {/* OPEN CUSTOM PAIN DAYS ALERT MODAL */}
      {openCustomPainLogDays && (
        <AlertDialog
          openModal={openCustomPainLogDays}
          closeModal={() => {
            setPainLogDays(14);
            closeCustomPainLogDaysHandler();
          }}
          data={{ title: "Date Range" }}
          submitText="Ok"
          formSubmitFunction={customPainDateHandler}
        >
          <CustomPainDateRange
            fromCustomDate={fromCustomDate}
            isTouchedCustomDate={isTouchedCustomDate}
            // customDateError={customDateError}
            toCustomDate={toCustomDate}
            setFromCustomDate={setFromCustomDate}
            setToCustomDate={setToCustomDate}
          />
        </AlertDialog>
      )}

      {/* OPEN CUSTOM STEP DAYS ALERT */}
      {openCustomStepCountDays && (
        <AlertDialog
          openModal={openCustomStepCountDays}
          closeModal={closeCustomPainLogDaysHandler}
          data={{ title: "Date Range" }}
          submitText="Ok"
          formSubmitFunction={customStepCountDaysHandler}
        >
          <CustomPainDateRange
            fromCustomDate={stepCountFromDate}
            toCustomDate={stepCountToDate}
            isTouchedCustomDate={isTouchedStepCountDate}
            setFromCustomDate={setStepCountFromDate}
            setToCustomDate={setStepCountToDate}
          />
        </AlertDialog>
      )}

      {/* OPEN CUSTOM MEDICINE STATS DAYS ALERT */}
      {openCustomMedicineStatsDays && (
        <AlertDialog
          openModal={openCustomMedicineStatsDays}
          closeModal={closeCustomPainLogDaysHandler}
          data={{ title: "Date Range" }}
          submitText="Ok"
          formSubmitFunction={customMedicineStatsDaysHandler}
        >
          <CustomPainDateRange
            fromCustomDate={medicineCustomFromDate}
            toCustomDate={medicineCustomToDate}
            isTouchedCustomDate={isTouchedMedicineDate}
            setFromCustomDate={setMedicineCustomFromDate}
            setToCustomDate={setMedicineCustomToDate}
          />
        </AlertDialog>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        gap="16px"
        position="relative"
      >
        {openSnackbar && (
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={closeSnackbarHandler}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" />,
              }}
              sx={{ fontSize: "16px", fontWeight: 500 }}
            >
              Case added to your current billing Plan{" "}
            </Alert>
          </Snackbar>
        )}
        <Stack gap={2} width="100%">
          {/* LINE CHART CARD ROW */}
          <CardUI
            maxWidth="100%"
            dashboard="true"
            padding="24px"
            height="100%"
            // overflow={
            //   billingSubscription?.subscription &&
            //   !billingSubscription?.subscription?.custom_subscription &&
            //   !caseAddedInPlan &&
            //   "hidden"
            // }
          >
            <Stack
              gap="16px"
              position="relative"
              height={painlogs?.pain_entries?.length <= 0 ? "100%" : "auto"}
            >
              {/* {billingSubscription?.subscription &&
                !billingSubscription?.subscription?.custom_subscription &&
                !caseAddedInPlan &&
                !loadingCaseDetail &&
                !loadingBilling &&
                !loading && (
                  <Stack
                    gap="10px"
                    padding="24px 16px"
                    alignItems="center"
                    position="absolute"
                    top="0"
                    left="50%"
                    sx={{ transform: "translate(-50%, 50%)" }}
                    maxWidth="441px"
                    width="100%"
                    bgcolor="#fff"
                    borderRadius="8px"
                    boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
                    zIndex="1"
                  >
                    <Box width="67px">
                      <img src={restrictedImg} alt="restricted image" />
                    </Box>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      fontSize="18px"
                    >
                      Limited Access
                    </Typography>
                    <Typography variant="body2">
                      This case is not part of your current plan, resulting in
                      limited access. To add it to your plan and gain full
                      access, click below.{" "}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setShowBillingPlan(true);
                        setBillingActive(true);
                      }}
                    >
                      Add Case to plan
                    </Button>
                  </Stack>
                )} */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  textTransform="uppercase"
                  fontSize="1rem"
                >
                  Client's Pain Logs
                </Typography>
                <Stack gap="8px" direction="row">
                  {/* CUSTOM DATE RANGE UI */}
                  {painLogDays === "custom" &&
                    fromCustomDate &&
                    toCustomDate && (
                      <IconButton
                        onClick={() => setOpenCustomPainLogDays(true)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "3px",
                          borderRadius: "4px",
                          bgcolor: "#F4F6F8",
                          "&:hover": {
                            bgcolor: "#F4F6F8", // Change this color to your desired hover background color
                          },
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="#0D0D0D"
                          padding="0 6px"
                        >
                          {dateWithoutYearFormat(fromCustomDate)}
                        </Typography>
                        <RightPainArrow />
                        <Typography
                          variant="caption"
                          color="#0D0D0D"
                          padding="0 6px"
                        >
                          {dateWithoutYearFormat(toCustomDate)}
                        </Typography>
                        <CalendarPainIcon />
                      </IconButton>
                    )}

                  {/* FILTER DAYS  */}
                  <FormControl
                  // disabled={
                  //   billingSubscription?.subscription &&
                  //   !billingSubscription?.subscription?.custom_subscription &&
                  //   !caseAddedInPlan
                  // }
                  >
                    <FilterDaysDropDown
                      labelId="painlogDays"
                      id="painlogDays"
                      value={painLogDays}
                      iconComponent={ExpandMoreOutlinedIcon}
                      caseAddedInPlan={caseAddedInPlan}
                      changeFilterHandler={changePainDaysHandler}
                    />
                  </FormControl>

                  <BodyPartsFilter
                    // billingActive={
                    //   !(
                    //     billingSubscription?.subscription &&
                    //     !billingSubscription?.subscription
                    //       ?.custom_subscription &&
                    //     !caseAddedInPlan
                    //   )
                    // }
                    filterButtonRef={filterButtonRef}
                    selectBodyPartsHandler={selectBodyPartsHandler}
                    bodyPartsAnchorEl={bodyPartsAnchorEl}
                    selectedBodyParts={selectedBodyParts}
                    changeBodyPartsHandler={changeBodyPartsHandler}
                    allBodyPartsData={allBodyPartsData}
                    setSelectedBodyParts={setSelectedBodyParts}
                    loading={loadingChartLogs}
                    loadingCaseDetail={loadingCaseDetail}
                  />
                </Stack>
              </Box>
              {loading || loadingChartLogs || loadingCaseDetail ? (
                <CaseChartSkeleton />
              ) : (
                <>
                  {painlogs?.pain_entries?.length > 0 ? (
                    <Stack
                      position="relative"
                      gap="24px"
                      sx={{
                        "& canvas": {
                          width: "100% !important",
                          height: "100% !important",
                        },
                      }}
                    >
                      {/* LINE CHART */}
                      <Stack bgcolor="#FCFCFC" borderRadius="8px">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="12px"
                          padding="8px 26px"
                        >
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="body1"
                              color="secondary"
                              fontSize="0.875rem"
                            >
                              Showing
                            </Typography>{" "}
                            <FormControl sx={{ marginLeft: "0.5rem" }}>
                              <Select
                                labelId="pains"
                                id="pains"
                                value={selectedPain}
                                IconComponent={ExpandMoreOutlinedIcon}
                                displayEmpty
                                size="small"
                                sx={{
                                  fontSize: "0.75rem",
                                  textTransform: "capitalize !important",
                                  marginRight: "10px !important",
                                  color: "#000 !important",
                                  borderRadius: "4px !important",
                                  background: "#F4F6F8",
                                  "& .MuiSelect-select": {
                                    padding: "0.2rem 0.25rem",
                                  },
                                  "&:before": {
                                    borderColor: "white !important",
                                  },
                                  "&:after": {
                                    borderColor: "white !important",
                                  },
                                  boxShadow: "none !important",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: "0  !important",
                                  },

                                  ".MuiSvgIcon-root ": {
                                    fill: "#000 !important",
                                  },
                                }}
                                onChange={changePainHandler}
                              >
                                {painOptions?.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>{" "}
                            <Typography
                              variant="body1"
                              color="secondary"
                              fontSize="0.875rem"
                            >
                              pain for days with multiple entries.
                            </Typography>
                          </Box>

                          {/* EXPORT ICON PNG, PDF */}
                          <IconButton
                            sx={{
                              backgroundColor: "#F4F6F8",
                              borderRadius: "4px",
                              height: "28px",
                              padding: "3px 8px",
                            }}
                            id="export-chart-btn"
                            aria-controls={
                              openExportChart ? "export-chart-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openExportChart ? "true" : undefined}
                            onClick={openExportListHandler}
                          >
                            <ExportIcon />
                          </IconButton>
                          <Menu
                            sx={{
                              "& .MuiPaper-root": {
                                minWidth: 142,
                              },
                            }}
                            id="export-chart-menu"
                            anchorEl={exportChartEl}
                            open={openExportChart}
                            onClose={exportChartCloseHandler}
                            MenuListProps={{
                              "aria-labelledby": "export-chart-btn",
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem onClick={exportAsPngHandler}>
                              PNG
                            </MenuItem>
                            <MenuItem onClick={exportAsPdfHandler}>
                              PDF
                            </MenuItem>
                            <MenuItem>
                              <CSVLink
                                asyncOnClick={true}
                                onClick={exportCsvHandler}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                  "&:hover": {
                                    color: "inherit",
                                    textDecoration: "none",
                                  },
                                }}
                                data={exportedCsvData}
                                filename={`${patientTitle}.csv`}
                              >
                                CSV
                              </CSVLink>
                            </MenuItem>
                          </Menu>
                        </Box>

                        {missedPainLogsEntries?.length === 0 ? (
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            height="335px"
                          >
                            <Avatar
                              src={EmptyBox}
                              alt="coming soon"
                              sx={{ width: "128px", height: "128px" }}
                              gap="8px"
                            />
                            <Typography
                              variant="body1"
                              color="secondary"
                              textAlign="center"
                            >
                              No data available for this date range, <br /> try
                              picking different date range
                            </Typography>
                          </Stack>
                        ) : (
                          <Box>
                            <CurveChart
                              chartRef={chartRef}
                              allDates={filteredChartPains}
                              painEntries={filteredPainsWithPainLevel}
                              selectedBodyParts={selectedBodyParts}
                            />
                          </Box>
                        )}
                      </Stack>

                      {/* PAIN LISTINGS */}
                      <Stack gap="8px" height="400px" overflow="auto">
                        {filteredPains?.reverse()?.map((data, i) =>
                          // selectedBodyPart === "all" ? (
                          data?.missed ? (
                            <Box
                              key={i}
                              display="flex"
                              gap="24px"
                              bgcolor="#FCFCFC"
                              padding="8px 16px"
                              borderRadius="8px"
                              width="100%"
                            >
                              <PainLogsDate date={data?.date} />
                              <Stack
                                gap="2px"
                                flex="1"
                                bgcolor="#D32F2F26"
                                borderRadius="8px"
                                padding="12px 10px"
                                textAlign="center"
                                color="#E76072"
                              >
                                <Typography
                                  variant="body1"
                                  fontSize="12px"
                                  fontWeight={600}
                                >
                                  {" "}
                                  Missed
                                </Typography>
                                {loadingDate === data?.date ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <CircularProgress
                                      size={20}
                                      sx={{ color: "#BC4D4D" }}
                                    />
                                  </Box>
                                ) : (
                                  caseDetail?.patient?.enrollment_status ===
                                    "enrolled" &&
                                  isCurrentMonthAndYear(data?.date) &&
                                  (dayjs(
                                    caseDetail?.patient?.initial_setup_done_at
                                  )?.isSame(data?.date) ||
                                    dayjs(
                                      caseDetail?.patient?.initial_setup_done_at
                                    )?.isBefore(data?.date)) && (
                                    <Typography
                                      variant="body2"
                                      color="rgba(0, 0, 0, 0.38)"
                                      sx={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        sendPainReminderHandler(data?.date)
                                      }
                                    >
                                      Send reminder to log pain
                                    </Typography>
                                  )
                                )}
                              </Stack>
                            </Box>
                          ) : (
                            <React.Fragment key={i}>
                              {data?.body_parts_data?.length ? (
                                <Box
                                  key={i}
                                  display="flex"
                                  gap="24px"
                                  bgcolor="#FCFCFC"
                                  padding="16px"
                                  borderRadius="8px"
                                  width="100%"
                                >
                                  <PainLogsDate date={data?.date} />
                                  <Box
                                    sx={{
                                      width: "100%",
                                      padding: "0 !important",
                                      // paddingLeft: "84px !important",
                                      paddingBottom: "12px !important",
                                      paddingRight: "16px !important",
                                    }}
                                  >
                                    {data?.body_parts_data?.map(
                                      (bodyPart, i) => (
                                        <PainLogDetail
                                          key={i}
                                          index={i}
                                          bodyPart={bodyPart}
                                          data={data}
                                        />
                                      )
                                    )}
                                  </Box>
                                </Box>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </Stack>

                      {/* <CollapsableUIBox
                        showListings={showPainLogsListing}
                        toggleCollapsableBoxHandler={() =>
                          setShowPainLogsListing((prev) => !prev)
                        }
                      /> */}
                    </Stack>
                  ) : (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      height="335px"
                    >
                      <Avatar
                        src={EmptyBox}
                        alt="coming soon"
                        sx={{ width: "128px", height: "128px" }}
                        gap="8px"
                      />
                      <Typography
                        variant="body1"
                        color="secondary"
                        textAlign="center"
                      >
                        No pain logs available.
                        <br />
                        Once user begin logging pain, it will start showing
                        here.
                      </Typography>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          </CardUI>
        </Stack>

        <Stack maxWidth="362px" width="100%" gap="16px">
          {/* STEP COUNT  */}
          <Stack
            bgcolor="#fff"
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            borderRadius="8px"
            sx={{
              padding: "24px",
              position: "relative",
            }}
            gap="24px"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                color="secondary"
                fontSize="1rem"
                textTransform="uppercase"
              >
                steps count
              </Typography>
              <Box display="flex" alignItems="center" gap="8px">
                <FormControl>
                  <FilterDaysDropDown
                    labelId="stepCounts"
                    id="stepCounts"
                    value={stepCountDays}
                    iconComponent={ExpandMoreOutlinedIcon}
                    caseAddedInPlan={caseAddedInPlan}
                    changeFilterHandler={changeStepCountDaysHandler}
                    // hideCustom={true}
                  />
                </FormControl>

                {/* <IconButton
                  sx={{
                    borderRadius: "4px",
                    bgcolor: "#F4F6F8",
                    padding: "7px",
                  }}
                >
                  <RefreshIcon />
                </IconButton> */}
              </Box>
            </Box>
            {stepsCount?.step_counts?.length ? (
              <Typography variant="body2" fontSize="16px" color="secondary">
                Today's Step Count
              </Typography>
            ) : (
              <></>
            )}
            {loadingCaseDetail || loadingStepsCount ? (
              <RectangleSkeletons height={50} totalRows={1} />
            ) : stepsCount?.step_counts?.length ? (
              <Typography variant="h3" color="secondary" fontSize="40px">
                {todayStepCount ? todayStepCount?.step_count : 0}
                <Box component="span" fontSize="16px" fontWeight={400}>
                  /4000 Steps{" "}
                </Box>
              </Typography>
            ) : (
              <></>
            )}
            {!loadingCaseDetail &&
            !loadingStepsCount &&
            stepsCount?.step_counts?.length === 0 ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                height="150px"
                gap="8px"
              >
                <Avatar
                  src={EmptyBox}
                  alt="coming soon"
                  sx={{ width: "128px", height: "128px" }}
                />

                <Typography
                  variant="body2"
                  color="secondary"
                  textAlign="center"
                >
                  No data available{" "}
                </Typography>
              </Stack>
            ) : (
              <Stack gap="4px">
                {loadingStepsCount || loadingCaseDetail ? (
                  <RectangleSkeletons height={157} totalRows={1} />
                ) : stepCountFilteredList?.length > 0 ? (
                  <StepCountChart
                    chartRef={stepCountChartRef}
                    allDates={stepCountFilteredList}
                  />
                ) : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="375px"
                  >
                    <Avatar
                      src={EmptyBox}
                      alt="coming soon"
                      sx={{
                        width: "128px",
                        height: "128px",
                        paddingBottom: "24px",
                      }}
                    />
                    <Typography variant="body1" color="secondary" pb={2}>
                      No data available
                    </Typography>
                  </Stack>
                )}

                <Typography variant="caption" color="#A1A1AA" fontWeight={500}>
                  {getStepCountDaysText(stepCountDays)}
                </Typography>
              </Stack>
            )}

            {stepsCount?.step_counts?.length !== 0 && (
              <CollapsableUIBox
                showListings={showStepsCount}
                toggleCollapsableBoxHandler={() =>
                  setShowStepsCount((prev) => !prev)
                }
              />
            )}
            {showStepsCount && (
              <>
                {loadingStepsCount || loadingCaseDetail ? (
                  <RectangleSkeletons height={55} totalRows={3} />
                ) : (
                  <Stack gap="8px" height="326px" overflow="auto">
                    {stepCountFilteredList?.length &&
                      stepCountFilteredList
                        ?.filter((item) => item?.missed === false)
                        ?.reverse()
                        ?.map((item, index) => (
                          <StepCountDetailCard
                            key={index}
                            stepCount={item?.step_count}
                            date={item?.date}
                            distance="-"
                          />
                        ))}
                  </Stack>
                )}
              </>
            )}
          </Stack>

          {/* MEDICINE CARD */}
          <Stack
            bgcolor="#fff"
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            borderRadius="8px"
            sx={{
              padding: "24px",
              position: "relative",
            }}
            gap="24px"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1" color="secondary" fontSize="1rem">
                MEDICINE{" "}
              </Typography>
              <FormControl>
                <FilterDaysDropDown
                  labelId="medicineDays"
                  id="medicineDays"
                  value={medicineDays}
                  iconComponent={ExpandMoreOutlinedIcon}
                  caseAddedInPlan={caseAddedInPlan}
                  changeFilterHandler={changeMedicineDaysHandler}
                />
              </FormControl>
            </Box>

            {!medicinesNotPrescribedDays &&
            !medicinesTakenDays &&
            !medicinesNotTakenDays ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                height="287px"
                gap="8px"
              >
                <Avatar
                  src={EmptyBox}
                  alt="coming soon"
                  sx={{ width: "128px", height: "128px" }}
                />

                <Typography
                  variant="body2"
                  color="secondary"
                  textAlign="center"
                >
                  No medicine data available for this date range.
                </Typography>
              </Stack>
            ) : (
              <>
                {" "}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="12px"
                  flexWrap="wrap"
                >
                  {loadingMedicineStats ? (
                    <RectangleSkeletons width="50%" height={20} totalRows={2} />
                  ) : (
                    <>
                      <Stack
                        borderLeft="4px solid #3F3CD8"
                        pl={1}
                        height="100%"
                      >
                        <Typography variant="body2" fontWeight={600}>
                          Medicine taken{" "}
                        </Typography>
                        <Typography variant="body2">
                          {medicinesTakenDays}{" "}
                          {medicinesTakenDays === 1 ? "day" : "days"}
                        </Typography>
                      </Stack>

                      <Stack
                        borderLeft="4px solid #FE7878"
                        pl={1}
                        height="100%"
                      >
                        <Typography variant="body2" fontWeight={600}>
                          No Medicine taken{" "}
                        </Typography>
                        <Typography variant="body2">
                          {medicinesNotTakenDays}{" "}
                          {medicinesNotTakenDays === 1 ? "day" : "days"}
                        </Typography>
                      </Stack>

                      <Stack
                        borderLeft="4px solid #FAD44D"
                        pl={1}
                        height="100%"
                      >
                        <Typography variant="body2" fontWeight={600}>
                          Not Prescribed{" "}
                        </Typography>
                        <Typography variant="body2">
                          {medicinesNotPrescribedDays}{" "}
                          {medicinesNotPrescribedDays === 1 ? "day" : "days"}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Box>
                <Stack
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box width="199.463px" height="199.463px">
                    {loadingMedicineStats ? (
                      <RectangleSkeletons
                        width="100%"
                        height="199.463px"
                        totalRows={1}
                      />
                    ) : (
                      <DoughnutChartUI
                        data={{
                          labels: [
                            "Medicine taken",
                            "No Medicine taken",
                            "Not Prescribed",
                          ],
                          datasets: [
                            {
                              label: "Medicine",
                              data: [
                                medicinesTakenDays,
                                medicinesNotTakenDays,
                                medicinesNotPrescribedDays,
                              ],
                              backgroundColor: [
                                "#3F3CD8",
                                "#FE7878",
                                "#FAD44D",
                              ],
                            },
                          ],
                        }}
                      />
                    )}
                  </Box>
                </Stack>{" "}
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default OverviewTab;
