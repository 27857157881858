import { Alert, Button, Snackbar, Stack } from "@mui/material";
import React from "react";

const AlertMessage = ({
  message,
  severity,
  open,
  handleClose,
  width,
  autoHideDurationTime = 3000,
  textAlign = "center",
  width100,
  children,
  bgcolor,
}) => {
  //   const handleClick = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = (event, reason) => {
  //     if (reason === "clickaway") {
  //       return;
  //     }

  //     setOpen(false);
  //   };

  return (
    <Stack spacing={2} sx={{ width: width100 && width100 }}>
      {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar
        sx={{ width: width && width }}
        open={open}
        autoHideDuration={autoHideDurationTime}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{
            width: "100%",
            textAlign: textAlign,
            bgcolor: bgcolor && bgcolor,
          }}
        >
          {children ? children : message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default AlertMessage;
