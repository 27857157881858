import React, { useEffect, useState } from "react";
import CommonDrawer from "../../common/CommonDrawer";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPatientsFunc,
  getPatientDetailByPatientId,
  resetPatientDetailByPatientId,
  resetUpdatePatient,
  updateCaseFunc,
} from "../../actions/Patients";
import CircularProgressLoading from "../../common/CircularProgress";
import AlertMessage from "../../common/AlertMessage";
import {
  resetUpdatedTeamMember,
  updateAgentTeamMembersFunc,
} from "../../actions/Agency";
import { CastForEducationTwoTone } from "@mui/icons-material";
import dayjs from "dayjs";
import { segmentError, segmentTrack } from "../../utils/helpers/tracking_utils";
import {
  caseTypeOptions,
  clearContact,
  findOptionByValue,
  formatPhoneNumber,
} from "../../utils/helpers";
import { appInsights } from "../../utils/helpers/AppInsights";
import { statesWithCities } from "../../utils/helpers/states_with_cities";

const states = Object.keys(statesWithCities);

const EditPatientDrawer = ({
  anchor,
  openEditCase,
  setOpenEditCase,
  onClose,
  selectedRowCaseId,
  fromCaseOverview,
  page,
  pageSize,
  agencyId,
  query,
  assignedTo,
  enrollmentStatus,
  educationGiven,
  consentStatusValue,
  sentCredentialsValue,
  selectedItems,
  activeTab,
}) => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: null,
    address: "",
    contactNumber: "",
    state: null,
    city: "",
    zipCode: "",
  });

  const dispatch = useDispatch();
  const patientDetailByPatientId = useSelector(
    (state) => state.patientDetailByPatientId
  );
  const { loading, error, patientDetail } = patientDetailByPatientId;

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const updateCase = useSelector((state) => state.updateCase);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    updatedCase,
  } = updateCase;

  const updateAgentTeamMembers = useSelector(
    (state) => state.updateAgentTeamMembers
  );
  const {
    loading: loadingUpdateMembers,
    error: errorUpdateMembers,
    updatedAgentTeamMembers,
  } = updateAgentTeamMembers;

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentSelf,
    error: errorAgentSelf,
    agentUser,
  } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!fromCaseOverview && selectedRowCaseId) {
      dispatch(getPatientDetailByPatientId(selectedRowCaseId));
    }
  }, [selectedRowCaseId]);

  useEffect(() => {
    if (patientDetail?.ok) {
      setFormValues({
        firstName: patientDetail?.patient?.first_name
          ? patientDetail?.patient?.first_name
          : "",
        lastName: patientDetail?.patient?.last_name
          ? patientDetail?.patient?.last_name
          : "",
        email: patientDetail?.patient?.email
          ? patientDetail?.patient?.email
          : "",
        dob: patientDetail?.patient?.dob
          ? dayjs(patientDetail?.patient?.dob)
          : null,

        state: patientDetail?.patient?.region
          ? patientDetail?.patient?.region
          : null,
        city: patientDetail?.patient?.city ? patientDetail?.patient?.city : "",
        address: patientDetail?.patient?.address
          ? patientDetail?.patient?.address
          : "",
        zipCode: patientDetail?.patient?.zipcode
          ? patientDetail?.patient?.zipcode
          : "",
        contactNumber: patientDetail?.patient?.contact_number
          ? patientDetail?.patient?.contact_number
          : "",
      });
    }
  }, [patientDetail, selectedRowCaseId]);

  const editCaseHandler = async (e) => {
    e.preventDefault();

    const patient_profile = {
      first_name: formValues?.firstName,
      last_name: formValues?.lastName,
      email: formValues?.email,
      dob: formValues?.dob?.utcOffset(0, true),
      address: formValues?.address,
      contact_number: formValues?.contactNumber,
      region: formValues?.state,
      city: formValues?.city,
      zipcode: formValues?.zipCode,
    };

    const patientData = {
      patient_id: patientDetail?.patient?.id,
      patient_profile,
    };

    dispatch(updateCaseFunc(patientData));

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "case_updated",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  useEffect(() => {
    if (updatedCase?.ok && fromCaseOverview) {
      setOpenEditCase(false);
    } else if (errorUpdate) {
      appInsights.trackTrace({
        message: `Error while editing case: ${
          errorUpdateMembers || "Could not edit the case!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_EDIT_CASE",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorUpdateMembers || "Could not edit the case!"}`,
      });
    }
  }, [updatedCase, errorUpdate]);

  useEffect(() => {
    if (updatedCase?.ok) {
      dispatch(getPatientDetailByPatientId(selectedRowCaseId));
      dispatch(resetUpdatePatient());
      const listData = {
        agency_id: agencyId,
        page: page,
        pageSize: pageSize,
        search: query,
        assigned_to: assignedTo,
        enrollment_status:
          activeTab === "bulk_imported" &&
          (enrollmentStatus === "" || enrollmentStatus === "all")
            ? ""
            : enrollmentStatus,
        cpt_codes: selectedItems,
        sort: {},
        tab: activeTab,
      };

      if (educationGiven !== "all") {
        listData.education_given = educationGiven;
      }

      if (consentStatusValue !== "all") {
        listData.tracking_consent_given = consentStatusValue;
      }
      if (sentCredentialsValue !== "all") {
        listData.credentials_sent = sentCredentialsValue;
      }

      dispatch(getAllPatientsFunc(listData));

      setOpenEditCase(false);
    } else if (errorUpdate) {
      appInsights.trackTrace({
        message: `Error while editing case: ${
          errorUpdateMembers || "Could not edit the case!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_EDIT_CASE",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorUpdateMembers || "Could not edit the case!"}`,
      });
    }
  }, [updatedCase, errorUpdate]);

  const contactNumberHandler = (e) => {
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setFormValues({ ...formValues, contactNumber: formattedNumber });
  };

  const isDisabled = !formValues?.firstName;

  return (
    <CommonDrawer
      anchor={anchor}
      open={openEditCase}
      setOpen={setOpenEditCase}
      onClose={onClose}
      height="100%"
    >
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {!loadingUpdate && errorUpdate && (
        <AlertMessage
          message={errorUpdate}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {loading ? (
        <CircularProgressLoading height="100vh" />
      ) : (
        <Stack gap="24px" height="100%" justifyContent="space-between">
          <Stack gap="24px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pb="32px"
            >
              <Typography variant="h5">Edit Patient</Typography>

              <IconButton
                size="small"
                onClick={() => {
                  setOpenEditCase(false);
                }}
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Box>
            <Typography
              variant="boxHeading"
              color="secondary"
              textTransform="uppercase"
            >
              Basic information{" "}
            </Typography>

            <Box display="flex" alignItems="center" width="100%" gap="24px">
              {/* FIRST NAME INPUT */}
              <TextField
                fullWidth
                required
                id="firstName"
                label="First Name"
                name="firstName"
                variant="outlined"
                value={formValues?.firstName}
                onChange={(e) => {
                  setFormValues({ ...formValues, firstName: e.target.value });
                }}
              />

              {/* LAST NAME FIELD */}
              <TextField
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                variant="outlined"
                value={formValues?.lastName}
                onChange={(e) =>
                  setFormValues({ ...formValues, lastName: e.target.value })
                }
              />
            </Box>

            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              value={formValues?.email}
              onChange={(e) =>
                setFormValues({ ...formValues, email: e.target.value })
              }
              // required
              // InputProps={{
              //   readOnly: true,
              // }}
            />
            <Box display="flex" alignItems="center" gap="24px">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  name="dob"
                  value={formValues?.dob}
                  // maxDate={dayjs()}
                  disableFuture
                  onChange={(newValue) =>
                    setFormValues({ ...formValues, dob: newValue })
                  }
                  onOpen={() => setIsPickerOpen(true)}
                  onClose={() => setIsPickerOpen(false)}
                  open={isPickerOpen}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      onClick: () => setIsPickerOpen(true),
                    },
                  }}
                />
              </LocalizationProvider>
              <TextField
                id="contactNumber"
                name="contactNumber"
                label="Contact Number"
                variant="outlined"
                value={formValues?.contactNumber}
                onChange={contactNumberHandler}
                fullWidth
                required
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            {/* ADDRESS FIELDS  */}
            <Typography variant="h6" fontSize="16px" color="secondary">
              ADDRESS
            </Typography>

            <TextField
              id="address"
              label="Address"
              name="address"
              value={formValues?.address}
              onChange={(e) =>
                setFormValues({ ...formValues, address: e.target.value })
              }
              variant="outlined"
              fullWidth
            />

            <Box display="flex" alignItems="center" gap="24px" width="100%">
              <TextField
                id="city"
                placeholder="San Mateo"
                label="City"
                name="city"
                value={formValues?.city}
                onChange={(e) =>
                  setFormValues({ ...formValues, city: e.target.value })
                }
                variant="outlined"
                fullWidth
              />

              <Autocomplete
                options={states}
                fullWidth
                value={formValues?.state}
                onChange={(event, newValue) =>
                  setFormValues({ ...formValues, state: newValue })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State/Region"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                )}
                sx={{ marginTop: -1 }}
              />
            </Box>

            <Box display="flex" alignItems="center" gap="24px" width="100%">
              <TextField
                id="zipCode"
                name="zipCode"
                value={formValues?.zipCode}
                onChange={(e) => {
                  if (/^\d*$/g.test(e.target.value)) {
                    setFormValues({ ...formValues, zipCode: e.target.value });
                  }
                }}
                label="Zip/Code"
                variant="outlined"
                fullWidth
              />
            </Box>
          </Stack>
          <Box sx={{ alignSelf: "flex-end" }} paddingBottom="24px">
            <LoadingButton
              id="update-case-submit"
              color="primary"
              variant="contained"
              loading={loadingUpdate}
              disabled={isDisabled || loading}
              onClick={editCaseHandler}
            >
              Save changes
            </LoadingButton>
          </Box>
        </Stack>
      )}
    </CommonDrawer>
  );
};

export default EditPatientDrawer;
