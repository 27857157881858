import { Skeleton, Stack } from "@mui/material";
import React from "react";

const ChatSkeleton = () => {
  return (
    <Stack gap="14px" height="100%" justifyContent="flex-end">
      <Skeleton variant="rounded" width="35rem" height="7rem" />
      <Skeleton
        variant="rounded"
        width="25rem"
        height="3rem"
        sx={{ alignSelf: "flex-end" }}
      />
    </Stack>
  );
};

export default ChatSkeleton;
