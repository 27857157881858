// const AuthReducer = (state = {}, action) => {
//   switch (action.type) {
//     // ======================================== Sign In Reducer ========================================
//     case Actions.USER_LOGIN_REQUEST:
//       return { ...state, sinInLoading: true };
//     case Actions.USER_LOGIN_SUCCESS:
//       return {
//         ...state,
//         sinInLoading: false,
//         signInData: action.payload?.data || '',
//       };
//     case Actions.USER_LOGIN_FAIL:
//       return { ...state, sinInLoading: false, error: action.payload };

//     // ======================================== Sign Up Reducer ========================================
//     case Actions.USER_SIGN_UP_REQUEST:
//       return { ...state, signUpLoading: true };
//     case Actions.USER_SIGN_UP_SUCCESS:
//       return {
//         ...state,
//         signUpLoading: false,
//         signUpData: action.payload?.data || '',
//       };
//     case Actions.USER_SIGN_UP_FAIL:
//       return { ...state, signUpLoading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

import {
  ADD_PASSWORD_FAIL,
  ADD_PASSWORD_REQUEST,
  ADD_PASSWORD_SUCCESS,
  AGENT_TOKEN,
  FORGOT_EMAIL_VERIFY_FAIL,
  FORGOT_EMAIL_VERIFY_REQUEST,
  FORGOT_EMAIL_VERIFY_SUCCESS,
  OTP_VERIFY_FAIL,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  RESEND_CLIENT_CREDENTIALS_FAIL,
  RESEND_CLIENT_CREDENTIALS_REQUEST,
  RESEND_CLIENT_CREDENTIALS_SUCCESS,
  RESET_LOGIN,
  RESET_OTP_VERIFY,
  RESET_RESEND_CLIENT_CREDENTIALS,
  RESET_UPDATE_FORGOTTEN_PASSWORD,
  RESET_USER_FORGOT,
  RESET_USER_REGISTER,
  UPDATE_FORGOTTEN_PASSWORD_FAIL,
  UPDATE_FORGOTTEN_PASSWORD_REQUEST,
  UPDATE_FORGOTTEN_PASSWORD_SUCCESS,
  USER_FORGOT_FAIL,
  USER_FORGOT_REQUEST,
  USER_FORGOT_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from "../../assets/constants/userConstants";

// USER LOGIN REDUCER
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case RESET_LOGIN:
      return {};
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

// USER REGISTER REDUCER
export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_USER_REGISTER:
      return {};
    default:
      return {};
  }
};

// USER REGISTER REDUCER
export const userForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FORGOT_REQUEST:
      return { loading: true };
    case USER_FORGOT_SUCCESS:
      return { loading: false, res: action.payload };
    case USER_FORGOT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_USER_FORGOT:
      return {};
    default:
      return state;
  }
};

// // FORGOT EMAIL VERIFY REDUCER
// export const forgotEmailVerifyReducer = (state = {}, action) => {
//   switch (action.type) {
//     case FORGOT_EMAIL_VERIFY_REQUEST:
//       return { loading: true };
//     case FORGOT_EMAIL_VERIFY_SUCCESS:
//       return { loading: false, success: action.payload };
//     case FORGOT_EMAIL_VERIFY_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

// OTP VERIFY REDUCER
export const otpVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case OTP_VERIFY_REQUEST:
      return { loading: true, error: null };
    case OTP_VERIFY_SUCCESS:
      return { loading: false, res: action.payload, error: null };

    case OTP_VERIFY_FAIL:
      return { loading: false, error: action.payload };
    case RESET_OTP_VERIFY:
      return {};
    default:
      return state;
  }
};

// UPDATE FORGOTTEN PASSWORD REDUCER
export const updateForgottenPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FORGOTTEN_PASSWORD_REQUEST:
      return { loading: true };
    case UPDATE_FORGOTTEN_PASSWORD_SUCCESS:
      return { loading: false, res: action.payload };
    case UPDATE_FORGOTTEN_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UPDATE_FORGOTTEN_PASSWORD:
      return { };
    default:
      return state;
  }
};

// ADD PASSWORD REDUCER
export const addPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PASSWORD_REQUEST:
      return { loading: true, error: null };
    case ADD_PASSWORD_SUCCESS:
      return { loading: false, res: action.payload, error: null };
    case ADD_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// RESEND CLIENT CREDENTIALS REDUCER
export const resendClientCredentialsReducer = (state = {}, action) => {
  switch (action.type) {
    case RESEND_CLIENT_CREDENTIALS_REQUEST:
      return { loading: true };
    case RESEND_CLIENT_CREDENTIALS_SUCCESS:
      return { loading: false, clientCreds: action.payload };
    case RESEND_CLIENT_CREDENTIALS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_RESEND_CLIENT_CREDENTIALS:
      return {};
    default:
      return state;
  }
};
