import axios from "axios";
import { BASE_URL } from "..";
import {
  ASSIGN_BULK_PATIENTS_FAIL,
  ASSIGN_BULK_PATIENTS_REQUEST,
  ASSIGN_BULK_PATIENTS_SUCCESS,
  EXPORT_BULK_EVIDENCE_FAIL,
  EXPORT_BULK_EVIDENCE_REQUEST,
  EXPORT_BULK_EVIDENCE_SUCCESS,
  GET_IMPORT_PATIENTS_FAIL,
  GET_IMPORT_PATIENTS_REQUEST,
  GET_IMPORT_PATIENTS_SUCCESS,
  IMPORT_PATIENT_FILE_FAIL,
  IMPORT_PATIENT_FILE_REQUEST,
  IMPORT_PATIENT_FILE_SUCCESS,
  IMPORT_STATUS_FAIL,
  IMPORT_STATUS_REQUEST,
  IMPORT_STATUS_SUCCESS,
  RESET_ASSIGN_BULK_PATIENTS,
  RESET_EXPORT_BULK_EVIDENCE,
  RESET_IMPORT_PATIENT_FILE,
  RESET_IMPORT_STATUS,
  SET_IMPORT_ID,
} from "../../assets/constants/importFileConstants";
import Cookies from "js-cookie";

// ================== IMPORT PATIENT FILE ACTION  =========================
export const importPatientFileFunc = (formData) => async (dispatch) => {
  try {
    dispatch({ type: IMPORT_PATIENT_FILE_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient/import-from-file`,
        formData,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: IMPORT_PATIENT_FILE_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: IMPORT_PATIENT_FILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetImportFile = () => (dispatch) =>
  dispatch({ type: RESET_IMPORT_PATIENT_FILE });

// ================== SET IMPORT ID ACTION  =========================
export const setImportIdFunc = (importId) => async (dispatch) => {
  dispatch({ type: SET_IMPORT_ID, payload: importId });
};

// ================== GET IMPORT PATIENTS FILE ACTION  =========================
export const getImportPatientsFunc =
  (importId, page, pageSize) => async (dispatch) => {
    try {
      dispatch({ type: GET_IMPORT_PATIENTS_REQUEST });

      const token = Cookies.get("token");
      const agencyId = Cookies.get("agency_id_vita");

      if (token) {
        // userInfo = JSON.parse(userInfo);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/patient/get-temp-patients?import_id=${importId}&agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
          config
        );
        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_IMPORT_PATIENTS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_IMPORT_PATIENTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// export const resetImportFile = () => (dispatch) =>
//   dispatch({ type: RESET_IMPORT_PATIENT_FILE });
// /patient/bulk-assign

// ================== ASSIGN BULK PATIENTS MEMBERS ACTION  =========================
export const assignBulkPatientsFunc = (bulkData) => async (dispatch) => {
  try {
    dispatch({ type: ASSIGN_BULK_PATIENTS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient/bulk-assign`,
        bulkData,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ASSIGN_BULK_PATIENTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ASSIGN_BULK_PATIENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetAssignBulk = () => (dispatch) =>
  dispatch({ type: RESET_ASSIGN_BULK_PATIENTS });

// ================== IMPORT STATUS ACTION  =========================
export const checkImportStatusFunc = (importId) => async (dispatch) => {
  try {
    dispatch({ type: IMPORT_STATUS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/patient/check-import-status?import_id=${importId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: IMPORT_STATUS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: IMPORT_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetImportStatus = () => (dispatch) =>
  dispatch({ type: RESET_IMPORT_STATUS });

// ================== IMPORT BULK EVIDENCE ACTION  =========================
export const exportBulkEvidenceFunc = (bulkData) => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_BULK_EVIDENCE_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient/generate-bulk-eoc`,
        bulkData,
        config
      );
      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: EXPORT_BULK_EVIDENCE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXPORT_BULK_EVIDENCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetExportBulkEvidence = () => (dispatch) =>
  dispatch({ type: RESET_EXPORT_BULK_EVIDENCE });
