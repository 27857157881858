// import { configureStore } from "@reduxjs/toolkit";
// import logger from "redux-logger";
// import rootReducer from "../reducers/index";
import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import {
  addPasswordReducer,
  otpVerifyReducer,
  resendClientCredentialsReducer,
  updateForgottenPasswordReducer,
  userForgotPasswordReducer,
  userLoginReducer,
  userRegisterReducer,
} from "../reducers/Auth";
import {
  addNewClientReducer,
  addScanCodeReducer,
  agentUpdateProfileReducer,
  checkPatientEmailReducer,
  checkPatientPhoneReducer,
  createWorkspaceReducer,
  deleteAgentReducer,
  deleteClientReducer,
  getAgencyEmailReducer,
  getAgencyReducer,
  getAgentProfileReducer,
  getAgentsListReducer,
  getAllAgenciesReducer,
  getAllClientsReducer,
  getAllClientsUnpaginatedReducer,
  getClientDataReducer,
  getQrCodeReducer,
  getUnpaginatedAgentsListReducer,
  inviteAgentReducer,
  reshareAgencyCredsReducer,
  updateAgencyReducer,
  updateAgentTeamMembersReducer,
  updateClientReducer,
} from "../reducers/Agency";
import Cookies from "js-cookie";
import {
  addEducationSetupReducer,
  addEquipSetupReducer,
  addNewCaseReducer,
  addPatientDetailsReducer,
  addPatientPasswordReducer,
  addRecordConsentReducer,
  deleteCaseReducer,
  exportPatientListingsReducer,
  generateEvidenceReducer,
  getAllCasesReducer,
  getAllPatientsByAgencyReducer,
  getPatientByPatientIdReducer,
  getChartPainlogsReducer,
  getClientPainlogsReducer,
  getDashboardAlertsReducer,
  getDashboardAnalyticsReducer,
  getMedicineDataReducer,
  getNextPatientReducer,
  getPatientReportsReducer,
  getPieChartStatsReducer,
  getTabsCountReducer,
  getUnpaginatedPatientsReducer,
  getUnreadChatMessagesReducer,
  patientListingStateReducer,
  reEnrollPatientReducer,
  unEnrollPatientReducer,
  updateCaseReducer,
  getPinnedNotesReducer,
} from "../reducers/Patients";
import {
  addBulkNotesReducer,
  changedAgencyIdReducer,
  createCustomNotesReducer,
  disableAppAccessReducer,
  enableAppAccessReducer,
  getAlertNotificationsReducer,
  getAllNotificationsReducer,
  getBodyPartsReducer,
  getChatNotificationsReducer,
  getCurrentPatientIndexReducer,
  getMobileStatusReducer,
  getReviewedPatientsReducer,
  getUnreviewedPatientsReducer,
  getUserActivityNotificationsReducer,
  getUserFeedNotificationsReducer,
  isActionPerformedReducer,
  markAsReadNotificationsReducer,
  markPatientReviewedReducer,
  sendBulkMessagesReducer,
  sendInactiveEmailReminderReducer,
  sendPainLogReminderReducer,
  sendPendingEmailReminderReducer,
  setShowReviewedScreenReducer,
  timerReducer,
  trackingImportPatientsReducer,
  updateCustomNotesReducer,
} from "../reducers/AppConfig";
import {
  cancelBillingSubscriptionReducer,
  createBillingSubscriptionReducer,
  getBillingSubscriptionReducer,
  updateBillingSubscriptionReducer,
} from "../reducers/BillingSubscription";
import {
  getMedicinesReducer,
  getStepsCountReducer,
} from "../reducers/StepsCount";
import {
  createBulkTimeReducer,
  createTimeEntryReducer,
  deleteTimeEntryReducer,
  getNotesReducer,
  getTimeEntryReducer,
  updateTimeEntryReducer,
} from "../reducers/TimeEntry";
import {
  addDiagnosisCodeReducer,
  getDiagnosisOptionsReducer,
  removeDiagnosisCodeReducer,
} from "../reducers/DiagnosisReducers";
import {
  createOrganizationReducer,
  getBillBreakDownReducer,
  getBillInvoicesReducer,
  getCurrentBillReducer,
  getOrgAgentsReducer,
  getOrganizationReducer,
  getOrgPatientsCountReducer,
  getOrgPaymentDetailsReducer,
  getPaymentInvoiceReducer,
  updateOrganizationReducer,
} from "../reducers/OrganizationReducers";
import {
  addCptDiagnosticCodesReducer,
  exportReportReducer,
  getCptCodesReducer,
  initCptSetupReducer,
  removeCptDiagnosisCodeReducer,
  updateCptCodeReducer,
} from "../reducers/CptCodesReducers";
import {
  assignBulkPatientsReducer,
  checkImportStatusReducer,
  exportBulkEvidenceReducer,
  getImportPatientsListReducer,
  importPatientFileReducer,
  setImportIdReducer,
} from "../reducers/ImportFileReducers";
import {
  downloadAllReportReducer,
  getAllPayersReducer,
  getReportCptCodesListReducer,
  getReportsListReducer,
  getReportStatsReducer,
} from "../reducers/Reports";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userForgotPassword: userForgotPasswordReducer,
  otpVerify: otpVerifyReducer,
  createWorkspace: createWorkspaceReducer,
  createOrganization: createOrganizationReducer,
  getOrganization: getOrganizationReducer,
  updateOrganization: updateOrganizationReducer,
  getOrgPatientsCount: getOrgPatientsCountReducer,
  getOrgAgents: getOrgAgentsReducer,
  getCurrentMonthBill: getCurrentBillReducer,
  getBillBreakdown: getBillBreakDownReducer,
  getPaymentInvoice: getPaymentInvoiceReducer,
  getBillInvoices: getBillInvoicesReducer,
  getOrgPaymentDetails: getOrgPaymentDetailsReducer,
  changedAgencyId: changedAgencyIdReducer,
  updateForgotPassword: updateForgottenPasswordReducer,
  addPassword: addPasswordReducer,
  agentProfile: getAgentProfileReducer,
  getAgencyDetail: getAgencyReducer,
  updateAgency: updateAgencyReducer,
  getQrCodeImage: getQrCodeReducer,
  addScanCode: addScanCodeReducer,
  getAgencyEmail: getAgencyEmailReducer,
  addPatientDetails: addPatientDetailsReducer,
  addPatientPassword: addPatientPasswordReducer,
  reshareAgencyCreds: reshareAgencyCredsReducer,
  reEnrollPatient: reEnrollPatientReducer,
  getAllAgencies: getAllAgenciesReducer,
  agentUpdateProfile: agentUpdateProfileReducer,
  getAgentsList: getAgentsListReducer,
  getUnpaginatedAgentLists: getUnpaginatedAgentsListReducer,
  deleteAgent: deleteAgentReducer,
  trackImportPatients: trackingImportPatientsReducer,
  checkImportStatus: checkImportStatusReducer,
  importPatientFile: importPatientFileReducer,
  exportBulkEvidence: exportBulkEvidenceReducer,
  getImportPatients: getImportPatientsListReducer,
  inviteAgent: inviteAgentReducer,
  getUnreviewedPatients: getUnreviewedPatientsReducer,
  getReviewedPatients: getReviewedPatientsReducer,
  markPatientReviewed: markPatientReviewedReducer,
  patientListingState: patientListingStateReducer,
  getNextPatient: getNextPatientReducer,
  getImportId: setImportIdReducer,
  assignBulkMembers: assignBulkPatientsReducer,
  getCurrentPatientIndex: getCurrentPatientIndexReducer,
  isActionPerformedDone: isActionPerformedReducer,
  getShowReviewedScreen: setShowReviewedScreenReducer,
  updateAgentTeamMembers: updateAgentTeamMembersReducer,
  getAllClients: getAllClientsReducer,
  getAllClientsUnpaginated: getAllClientsUnpaginatedReducer,
  getClientData: getClientDataReducer,
  resendClientCredentials: resendClientCredentialsReducer,
  addNewClient: addNewClientReducer,
  updateClient: updateClientReducer,
  deleteClient: deleteClientReducer,
  getAllCases: getAllCasesReducer,
  getAllAgencyPatients: getAllPatientsByAgencyReducer,
  getAllUnpaginatedPatients: getUnpaginatedPatientsReducer,
  patientDetailByPatientId: getPatientByPatientIdReducer,
  addRecordConsent: addRecordConsentReducer,
  addEquipSetup: addEquipSetupReducer,
  addEducationSetup: addEducationSetupReducer,
  unEnrollPatient: unEnrollPatientReducer,
  getClientPainlogs: getClientPainlogsReducer,
  getChartPainLogs: getChartPainlogsReducer,
  getMedicineData: getMedicineDataReducer,
  addNewCase: addNewCaseReducer,
  updateCase: updateCaseReducer,
  deletedCase: deleteCaseReducer,
  dashboardAnalytics: getDashboardAnalyticsReducer,
  dashboardAlerts: getDashboardAlertsReducer,
  getUnreadChatMessages: getUnreadChatMessagesReducer,
  disableAppAccess: disableAppAccessReducer,
  enableAppAccess: enableAppAccessReducer,
  getUserFeedNotifications: getUserFeedNotificationsReducer,
  getAllNotifications: getAllNotificationsReducer,
  getChatNotifications: getChatNotificationsReducer,
  getAlertNotifications: getAlertNotificationsReducer,
  getUserActivityNotifications: getUserActivityNotificationsReducer,
  markedAsReadNotifications: markAsReadNotificationsReducer,
  getAllBodyParts: getBodyPartsReducer,
  getPieChartStats: getPieChartStatsReducer,
  getBillingSubscription: getBillingSubscriptionReducer,
  createBillingSubscription: createBillingSubscriptionReducer,
  updateBillingSubscription: updateBillingSubscriptionReducer,
  cancelBillingSubscription: cancelBillingSubscriptionReducer,
  sendPainLogReminder: sendPainLogReminderReducer,
  addBulkNotes: addBulkNotesReducer,
  getStepsCount: getStepsCountReducer,
  getMedicinesStats: getMedicinesReducer,
  timerModal: timerReducer,
  createTimeEntry: createTimeEntryReducer,
  createBulkTime: createBulkTimeReducer,
  sendBulkMessages: sendBulkMessagesReducer,
  getTimeEntry: getTimeEntryReducer,
  deleteTimeEntry: deleteTimeEntryReducer,
  updateTimeEntry: updateTimeEntryReducer,
  getDiagnosisOptions: getDiagnosisOptionsReducer,
  addDiagnosisCode: addDiagnosisCodeReducer,
  removeDiagnosisCode: removeDiagnosisCodeReducer,
  getNotes: getNotesReducer,
  initCptSetup: initCptSetupReducer,
  getCptCodes: getCptCodesReducer,
  updateCptCode: updateCptCodeReducer,
  removeCptDiagnosisCode: removeCptDiagnosisCodeReducer,
  addCptDiagnosticCodes: addCptDiagnosticCodesReducer,
  checkPatientEmail: checkPatientEmailReducer,
  checkPatientPhone: checkPatientPhoneReducer,
  exportReport: exportReportReducer,
  exportPatientListings: exportPatientListingsReducer,
  getPatientReports: getPatientReportsReducer,
  createCustomNotes: createCustomNotesReducer,
  updateCustomNotes: updateCustomNotesReducer,
  getMobileStatus: getMobileStatusReducer,
  sendInactiveEmailReminder: sendInactiveEmailReminderReducer,
  sendPendingEmailReminder: sendPendingEmailReminderReducer,
  generateEvidenceReport: generateEvidenceReducer,
  getTabsCount: getTabsCountReducer,
  getReportsList: getReportsListReducer,
  downloadAllReport: downloadAllReportReducer,
  getReportStats: getReportStatsReducer,
  getReportCptCodesList: getReportCptCodesListReducer,
  getPinnedNotes: getPinnedNotesReducer,
  getAllPayers:getAllPayersReducer
});

// const userInfoStorage = localStorage.getItem("userInfo")
//   ? JSON.parse(localStorage.getItem("userInfo"))
//   : null;

// const userToken = localStorage.getItem("agentToken")
//   ? JSON.parse(localStorage.getItem("agentToken"))
//   : null;

const token = Cookies.get("token") ?? null;
const agentId = Cookies.get("agent_id_vita") ?? null;
const agencyId = Cookies.get("agency_id_vita") ?? null;

const initialState = {
  userLogin: {
    userInfo: { token, agentId, agencyId },
    // userToken: userToken,
  },
};

const middlewares = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
