import { Alert, Stack } from "@mui/material";
import React from "react";
import AppContactDetailCard from "../AppContactDetailCard";
import AppMobileInfoCard from "../AppMobileInfoCard";

const InactiveIconContent = ({
  patientId,
  patientName,
  patientContactNumber,
  patientEmail,
  firstLogin,
  lastAppOpened,
  osVersion,
  appVersion,
  ever_pain_logged,
}) => {
  return (
    <Stack gap="24px">
      {ever_pain_logged === undefined || !!ever_pain_logged ? (
        <Alert severity="warning">
          {patientName} is inactive and hasn't logged pain in last 7 days.
          Please remind them to start logging pain.
        </Alert>
      ) : (
        <Alert severity="warning">
          {patientName} is enrolled & has logged in but never log their pain.
          Please remind them to start logging pain.
        </Alert>
      )}

      <AppMobileInfoCard
        firstLogin={firstLogin}
        lastAppOpened={lastAppOpened}
        appVersion={appVersion}
        osVersion={osVersion}
      />

      <AppContactDetailCard
        patientId={patientId}
        patientContactNumber={patientContactNumber}
        patientEmail={patientEmail}
        status="inactive"
      />
    </Stack>
  );
};

export default InactiveIconContent;
